import { Component, OnInit } from '@angular/core';
import { CampionariService } from 'app/services/campionari.service';

@Component({
  selector: 'app-campionari',
  templateUrl: './campionari.component.html',
  styleUrls: ['./campionari.component.sass']
})
export class CampionariComponent implements OnInit {

  campionari: any[] = [];
  nocampionari: boolean = false;

  constructor(private campionariService: CampionariService) { }

  ngOnInit(): void {
    this.getCampionari();
  }
  getCampionari(): void {
    this.nocampionari = false;
    this.campionariService.getCampionari()
    .subscribe(resp => {
      console.log(resp.data.length);
      this.campionari = resp.data;
      if (this.campionari.length == 0)
        this.nocampionari = true;
    });
  }

  addCampionario(): void {
    const form: FormData = new FormData(document.querySelector('#campionario') as HTMLFormElement);
    let dati: { [key: string]: any } = {
      id_stagione: Number(form.get('id_stagione')),
      id_brand: Number(form.get('id_brand')),
      attivo: form.get('attivo') != null ? true : false,
      data_apertura: form.get('data_apertura')+'T00:00:00.000Z',
      data_pubblicazione: form.get('data_pubblicazione')+'T00:00:00.000Z',
      data_chiusura: form.get('data_chiusura')+'T23:59:59.000Z',
    };
    console.log(dati);
    this.campionariService.createCampionario(dati)
    .subscribe(resp => {
      this.getCampionari();
      // @ts-expect-error
      $('#addCampionarioModal').modal('hide');
    },
    (error) => {
      console.log(error);
      alert(`Errore nell'associazione del cliente:` + error.toString())
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-layout',
  template: `
    <router-outlet></router-outlet>
  `,
  styles: [
  ]
})
export class LoginLayoutComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

}

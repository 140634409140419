<section>
  <div class="container-fluid">
    <div class="row row-eq-height height-100vh">
      <div class="col-md-8 p-0">
        <div class="login-form">
          <img src="assets/img/SellUp-Logo-Black.png" alt="" width="150"/>
          <form id="formRegistrazioneBrand">
            <!-- NOME ORGANIZZAZIONE -->
            <div class="form-group">
              <i class="fas fa-store"></i>
              <input type="text"
                     class="form-control "
                     [(ngModel)]="user.nome_organizzazione"
                     placeholder="Nome Organizzazione *"
                     [ngModelOptions]="{standalone: true}"
                     required autofocus>
            </div>

            <!-- END NOME ORGANIZZAZIONE -->

            <!-- NOME -->

            <div class="form-group">
              <i class="fas fa-user"></i>
              <input type="text"
                     class="form-control"
                     [(ngModel)]="user.nome"
                     placeholder="Nome *"
                     [ngModelOptions]="{standalone: true}"
                     required autofocus>
            </div>

            <!-- END NOME -->


            <!-- COGNOME -->

            <div class="form-group">
              <i class="fas fa-user-alt"></i>
              <input type="text"
                     class="form-control"
                     [(ngModel)]="user.cognome"
                     placeholder="Cognome *"
                     [ngModelOptions]="{standalone: true}"
                     required autofocus>
            </div>


            <!-- END COGNOME -->


            <!-- EMAIL -->

            <div class="form-group">
              <i class="fas fa-envelope"></i>
              <input type="email"
                     class="form-control"
                     placeholder="Email *"
                     [(ngModel)]="user.email"
                     [ngModelOptions]="{standalone: true}"
                     required autocomplete="email">
            </div>

            <!-- END EMAIL -->


            <!-- PASSWORD -->
            <div class="form-group">
              <i class="fas fa-lock"></i>
              <input type="password"
                     class="form-control"
                     min="5"
                     [(ngModel)]="user.password"
                     placeholder="Password *"
                     [ngModelOptions]="{standalone: true}"
                     required autocomplete="password">
            </div>


            <!-- END PASSWORD -->


            <!-- TELEFONO -->

            <div class="form-group">
              <i class="fas fa-phone"></i>
              <input type="phone"
                     class="form-control"
                     [(ngModel)]="user.telefono"
                     placeholder="Telefono"
                     [ngModelOptions]="{standalone: true}"
                     required autofocus>
            </div>

            <!-- END TELEFONO -->


            <div class="row mb-4">
              <div class="col-md-12">

                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" name="privacy" [(ngModel)]="accept"
                         [ngModelOptions]="{standalone: true}"
                         id="privacy">
                  <label class="custom-control-label" id="label-privacy" for="privacy"
                         style="display: block;height: inherit;">
                    <small>Registrandomi dichiaro di aver preso visione e accettato <a
                      class="text-giallo" href="/term-and-conditions"
                      target="_blank" style="text-decoration:underline">"Termini e
                      Condizioni"</a> (ivi compresa la Privacy Policy) riportate sul
                      sito</small>
                  </label>
                </div>

              </div>

            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="g-recaptcha" data-sitekey=""></div>
              </div>
            </div>
            <div class="text-center">
              <button type="button" id="registrazioneButton" [disabled]="accept != true" (click)="register()"
                      class="btn my-4 accedi">Registrati
              </button>
              <hr>
              <a href="/login">Torna al Login</a>
            </div>
          </form>
        </div>
      </div>
      <div class="col-md-4 p-0 xs-display-none">
        <div class="img-intro" style="background: url('assets/img/brand-reg.jpeg ');filter: grayscale(1);">
          <div class="intro-login-text">
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

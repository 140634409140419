<div class="row">
  <div class="col-md-12">
    <div class="card card-colored classe-retailer">
      <h1 class="text-uppercase" style="margin-bottom: 0; padding-bottom: 0;">
        Carrello - MARKETPLACE Retailer</h1>
      <h2 class="prezzo-totale">
        Totale: <span class="prezzo_totale">366,00€</span></h2>

      <div class="container-box row">
        <div class="col-md-12">
          <div class="box height-box riepilogo-container">

            <h3 class="text-uppercase">1 articolo nel carrello</h3>
            <div class="">
              <button type="button" class="btn btn1 aggiorna">AGGIORNA
                CARRELLO
              </button>
            </div>
            <div class="r-cart">
              <div class="table-responsive">
                <table id="table" class="table table-hover text-left">
                  <thead>
                  <tr>
                    <th>Articolo</th>
                    <th>Prezzo</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="m-0">
                    <td data-title="Articolo">
                      <a
                        href="https://r2w-media-dev.fra1.cdn.digitaloceanspaces.com/articoli/56/6329721f6a0c7.JPG"
                        data-lightbox="image-1" data-title="">
                        <img id="img-1470" width="50"
                             src="https://r2w-media-dev.fra1.cdn.digitaloceanspaces.com/articoli/56/6329721f6a0c7.JPG"
                             class="img-carr">
                      </a>
                      Derby<br>
                      Cod.: 60000
                    </td>
                    <td data-title="Prezzo">122,00€</td>
                    <td><i class="fa fa-trash cestino-carrello float-right"></i></td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                  <tr>
                    <th>39</th>
                    <th>39½</th>
                    <th>40</th>
                    <th>40½</th>
                    <th>41</th>
                    <th>41½</th>
                    <th>42</th>
                    <th>42½</th>
                    <th>43</th>
                    <th>43½</th>
                    <th>44</th>
                    <th>44½</th>
                    <th>45</th>
                    <th>45½</th>
                    <th>46</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr style="background: #f4f5f8;">
                    <td>
                      <select>
                        <option value=""></option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                    </td>
                    <td>
                      <select>
                        <option value=""></option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                    </td>
                    <td>
                      <select>
                        <option value=""></option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                    </td>
                    <td>
                      <select>
                        <option value=""></option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                    </td>
                    <td>
                      <select>
                        <option value=""></option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                    </td>
                    <td>
                      <select>
                        <option value=""></option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                    </td>
                    <td>
                      <select>
                        <option value=""></option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                    </td>
                    <td>
                      <select>
                        <option value=""></option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                    </td>
                    <td>
                      <select>
                        <option value=""></option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                    </td>
                    <td>
                      <select>
                        <option value=""></option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                    </td>
                    <td>
                      <select>
                        <option value=""></option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                    </td>
                    <td>
                      <select>
                        <option value=""></option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                    </td>
                    <td>
                      <select>
                        <option value=""></option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                    </td>
                    <td>
                      <select>
                        <option value=""></option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                    </td>
                    <td>
                      <select>
                        <option value=""></option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 float-right">
              <button class="btn btn-cart float-right">Pagamento</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="agente">
  <div class="col-md-12">
    <div class="card card-colored card-retailer">
      <h1 class="text-uppercase text-center">Dati agente - ID {{agente.id}}</h1>
      <div class="container-box row justify-content-center">
        <div class="col-md-12">
            <div class="box utente-img CBox">
             <div class="row">
               <div class="col-md-10">
                 <div class="imgClienti">
                   <div class="img" style="width: 200px;">
                     <img loading="lazy" class="imgProfilo" src="https://picsum.photos/140/?random={{agente.id}}">
                   </div>
                 </div>
               </div>
               <div class="col-md-2">
                 <button class="btn btn-outline-dark" data-toggle="modal" data-target="#anagraficaModal">Modifica</button>
               </div>
             </div>

              <div class="values clientiV">
                <h2>ANAGRAFICA</h2>
                <p><span>Nome:</span> {{agente.nome}} {{agente.cognome}}</p>
                <p><span>Nome showroom:</span> {{agente.nome_showroom}}</p>
                <p><span>Indirizzo showroom:</span> {{agente.indirizzo_showroom}}</p>
                <p><span>Email:</span> {{agente.email}}</p>
                <p><span>Telefono:</span> {{agente.telefono}}</p>
              </div>
              <div class="values clientiV">
                <h2>FATTURAZIONE</h2>
                <p><span>Ragione sociale:</span> {{agente.ragione_sociale}}</p>
                <p><span>P.Iva:</span> {{agente.partita_iva}}</p>
                <p><span>Codice fiscale:</span> {{agente.codice_fiscale}}</p>
                <p><span>Sede Legale:</span> {{agente.sede_legale_indirizzo}}</p>
                <p><span>Pec:</span> {{agente.pec}}</p>
              </div>
            </div>
        </div>
        <div class="col-md-12">
          <div class="box">
            <div class="titlePA">
              <div class="form-group">
                <label class="form-label">Seleziona brand:</label>
                <select class="form-control" id="brands" (change)="selectBrand($event)">
                  <option *ngFor="let brand of agente.brand_agente" value="{{brand.id_brand}}">Brand {{brand.id_brand}}</option>
                </select>
              </div>
              <div class="button-flex">
                <button class="btn btn1" data-toggle="modal" data-target="#provvigioneModal">Modifica</button>
                <button class="btn btn2" data-toggle="modal" data-target="#addBrandModal">Aggiungi</button>
                <button class="btn btn3" data-toggle="modal" (click)="deleteBrand()">Rimuovi</button>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="values clientiV">
                  <h2>PROVVIGIONI E AREE DI COMPETENZA</h2>
                  <p><span>Brand:</span> {{brand_selezionato.id_brand}} </p>
                  <p><span>Provvigione:</span> {{brand_selezionato.provvigione}}%</p>
                  <p><span>Area di competenza:</span> {{brand_selezionato.area_competenza}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="box utente-img">
            <div class="titlePA">
              <h2 class="align-items-center">CLIENTI</h2>
              <div class="button-flex" style="width: 60%">
                <button title="" type="button" class="btn btn1" data-toggle="modal" data-target="#addClienteModal" >
                  <i class="fas fa-add"></i> Aggiungi
                </button>
                <button type="button" title="" class="btn btn2" (click)="deselectAll()">
                  <i class="fas fa-times"></i> Resetta
                </button>
                <button type="submit" title="" class="btn btn3" (click)="filter()">
                  <i class="fas fa-search"></i> Cerca
                </button>
              </div>
            </div>

            <form id="filters" action="/" method="get" (submit)="filter()">
              <div class="row">
                <div class="col-md-4 pr-0">
                  <input class="form-control" type="text" name="cliente.ragione_sociale" value="" placeholder="Ragione sociale">
                </div>
                <div class="col-md-4 pr-0">
                  <input class="form-control" type="text" name="cliente.partita_iva" value="" placeholder="Partita iva">
                </div>
                <div class="col-md-4 pr-0">
                  <select id="brand" multiple="multiple" required="" name="brand.id" data-live-search="true" class="form-control select2-brand" tabindex="null">
                    <option *ngFor="let brand of brands_filter" value="{{brand.id}}">{{brand.nome}}</option>
                  </select>
                </div>
              </div>
              <div class="row mt-2">


              </div>
            </form>
            <table class="table table-mc-light-blue mt-4">
              <thead>
                <tr>
                  <th scope="col">Ragione Sociale</th>
                  <th scope="col">P.Iva</th>
                  <th scope="col">Brand</th>
                  <th scope="col">Provvigione</th>
                  <th scope="col">Inizio</th>
                  <th scope="col">Fine</th>
                  <th scope="col">Modifica</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let cliente of clienti_brand">
                  <td scope="col">{{cliente.cliente.ragione_sociale}}</td>
                  <td scope="col">{{cliente.cliente.partita_iva}}</td>
                  <td scope="col">{{cliente.brand.nome}}</td>
                  <td scope="col">{{cliente.provvigione}}%</td>
                  <td scope="col">{{cliente.data_inizio}}</td>
                  <td scope="col">{{cliente.data_fine}}</td>
                  <td scope="col">
                    <div class="btn-group">
                      <a class="btn btn1" (click)="selectCliente(cliente.id)">Modifica</a>
                      <a class="btn btn2" (click)="deleteCliente(cliente.id)">Rimuovi</a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-md-12">
          <div class="box utente-img">
            <h2>PROVVIGIONI</h2>
            <table class="table table-mc-light-blue">
              <thead>
                <tr>
                  <th scope="col">Brand</th>
                  <th scope="col">Stagione</th>
                  <th scope="col">Valore Ordini</th>
                  <th scope="col">Provvigioni maturate</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let brand of agente.brand_agente">
                  <td scope="col">Brand {{brand.id}}</td>
                  <td scope="col">Stagione 1</td>
                  <td scope="col">{{brand.id * 1000}} EURO</td>
                  <td scope="col">{{brand.id * 50}} EURO</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="agente && agenteForm" class="modal fade" id="anagraficaModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <form [formGroup]="agenteForm" (submit)="update()">
        <div class="modal-header">
          <h5 class="modal-title">Anagrafica e fatturazione</h5>
        </div>
        <div class="modal-body">
          <h4 class="modal4">Anagrafica</h4>
          <div class="form-group">
            <label for="nome" class="form-label">Nome e cognome</label>
            <div class="flex-row d-flex" style="gap: 10px">
              <input type="text" class="form-control" id="nome" formControlName="nome" value="{{agente.nome}}" placeholder="Nome">
              <input type="text" class="form-control" id="cognome" formControlName="cognome" value="{{agente.cognome}}" placeholder="Cognome">

            </div>

          </div>
          <div class="form-group">
            <label for="indirizzo" class="form-label">Nome showroom</label>
            <input type="text" class="form-control" id="nome_showroom" formControlName="nome_showroom" value="{{agente.nome_showroom}}">
          </div>
          <div class="form-group">
            <label for="indirizzo" class="form-label">Indirizzo showroom</label>
            <input type="text" class="form-control" id="indirizzo_showroom" formControlName="indirizzo_showroom" value="{{agente.indirizzo_showroom}}">
          </div>
          <div class="form-group">
            <label for="email" class="form-label">Email</label>
            <input type="email" class="form-control" id="email" formControlName="email" value="{{agente.email}}">
          </div>
          <div class="form-group">
            <label for="telefono" class="form-label">Telefono</label>
            <input type="text" class="form-control" id="telefono" formControlName="telefono" value="{{agente.telefono}}">
          </div>
          <h4 class="modal4">Fatturazione</h4>
          <div class="form-group">
            <label for="ragione_sociale" class="form-label">Ragione sociale</label>
            <input type="text" class="form-control" id="ragione_sociale" formControlName="ragione_sociale" value="{{agente.ragione_sociale}}">
          </div>
          <div class="form-group">
            <label for="partita_iva" class="form-label">Partita iva</label>
            <input type="text" class="form-control" id="partita_iva" formControlName="partita_iva" value="{{agente.partita_iva}}">
          </div>
          <div class="form-group">
            <label for="codice_fiscale" class="form-label">Codice Fiscale</label>
            <input type="text" class="form-control" id="partita_iva" formControlName="codice_fiscale" value="{{agente.codice_fiscale}}">
          </div>
          <div class="form-group">
            <label for="indirizzo" class="form-label">Sede legale</label>
            <input type="text" class="form-control" id="nome_showroom" formControlName="nome_showroom" value="{{agente.sede_legale_indirizzo}}">
          </div>
          <div class="form-group">
            <label for="email" class="form-label">Pec</label>
            <input type="email" class="form-control" id="pec" formControlName="pec" value="{{agente.pec}}">
          </div>
        </div>
        <div class="modal-footer">
          <div class="button-flex" style="width: 100%;">
            <button type="button" class="btn btn1" data-dismiss="modal">Chiudi</button>
            <button type="button" class="btn btn2" type="submit">Salva</button>
          </div>

        </div>
      </form>
    </div>
  </div>
</div>
<div *ngIf="agente" class="modal fade" id="provvigioneModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Provvigioni e aree di competenza</h5>
      </div>
      <div class="modal-body">
        <form id="brand_agente">
          <h4 class="modal4">Brand {{brand_selezionato.id_brand}}</h4>
          <div class="form-group">
            <label for="area_competenza" class="form-label">Area competenza</label>
            <input type="text" class="form-control" id="area_competenza" name="area_competenza" value="{{brand_selezionato.area_competenza}}">
          </div>
          <div class="form-group">
            <label for="email" class="col-sm-2 col-form-label">Provvigione</label>
            <div class="input-group mb-1">
              <input type="number" class="form-control" id="provvigione" name="provvigione" value="{{brand_selezionato.provvigione}}">
              <div class="input-group-append">
                <span class="input-group-text">%</span>
              </div>
            </div>
          </div>
        </form>
          <div class="button-flex" style="width: 100%;">
            <button type="button" class="btn btn1" data-dismiss="modal">Chiudi</button>
            <button type="button" class="btn btn2" (click)="updateBrand()">Salva</button>
          </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="agente" class="modal fade" id="addBrandModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Aggiungi provvigioni e aree di competenza</h5>
      </div>
      <div class="modal-body">
        <form id="brand_agente_add">
          <div class="form-group">
            <label for="id_brand" class="form-label">Brand</label>
            <select class="form-control" id="id_brand" name="id_brand">
              <option value="23">Chiara Ferragni Collection</option>
              <option value="24">ACBC</option>
              <option value="25">Alexander Hotto</option>
            </select>
          </div>
          <div class="form-group">
            <label for="area_competenza" class="form-label">Area competenza</label>
            <input type="text" class="form-control" id="area_competenza" name="area_competenza" value="">
          </div>
          <div class="form-group">
            <label for="email" class="col-sm-2 col-form-label">Provvigione</label>
            <div class="input-group mb-1">
              <input type="number" class="form-control" id="provvigione" name="provvigione" value="0">
              <div class="input-group-append">
                <span class="input-group-text">%</span>
              </div>
            </div>
          </div>
        </form>
        <div class="button-flex" style="width: 100%;">
          <button type="button" class="btn btn1" data-dismiss="modal">Chiudi</button>
          <button type="button" class="btn btn2" (click)="addBrand()">Salva</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="agente && cliente_selezionato" class="modal fade" id="clienteModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Modifica cliente - {{cliente_selezionato.cliente.nome}}</h5>
      </div>
      <div class="modal-body">
        <form id="cliente_agente">
          <div class="form-group">
            <label for="provvigione" class="form-label">Provvigione</label>
            <div class="input-group mb-1">
              <input type="number" class="form-control" id="provvigione" name="provvigione" value="{{cliente_selezionato.provvigione}}">
              <div class="input-group-append">
                <span class="input-group-text">%</span>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="data_inizio" class="form-label">Data inizio</label>
            <input type="date" class="form-control" id="data_inizio" name="data_inizio" value="{{cliente_selezionato.data_inizio}}">
          </div>
          <div class="form-group">
            <label for="data_fine" class="form-label">Data fine</label>
            <input type="date" class="form-control" id="data_fine" name="data_fine" value="{{cliente_selezionato.data_fine}}">
          </div>
        </form>
        <div class="button-flex" style="width: 100%">
          <button type="button" class="btn btn1" data-dismiss="modal">Chiudi</button>
          <button type="button" class="btn btn2" (click)="updateCliente()">Salva</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="agente && clienti" class="modal fade" id="addClienteModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Associa cliente</h5>
      </div>
      <div class="modal-body">
        <form id="cliente_agente_add">
          <div class="form-group">
            <label for="id_cliente" class="form-label">Cliente</label>
            <select class="form-control" id="id_cliente" name="id_cliente">
              <option *ngFor="let cliente of clienti" value="{{cliente.id}}">{{cliente.nome}} - {{cliente.ragione_sociale}}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="id_brand" class="form-label">Cliente</label>
            <select class="form-control" id="id_brand" name="id_brand">
              <option value="23">Chiara Ferragni Collection</option>
              <option value="24">ACBC</option>
              <option value="25">Alexander Hotto</option>
            </select>
          </div>
          <div class="form-group">
            <label for="email" class="col-sm-2 col-form-label">Provvigione</label>
            <div class="input-group mb-1">
              <input type="number" class="form-control" id="provvigione" name="provvigione" value="0">
              <div class="input-group-append">
                <span class="input-group-text">%</span>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="data_inizio" class="form-label">Data inizio</label>
            <input type="date" class="form-control" id="data_inizio" name="data_inizio" value="">
          </div>
          <div class="form-group">
            <label for="data_fine" class="form-label">Data fine</label>
            <input type="date" class="form-control" id="data_fine" name="data_fine" value="">
          </div>
        </form>
        <div class="button-flex" style="width: 100%;">
          <button type="button" class="btn btn1" data-dismiss="modal">Chiudi</button>
          <button type="button" class="btn btn2" (click)="addCliente()">Salva</button>
        </div>
      </div>
    </div>
  </div>
</div>



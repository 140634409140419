<section>
  <div class="container-fluid">
    <div class="row row-eq-height height-100vh">
      <div class="col-md-8 p-0">
        <div class="login-form">
          <img src="assets/img/SellUp-Logo-Black.png" alt="" width="150"/>
          <form id="formRegistrazioneRetailer">
            <!-- NOME NEGOZIO -->
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <i class="fas fa-store"></i>
                  <input type="text"
                         class="form-control errore-input"
                         name="nome_negozio"
                         [(ngModel)]="user.nome_negozio"
                         [ngModelOptions]="{standalone: true}"
                         placeholder="Nome Negozio *"
                         required autofocus>
                </div>
              </div>


              <!-- END NOME NEGOZIO -->


              <!-- NOME -->
              <div class="col-md-6">
                <div class="form-group">
                  <i class="fas fa-user"></i>
                  <input type="text"
                         class="form-control"
                         name="nome"
                         [(ngModel)]="user.nome"
                         [ngModelOptions]="{standalone: true}"
                         placeholder="Nome *"
                         required autofocus>
                </div>

              </div>
              <!-- END NOME -->


              <!-- COGNOME -->
              <div class="col-md-6">
                <div class="form-group">
                  <i class="fas fa-users"></i>
                  <input type="text"
                         class="form-control"
                         name="cognome"
                         [(ngModel)]="user.cognome"
                         [ngModelOptions]="{standalone: true}"
                         placeholder="Cognome *"
                         required autofocus>
                </div>

              </div>

              <!-- END COGNOME -->


              <!-- EMAIL -->
              <div class="col-md-6">
                <div class="form-group">
                  <i class="fas fa-envelope"></i>
                  <input type="email"
                         class="form-control"
                         name="email"
                         placeholder="Email *"
                         [(ngModel)]="user.email"
                         [ngModelOptions]="{standalone: true}"
                         required autocomplete="email">
                </div>
              </div>

              <!-- END EMAIL -->


              <!-- PASSWORD -->
              <div class="col-md-6">
                <div class="form-group">
                  <i class="fas fa-lock"></i>
                  <input type="password"
                         class="form-control"
                         min="5"
                         [(ngModel)]="user.password"
                         [ngModelOptions]="{standalone: true}"
                         placeholder="Password *"
                         required autocomplete="password">
                </div>
              </div>


              <!-- END PASSWORD -->


              <!-- TELEFONO -->
              <div class="col-md-6">
                <div class="form-group">
                  <i class="fas fa-phone"></i>
                  <input type="phone"
                         class="form-control"
                         name="telefono"
                         [(ngModel)]="user.telefono"
                         [ngModelOptions]="{standalone: true}"
                         placeholder="Telefono"
                         required autofocus>
                </div>

              </div>
              <!-- END TELEFONO -->


              <!-- INDIRIZZO DELLO STORE -->
              <div class="col-md-6">
                <div class="form-group">
                  <i class="fas fa-map-marker"></i>
                  <input
                    id="google_maps_magazzino"
                    type="text"
                    class="form-control"
                    name="indirizzo_negozio"
                    [(ngModel)]="user.indirizzo_negozio"
                    [ngModelOptions]="{standalone: true}"
                    placeholder="Indirizzo dello Store *"
                    selector="retailer"
                    required autofocus>
                </div>
              </div>


            </div>

            <div class="row">
              <div class="col-md-12">

                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" name="privacy" [(ngModel)]="accept"
                         [ngModelOptions]="{standalone: true}"
                         id="privacy">
                  <label class="custom-control-label" id="label-privacy" for="privacy"
                         style="display: block;height: inherit;">
                    <small>
                      Registrandomi dichiaro di aver preso visione e accettato <a
                      class="text-giallo" href="/term-and-conditions"
                      target="_blank" style="text-decoration:underline">"Termini e
                      Condizioni"</a> (ivi compresa la Privacy Policy) riportate sul
                      sito</small>
                  </label>
                </div>

              </div>

            </div>


            <div class="row" style="margin-top:2%">
              <div class="col-md-2">
                <div class="g-recaptcha"
                     data-sitekey=""></div>
              </div>
            </div>


            <div class="text-center">
              <button type="button" id="registrazioneButton"
                      [disabled]="accept != true" (click)="register()"
                      class="btn my-4 accedi">Registrati
              </button>
              <hr>
              <a href="/login">Torna al Login</a>
            </div>


          </form>

        </div>
      </div>
      <div class="col-md-4 p-0 xs-display-none">
        <div class="img-intro" style="background: url('assets/img/sign-up-retailer.jpg'); filter: grayscale(1); background-position: center !important">
          <div class="intro-login-text">
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

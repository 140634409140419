import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { RedirectGuard } from './auth/guards/redirect.guard';
import { LoginComponent } from './pages/login/login.component';
import { LoggedInGuard } from './auth/guards/logged-in.guard';
import { CampionariComponent } from './pages/campionari/campionari.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { SignUpBrandComponent } from './pages/brand/sign-up-brand/sign-up-brand.component';
import { ListRetailerBrandComponent } from './pages/brand/list-retailer-brand/list-retailer-brand.component';
import { SignUpRetailerComponent } from './pages/retailer/sign-up-retailer/sign-up-retailer.component';
import { ListBrandRetailerComponent } from './pages/retailer/list-brand-retailer/list-brand-retailer.component';
import { ListOrdersComponent } from './pages/order/list-orders/list-orders.component';
import { DetailArticle } from './models/detailArticle';
import { DetailOrderComponent } from './pages/order/detail-order/detail-order.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { CampionariDetailComponent } from './pages/campionari-detail/campionari-detail.component';
import { ClientiComponent } from './pages/clienti/clienti.component';
import { ClientiDetailComponent } from './pages/clienti-detail/clienti-detail.component';
import { AgentiComponent } from './pages/agenti/agenti.component';
import { AgentiDetailComponent } from './pages/agenti-detail/agenti-detail.component';
import { ListListiniComponent } from './pages/listini/list-listini/list-listini.component';

import { ListArticlesComponent } from './pages/list-articles/list-articles.component';
import { StoreGeneralComponent } from './pages/store-general/store-general.component';
import { MarketPlaceArticlesComponent } from './pages/market-place-articles/market-place-articles.component';
import { DataArticleComponent } from './pages/data-article/data-article.component';
import { MarketPlaceComponent } from './pages/market-place/market-place.component';
import { PreordiniComponent } from './pages/preordini/preordini.component';
import { PreordiniDetailComponent } from './pages/preordini-detail/preordini-detail.component';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout.component';
import { BaseLayoutComponent } from './layouts/base-layout/base-layout.component';
import {MovimentiMagazzinoComponent} from "./pages/movimenti-magazzino/movimenti-magazzino.component";
import {CreaMovimentoComponent} from "./pages/crea-movimento/crea-movimento.component";
import {DettaglioMovimentoComponent} from "./pages/dettaglio-movimento/dettaglio-movimento.component";
import { CreateArticleComponent } from './pages/create_article/create-article.component';
import { DetailArticleComponent } from './pages/detail-article/detail-article.component';
import { MarketplaceBrandComponent } from "./pages/marketplace-brand/marketplace-brand.component";
import { MarketplaceRetailerComponent } from "./pages/marketplace-retailer/marketplace-retailer.component";
import { CheckoutBrandComponent } from "./pages/checkout-brand/checkout-brand.component";
import { DetailListiniComponent } from './pages/listini/detail-listini/detail-listini.component';
import {CheckoutRetailerComponent} from "./pages/checkout-retailer/checkout-retailer.component";
import {ThankyouPageComponent} from "./pages/thankyou-page/thankyou-page.component";
import { TestComponent } from './pages/test/test.component';

const routes: Routes = [
  { path: "", component: LoginLayoutComponent, canActivate: [RedirectGuard], children: [
    { path: "", component: LoginComponent },
    { path: "sign-up", component: SignUpComponent},
    { path: "sign-up-brand", component: SignUpBrandComponent},
    { path: "sign-up-retailer", component: SignUpRetailerComponent},
  ]},
  { path: "", component: BaseLayoutComponent, canActivate: [LoggedInGuard], children: [
    { path: "dashboard", component: DashboardComponent },

    { path: "profile", component: ProfileComponent, canActivate: [LoggedInGuard]},
    { path: "retailers-list", component: ListRetailerBrandComponent, canActivate: [LoggedInGuard]},

    { path: "store", component: StoreGeneralComponent, canActivate: [LoggedInGuard]},
    { path: "brands-list", component: ListBrandRetailerComponent, canActivate: [LoggedInGuard]},
    { path: "retailer-list", component: ListRetailerBrandComponent, canActivate: [LoggedInGuard]},

    { path: "orders-list", component: ListOrdersComponent, canActivate: [LoggedInGuard]},
    { path: "order-detail/:id", component: DetailOrderComponent, canActivate: [LoggedInGuard]},

    { path: "article-detail", component: DetailArticleComponent, canActivate: [LoggedInGuard]},
    { path: "article-create", component: CreateArticleComponent, canActivate: [LoggedInGuard]},


    { path: "market-place", component: MarketPlaceComponent, canActivate: [LoggedInGuard]},
    { path: "market-place-article/:id", component: DetailOrderComponent, canActivate: [LoggedInGuard]},

    { path: "campionari", component: CampionariComponent, canActivate: [LoggedInGuard]},
    { path: "campionari/:id", component: CampionariDetailComponent, canActivate: [LoggedInGuard]},
    { path: "clienti", component: ClientiComponent, canActivate: [LoggedInGuard]},
    { path: "clienti/:id", component: ClientiDetailComponent, canActivate: [LoggedInGuard]},
    { path: "agenti", component: AgentiComponent, canActivate: [LoggedInGuard]},
    { path: "agenti/:id", component: AgentiDetailComponent, canActivate: [LoggedInGuard]},
    { path: "preordini", component: PreordiniComponent, canActivate: [LoggedInGuard]},
    { path: "preordini/:id", component: PreordiniDetailComponent, canActivate: [LoggedInGuard]},
    { path: "preordini/nuovo", component: PreordiniDetailComponent, canActivate: [LoggedInGuard]},
    { path: "movimenti-magazzino", component: MovimentiMagazzinoComponent, canActivate: [LoggedInGuard]},
    { path: "crea-movimento", component: CreaMovimentoComponent, canActivate: [LoggedInGuard]},
    { path: "dettaglio-movimento", component: DettaglioMovimentoComponent, canActivate: [LoggedInGuard]},
    { path: "marketplace-brand", component: MarketplaceBrandComponent, canActivate: [LoggedInGuard]},
    { path: "marketplace-retailer", component: MarketplaceRetailerComponent, canActivate: [LoggedInGuard]},
    { path: "checkout-brand", component: CheckoutBrandComponent, canActivate: [LoggedInGuard]},
    { path: "listini-list", component: ListListiniComponent, canActivate: [LoggedInGuard]},
    { path: "listini-detail/:id", component: DetailListiniComponent, canActivate: [LoggedInGuard]},
    { path: "checkout-retailer", component: CheckoutRetailerComponent, canActivate: [LoggedInGuard]},
    { path: "thankyou-page", component: ThankyouPageComponent, canActivate: [LoggedInGuard]},
    { path: "test-page", component: TestComponent, canActivate: [LoggedInGuard]},
  ]},

  { path: "**", redirectTo: ""}
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'app/auth/auth.service';
import { ArticoloService } from 'app/services/articolo.service';

@Component({
  selector: 'app-data-article',
  templateUrl: './data-article.component.html',
  styleUrls: ['./data-article.component.sass']
})
export class DataArticleComponent implements OnInit {
  roleUserLogged :string |undefined;
  public article : any = {};
  public modalIsVisible :boolean = false;

  constructor(private route: ActivatedRoute, private auth: AuthService, private articoloService: ArticoloService) {
    this.modalIsVisible = true;

  }

  ngOnInit(): void {
    const user = this.auth.getCurrentUser();
    this.roleUserLogged = user?.tipo;

      this.route.queryParams
      .subscribe(params => {
        let  id_articolo = params.id_articolo;
        let  id_tessuto = params.id_tessuto;
        let  id_colore = params.id_colore;
        this.articoloService.getDetail(id_articolo, id_tessuto, id_colore).then((resp :any)=> {
          console.log(`promise result: ${resp['data']}`);
          this.article = resp['data'];
          this.modalIsVisible = false;
        });
      }
    );
  }

  modaleModificaDescrizioni(){}

  eliminaArticolo(){}

  loadModaleModificaDatiGenerali(){}

  abilitaModifica(){}


}

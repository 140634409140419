<div class="upFile" (dragover)="handleDragOver($event)" (drop)="handleDrop($event)">
    <div class="file-upload" (click)="openFileSelector()">
        <h2 *ngIf="files.length == 0">Upload File</h2>
        <div *ngFor="let file of files; let index = index" class="file-item">
            <div *ngIf="isImage(file)" class="thumbnail">
                <img [src]="getFilePreview(file)" alt="Thumbnail" />
            </div>
            <span>{{ file.name }}</span>
            <button (click)="removeFile(index,$event)">Remove</button>
        </div>
        <input type="file" #fileUploader (change)="handleFileChange($event)" style="display: none" multiple>
    </div>
    <button class="upAll" (click)="uploadAllFiles()">Upload All</button>
</div>

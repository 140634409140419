export class User {
    static clone(obj: User) {
      return JSON.parse(JSON.stringify(obj));
    }
  
    id: number | undefined;
    nome_organizzazione?: string;
    nome_negozio?: string;
    nome: string | undefined;
    cognome: string | undefined;
    telefono?: string;
    indirizzo_negozio?: string;
    email: string | undefined;
    password: string | undefined;
  }
  
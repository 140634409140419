  <section>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div *ngIf="message != ''" class="card" [class]="messageclass">
            <div class="card-body text-white p-0">
              {{message}}
            </div>
          </div>
          <div *ngIf="loading">
            <div class="modal-dialog modal-sm">
              <div class="modal-content">
                <div class="modal-body" style="text-align: center;">
                  <div class="loader" style="margin-left: 30%;"></div>
                  <br>
                  <h5>Caricamento dati in corso...</h5>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!loading" class="card card-colored overflow-hidden classe-retailer">
            <h1 class="text-uppercase text-white border-bottom-ab">Modifica articolo - {{article.nome}}</h1>
            <div class="container-box">
              <div class="box">
                <div class="wrapper row">
                  <div class="preview col-md-5">
                    <div class="main-img">
                        <a href="#"
                          data-lightbox="image-1" data-title="">
                          <img src="/img/placeholder-articolo.jpg">
                      </a>
                    </div>
                  </div>
                  <div class="details col-md-7 classe-retailer">
                    <div class="form-group row">
                      <label for="brand" class="col-sm-2 col-form-label"><b>Brand:</b></label>
                      <div class="col-sm-10">
                        <select class="form-control form-control-sm" id="brand" [(ngModel)]="article.id_brand" disabled>
                          <option [value]="article.id_brand">{{article.brandNome}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="codice_articolo" class="col-sm-2 col-form-label"><b>Codice Articolo:</b></label>
                      <div class="col-sm-10">
                        <input type="text" class="form-control form-control-sm" id="codice_articolo" [(ngModel)]="article.codice_articolo" />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="nome_articolo" class="col-sm-2 col-form-label"><b>Nome:</b></label>
                      <div class="col-sm-10">
                        <input type="text" class="form-control form-control-sm" id="nome_articolo" [(ngModel)]="article.nome_articolo" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 mt-4 dettagli-arti classe-retailer">
                    <div class="form-group row">
                      <label for="stagione" class="col-sm-2 col-form-label"><b>Stagione:</b></label>
                      <div class="col-sm-6">
                        <select class="form-control form-control-sm" id="stagione" [(ngModel)]="article.id_stagione">
                          <option *ngFor="let stagione of filters.stagioni" [value]="stagione.id">{{stagione.nome}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="collezione" class="col-sm-2 col-form-label"><b>Collezione:</b></label>
                      <div class="col-sm-6">
                        <select class="form-control form-control-sm" id="collezione" [(ngModel)]="article.id_collezione">
                          <option *ngFor="let collezione of filters.collezioni" [value]="collezione.id">{{collezione.nome}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="settore" class="col-sm-2 col-form-label"><b>Settore:</b></label>
                      <div class="col-sm-6">
                        <select class="form-control form-control-sm" id="settore" [(ngModel)]="article.id_settore">
                          <option *ngFor="let settore of filters.settori" [value]="settore.id">{{settore.nome}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="categoria" class="col-sm-2 col-form-label"><b>Categoria:</b></label>
                      <div class="col-sm-6">
                        <select class="form-control form-control-sm" id="categoria" [(ngModel)]="article.id_categoria">
                          <option *ngFor="let categoria of filters.categorie" [value]="categoria.id">{{categoria.nome}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12" style="text-align:center">
                        <button data-toggle="tooltip" title="Salva Articolo Variante" class="btn btn-primary"
                                type="button" (click)="saveDatiGenerali()">SALVA DATI GENERALI
                        </button>
                      </div>
                    </div>
                    <hr>
                    <p>
                      <b>Variante Tessuto: </b>
                    </p>
                    <div class="form-group row">
                      <div class="col-sm-8">
                        <select class="form-control form-control-sm" id="tessuto" [(ngModel)]="article.id_tessuto">
                          <option *ngFor="let tessuto of filters.tessuti" [value]="tessuto.id">{{tessuto.nome}}</option>
                        </select>
                      </div>
                      <div class="col-sm-4">
                        <button class="btn btn-primary" type="button" data-toggle="collapse" data-target="#nuovo_tessuto" aria-expanded="false" aria-controls="collapseExample">
                          Aggiungi nuovo
                        </button>
                      </div>
                    </div>
                    <div class="collapse" id="nuovo_tessuto">
                      <div class="form-group row">
                        <div class="col-sm-4">
                          <input type="text" class="form-control form-control-sm" id="codice_tessuto_add" [(ngModel)]="article.codice_tessuto_add" placeholder="Aggiungi un nuovo codice tessuto"/>
                        </div>
                        <div class="col-sm-4">
                          <input type="text" class="form-control form-control-sm" id="descrizione_tessuto_add" [(ngModel)]="article.descrizione_tessuto_add" placeholder="Aggiungi un nuovo nome tessuto"/>
                        </div>
                        <div class="col-sm-4">
                          <button class="btn btn-primary" type="button" (click)="addTessuto()">
                            Aggiungi
                          </button>
                        </div>
                      </div>
                    </div>
                    <hr>
                    <p>
                      <b>Variante Colore:</b>
                    </p>
                    <div class="form-group row">
                      <div class="col-sm-8">
                        <select class="form-control form-control-sm" id="colore" [(ngModel)]="article.id_colore">
                          <option *ngFor="let colore of filters.colori" [value]="colore.id">{{colore.nome}}</option>
                        </select>
                      </div>
                      <div class="col-sm-4">
                        <button class="btn btn-primary" type="button" data-toggle="collapse" data-target="#nuovo_colore" aria-expanded="false" aria-controls="collapseExample">
                          Aggiungi nuovo
                        </button>
                      </div>
                    </div>
                    <div class="collapse" id="nuovo_colore">
                      <div class="form-group row">
                        <div class="col-sm-4">
                          <input type="text" class="form-control form-control-sm" id="codice_colore_add" [(ngModel)]="article.codice_colore_add" placeholder="Aggiungi un nuovo codice colore"/>
                        </div>
                        <div class="col-sm-4">
                          <input type="text" class="form-control form-control-sm" id="descrizione_colore_add" [(ngModel)]="article.descrizione_colore_add" placeholder="Aggiungi un nuovo nome colore"/>
                        </div>
                        <div class="col-sm-4">
                          <button class="btn btn-primary" type="button" (click)="addColore()">
                            Aggiungi
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12" style="text-align:center">
                      <button data-toggle="tooltip" title="Salva Articolo Variante" class="btn btn-primary"
                              type="button" (click)="saveTessutoColore()">SALVA TESSUTO/COLORE
                      </button>
                    </div>
                    <hr>

                    <!-- PREZZO -->
                    <div class="form-group row">
                      <label for="prezzo_ingrosso" class="col-sm-2 col-form-label"><b>Prezzo ingrosso:</b></label>
                      <div class="col-sm-4">
                        <input type="text" class="form-control form-control-sm" id="prezzo_ingrosso" [(ngModel)]="article.prezzo_ingrosso"/>
                      </div>
                      <label for="prezzo_dettaglio" class="col-sm-2 col-form-label"><b>Prezzo dettaglio consigliato:</b></label>
                      <div class="col-sm-4">
                        <input type="text" class="form-control form-control-sm" id="prezzo_dettaglio" [(ngModel)]="article.prezzo_dettaglio"/>
                      </div>
                    </div>
                    <!-- END PREZZO -->
                    <div class="row">
                      <div class="col-md-12" style="text-align:center">
                        <button data-toggle="tooltip" title="Salva Articolo Variante" class="btn btn-primary"
                                type="button" (click)="savePrices()">SALVA PREZZI
                        </button>
                      </div>
                    </div>
                    <hr>
                    <p>
                      <b>Descrizione Breve</b>
                      <textarea class="form-control" rows="2" [(ngModel)]="article.descrizione_breve"></textarea>
                    </p>
                    <p>
                      <b>Descrizione Completa</b>
                      <textarea class="form-control" rows="5" [(ngModel)]="article.descrizione_estesa"></textarea>
                    </p>
                    <div class="row">
                      <div class="col-md-12" style="text-align:center">
                        <button data-toggle="tooltip" title="Salva Articolo Variante" class="btn btn-primary"
                                type="button" (click)="saveDescriptions()">SALVA DESCRIZIONI
                        </button>
                      </div>
                    </div>
                    <hr>
                    <div class="form-group row">
                      <label for="taglieTipologie" class="col-sm-2 col-form-label"><b>Scala taglie:</b></label>
                      <div class="col-sm-10">
                        <select class="form-control form-control-sm" id="taglieTipologie" [(ngModel)]="article.tagliaTipologia" disabled>
                          <option *ngFor="let scala_taglie of filters.taglieTipologie" [value]="scala_taglie.nome">{{scala_taglie.nome}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="table-responsive">
                      <table class="table table-striped bg-white table-bordered table-elenco-r classe-brand px-3">
                        <thead class="bg-dark text-white">
                        <tr>
                          <th scope="col">Taglia</th>
                          <th scope="col">Giacenza</th>
                          <th scope="col">Barcode fornitore</th>
                          <th scope="col">Barcode negoziante</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let row of table">
                          <td scope="col">{{row.nome}}</td>
                          <td scope="col"><input type="text" [(ngModel)]="row.giacenza" /></td>
                          <td scope="col"><input type="text" [(ngModel)]="row.barcode_fornitore" /></td>
                          <td scope="col"><input type="text" [(ngModel)]="row.barcode_negoziante" /></td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-md-12" style="text-align:center">
                      <button data-toggle="tooltip" title="Salva Articolo Variante" class="btn btn-primary"
                              type="button" (click)="saveVarianti()">SALVA DATI
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>


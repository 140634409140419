import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserLogin } from './interfaces/user-login';
import { UserSession } from './interfaces/user-session';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  BACKEND_PATH = environment.backendPath;
  LARAVEL_PATH = environment.laravelPath;
  REDIRECT_PATH = '/dashboard';

  private loggedUserSubject: BehaviorSubject<UserSession | null>;
  private loggedUserSubjectLaravel: BehaviorSubject<any | null>;

  constructor( private http: HttpClient ) {
    this.loggedUserSubject = new BehaviorSubject<UserSession | null>(null);
    this.loggedUserSubjectLaravel = new BehaviorSubject<any | null>(null);
  }

  login(credentials: UserLogin) {
    this.loginLaravel(credentials);
  }

  loginNode(credentials : UserLogin) {
    this.http.post<UserSession>(`${this.BACKEND_PATH}/auth/login`, credentials)
      .subscribe((resp: any) => {
        const user : UserSession = resp.data;
        this.loggedUserSubject.next(user);
        window.location.reload();
      },
      (error) => {
        alert("Email o password errati");
      });
  }

  loginLaravel(credentials : UserLogin) {
    this.http.post<any>(`${this.BACKEND_PATH}/accedi`, credentials)
      .subscribe((user: any | null) => {
        this.loggedUserSubjectLaravel.next(user);
        this.loginNode(credentials);
      },
      (error) => {
        alert("Email o password errati");
      });
  }

  public async getCurrentUserAsync(): Promise<UserSession | null> {
    if (this.loggedUserSubject.value == null) {
      const resp = await this.http.get<any>(`${this.BACKEND_PATH}/auth/user`).toPromise()
      .catch(error => {
        alert('Errore di connessione al backend');
      });
      const user : UserSession | null = resp.data;
      if (user)
        this.loggedUserSubject.next(user);
    }
    return this.loggedUserSubject.value;
  }

  public getCurrentUser(): UserSession | null {
    if (this.loggedUserSubject.value == null) {
      const resp = this.http.get<any>(`${this.BACKEND_PATH}/auth/user`).subscribe(resp => {
      const user : UserSession = resp.data;
      if (user)
        this.loggedUserSubject.next(user);
      });
    }
    return this.loggedUserSubject.value;
  }

  public logout(): void {
    this.http.post<any>(`${this.BACKEND_PATH}/auth/logout`, null).subscribe(resp => {
      console.log('resp',resp);
      this.loggedUserSubject.next(null);
      this.loggedUserSubjectLaravel.next(null);
      window.location.href = "/";
    });
  }
}

<div class="row" *ngIf="cliente">
  <div class="col-md-12">
    <div class="card card-colored card-retailer">
      <h1 class="text-uppercase text-center" style="padding-bottom: 0">Dati cliente - ID {{cliente.id}}</h1>
      <p class="text-white text-center">Il cliente ha effettuato <b>{{num_ordini}}</b> ordini, per un totale
        di <b>{{tot_ordini}}</b> euro.</p>
      <div class="container-box row justify-content-center">
        <div class="col-md-12">
          <div class="box utente-img CBox">
            <div class="row">
              <div class="col-md-10">
                <div class="imgClienti">
                  <img loading="lazy" class="imgProfilo"
                       src="https://picsum.photos/140/?random={{cliente.id}}">
                </div>
              </div>
              <div class="col-md-2">
                <button class="btn btn-outline-dark float-right" data-toggle="modal"
                        data-target="#anagraficaModal">Modifica
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="values clientiV">
                  <h2>ANAGRAFICA</h2>
                  <div class="zig-zag"></div>
                  <p><span>Nome:</span> {{cliente.nome}}</p>
                  <p><span>Indirizzo:</span> {{cliente.indirizzo}}</p>
                  <p><span>Tipo:</span> {{cliente.tipo}}</p>
                  <p><span>Canale:</span> {{cliente.canale}}</p>
                  <p><span>Referente:  {{cliente.referente}}</span></p>
                  <p><span>Email:</span> {{cliente.email}}</p>
                  <p><span>Telefono:</span> {{cliente.telefono}}</p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="values clientiV">
                  <h2>FATTURAZIONE</h2>
                  <div class="zig-zag"></div>
                  <p><span>Ragione sociale:</span> {{cliente.ragione_sociale}}</p>
                  <p><span>P.Iva:</span> {{cliente.partita_iva}}</p>
                  <p><span>Pec:</span> {{cliente.pec}}</p>
                  <p><span>Sdi:</span> {{cliente.sdi}}</p>
                  <p><span>Sede Legale:</span> {{cliente.sede_legale}}</p>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="col-md-12">
          <div class="box utente-img CBox">
            <button class="btn btn-outline-dark position-absolute top-0 right-0 m-3" data-toggle="modal"
                    data-target="#listinoModal">Storico
            </button>
            <div class="values clientiV">
              <h2>VENDITE</h2>
              <div class="zig-zag" style="width: 30%"></div>
              <p><span>Agente:</span></p>
              <p><span>Listino:</span> EU</p>
              <p><span>Sconto:</span> {{sconto}}%</p>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="box utente-img d-flex">
            <div class="values clientiV">
              <h2>CONDIZIONI COMMERCIALI</h2>
              <div class="zig-zag"></div>
              <div *ngIf="!checkCondizioni() && condizioni_commerciali.length > 0">
                <div class="table-responsive">
                  <table class="table table-striped bg-white table-bordered classe-brand px-3">
                    <thead class="bg-dark text-white">
                      <tr>
                        <th colspan="3">Info</th>
                        <th colspan="2">Preordine</th>
                        <th colspan="2">Ordine</th>
                        <th colspan="2">Riassortimento</th>
                        <th colspan="2">Reso</th>
                        <th colspan="2">Cambio Reso</th>
                        <th colspan="2">Cambio Ordine</th>
                        <th></th>
                      </tr>
                      <tr>
                        <th>Brand</th>
                        <th>Sconto</th>
                        <th>Pagamento</th>
                        <th>Val Min</th>
                        <th>Qta Min</th>
                        <th>Val Min</th>
                        <th>Qta Min</th>
                        <th>Val Min</th>
                        <th>Qta Min</th>
                        <th>Val Max</th>
                        <th>Qta Max</th>
                        <th>Val Max</th>
                        <th>Qta Max</th>
                        <th>Val Min</th>
                        <th>Qta Min</th>
                        <th>Operazioni</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let condizione of condizioni_commerciali">
                        <td>{{condizione.brand.nome}}</td>
                        <td>{{condizione.sconto}}</td>
                        <td>{{condizione.condizioni_pagamento.nome}}</td>
                        <td>{{condizione.preordine_valore_min}}</td>
                        <td>{{condizione.preordine_quantita_min}}</td>
                        <td>{{condizione.ordine_valore_min}}</td>
                        <td>{{condizione.ordine_pezzi_min}}</td>
                        <td>{{condizione.ordine_riassortimento_valore_min}}</td>
                        <td>{{condizione.ordine_riassortimento_quantita_min}}</td>
                        <td>{{condizione.reso_valore_max ? condizione.reso_valore_max : '-'}}</td>
                        <td>{{condizione.reso_quantita_max ? condizione.reso_quantita_max : '-'}}</td>
                        <td>{{condizione.cambio_reso_valore_max ? condizione.cambio_reso_valore_max : '-'}}</td>
                        <td>{{condizione.cambio_reso_quantita_max ? condizione.cambio_reso_quantita_max : '-'}}</td>
                        <td>{{condizione.cambio_ordine_valore_min ? condizione.cambio_ordine_valore_min : '-'}}</td>
                        <td>{{condizione.cambio_ordine_quantita_min ? condizione.cambio_ordine_quantita_min : '-'}}</td>
                        <td><button class="btn btn-primary" data-toggle="modal" data-target="#condizioniModal" (click)="modificaCondizione(condizione)">Modifica</button></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div *ngIf="condizioni_commerciali.length == 0">
                Non ci sono condizioni commerciali. <a href="javascript:void(0);" (click)="createCondizioni()">Creale ora!</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="cliente && clienteForm" class="modal fade" id="anagraficaModal" tabindex="-1" role="dialog"
     aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <form [formGroup]="clienteForm" (submit)="update()">
        <div class="modal-header">
          <h5 class="modal-title">Dati cliente</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <h4 class="modal4">Anagrafica</h4>
          <div class="">
            <label for="nome" class="form-label">Nome</label>
            <input type="text" class="form-control" id="nome" formControlName="nome"
                   value="{{cliente.nome}}">
          </div>
          <div class="">
            <label for="indirizzo" class="form-label">Indirizzo</label>
            <input type="text" class="form-control" id="indirizzo" formControlName="indirizzo"
                   value="{{cliente.indirizzo}}">
          </div>
          <!-- <div class=" row mb-2">
            <label class="col-sm-2 col-form-label">Località</label>
            <div class="col-sm-3">
              <input type="text" class="form-control" id="cap" placeholder="CAP" value="{{cliente.negozio.cap}}">
            </div>
            <div class="col-sm-4">
              <input type="text" class="form-control" id="citta" placeholder="Città" value="{{cliente.negozio.citta}}">
            </div>
            <div class="col-sm-3">
              <input type="text" class="form-control" id="provincia" placeholder="Provincia" value="{{cliente.negozio.provincia}}">
            </div>
          </div> -->
          <div class="">
            <label for="tipo" class="form-label">Tipo</label>
            <select class="form-control" id="tipo" formControlName="tipo">
              <option *ngFor="let tipo of tipi" value="{{tipo}}">{{tipo}}</option>
            </select>
          </div>
          <div class="">
            <label for="canale" class="form-label">Canale</label>
            <select class="form-control" id="canale" formControlName="canale">
              <option *ngFor="let canale of canali" value="{{canale}}">{{canale}}</option>
            </select>
          </div>
          <div class="">
            <label for="referente" class="form-label">Referente</label>
            <input type="text" class="form-control" id="referente" formControlName="referente"
                   value="{{cliente.referente}}">
          </div>
          <div class="">
            <label for="email" class="form-label">Email</label>
            <input type="email" class="form-control" id="email" formControlName="email"
                   value="{{cliente.email}}">
          </div>
          <div class="">
            <label for="telefono" class="form-label">Telefono</label>
            <input type="text" class="form-control" id="telefono" formControlName="telefono"
                   value="{{cliente.telefono}}">
          </div>
          <h4 class="modal4">Fatturazione</h4>
          <div class="">
            <label for="ragione_sociale" class="form-label">Ragione sociale</label>
            <input type="text" class="form-control" id="ragione_sociale" formControlName="ragione_sociale"
                   value="{{cliente.ragione_sociale}}">
          </div>
          <div class="">
            <label for="partita_iva" class="form-label">P.Iva</label>
            <input type="text" class="form-control" id="partita_iva" formControlName="partita_iva"
                   value="{{cliente.partita_iva}}">
          </div>
          <div class="">
            <label for="indirizzo" class="form-label">Sede legale</label>
            <input type="text" class="form-control" id="indirizzo" formControlName="sede_legale"
                   value="{{cliente.sede_legale}}">
          </div>
          <div class="">
            <label for="email" class="form-label">Pec</label>
            <input type="email" class="form-control" id="email" formControlName="pec"
                   value="{{cliente.pec}}">

          </div>
          <div class="">
            <label for="telefono" class="form-label">SDI</label>
            <input type="text" class="form-control" id="telefono" formControlName="sdi"
                   value="{{cliente.sdi}}">

          </div>
        </div>
        <div class="modal-footer">
          <div class="button-flex" style="width: 100%;">
            <button type="button" class="btn btn1" data-dismiss="modal">Chiudi</button>
            <button type="button" class="btn btn2" type="submit">Salva</button>
          </div>

        </div>
      </form>
    </div>
  </div>
</div>

<div *ngIf="cliente" class="modal fade" id="listinoModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{cliente.nome}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Chiudi">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Il cliente ha effettuato <b>{{num_ordini}}</b> ordini, per un totale di <b>{{tot_ordini}}</b> euro.
        </p>
        <a class="btn btn-outline-dark mb-3" href="#" target="_blank">Visualizza ordini</a>
        <table class="table table-hover table-mc-light-blue">
          <thead class="bg-dark text-white">
          <tr>
            <th scope="col">Nome listino</th>
            <th scope="col">Sconto</th>
            <th scope="col">Data inizio</th>
            <th scope="col">Data fine</th>
            <th scope="col">Num. e valore ordini</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>EU</td>
            <td>10% Cond.Comm.</td>
            <td>01/09/22</td>
            <td></td>
            <td>3 ordini - 10.000 euro</td>
          </tr>
          <tr>
            <td>EU</td>
            <td>20% Prom.</td>
            <td>01/09/22</td>
            <td>01/09/23</td>
            <td>1 ordini - 1.000 euro</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn1" data-dismiss="modal">Chiudi</button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="cliente" class="modal fade" id="condizioniModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Condizioni commerciali</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Chiudi">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form>
          <div *ngIf="!checkCondizioni()">
            <label for="conto_vendita" class="form-label">Conto vendita</label>
            <div class="">
              <div class="col-sm-12">
                <input class="form-check-input" type="checkbox" value="" id="conto_vendita">
              </div>
            </div>
            <div class="mb-2">
              <label for="pre-ordine" class="form-label">Pre-ordine minimo</label>
              <div class="row">
                <div class="col-sm-6">
                  <div class="input-group mb-1">
                    <input type="text" class="form-control" id="pre-ordine-euro" value="" placeholder="Euro">
                    <div class="input-group-append">
                      <span class="input-group-text">Euro</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="input-group mb-1">
                    <input type="text" class="form-control" id="pre-ordine-pezzi" value=""
                           placeholder="pezzi">
                    <div class="input-group-append">
                      <span class="input-group-text">pezzi</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-2">
              <label for="pronto" class="form-label">Ordine sul pronto</label>
              <div class="row">
                <div class="col-sm-6">
                  <div class="input-group mb-1">
                    <input type="text" class="form-control" id="pronto-euro" value="" placeholder="Euro">
                    <div class="input-group-append">
                      <span class="input-group-text">Euro</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="input-group mb-1">
                    <input type="text" class="form-control" id="pronto-pezzi" value="" placeholder="pezzi">
                    <div class="input-group-append">
                      <span class="input-group-text">pezzi</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-2">
              <label for="reso" class="form-label">Reso max per stagione</label>
              <div class="row">
                <div class="col-sm-6">
                  <div class="input-group mb-1">
                    <input type="text" class="form-control" id="reso-euro" value="" placeholder="Euro">
                    <div class="input-group-append">
                      <span class="input-group-text">Euro</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="input-group mb-1">
                    <input type="text" class="form-control" id="reso-pezzi" value="" placeholder="pezzi">
                    <div class="input-group-append">
                      <span class="input-group-text">pezzi</span>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="mb-2">
              <label for="sostituzioni" class="form-label">Sostituzioni: max rendibile</label>
              <div class="row">
                <div class="col-sm-6">
                  <div class="input-group mb-1">
                    <input type="text" class="form-control" id="sostituzioni-euro" value=""
                           placeholder="Euro">
                    <div class="input-group-append">
                      <span class="input-group-text">Euro</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="input-group mb-1">
                    <input type="text" class="form-control" id="sostituzioni-pezzi" value=""
                           placeholder="pezzi">
                    <div class="input-group-append">
                      <span class="input-group-text">pezzi</span>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="mb-2">
              <label for="pagamento" class="form-label">Metodo di pagamento</label>
                <select class="form-control" id="canale">
                  <option value="bonifico60">Bonifico a 60 giorni</option>
                </select>
            </div>
          </div>
          <div class="mb-2">
            <label for="ragione_sociale" class="form-label">Sconto</label>
              <div class="input-group mb-1">
                <input type="number" class="form-control" id="sconto" name="sconto" [(ngModel)]="condizione_selected.sconto">
                <div class="input-group-append">
                  <span class="input-group-text">%</span>
                </div>
              </div>
            </div>
        </form>
      </div>
      <div class="modal-footer">
        <div class="button-flex" style="width: 100%">
          <button type="button" class="btn btn1" data-dismiss="modal">Chiudi</button>
          <button type="button" class="btn btn2" data-dismiss="modal" (click)="salvaCondizione()">Salva</button>
        </div>

      </div>
    </div>
  </div>
</div>

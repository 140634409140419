import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PagamentiService {

  private pagamentiUrl = environment.backendPath+'/pagamenti/';

  constructor(private http : HttpClient) { }

  getPagamenti(): Observable<any> {
    return this.http.get<any>(this.pagamentiUrl);
  }
}

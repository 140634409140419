<section>
  <div class="">
  <div class="row">
    <div class="col-md-12">
      <div class="card card-colored card-brand" style="overflow:visible">
        <h1 class="text-uppercase text-white border-bottom-ab">Lista clienti</h1>
        <button class="btn agg-mag" data-toggle="modal" data-target="#creazioneModal">Aggiungi</button>
        <div class="button-mag">
          <form id="filters" action="#" method="get" (submit)="filter()">
            <div class="row">
              <div class="col-md-4 pr-0">
                <input class="form-control" type="text" name="nome" value="" placeholder="Nome (negozio, showroom, nome e cognome)">
              </div>
              <div class="col-md-4 pr-0">
                <input class="form-control" type="text" name="ragione_sociale" value="" placeholder="Ragione sociale">
              </div>
              <div class="col-md-4 pr-0">
                <input class="form-control" type="text" name="partita_iva" value="" placeholder="Partita iva">
              </div>
              <div class="col-md-4 pr-0">
                <input class="form-control" type="email" value="" placeholder="Email" name="email">
              </div>
              <div class="col-md-4 pr-0">
                <select id="canale" class="form-control select2-canale" multiple="multiple" required name="canale" data-live-search="true">
                  <option *ngFor="let canale of canali" value="{{canale}}">{{canale}}</option>
                </select>
              </div>
              <div class="col-md-4 pr-0">
                <select id="tipo" class="form-control select2-tipo" multiple="multiple" required name="tipo" data-live-search="true">
                  <option *ngFor="let tipo of tipi" value="{{tipo}}">{{tipo}}</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="button-flex">
                    <button title="" type="button" class="btn btn1">
                        <i class="fas fa-file-excel"></i> Export
                    </button>
                    <button type="button" title="" class="btn btn2" (click)="deselectAll()">
                        <i class="fas fa-times"></i> Resetta
                    </button>
                    <button type="submit" title="" class="btn btn3" (click)="filter()">
                        <i class="fas fa-search"></i> Cerca
                    </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

</div>
</section>

  <section>
    <div class="">
      <div class="row">
        <div class="col-12">
          <div class="card px-3">
            <div class="table-responsive">
            <table class="table table-hover table-mc-light-blue">
              <thead>
              <tr>
                <th scope="col" (click)="order($event)" data-order="nome" data-by="">Nome</th>
                <th scope="col" (click)="order($event)" data-order="ragione_sociale" data-by="">Ragione sociale</th>
                <th scope="col" (click)="order($event)" data-order="partita_iva" data-by="">P.Iva</th>
                <th scope="col" (click)="order($event)" data-order="email" data-by="">Email</th>
                <th scope="col" (click)="order($event)" data-order="canale" data-by="">Canale</th>
                <th scope="col" (click)="order($event)" data-order="tipo" data-by="">Tipo</th>
                <th scope="col" (click)="order($event)" data-order="sede_legale">Indirizzo</th>
                <th scope="col" (click)="order($event)">Dettagli</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let cliente of clienti">
                <td scope="col">{{cliente.nome}}</td>
                <td scope="col">{{cliente.ragione_sociale}}</td>
                <td scope="col">{{cliente.partita_iva}}</td>
                <td scope="col">{{cliente.email}}</td>
                <td scope="col">{{cliente.canale}}</td>
                <td scope="col">{{cliente.tipo}}</td>
                <td scope="col">{{cliente.sede_legale}}</td>
                <td scope="col">
                  <div class="btn-group" role="group">
                    <a routerLink="/clienti/{{cliente.id}}" class="btn btn1">Dettaglio</a>
                    <a class="btn btn2" (click)="delete(cliente.id)" [attr.data-cliente]="cliente.id">Rimuovi</a>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>

<div *ngIf="clienteForm" class="modal fade" id="creazioneModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <form [formGroup]="clienteForm" (submit)="create()">
        <div class="modal-header">
          <h5 class="modal-title">Dati cliente</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <h4 class="modal4">Anagrafica</h4>
          <div class="form-group">
            <label for="nome" class="">Nome</label>
            <input type="text" class="form-control" id="nome" formControlName="nome">
          </div>
          <div class="form-group">
            <label for="indirizzo" class="">Indirizzo</label>
            <input type="text" class="form-control" id="indirizzo" formControlName="indirizzo">
          </div>
          <!-- <div class="form-group row mb-2">
            <label class="col-sm-2 col-form-label">Località</label>
            <div class="col-sm-3">
              <input type="text" class="form-control" id="cap" placeholder="CAP" value="{{cliente.negozio.cap}}">
            </div>
            <div class="col-sm-4">
              <input type="text" class="form-control" id="citta" placeholder="Città" value="{{cliente.negozio.citta}}">
            </div>
            <div class="col-sm-3">
              <input type="text" class="form-control" id="provincia" placeholder="Provincia" value="{{cliente.negozio.provincia}}">
            </div>
          </div> -->
          <div class="form-group">
            <label for="tipo" class="col-sm-2 col-form-label">Tipo</label>
            <select class="form-control" id="tipo" formControlName="tipo">
              <option value=""></option>
              <option *ngFor="let tipo of tipi" value="{{tipo}}">{{tipo}}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="canale" class="col-sm-2 col-form-label">Canale</label>
            <select class="form-control" id="canale" formControlName="canale">
              <option value=""></option>
              <option *ngFor="let canale of canali" value="{{canale}}">{{canale}}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="referente" class="">Referente</label>
            <input type="text" class="form-control" id="referente" formControlName="referente">

          </div>
          <div class="form-group">
            <label for="email" class="">Email</label>
            <input type="email" class="form-control" id="email" formControlName="email">

          </div>
          <div class="form-group">
            <label for="telefono" class="">Telefono</label>
            <input type="text" class="form-control" id="telefono" formControlName="telefono">

          </div>
          <h4 class="modal4">Fatturazione</h4>
          <div class="form-group">
            <label for="ragione_sociale" class="">Ragione sociale</label>
            <input type="text" class="form-control" id="ragione_sociale" formControlName="ragione_sociale">
          </div>
          <div class="form-group">
            <label for="partita_iva" class="">P.Iva</label>
            <input type="text" class="form-control" id="partita_iva" formControlName="partita_iva">

          </div>
          <div class="form-group">
            <label for="indirizzo" class="">Sede legale</label>
            <input type="text" class="form-control" id="indirizzo" formControlName="indirizzo">
          </div>
          <div class="form-group">
            <label for="email" class="">Pec</label>
            <input type="email" class="form-control" id="email" formControlName="pec">
          </div>
          <div class="form-group">
            <label for="telefono" class="">SDI</label>
            <input type="text" class="form-control" id="sdi" formControlName="sdi">

          </div>
        </div>
        <div class="modal-footer">
          <div class="button-flex" style="width: 100%">
            <button type="button" class="btn btn1" data-dismiss="modal">Chiudi</button>
            <button type="button" class="btn btn2" type="submit">Salva</button>
          </div>
        </div>
      </form>
    </div>
  </div>

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LoginComponent } from './pages/login/login.component';
import { HttpClientModule } from '@angular/common/http';
import { AuthModule } from './auth/auth.module';
import { HttpInterceptorsProviders } from './auth/interceptors';
import { CampionariComponent } from './pages/campionari/campionari.component';
import { CampionariDetailComponent } from './pages/campionari-detail/campionari-detail.component';
import { ClientiComponent } from './pages/clienti/clienti.component';
import { ClientiDetailComponent } from './pages/clienti-detail/clienti-detail.component';
import { SignUpRetailerComponent } from './pages/retailer/sign-up-retailer/sign-up-retailer.component';
import { SignUpBrandComponent } from './pages/brand/sign-up-brand/sign-up-brand.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { DetailArticoloRetailerComponent } from './pages/retailer/detail-articolo-retailer/detail-articolo-retailer.component';
import { ListBrandRetailerComponent } from './pages/retailer/list-brand-retailer/list-brand-retailer.component';
import { ListRetailerBrandComponent } from './pages/brand/list-retailer-brand/list-retailer-brand.component';
import { ItemListBrandComponent } from './components/item-list-brand/item-list-brand.component';
import { ListOrdersComponent } from './pages/order/list-orders/list-orders.component';
import { DetailOrderComponent } from './pages/order/detail-order/detail-order.component';
import { TableOrdersComponent } from './components/table-orders/table-orders.component';
import { MatTabsModule } from "@angular/material/tabs";
import { DialogRequestAuthorizationComponent } from './dialogs/dialog-request-authorization/dialog-request-authorization.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProfileComponent } from './pages/profile/profile.component';
import { DialogChangeAvatarComponent } from './dialogs/dialog-change-avatar/dialog-change-avatar.component';
import { DialogChangePasswordComponent } from './dialogs/dialog-change-password/dialog-change-password.component';
import { ListArticlesComponent } from './pages/list-articles/list-articles.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { StoreGeneralComponent } from './pages/store-general/store-general.component';
import { AgentiComponent } from './pages/agenti/agenti.component';
import { AgentiDetailComponent } from './pages/agenti-detail/agenti-detail.component';
import { MarketPlaceComponent } from './pages/market-place/market-place.component';
import { MarketPlaceArticlesComponent } from './pages/market-place-articles/market-place-articles.component';
import { DataArticleComponent } from './pages/data-article/data-article.component';
import { DataArticleTableComponent } from './pages/data-article-table/data-article-table.component';
import { CreateArticleComponent } from './pages/create_article/create-article.component';
import { PreordiniComponent } from './pages/preordini/preordini.component';
import { PreordiniDetailComponent } from './pages/preordini-detail/preordini-detail.component';
import { RouterModule } from '@angular/router';
import { BaseLayoutComponent } from './layouts/base-layout/base-layout.component';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout.component';
import { DetailArticleComponent } from './pages/detail-article/detail-article.component';
import { MovimentiMagazzinoComponent } from './pages/movimenti-magazzino/movimenti-magazzino.component';
import { CreaMovimentoComponent } from './pages/crea-movimento/crea-movimento.component';
import { DettaglioMovimentoComponent } from './pages/dettaglio-movimento/dettaglio-movimento.component';
import { QuillModule } from 'ngx-quill';
import { GoogleAutocompleteComponent } from './components/google-autocomplete/google-autocomplete.component';
import { MarketplaceBrandComponent } from './pages/marketplace-brand/marketplace-brand.component';
import { MarketplaceRetailerComponent } from './pages/marketplace-retailer/marketplace-retailer.component';
import { CheckoutBrandComponent } from './pages/checkout-brand/checkout-brand.component';
import { CheckoutRetailerComponent } from './pages/checkout-retailer/checkout-retailer.component';
import { ListListiniComponent } from './pages/listini/list-listini/list-listini.component';
import { TableListiniComponent } from './components/table-listini/table-listini.component';
import { DetailListiniComponent } from './pages/listini/detail-listini/detail-listini.component';
import { ThankyouPageComponent } from './pages/thankyou-page/thankyou-page.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { TestComponent } from './pages/test/test.component';



const materialModules = [
  MatTabsModule,
  FormsModule,
  MatSlideToggleModule
];


const dialogModule = [
  DialogRequestAuthorizationComponent,
  DialogChangeAvatarComponent,
  DialogChangePasswordComponent,
];



@NgModule({
  declarations: [
    AppComponent,
    ...dialogModule,
    SidebarComponent,
    HeaderComponent,
    FooterComponent,
    BaseLayoutComponent,
    LoginLayoutComponent,
    DashboardComponent,
    ProfileComponent,
    LoginComponent,
    CampionariComponent,
    SignUpRetailerComponent,
    SignUpBrandComponent,
    SignUpComponent,
    DetailArticoloRetailerComponent,
    ListBrandRetailerComponent,
    ListRetailerBrandComponent,
    ItemListBrandComponent,
    ListOrdersComponent,
    DetailOrderComponent,
    TableOrdersComponent,
    DialogRequestAuthorizationComponent,
    DialogChangeAvatarComponent,
    DialogChangePasswordComponent,


    CampionariDetailComponent,
    ClientiComponent,
    ClientiDetailComponent,
    ListArticlesComponent,
    StoreGeneralComponent,
    AgentiComponent,
    AgentiDetailComponent,
    MarketPlaceComponent,
    MarketPlaceArticlesComponent,
    DataArticleComponent,
    DataArticleTableComponent,
    CreateArticleComponent,
    DetailArticleComponent,
    PreordiniComponent,
    PreordiniDetailComponent,
    MovimentiMagazzinoComponent,
    CreaMovimentoComponent,
    DettaglioMovimentoComponent,
    GoogleAutocompleteComponent,
    MarketplaceBrandComponent,
    MarketplaceRetailerComponent,
    CheckoutBrandComponent,
    CheckoutRetailerComponent,
    ListListiniComponent,
    TableListiniComponent,
    DetailListiniComponent,
    ThankyouPageComponent,
    FileUploaderComponent,
    TestComponent,

  ],
  imports: [
    ...materialModules,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AuthModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    RouterModule,
    QuillModule.forRoot(),
  ],
  providers: [HttpInterceptorsProviders],
  bootstrap: [AppComponent]
})
export class AppModule { }

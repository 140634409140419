import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/auth/auth.service';
import { ArticoloService } from 'app/services/articolo.service';
import { UtilitiesService } from 'app/services/utilities.service';

@Component({
  selector: 'app-market-place',
  templateUrl: './market-place.component.html',
  styleUrls: ['./market-place.component.sass']
})
export class MarketPlaceComponent implements OnInit {
  public user : any;
  public listArticles : any[] = [];
  roleUserLogged :string |undefined;
  public modalIsVisible :boolean = false;

  public brand_for_search : any[] = [];
  public stagioni_for_search : any[] = [];
  public collezioni_for_search : any[] = [];
  public taglie_for_search : any[] = [];
  public tessuti_for_search : any[] = [];
  public colori_for_search : any[] = [];
  public settori_for_search : any[] = [];
  public taglie_descrizioni_for_search : any[] = [];

  public filter: any = {}


  //categorie
  public macrocategorie_for_search : any[] = [];
  public categorie_for_search : any[] = [];
  public sottocategorie_for_search : any[] = [];

    //campi per filtri
    public sizeStr: string = ""
    public sectorStr: string = ""
    public macroStr: string = ""
    public categoriaStr: string = ""

  constructor(private articoloService : ArticoloService, private auth: AuthService, private utilitiesService : UtilitiesService) {
    this.modalIsVisible = true;
    const user = this.auth.getCurrentUser();
    this.roleUserLogged = user?.tipo;
    this.user = user;
    this.loadList({});
  }

  ngOnInit(): void {

  }

  loadList(objFilter : any){

    this.utilitiesService.getListMacroCategoriesBySector("settori=").then((resp: any) => {
      this.macrocategorie_for_search = resp['data'].macrocategorie
    });

    this.utilitiesService.getListCategoriesByMacro("id_macrocategoria=").then((resp: any) => {
      this.categorie_for_search = resp['data'].macrocategorie
    });


    this.utilitiesService.getListSottoCategoriesByCategory("id_categoria=").then((resp: any) => {
      this.sottocategorie_for_search = resp['data'].macrocategorie
    });


    this.articoloService.getListMarketPlace(this.user.id_organizzazione, objFilter).then((resp: any) => {
      var list = resp['data'].articoli.data
      this.listArticles = Object.values(list);
      this.modalIsVisible = false;

      this.brand_for_search = resp['data'].brand_for_search
      this.stagioni_for_search = resp['data'].stagioni_for_search
      this.collezioni_for_search = resp['data'].collezioni_for_search
      this.taglie_for_search = resp['data'].taglie_for_search
      this.tessuti_for_search = resp['data'].tessuti_for_search
      this.colori_for_search = resp['data'].brand_for_search
      this.brand_for_search = resp['data'].settori_for_search

      console.log(list)
    });
  }



  search(){
    this.filter.is_ajax = true
    this.filter.export = 0
    this.listArticles = [];
    this.loadList(this.filter);
  }

  resetForm(){
    this.filter = {}
    this.loadList({});
  }


  sizeChange(size:any){
    this.sizeStr = this.sizeStr == "" ? "id_taglia[]=" + size : this.sizeStr + "&id_taglia[]=" + size;
    this.utilitiesService.getListSizesDescBySize(this.sizeStr).then((resp: any) => {
      this.taglie_descrizioni_for_search = resp['data'].taglieDescrizioni
    });
  }

  sectorChange(sector:any){
    this.sectorStr = this.sectorStr == "" ? "settori[]=" + sector : this.sectorStr + "&settori[]=" + sector;
    this.utilitiesService.getListMacroCategoriesBySector(this.sectorStr).then((resp: any) => {
      this.macrocategorie_for_search = resp['data'].macrocategorie
    });
  }

  macroChange(macrocategoria:any){
    this.macroStr = this.macroStr == "" ? "id_macrocategoria=[]=" + macrocategoria : this.macroStr + "&id_macrocategoria=[]=" + macrocategoria;
    this.utilitiesService.getListCategoriesByMacro(this.macroStr).then((resp: any) => {
      this.categorie_for_search = resp['data'].macrocategorie
    });
  }


  categoryChange(categoria:any){
    this.categoriaStr = this.categoriaStr == "" ? "id_categoria==[]=" + categoria : this.categoriaStr + "&id_categoria==[]=" + categoria;
    this.utilitiesService.getListSottoCategoriesByCategory(this.categoriaStr).then((resp: any) => {
      this.sottocategorie_for_search = resp['data'].macrocategorie
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AgentiService } from 'app/services/agenti.service';
import { CampionariService } from 'app/services/campionari.service';
import { ClientiService } from 'app/services/clienti.service';
import { PagamentiService } from 'app/services/pagamenti.service';
import { PreordiniService } from 'app/services/preordini.service';

@Component({
  selector: 'app-preordini-detail',
  templateUrl: './preordini-detail.component.html',
  styleUrls: ['./preordini-detail.component.sass']
})
export class PreordiniDetailComponent implements OnInit {

  private id_preordine: number = 0;
  preordine: any | undefined;
  prodotti: any[] = [];
  agenti: any[] = [];
  clienti: any[] = [];
  cliente_selezionato: any | undefined;
  aziende: any[] = [];
  azienda_selezionata: any | undefined;
  magazzini: any[] = [];
  magazzino_selezionato: any | undefined;
  pagamenti: any[] = [];
  campionari: any[] = [];
  prodotti_campionario: any[] = [];


  constructor(private route: ActivatedRoute, private preordiniService: PreordiniService, private agentiService: AgentiService, private clientiService: ClientiService, private pagamentiService: PagamentiService, private campionariService: CampionariService) { }

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get('id'))
      this.id_preordine = parseInt(this.route.snapshot.paramMap.get('id')!, 10);
    this.load();
  }

  load(): void {
    this.getAgenti();
    this.getClienti();
    if (this.id_preordine > 0) {
      this.getPreordine();
      this.getProdotti();
    }
    this.getPagamenti();
    this.getCampionari();
  }

  getPreordine(): void {
    this.preordiniService.getPreordine(this.id_preordine)
    .subscribe(resp => {
      this.preordine = resp.data;
      this.preordine.data_ordine = this.preordine.data_ordine.substring(0, this.preordine.data_ordine.indexOf('T'))
      this.selectClienteById(this.preordine.id_retailer);
      this.getCampionario();
    },
    error => {
      window.location.href = '/preordini/nuovo';
    })
  }

  salvaPreordine(): void {
    const inputs = document.querySelectorAll('.input-preordine');
    console.log(inputs);
    let preordine: any = {};
    for (const input of inputs) {
      if (input.id == "campionari") {
        const campionario = this.campionari.find(item => item.id == parseInt((input as HTMLInputElement).value));
        preordine['id_campionario'] = parseInt(campionario.id);
        preordine['id_brand'] = parseInt(campionario.id_brand);
        preordine['id_stagione'] = parseInt(campionario.id_stagione);
      }
      else if (input.id == "data_ordine"){
        preordine[input.id] = new Date((input as HTMLInputElement).value).toISOString();
      }
      else {
        preordine[input.id] = parseInt((input as HTMLInputElement).value);
      }
    }
    if (!this.preordine) {
      preordine["id_iva"] = 1;
      preordine["numero"] = 1;
      preordine["ordini_brand_prodotti"] = [];
    }
    console.log('preordine',preordine);
    if (this.id_preordine > 0) {
      this.preordiniService.updatePreordine(this.id_preordine, preordine)
      .subscribe(resp => {
        console.log(resp.data);
        this.load();
      });
    }
    else {
      this.preordiniService.createPreordine(preordine)
      .subscribe(resp => {
        console.log(resp.data);
        window.location.href = "/preordini/"+resp.data.id.toString();
      });
    }

    /*preordineid_brand: number
    id_retailer: number
    id_stagione: number
    id_agente: number
    id_campionario: number
    data_ordine: Date
    numero: number
    id_magazzino_spedizione: number
    id_pagamento: number
    id_azienda_fatturazione: number
    id_azienda_fornitore: number
    id_iva: number*/
  }

  getProdotti(codice: string = ""): void {
    this.preordiniService.getProdotti(this.id_preordine)
    .subscribe(resp => {
      //this.prodotti = resp.data;
      this.loadProdotti(resp.data);
    })
  }

  getAgenti(): void {
    this.agentiService.getAgenti()
    .subscribe(resp => {
      this.agenti = resp.data;
    });
  }

  getClienti(): void {
    this.clientiService.getClienti()
    .subscribe(resp => {
      this.clienti = resp.data;
    })
  }

  selectCliente(event: Event): void {
    this.azienda_selezionata = undefined;
    this.magazzino_selezionato = undefined;
    this.cliente_selezionato = this.clienti.find((item: { id: number }) => item.id == parseInt((event.target! as HTMLSelectElement).value));
    this.loadCliente();
  }

  selectClienteById(id: number): void {
    const _this = this;
    const interval = setInterval(function() {
      if (_this.clienti.length == 0) return;
      _this.cliente_selezionato = _this.clienti.find((item: { id_organizzazione: number }) => item.id_organizzazione == id);
      _this.loadCliente();
      clearInterval(interval);
    }, 10);
    console.log(this.cliente_selezionato);
  }

  loadCliente(): void {
    if (this.cliente_selezionato) {
      this.aziende = this.cliente_selezionato.organizzazione.aziende;
      if (this.aziende.length > 0) {
        this.azienda_selezionata = this.aziende[0];
      }
      this.magazzini = this.cliente_selezionato.organizzazione.magazzini;
      if (this.magazzini.length > 0) {
        this.magazzino_selezionato = this.magazzini[0];
      }
    }
  }

  getPagamenti(): void {
    this.pagamentiService.getPagamenti()
    .subscribe(resp => {
      this.pagamenti = resp.data;
    })
  }

  order(event: Event): void {
    const order = (event.target as HTMLElement).dataset.order!;
    const by = (event.target as HTMLElement).dataset.by!;
    document.querySelectorAll('th').forEach(element => element.classList.remove("order-asc","order-desc"))
    if (by == "" || by == "desc") {
      this.prodotti.sort((elem1, elem2) => typeof(order.split('.').reduce((o,i)=> o[i], elem1)) == "string" ? order.split('.').reduce((o,i)=> o[i], elem1).localeCompare(order.split('.').reduce((o,i)=> o[i], elem2)) : order.split('.').reduce((o,i)=> o[i], elem1) > (order.split('.').reduce((o,i)=> o[i], elem2)));
      (event.target as HTMLElement).dataset.by = "asc";
      (event.target as HTMLElement).classList.add("order-asc");
    }
    else {
      this.prodotti.sort((elem1, elem2) => typeof(order.split('.').reduce((o,i)=> o[i], elem1)) == "string" ? order.split('.').reduce((o,i)=> o[i], elem2).localeCompare(order.split('.').reduce((o,i)=> o[i], elem1)) : order.split('.').reduce((o,i)=> o[i], elem1) < (order.split('.').reduce((o,i)=> o[i], elem2)));
      (event.target as HTMLElement).dataset.by = "desc";
      (event.target as HTMLElement).classList.add("order-desc");
    }
  }

  loadProdotti(data: any) {
    console.log('prodotti', data);
    const keys = Object.keys(data);
    const prodotti: any[] = [];
    keys.forEach(function (key) {
      const prodotto: any = {};
      prodotto['varianti'] = data[key];
      prodotto['nome'] = key.split(',')[0];
      prodotto['codice'] = key.split(',')[1].replace('\n','');
      prodotto['quantita'] = data[key].reduce((somma: number, articolo: any) => {
        return (somma + parseInt(articolo.quantita));
      }, 0);
      prodotto['prezzo_unitario'] = data[key][0].prezzo_vendita_ingrosso;
      prodotto['prezzo_totale'] = prodotto['prezzo_unitario'] * prodotto['quantita'];
      prodotti.push(prodotto);
    })
    this.prodotti = prodotti;

  }

  async aggiungiProdotto(id_articolo: any) {
    let articolo = this.prodotti_campionario.find(element => { return element.id == id_articolo });
    console.log(articolo);
    const aggiunta = articolo.articoli_varianti[0];
    let prodotto: any = {}
    prodotto.id_articolo = parseInt(articolo.id);
    prodotto.id_articolo_variante = parseInt(aggiunta.id);
    prodotto.id_taglia_tipologia_descrizione = parseInt(aggiunta.id_taglia_tipologia_descrizione);
    prodotto.id_tessuto = parseInt(aggiunta.id_tessuto);
    prodotto.id_colore = parseInt(aggiunta.id_colore);
    prodotto.quantita = 0;
    this.preordiniService.addProdotto(this.id_preordine, 0, prodotto)
    .subscribe((resp) => {
      console.log('aggiunta',resp.data);
      console.log('preordine', this.id_preordine);
      this.getProdotti();
    });
  }

  async ordinaProdotto(codice: string) {
    const inputs = document.querySelectorAll('#articolo'+codice+' input');
    const prodotti: any[] = [];
    for (const input of inputs) {
      const prodotto: any = {};
      prodotto.quantita = parseInt((input as HTMLInputElement).value) > 0 ? parseInt((input as HTMLInputElement).value) : 0;
      prodotto.id_articolo = parseInt((input as HTMLElement).dataset.articolo!);
      prodotto.id_articolo_variante = parseInt((input as HTMLElement).dataset.variante!);
      prodotto.id_taglia_tipologia_descrizione = parseInt((input as HTMLElement).dataset.taglia!);
      prodotto.id_tessuto = parseInt((input as HTMLElement).dataset.tessuto!);
      prodotto.id_colore = parseInt((input as HTMLElement).dataset.colore!);
      const id_prodotto_preordine = parseInt((input as HTMLElement).dataset.articoloPreordine!);
      await new Promise<void>((resolve, reject) => {
        this.preordiniService.addProdotto(this.id_preordine, id_prodotto_preordine, prodotto)
        .subscribe(() => {
          resolve();
        });
      });
    }
    this.getProdotti(codice);
  }

  async rimuoviProdotto(codice: string) {
    if (confirm("Vuoi rimuovere il prodotto?")) {
      const inputs = document.querySelectorAll('#articolo'+codice+' input');
      const prodotti: any[] = [];
      for (const input of inputs) {
        const id_prodotto_preordine = parseInt((input as HTMLElement).dataset.articoloPreordine!);
        if (id_prodotto_preordine > 0)
        await new Promise<void>((resolve, reject) => {
          this.preordiniService.rimuoviProdotto(this.id_preordine, id_prodotto_preordine)
          .subscribe(() => {
            resolve();
          });
        });
      }
      this.getProdotti();
    }
  }

  getCampionari(): void {
    this.campionariService.getCampionari()
    .subscribe(resp => {
      this.campionari = resp.data;
      console.log('campionari', this.campionari);
    })
  }

  getCampionario(): void {
    this.campionariService.getArticoli(this.preordine.id_campionario)
    .subscribe(resp => {
      this.prodotti_campionario = resp.data;
      console.log(this.prodotti_campionario);
    })
  }

  openDetails(codice: string): void {
    document.querySelector('#articolo'+codice)!.classList.add('show');
    document.querySelector('#articolo'+codice)!.scrollIntoView();
  }
}

<section style="background: #031d6d">
  <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-md-8 col-xs-12" style="position: relative; height: 100vh;">
          <div class="card-body-scelta">
            <img src="assets/img/SellUp-Logo-Black.png" width="150" alt="" />
            <a routerLink="/sign-up-brand"><i class="fi fi-rr-shop"></i>COME BRAND?</a>
            <a routerLink="/sign-up-retailer" class="scelta2"><i class="fi fi-rr-shopping-bag"></i>COME RETAILER?</a>
            <a routerLink="/" class="text-light back-login"><i class="fas fa-chevron-left"></i>Torna al Login</a>


          </div>
        </div>
      </div>
  </div>


</section>

import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-google-autocomplete',
  templateUrl: './google-autocomplete.component.html',
  styleUrls: ['./google-autocomplete.component.sass']
})

export class GoogleAutocompleteComponent implements OnInit {

  constructor() { }

  @Output() addressEvent = new EventEmitter<string>();
  predictions: any[] = [];

  ngOnInit(): void {

  }

  async searchAddress(search: string) {
    if (search.length > 3) {
      const url = environment.backendPath+'/place-api/autocomplete/'+this.escapeAddress(search)
      fetch(url).then(response => {
        const json = response.json().then(json => {
          this.predictions = json.predictions;
          console.log(this.predictions, json);
        })
      })
    }
  }

  exportAddress(address: any) {
    const url = environment.backendPath+'/place-api/'+address.place_id+'/coordinates'
      fetch(url).then(response => {
        const json = response.json().then(json => {
          console.log('api2', json);
          const address_export = {...address, ...json.result}
          address_export.cap = address_export.formatted_address.split(",")[1].split(" ")[1];
          address_export.longitudine = address_export.geometry.location.lng;
          address_export.latitudine = address_export.geometry.location.lat;
          this.addressEvent.emit(address_export);
        })
      })
  }

  extractAddress(address: string) {

  }

  escapeAddress(address: string) {
    return address.replace("+", "%2B").replace(" ","%20");
  }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ClientiService } from 'app/services/clienti.service';

@Component({
  selector: 'app-clienti',
  templateUrl: './clienti.component.html',
  styleUrls: ['./clienti.component.scss']
})
export class ClientiComponent implements OnInit {

  clienti : any[] = [];
  clienti_all : any[] = [];
  canali = ['wholesale','retail','ecommerce'];
  tipi = ['RETAILER','CONSUMER','DISTRIBUTOR'];

  clienteForm = this.fb.group({
    nome: ['', [Validators.required]],
    ragione_sociale: ['', [Validators.required]],
    partita_iva: ['', [Validators.required]],
    telefono: ['', [Validators.required]],
    email: ['', [Validators.required]],
    pec: ['', [Validators.required]],
    sdi: ['', [Validators.required]],
    sede_legale: ['', [Validators.required]],
    indirizzo: ['', [Validators.required]],
    referente: ['', [Validators.required]],
    canale: ['', [Validators.required]],
    tipo: ['', [Validators.required]],
  })

  constructor(private clientiService: ClientiService, private fb: FormBuilder) { }

  get fcliente() { return this.clienteForm.controls; }

  ngOnInit(): void {
    this.getClienti();
    setTimeout(this.loadFilters, 1000);
  }

  getClienti(): void {
    this.clientiService.getClienti()
    .subscribe(resp => {
      console.log(resp.data);
      this.clienti_all = resp.data;
      this.clienti = resp.data;
    })
  }

  loadFilters(): void {
    const filtri = document.querySelectorAll('#filters select');
    filtri.forEach(filtro => {
      // @ts-expect-error
      $('#'+filtro.id).selectpicker({
        noneSelectedText: filtro.id
      })
    });
  }

  deselectAll(): void {
    // @ts-expect-error
    $('select option').attr("selected",false);
    // @ts-expect-error
    $('select').val('').trigger('change');
    // @ts-expect-error
    $('select').selectpicker('refresh');
    document.querySelectorAll('form input').forEach(input => (input as HTMLInputElement).value = '');
    this.clienti = this.clienti_all;
  }

  order(event: Event): void {
    const order = (event.target as HTMLElement).dataset.order!;
    const by = (event.target as HTMLElement).dataset.by!;
    document.querySelectorAll('th').forEach(element => element.classList.remove("order-asc","order-desc"))
    if (by == "" || by == "desc") {
      this.clienti.sort((elem1, elem2) => order.split('.').reduce((o,i)=> o[i], elem1).localeCompare(order.split('.').reduce((o,i)=> o[i], elem2)));
      (event.target as HTMLElement).dataset.by = "asc";
      (event.target as HTMLElement).classList.add("order-asc");
    }
    else {
      this.clienti.sort((elem1, elem2) => order.split('.').reduce((o,i)=> o[i], elem2).localeCompare(order.split('.').reduce((o,i)=> o[i], elem1)));
      (event.target as HTMLElement).dataset.by = "desc";
      (event.target as HTMLElement).classList.add("order-desc");
    }
  }

  filter(): void {
    this.clienti = this.clienti_all;
    const form: FormData = new FormData(document.querySelector('#filters') as HTMLFormElement);
    let dati: { [key: string]: Array<string> } = {};
    for (let entry of form.keys()) {
      console.log(entry.toString());
      dati[entry.toString()] = form.getAll(entry) as Array<string>;
    }
    console.log(dati);
    this.clienti = this.clienti.filter((cliente) =>
    { return Object.entries(dati).every(([key,arrayValue]) =>
    { return arrayValue.some((value) =>
    { return key.split('.').reduce((o,i)=> o[i], cliente).toString().toLowerCase().includes(value.toString().toLowerCase())})})});
  }

  create(): void {
    let cliente = {
      nome: this.fcliente.nome.value,
      ragione_sociale: this.fcliente.ragione_sociale.value,
      partita_iva: this.fcliente.partita_iva.value,
      telefono: this.fcliente.telefono.value,
      email: this.fcliente.email.value,
      pec: this.fcliente.pec.value,
      sdi: this.fcliente.sdi.value,
      sede_legale: this.fcliente.sede_legale.value,
      indirizzo: this.fcliente.indirizzo.value,
      referente: this.fcliente.referente.value,
      canale: this.fcliente.canale.value,
      tipo: this.fcliente.tipo.value,
      id_organizzazione: 0,
    }
    this.clientiService.createOrganizzazioneCliente(cliente)
      .subscribe(resp => {
        console.log("LARAVEL RESP:", resp);
        cliente.id_organizzazione = resp.organizzazione;
        this.clientiService.createCliente(cliente)
          .subscribe(resp => {
            if (resp.status = 200) {
              this.getClienti();
              // @ts-expect-error
              $('#creazioneModal').modal('hide');
            }
          },
          (error) => {
            console.log(error);
            alert('Errore nella creazione del cliente: ' + error.toString())
        });
      })

  }

  createOrganizzazione(cliente: any, data: any) {
    this.clientiService.createOrganizzazioneCliente(cliente)
      .subscribe(resp => {
        console.log("LARAVEL RESP:", resp);
        this.clientiService.updateCliente(data.id, {id_organizzazione: resp.organizzazione})
        .subscribe(resp => {
          if (resp.status = 200) {
            this.getClienti();
          }
        })
      })
    }

  delete(id_cliente: string): void {
    if (confirm('Vuoi cancellare il cliente?')) {
      //const id_cliente = (event.target! as HTMLElement).dataset.cliente!;
      this.clientiService.deleteCliente(id_cliente)
      .subscribe(resp => {
        if (resp.status = 200) {
          this.getClienti();
        }
      },
      (error) => {
        console.log(error);
        alert('Errore nella cancellazione del cliente: ' + error.toString())
      });
    }
  }

}


import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CampionariService {

  private campionariUrl = environment.backendPath +'/campionari/';
  private laravelUrl = environment.backendPath +'/magazzini/retailer/campionari/';

  constructor(private http : HttpClient, private auth : AuthService) { }

  getCampionari(): Observable<any> {
    return this.http.get<any>(this.campionariUrl);
  }
  getCampionario(id: number): Observable<any> {
    return this.http.get<any>(this.campionariUrl+id.toString());
  }
  getArticoli(id: number, querystring: string = ""): Observable<any> {
    const user = this.auth.getCurrentUser();
    // const id_retailer = user?.id.toString();
    return this.http.get<any>(this.campionariUrl+id.toString()+"/articoli/");
  }
  getFiltri(id: number): Observable<any> {
    return this.http.get<any>(this.campionariUrl+id.toString()+'/filtri/');
  }
  createCampionario(dati: any): Observable<any> {
    return this.http.post<any>(this.campionariUrl+'aggiungi/', dati);
  }
  exportCampionario(dati: any): Observable<any> {
    return this.http.post<any>(this.campionariUrl+'campionario_excel/export/', dati);
  }
  importCampionario(dati: any): Observable<any> {
    return this.http.post<any>(this.campionariUrl+'campionario_excel', dati);
  }
  downloadImportTemplateCampionario(): Observable<any> {
    return this.http.get<any>(this.campionariUrl+'campionario_excel/import_template');
  }
}

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent {
  title = 'r2w-frontend';
  isAuthenticated = true;

  messageError: string;
  email: string | undefined;
  password: string | undefined;

  constructor(
    private route: Router,
    public auth: AuthService
  ) {
    this.messageError = "";
  }

  goToRegistrazione(){
    this.isAuthenticated = true;
    this.route.navigate(["registrazione"]);
  }

  login() {
    this.messageError = "";
    if (this.email === undefined || this.email === "") {
      //this.messageService.showMessageError("Username non valida");
      this.messageError = "Username non valida";
      return;
    }

    if (this.password === undefined || this.password === "") {
      //this.messageService.showMessageError("Password non valida");
      this.messageError = "Username non valida";
      return;
    }

    /*this.auth.login(this.username, this.password).subscribe(
      (user: User) => {
        this.route.navigate(["/"]).then(x=> {
          window.location.reload();
        });
      },
      (err) => {
        console.error("Error login");
        this.messageError = "Errore in fase di login";
      }
    );*/

  }

  resetPwd(){}



}

<section>
  <div class="">
    <div class="">


      <div class="card card-colored overflow-hidden card-elenco">

        <h1 class="text-uppercase text-white border-bottom-ab">NEGOZI - RICHIESTE DI AUTORIZZAZIONE</h1>
        <!-- RICHIESTE AUTORIZZAZIONE -->
        <ng-container *ngIf="listShopWaiting!.length > 0">
          <div class="table-responsive">
            <table class="table table-striped bg-white table-bordered table-elenco-r classe-brand">
              <thead>
              <tr>

                <th scope="col">Negozio</th>
                <th scope="col">Azienda</th>
                <th scope="col">Brand</th>
                <th scope="col">Azioni</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let item of listShopWaiting;">

                <td>
                  <div>
                    <img loading="lazy" src="{{item.nome}}">
                  </div>
                  {{item.negozio.nome}}


                </td>
                <!-- DETTAGLI -->
                <td>
                  <b>P.IVA: {{item.partita_iva}}</b>


                  <br>


                  <a href="https://www.google.com/maps/place/?q=place_id" target="_blank">
                    {{item.negozio.indirizzo}}
                  </a>


                </td>
                <td>
                  <div>
                    <img loading="lazy" src="{{item.nome}}">
                    <br>
                    {{item.brand.nome}}
                  </div>
                </td>
                <td style="width: 10%;">
                  <div class="button-scelta">
                    <button type="button"
                            class="btn btn-secondary" (click)="setItem(item)">Rifiuta
                    </button>
                    <button type="button"
                            class="btn btn-primary" (click)="acceptRequest(item)">Accetta
                    </button>
                  </div>
                </td>
              </tr>

              </tbody>
            </table>
          </div>
        </ng-container>
        <ng-container *ngIf="listShopWaiting!.length == 0 && !modalIsVisible">
          <div class="alert alert-warning col-md-12" role="alert">
            <strong>Info!</strong> nessuna richiesta di autorizzazione
          </div>
        </ng-container>
        <!-- END RICHIESTE DI AUTORIZZAZIONE -->

      </div>


      <div class="card card-colored overflow-hidden card-elenco">
        <h1 class="text-uppercase text-white border-bottom-ab">NEGOZI AUTORIZZATI</h1>


        <!-- RATAILER AUTORIZZATI -->
        <ng-container *ngIf="listShopAuthorized!.length > 0">
          <div class="table-responsive">
            <table class="table table-striped bg-white table-bordered table-elenco-r classe-brand">
              <thead>

              <tr>
                <th scope="col">Negozi</th>
                <th scope="col">Azienda</th>
                <th scope="col">Brand</th>
                <th scope="col">Azioni</th>
              </tr>

              </thead>
              <tbody>
              <tr *ngFor="let item of listShopAuthorized; let indexOfelement=index;">
                <td>
                  <div>

                    <img loading="lazy" src="{{ item.negozio!.media_path}}">
                  </div>
                  {{ item.negozio!.nome}}
                </td>

                <td>
                  <b>P.IVA:</b>
                  {{ item.partita_iva}}

                  <br>

                  <ng-container *ngIf="item.negozio!.place_id != null">
                    <a href="https://www.google.com/maps/place/?q=place_id:{{item.negozio!.place_id}}"
                       target="_blank">
                      {{item.negozio!.formatted_address}}
                    </a>
                  </ng-container>

                  <ng-container *ngIf="item.negozio!.place_id == null">
                    {{item.negozio!.formatted_address}}
                  </ng-container>

                </td>


                <td>
                  <div class="">
                    <img loading="lazy" src="">
                    <br>
                    {{item.brand!.nome}}

                  </div>
                </td>


                <td style="width: 20%;">
                  <button (click)="setItem(item)"
                          data-toggle="modal" data-target="#modale-rimuovi-autorizzazione"
                          class="btn btn-danger border-radius-30">Rimuovi
                  </button>
                </td>
              </tr>

              </tbody>
            </table>
          </div>
        </ng-container>
        <ng-container *ngIf="listShopAuthorized!.length == 0 && !modalIsVisible">
          <div class="alert alert-warning col-md-12" role="alert">
            <strong>Info!</strong> nessun Negozio autorizzato
          </div>
        </ng-container>
        <!-- END RATAILER AUTORIZZATI -->

      </div>

      <div class="card card-colored overflow-hidden  card-elenco">

        <!-- LISTA RETAILER -->

        <div class="row">
          <div class="col-md-6">
            <h1 class="text-uppercase text-white border-bottom-ab">ELENCO NEGOZI</h1>
          </div>
          <div class="col-md-6">
            <div class="temp-emailbox temp-emailbox-search classe-r">
              <div class="temp-emailbox-form">
                <div class="input-box-col">
                  <div class="input-warp">
                    <input placeholder="Cerca" type="text" id="negozio_search" [(ngModel)]="searchText"
                           value="">
                    <button type="submit" (click)="search()"
                            class="btn-rds icon-btn btn-l-gary popover-qr">
                      <i class="fas fa-search"></i>
                      <span>Cerca</span>
                    </button>
                  </div>
                </div>
                <div class="input-box-col hidden-xs-sm">
                  <button type="reset"
                          (click)="reset()"
                          class="btn-rds icon-btn bg-theme click-to-copy copyIconGreenBtn">
                    <i class="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- RETAILER -->
        <input type="hidden" id="current_page">

        <ng-container *ngIf="listShop!.length > 0">

          <div class="table-responsive">
            <table
              class="table table-striped bg-white table-bordered table-elenco-r classe-brand">
              <thead>

              <tr>
                <th scope="col">Negozio</th>
                <th scope="col">Indirizzo</th>
                <th scope="col">Dettagli</th>
              </tr>

              </thead>
              <tbody>
              <tr *ngFor="let item of listShop;">

                <td style="text-align:left">
                  <div class="img-elenco-">
                    <img loading="lazy" src="{{item.media_path}}">
                    <span>{{item.nome}}</span>
                  </div>

                </td>

                <td>
                  <ng-container *ngIf="item.place_id != null">
                    <a href="https://www.google.com/maps/place/?q=place_id:{{item.place_id}}" target="_blank">
                      {{item.indirizzo}}
                    </a>
                  </ng-container>
                  <ng-container *ngIf="item.place_id == null">
                    {{item.indirizzo}}
                  </ng-container>

                </td>

                <td style="text-align:center">
                  <div class="social-info">
                    <!-- SITO WEB -->
                    <ng-container *ngIf="item.sito_web != null">
                      <a target="_blank" href="{{item.sito_web}}">
                        <i class="fas fa-lg fa-globe" style="cursor:pointer"></i>
                      </a>
                    </ng-container>

                    <!-- FACEBOOK -->
                    <ng-container *ngIf="item.facebook != null">
                      <a target="_blank" href="{{item.facebook}}">
                        <i class="fab fa-lg fa-facebook-f" style="cursor:pointer"></i>
                      </a>
                    </ng-container>
                    <!-- TWITTER -->
                    <ng-container *ngIf="item.twitter != null">
                      <a target="_blank" href="{{item.twitter}}">
                        <i class="fab fa-lg fa-twitter" style="cursor:pointer"></i>
                      </a>
                    </ng-container>
                    <!-- INSTAGRAM -->
                    <ng-container *ngIf="item.instagram != null">
                      <a target="_blank" href="{{item.instagram}}">
                        <i class="fab fa-lg fa-instagram" style="cursor:pointer"></i>
                      </a>
                    </ng-container>
                    <!-- LINKEDIN -->
                    <ng-container *ngIf="item.linkedin != null">
                      <a target="_blank" href="{{item.linkedin}}">
                        <i class="fab fa-lg fa-linkedin" style="cursor:pointer"></i>
                      </a>
                    </ng-container>
                    <!-- PINTEREST -->
                    <ng-container *ngIf="item.pinterest != null">
                      <a target="_blank" href="{{item.pinterest}}">
                        <i class="fab fa-lg fa-pinterest" style="cursor:pointer"></i>
                      </a>
                    </ng-container>
                  </div>

                </td>

              </tr>

              </tbody>
            </table>
          </div>

        </ng-container>
        <ng-container *ngIf="listShop!.length == 0 && !modalIsVisible">
          <div class="alert alert-warning col-md-12" role="alert">
            <strong>Info!</strong> nessun Negozio presente
          </div>
        </ng-container>
        <!-- END RATAILER -->


        <!-- END LISTA RETAILER -->

      </div>


    </div>
  </div>


  <div *ngIf="modalIsVisible"
       style="/*! margin-top: -2%; */position: fixed;top: 0;width: 100%;z-index: 10;height: 100%;right: 0;">
    <div class="modal-dialog modal-dialog-centered modal-sm">
      <div class="modal-content">
        <div class="modal-body" style="text-align: center;">
          <div class="loader" style="margin-left: 30%;"></div>
          <br>
          <h5>Caricamento dati in corso...</h5>
        </div>

      </div>
    </div>
  </div>
</section>


<div class="modal fade" id="modale-rimuovi-autorizzazione" tabindex="-1" role="dialog"
     aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal- modal-dialog-centered modal-" role="document">
    <div class="modal-content">

      <div class="modal-header">
        <h6 class="modal-title" style="text-transform: uppercase;font-size: 1.0625rem">Rimuovi Autorizzazione
          <b>{{objModal.brand}}</b>
          per il negozio <b>{{objModal.negozio}}</b>
        </h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>

      <input type="hidden" [(ngModel)]="objModal.id" value="{{objModal.id}}">
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <textarea id="textAreaRifiuto" [(ngModel)]="textAreaRifiuto" class="form-control"
                      placeholder="Srivi qui la motivazione del rifiuto..."></textarea>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" data-dismiss="modal" class="btn btn-link">Chiudi</button>
        <button type="submit" (click)="removeAuth()" class="btn btn-primary ml-auto">Conferma</button>
      </div>

    </div>
  </div>
</div>

<div class="flexC">
<div class="card-b-e-box-">
  <div class="card-b-e-">
      <div class="cardR">
        <h4> {{item.nome}}</h4>
        <a target="_blank" href="#">
          <img src="{{item.media_path}}"></a>
        <p>
          <!--- if($brandObj->telefono){ -->
          <a *ngIf="item.telefono != null" href="tel:{{item.telefono}}"><i
            class="fi fi-rs-phone-call"></i>{{item.telefono}}</a>
          <!-- }  --><br>
          <i class="fi fi-rs-envelope"></i> <a href="mailto:">{{item.email}}</a>
        </p>
      </div>
      <div class="">
        <div class="social-info">
          <!-- SITO WEB -->

          <a *ngIf="item.sito_web != null" target="_blank" href="{{item.sito_web}}">
            <i class="fas fa-lg fa-globe" style="cursor:pointer"></i>
          </a>

          <!-- FACEBOOK -->

          <a *ngIf="item.facebook != null" target="_blank" href="{{item.facebook}}">
            <i class="fab fa-lg fa-facebook-f" style="cursor:pointer"></i>
          </a>

          <!-- TWITTER -->

          <a *ngIf="item.twitter != null" target="_blank" href="{{item.twitter}}">
            <i class="fab fa-lg fa-twitter" style="cursor:pointer"></i>
          </a>

          <!-- INSTAGRAM -->

          <a *ngIf="item.instagram != null" target="_blank" href="{{item.instagram}}">
            <i class="fab fa-lg fa-instagram" style="cursor:pointer"></i>
          </a>

          <!-- LINKEDIN -->

          <a *ngIf="item.linkedin != null" target="_blank" href="{{item.linkedin}}">
            <i class="fab fa-lg fa-linkedin" style="cursor:pointer"></i>
          </a>

          <!-- PINTEREST -->

          <a *ngIf="item.pinterest != null" target="_blank" href="{{item.pinterest}}">
            <i class="fab fa-lg fa-pinterest" style="cursor:pointer"></i>
          </a>

        </div>

      </div>
      <div temp-emailbox classe-retailer *ngIf="item.richieste.length == 0">
        <!-- RICHIEDI COLLEGAMENTO -->
        <ng-container
          *ngIf="item.richieste.length == 0 || (item.richieste[0].stato != 'NON AUTORIZZATO' && item.richieste[0].stato != 'AUTORIZZATO' && item.richieste[0].stato != 'IN ATTESA')">
          <a
            data-toggle="modal" [href]="'#modale-richiedi-autorizzazione'"
            (click)="selectBrand(item)"
            class="btn btn1 w-100" style="color: white">
            RICHIEDI COLLEGAMENTO
          </a>
        </ng-container>
      </div>
      <div *ngFor="let richiesta of item.richieste">
        <!-- RICHIESTA INVIATA -->
        {{richiesta.negozio.nome}} - {{richiesta.stato}}
        <ng-container *ngIf="richiesta.stato == 'IN ATTESA'">
          <button class="btn btn-sm  border-radius-30 w-auto"
                  title=""
                  style="background: #031d6d; cursor: alias; border: #031d6d;">
            RICHIESTA INVIATA
          </button>
        </ng-container>
        <!-- AUTORIZZATO -->
        <ng-container *ngIf="richiesta.stato == 'AUTORIZZATO'">
          <button class="btn btn-sm  border-radius-30 w-auto"
                  title=""
                  style="background: #f3f5f8; cursor: alias; border: #f3f5f8; color: black">
            COLLEGATO
          </button>
        </ng-container>
        <!-- NON AUTORIZZATO -->
        <ng-container *ngIf="richiesta.stato == 'NON AUTORIZZATO'">
          <button class="btn btn-sm  border-radius-30 w-auto"
                  title=""
                  style="cursor:alias">
            NON AUTORIZZATO
          </button>
        </ng-container>
      </div>

  </div>
</div>
</div>


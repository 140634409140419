<section>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-colored card-retailer">
        <h1 class="text-uppercase text-white border-bottom-ab">
          Preordine
          <span *ngIf="!preordine"> - Nuovo</span>
          <span *ngIf="preordine"> - Modifica</span>
        </h1>
        <button class="btn agg-mag" (click)="salvaPreordine()">Salva</button>
        <div class="container-box row justify-content-center">
          <div class="col-md-12">
            <div class="box">
              <h2 class="preTitle">INFORMAZIONI ORDINE</h2>
              <div *ngIf="preordine" class="form-group">
                <label for="numero" class="form-label">Numero ordine:</label>
                <input type="text" readonly class="input-preordine form-control" id="numero"
                       [value]="preordine ? preordine.numero : ''">
              </div>
              <div class="form-group">
                <label for="data_ordine" class="form-label">Data:</label>
                <input type="date" class="input-preordine form-control" id="data_ordine" name="data_ordine"
                       [value]="preordine ? preordine.data_ordine : ''">
              </div>
              <div *ngIf="preordine" class="form-group">
                <label for="numero" class="form-label">Numero ordine:</label>
                <input type="text" readonly class="input-preordine form-control" id="numero"
                       [value]="preordine ? preordine.numero : ''">
              </div>
              <div class="form-group">
                <label for="id_campionario" class="form-label">Campionario:</label>
                <select id="campionari" class="input-preordine form-control" name="id_campionario"
                        [disabled]="preordine">
                  <option></option>
                  <option *ngFor="let campionario of campionari" value="{{campionario.id}}"
                          [selected]="preordine && preordine.id_campionario == campionario.id">{{campionario.brand.nome}} {{campionario.stagione.nome}}</option>
                </select>
              </div>
              <div class="form-group">
                <label for="id_agente" class="form-label">Agente:</label>
                <select class="input-preordine form-control" id="id_agente" name="id_agente">
                  <option></option>
                  <option *ngFor="let agente of agenti" value="{{agente.id}}"
                          [selected]="preordine && preordine.id_agente == agente.id">{{agente.ragione_sociale}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="box">
              <h2 class="preTitle">INFORMAZIONI CLIENTE</h2>
              <div class="form-group">
                <label class="form-label">Cliente</label>
                <select class="form-control input-preordine" id="id_cliente" name="id_cliente"
                        (change)="selectCliente($event)">
                  <option></option>
                  <option *ngFor="let cliente of clienti" value="{{cliente.id}}"
                          [selected]="preordine && cliente_selezionato && cliente_selezionato.id == cliente.id">{{cliente.ragione_sociale}}</option>
                </select>

              </div>
              <div *ngIf="cliente_selezionato" class="risultatoPre">
                <p><strong>Ragione sociale: </strong>{{cliente_selezionato.ragione_sociale}}</p>
                <p><strong>Partita IVA: </strong>{{cliente_selezionato.partita_iva}}</p>
                <p><strong>Sede legale: </strong>{{cliente_selezionato.sede_legale}}</p>
                <p><strong>Telefono: </strong>{{cliente_selezionato.telefono}}</p>
              </div>
              <div class="form-group">
                <label class="form-label">Indirizzo di fatturazione</label>
                <select class="form-control input-preordine" id="id_azienda_fatturazione"
                        name="id_azienda_fatturazione">
                  <option></option>
                  <option *ngFor="let azienda of aziende" value="{{azienda.id}}"
                          [selected]="azienda_selezionata && azienda_selezionata.id == azienda.id">{{azienda.ragione_sociale}}</option>
                </select>
              </div>
              <div *ngIf="azienda_selezionata" class="risultatoPre">
                <p><strong>Ragione sociale: </strong>{{azienda_selezionata.ragione_sociale}}</p>
                <p><strong>Partita IVA: </strong>{{azienda_selezionata.partita_iva}}</p>
                <p><strong>Sede legale: </strong>{{azienda_selezionata.formatted_address}}</p>
                <p><strong>Pec: </strong>{{azienda_selezionata.pec}}</p>
                <p><strong>Sdi: </strong>{{azienda_selezionata.sdi}}</p>
              </div>

              <div class="form-group">
                <label class="form-label">Indirizzo di spedizione</label>
                <select class="form-control input-preordine" id="id_magazzino_spedizione"
                        name="id_magazzino_spedizione">
                  <option></option>
                  <option *ngFor="let magazzino of magazzini" value="{{magazzino.id}}"
                          [selected]="magazzino_selezionato && magazzino_selezionato.id == magazzino.id">{{magazzino.nome}}</option>
                </select>
              </div>
              <div *ngIf="magazzino_selezionato" class="risultatoPre">
                <p><strong>Nome: </strong>{{magazzino_selezionato.nome}}</p>
                <p><strong>Indirizzo: </strong>{{magazzino_selezionato.formatted_address}}</p>
                <p><strong>Email: </strong>{{magazzino_selezionato.email}}</p>
                <p><strong>Telefono: </strong>{{magazzino_selezionato.telefono}}</p>
              </div>

              <div class="form-group">
                <label class="form-label">Pagamento</label>
                <select class="form-control input-preordine" id="id_pagamento" name="id_pagamento">
                  <option></option>
                  <option *ngFor="let pagamento of pagamenti" value="{{pagamento.id}}"
                          [selected]="preordine && pagamento.id == preordine.id_pagamento">{{pagamento.nome}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-12" *ngIf="preordine">
            <div class="box">
              <div class="titlePA">
                <h2>ARTICOLI</h2>
                <button class="btn btn1" data-toggle="modal" data-target="#prodottiModal">Aggiungi
                </button>
              </div>
              <table class="table table-mc-light-blue">
                <thead>
                <tr>
                  <th scope="col" (click)="order($event)" data-order="codice" data-by="">Codice</th>
                  <th scope="col" (click)="order($event)" data-order="nome" data-by="">Descrizione</th>
                  <th scope="col" (click)="order($event)" data-order="quantita" data-by="">Quantita</th>
                  <th scope="col" (click)="order($event)" data-order="prezzo_unitario" data-by="">Prezzo
                    unitario
                  </th>
                  <th scope="col" (click)="order($event)" data-order="prezzo_totale" data-by="">Prezzo
                    totale
                  </th>
                  <th scope="col" (click)="order($event)" data-by="">Modifica</th>
                </tr>
                </thead>
                <tbody *ngFor="let prodotto of prodotti">
                <tr>
                  <td>{{prodotto.codice}}</td>
                  <td>{{prodotto.nome}}</td>
                  <td>{{prodotto.quantita}}</td>
                  <td>{{prodotto.prezzo_unitario.toFixed(2)}} €</td>
                  <td>{{prodotto.prezzo_totale.toFixed(2)}} €</td>
                  <td>
                    <div class="btn-group">
                      <a class="btn btn1" data-toggle="collapse"
                         href="#articolo{{prodotto.codice}}" role="button" aria-expanded="false"
                         aria-controls="collapseExample">Dettagli</a>
                      <a class="btn btn2" role="button"
                         (click)="rimuoviProdotto(prodotto.codice)">Rimuovi</a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="100%" class="p-0">
                    <div class="collapse" id="articolo{{prodotto.codice}}">
                      <div class="risultatoPre">
                        <div class="d-flex mb-3">
                          <div *ngFor="let variante of prodotto.varianti" class="input-group mx-2">
                            <div class="input-group-prepend">
                              <span class="input-group-text bg-gray text-white">{{variante.taglia}}</span>
                            </div>
                            <input type="number" class="form-control fs-6"
                                   [attr.data-articolo-preordine]="variante.id_prodotto_preordine"
                                   [attr.data-articolo]="variante.id_articolo"
                                   [attr.data-variante]="variante.id" [attr.data-colore]="variante.id_colore"
                                   [attr.data-tessuto]="variante.id_tessuto"
                                   [attr.data-taglia]="variante.id_taglia_tipologia_descrizione"
                                   [value]="variante.quantita" min="0">
                          </div>
                        </div>
                        <div class="text-end">
                          <button class="btn btn1" (click)="ordinaProdotto(prodotto.codice)">
                            Salva
                          </button>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div *ngIf="preordine" class="modal fade" id="prodottiModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Aggiongi articoli</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Chiudi">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngFor="let prodotto of prodotti_campionario" class="mb-2">
          <div class="d-flex justify-content-between align-items-center">
            <div>{{prodotto.id}} - {{prodotto.nome}}</div>
            <button class="btn btn2" (click)="aggiungiProdotto(prodotto.id)">Aggiungi
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

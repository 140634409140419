import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/auth/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.sass']
})
export class DashboardComponent implements OnInit {

  roleUserLogged :string |undefined;

  constructor(private http: HttpClient, private service: AuthService) { }

  ngOnInit(): void {
    this.service.getCurrentUserAsync().then(value => {
      console.log(`promise result: ${value}`);
      this.roleUserLogged = value?.tipo;
    });
  }

}

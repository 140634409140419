<div class="row">
    <div class="col-md-12">
        <h1 class="text-uppercase">Dettaglio Listino</h1>
        <div class="container-box" *ngIf="data">
            <div class="box">
                <div class="row">
                    <div class="col-md-6">
                        <a style="padding: 5px 20px" class="btn btn1" data-toggle="collapse"
                            href="#clientiAssociati">Visualizza clienti cui è associato il listino</a>
                        <a style="padding: 5px 20px" class="btn btn1" data-toggle="modal" data-target="#addArticleModal">
                            Aggiungi Articolo
                        </a>
                        <a style="padding: 5px 20px" class="btn btn1" data-toggle="modal" data-target="#downloadTemplateModel">
                            Download Import Template XLSX
                        </a>
                        <a style="padding: 5px 20px" class="btn btn1" data-toggle="modal" data-target="#importModal">
                            Import XLSX
                        </a>
                        <a style="padding: 5px 20px" class="btn btn1" data-toggle="modal" (click)="downloadExportListino()">
                            Export XLSX
                        </a>
                    </div>
                </div>
                <div class="row">
                    <div class="collapse col-md-6" id="clientiAssociati">
                        <table class="table tabella-dettaglio">
                            <thead class="thead-light">
                                <tr>
                                    <th>Nome</th>
                                    <th>Ragione Sociale</th>
                                    <th>Tipo</th>
                                    <th>Mercato</th>
                                </tr>
                                <tr *ngFor="let row of data.clienti">
                                    <td>{{row!.cliente.nome}}</td>
                                    <td>{{row!.cliente.ragione_sociale}}</td>
                                    <td>{{row!.cliente.tipo}}</td>
                                    <td>{{data!.mercato!.nome}}</td>
                                    <!-- TOFIX: da dove prendo il mercato? L'associazione al cliente non esiste -->
                                </tr>

                            </thead>
                        </table>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <table class="table tabella-dettaglio">
                            <thead class="thead-light">
                                <tr>
                                    <th scope="col"><i class="fas fa-camera-retro"></i></th>
                                    <th scope="col">Stagione</th>
                                    <th scope="col">Brand</th>
                                    <th scope="col">Cod. Articolo</th>
                                    <th scope="col">Cod. Variante</th>
                                    <th scope="col">Mercato</th>
                                    <th scope="col">Prezzo Sellin</th>
                                    <th scope="col">Prezzo Sellout</th>
                                    <th scope="col">Modifica</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let row of data.articoli">
                                    <tr>
                                        <td></td>
                                        <td>{{row!.articolo!.stagione!.nome}} </td>
                                        <td>{{row!.articolo!.brand!.nome}} </td>
                                        <td>{{row!.articolo!.codice_articolo}}</td>
                                        <td>{{row!.articolo_variante!.codice}}</td>
                                        <td>{{data.mercato!.nome}}</td>
                                        <td>{{row!.prezzo_sellin}} {{data.mercato!.valuta}}</td>
                                        <td>{{row!.prezzo_sellout}} {{data.mercato!.valuta}}</td>

                                        <td>
                                            <a style="padding: 5px 20px" class="btn btn-secondary" data-toggle="collapse"
                                                href="#articolo{{row!.articolo!.codice_articolo.trim()}}">MODIFICA</a>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="9">
                                            <div class="collapse" id="articolo{{row!.articolo!.codice_articolo.trim()}}">
                                                <table class="table">
                                                    <thead class="thead-dark">
                                                        <tr>
                                                            <th scope="col">Prezzo Sellin</th>
                                                            <th scope="col">Prezzo Sellout</th>
                                                            <th scope="col"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <input class="form-control" type="text" name="prezzo_sellin" [(ngModel)]="row.prezzo_sellin" [placeholder]="row!.prezzo_sellin + ' ' + data.mercato!.valuta">
                                                            </td>
                                                            <td>
                                                                <input class="form-control" type="text" name="prezzo_sellout" [(ngModel)]="row.prezzo_sellout" [placeholder]="row!.prezzo_sellout + ' ' + data.mercato!.valuta">
                                                            </td>
                                                            <td>
                                                                <button title="" type="button" class="btn btn1" (click)="onUpdateArticoloClick(data.id, row.id, row.prezzo_sellin, row.prezzo_sellout)">
                                                                    <i class="fas fa-check"></i> Conferma
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>

                                </ng-container>
                            </tbody>

                        </table>
                    </div>
                </div>

                <div class="modal fade" id="addArticleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Aggiungi Articolo</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="form-group">
                                    <label for="inputField1">Codice Articolo</label>
                                    <select type="text" class="form-control" placeholder="Codice Articolo" [(ngModel)]="addArticleData.codice_articolo" (change)="updateSelect()">
                                        <option default selected hidden></option>
                                        <option *ngFor="let art of selectOptions.articoli" [value]="art.id">{{art?.articolo?.codice_articolo}}</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label for="inputField1">Codice Tessuto</label>
                                    <select type="text" class="form-control" placeholder="Codice Tessuto" [(ngModel)]="addArticleData.codice_tessuto" (change)="updateSelect()">
                                        <option default selected hidden></option>
                                        <option *ngFor="let art of selectOptions.tessuti" [value]="art.id">{{art?.codice}} - {{art?.nome}}</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label for="inputField1">Codice Colore</label>
                                    <select type="text" class="form-control" placeholder="Codice Colore" [(ngModel)]="addArticleData.codice_colore">
                                        <option default selected hidden></option>
                                        <option *ngFor="let art of selectOptions.colori" [value]="art.id">{{art?.codice}} - {{art?.nome}}</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label for="inputField1">Prezzo Sellin</label>
                                    <input class="form-control" placeholder="Prezzo Sellin" [(ngModel)]="addArticleData.prezzo_sellin" />
                                </div>
                                <div class="form-group">
                                    <label for="inputField1">Prezzo Sellout</label>
                                    <input class="form-control" placeholder="Prezzo Sellout" [(ngModel)]="addArticleData.prezzo_sellout" />
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="button" class="btn btn-primary" (click)="addArticoloToListino()">Save changes</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="downloadTemplateModel" tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Download Import Template</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="form-group">
                                    <label for="inputField1">Brand</label>
                                    <select type="text" class="form-control" placeholder="Brand" [(ngModel)]="downloadTemplateData.brandId">
                                        <option default selected hidden>Brands...</option>
                                        <option *ngFor="let elm of selectOptions.brands" [value]="elm.id">{{elm?.nome}}</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label for="inputField1">Brand</label>
                                    <select type="text" class="form-control" placeholder="Brand" [(ngModel)]="downloadTemplateData.stagioneId">
                                        <option default selected hidden>Stagioni...</option>
                                        <option *ngFor="let elm of selectOptions.stagioni" [value]="elm.id">{{elm?.nome}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="button" class="btn btn-primary" (click)="downloadTemplate()">Download</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="importModal" tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog modal-xl" role="document">
                        <div class="modal-content" style="min-height:80vh">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Importa Excel</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="form-group">
                                    <label for="inputField1">Import Excel</label>
                                    <input class="form-control" type="file" placeholder="Excel" id="listinoImportXlsx" accept=".xlsx">
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal" id="closeImportModal">Close
                                </button>
                                <button type="button" class="btn btn-primary" (click)="importListino()">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

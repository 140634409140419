<section>
  <div class="">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-colored card-retailer">
          <h1 class="text-uppercase text-white border-bottom-ab">Marketplace</h1>
          <div class="button-mag">

            <div class="row">
              <div class="col-md-2 pr-0">
                <select id="brand" multiple="multiple" required
                        class="form-control select2-brand" [(ngModel)]="filter.brand"
                        data-live-search="true">
                  <option value="">BRAND</option>
                  <ng-container *ngFor="let item of brand_for_search">
                    <option value="{{item.id}}">{{item.nome}}</option>
                  </ng-container>

                </select>
              </div>
              <div class="col-md-2 pr-0">
                <input type="text" name="codice_articolo"
                       value="" [(ngModel)]="filter.codice_articolo"
                       placeholder="CODICE ARTICOLO" class="form-control">
              </div>
              <div class="col-md-2 pr-0">
                <input type="text" class="form-control" [(ngModel)]="filter.barcode"
                       value=""
                       placeholder="RICERCA BARCODE">
              </div>
              <div class="col-md-2 pr-0">
                <select id="stagioni" multiple="multiple" placeholder="Stagione" required
                        class="form-control select2-stagioni" [(ngModel)]="filter.stagioni"
                        data-live-search="true">
                  <option value="">STAGIONE</option>
                  <ng-container *ngFor="let item of stagioni_for_search">
                    <option value="{{item.id}}">{{item.nome}}</option>
                  </ng-container>
                </select>
              </div>
              <div class="col-md-2 pr-0">
                <select id="collezioni" multiple="multiple" placeholder="Collezione"
                        required class="form-control select2-collezioni" [(ngModel)]="filter.collezioni"
                        data-live-search="true">
                  <option value="">COLLEZIONE</option>
                  <ng-container *ngFor="let item of collezioni_for_search">
                    <option value="{{item.id}}">{{item.nome}}</option>
                  </ng-container>
                </select>
              </div>

            </div>
            <div class="row">
              <div class="col-md-2 pr-0">
                <select id="tessuti" multiple="multiple" placeholder="Tessuto" required
                        class="form-control select2-tessuti" [(ngModel)]="filter.tessuti"
                        data-live-search="true">
                  <option value="">TESSUTO</option>
                  <ng-container *ngFor="let item of tessuti_for_search">
                    <option value="{{item.id}}">{{item.nome}}</option>
                  </ng-container>
                </select>
              </div>
              <div class="col-md-2 pr-0">
                <select id="colori" multiple="multiple" placeholder="Colore" required
                        class="form-control select2-colori" [(ngModel)]="filter.colori"
                        data-live-search="true">
                  <option value="">COLORE</option>
                  <ng-container *ngFor="let item of colori_for_search">
                    <option value="{{item.id}}">{{item.nome}}</option>
                  </ng-container>
                </select>
              </div>
              <div class="col-md-2 pr-0">
                <select (ngModelChange)="sectorChange($event)"
                        id="settori" multiple [(ngModel)]="filter.settore"
                        placeholder="Settore" required
                        class="form-control select2-settori"
                        data-live-search="true">
                  <option value="">SETTORE</option>
                  <ng-container *ngFor="let item of settori_for_search">
                    <option value="{{item.id}}">{{item.nome}}</option>
                  </ng-container>
                </select>
              </div>

              <div class="col-md-2 pr-0">
                <select (ngModelChange)="macroChange($event)"
                        id="categorie" multiple placeholder="Categorie" required
                        [(ngModel)]="filter.macrocategorie"
                        class="form-control select2-categorie"
                        data-live-search="true">
                  <option value="">MACROCATEGORIA</option>
                  <ng-container *ngFor="let item of macrocategorie_for_search">
                    <option value="{{item.id}}">{{item.nome}}</option>
                  </ng-container>

                </select>
              </div>


              <div class="col-md-2 pr-0">
                <select id="categorie" (ngModelChange)="categoryChange($event)"
                        multiple placeholder="Categorie" required [(ngModel)]="filter.categorie"
                        class="form-control select2-categorie"
                        data-live-search="true">
                  <option value="">CATEGORIA</option>
                  <ng-container *ngFor="let item of categorie_for_search">
                    <option value="{{item.id}}">{{item.nome}}</option>
                  </ng-container>

                </select>
              </div>

              <div class="col-md-2 pr-0">
                <select id="categorie" multiple placeholder="Categorie" required
                        [(ngModel)]="filter.sottocategorie"
                        class="form-control select2-categorie"
                        data-live-search="true">
                  <option value="">SOTTOCATEGORIA</option>
                  <ng-container *ngFor="let item of sottocategorie_for_search">
                    <option value="{{item.id}}">{{item.nome}}</option>
                  </ng-container>

                </select>
              </div>
            </div>

            <div class="row">
              <div class="col-md-2 pr-0">
                <select id="taaglie" multiple="multiple" placeholder="Taglia" required
                        class="form-control select2-taglie" [(ngModel)]="filter.taglie"
                        data-live-search="true">
                  <option value="">TAGLIA</option>
                  <ng-container *ngFor="let item of taglie_for_search">
                    <option value="{{item.id}}">{{item.nome}}</option>
                  </ng-container>
                </select>
              </div>

              <div class="col-md-2 pr-0">
                <select id="taaglie" multiple="multiple" placeholder="Taglia" required
                        class="form-control select2-taglie" [(ngModel)]="filter.taglia_descrizione"
                        data-live-search="true">
                  <option value="">TAGLIA DESCRIZIONE</option>
                  <ng-container *ngFor="let item of taglie_for_search">
                    <option value="{{item.id}}">{{item.nome}}</option>
                  </ng-container>
                </select>
              </div>

              <div class="col-md-2">
                <div class="giac-input">
                  <label class="giac-label">GIACENZE</label>
                  <input id="s1" type="checkbox" class="switch" [(ngModel)]="filter.giacenza" name="giacenza">
                </div>
              </div>

              <div class="col-md-2 pr-0">
                <div class="form-flex">
                  <input type="number" class="form-control" [(ngModel)]="filter.prezzo_dal"
                         placeholder="PREZZO DAL"
                         name="prezzo_dal">
                  <input type="number" class="form-control" [(ngModel)]="filter.prezzo_al"
                         placeholder="PREZZO AL"
                         name="prezzo_al">
                </div>

              </div>


            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="button-flex">
                  <button title="" type="button"
                          class="btn btn1">
                    <i class="fas fa-file-excel"></i> Export
                  </button>
                  <button type="button" (click)="resetForm()" title="" class="btn btn2">
                    <i class="fas fa-times"></i> resetta
                  </button>
                  <button type="button" title=""
                          (click)="search()"
                          class="btn btn3"
                          style="width: 100%">
                    <i class="fas fa-search"></i> Cerca
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="modalIsVisible" style="margin-top:-2%">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-body" style="text-align: center;">
          <div class="loader" style="margin-left: 30%;"></div>
          <br>
          <h5>Caricamento dati in corso...</h5>
        </div>

      </div>
    </div>
  </div>
</section>

<section>
    <div class="row">
      <div id="containerListaArticoli">
        <!-- LISTA ARTICOLI -->
        <ng-container *ngIf="listArticles.length > 0">

          <ng-container *ngFor="let item of listArticles; let indexOfelement=index;">
            <app-market-place-articles [item]=item></app-market-place-articles>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="listArticles.length == 0 && !modalIsVisible">
          <div class="alert alert-warning col-md-12" role="alert">
            <strong>Info!</strong> Nessun articolo censito
          </div>
        </ng-container>
      </div>
    </div>
</section>

<div class="box" id="contListiniList">
    <div class="table-responsive">

        <table id="table" class="table table-hover table-mc-light-blue">
            <thead>
                <tr>
                    <th><b>Nome</b></th>
                    <th><b>Brand</b></th>
                    <th><b>Stagione</b></th>
                    <th><b>Tipo Cliente</b></th>
                    <th><b>Valuta</b></th>
                    <th><b>Dettaglio</b></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngIf="list.length > 0">
                    <tr *ngFor="let elm of list; let indexOfelement=index;">
                        <td>{{elm.nome}}</td>
                        <td>{{elm.organizzazione?.nome}}</td>
                        <td>Stagione Placeholder</td>
                        <td>{{elm.mercato.nome}}</td>
                        <td>{{elm.mercato.valuta}}</td>

                        <td>

                            <!-- <i
                                style="cursor:pointer;color:green"
                                class="fas fa-file-excel excell"></i>
                            <i
                                style="cursor:pointer;color:red" class="fas fa-file-pdf"></i> -->
                            <a routerLink="/listini-detail/{{elm.id}}">
                                <i
                                    style="cursor:pointer"
                                    class="fas fa-angle-double-right"></i>
                            </a>


                        </td>
                    </tr>
                </ng-container>
                <ng-container *ngIf="list.length == 0">
                    <tr>
                        <td colspan="7" style="text-align:center">NESSUN LISTINO
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from "environments/environment";


@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  api: string = environment.api;
  constructor(private http: HttpClient) { }

  //suppongo che va cambiato il path che è uguale al retailer
  getDataBySuperAdmin(negozio: string) : Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/dashboard/retailer/${negozio}`);
  }

  getDataByBrand(negozio: string) : Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/dashboard/brand/${negozio}`);
  }


  getDataByRetailer(negozio: string) : Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/dashboard/retailer/${negozio}`);
  }



  getDataCarrelloBadge(id_retailer: bigint) : any{
    return this.http.get<any>(`${this.api}/carrello/badge?id_retailer=${id_retailer}`).toPromise();
  }



}

<div class="row">
  <div class="col-md-12">
    <div class="card card-colored classe-retailer">
      <h1 class="text-uppercase">CHECKOUT RETAILER</h1>
      <div class="container-box row">
        <div class="col-md-8">
          <div class="box riepilogo-checkout">
            <div class="card-check">
              <div class="imgCheck">
                <img src="/assets/img/box-open.png" alt=""/>
              </div>
              <h2 class="card__title"><i class="fi fi-rr-angle-small-right"></i>Dati Spedizione</h2>
              <div class="form-group">
                <select class="form-control selectCarrello">
                  <option value="">Seleziona</option>
                  <option>Sofita, Viale delle Provincie, Roma (00162)</option>
                </select>
              </div>
              <div class="ind-spe card-indirizzo-spedizione">
                <div class="dati-spe">
                  <p class="card__text">
                    <span>gaja sabatino</span><br>
                    <span>Corso Lodi, 38 Milano (20135)</span><br>
                    <span>gaja.sabatino@gmail.com</span><br>
                    <span>3938828440</span>
                  </p>
                </div>
              </div>
              <p>
                <a class="btn btn1 mt-2 mb-2" data-toggle="collapse" href="#nuovoIndirizzo"
                   role="button" aria-expanded="false" aria-controls="nuovoIndirizzo">
                  Aggiungi un nuovo indirizzo
                </a>
              </p>
              <div class="row">
                <div class="col">
                  <div class="collapse multi-collapse" id="nuovoIndirizzo">
                    <div class="card card-body card-ind">
                      <div class="row">
                        <div class="col-md-12">
                          <input type="text" placeholder="Nome e Cognome">
                        </div>
                        <div class="col-md-12">
                          <input type="text" autocomplete="off"
                                 placeholder="Indirizzo">
                        </div>
                        <div class="col-md-12">
                          <input type="number" placeholder="Telefono">
                        </div>
                        <div class="col-md-12">
                          <input type="email" placeholder="Email">
                        </div>
                        <div class="col-md-12 text-right">
                          <button type="button" class="btn btn1">
                            SALVA
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-check mt-4">
              <div class="imgCheck">
                <img src="assets/img/wallet-arrow.png" alt="" />
              </div>
              <h2 class="card__title">
                <i class="fi fi-rr-angle-small-right"></i>Pagamento</h2>

              <div class="mt-4">

                <div class="form-group">
                  <label>Modalità di pagamento</label>
                  <select class="form-control">
                    <option>Bonifico</option>
                  </select>
                  <span class="material-input"></span></div>

                <div class="form-group">
                  <label>Fatturazione</label>
                  <select class="form-control selectCarrello">
                    <option>Seleziona</option>
                    <option>sofita srl</option>
                  </select>
                </div>
                <div class="ind-spe card-indirizzo-spedizione">
                  <span>gaja sabatino</span><br>
                  <span>Corso Lodi, 38 Milano (20135)</span><br>
                  <span>gaja.sabatino@gmail.com</span><br>
                  <span>3938828440</span>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="col-md-4 pl-0">
          <div class="checkList">
            <div class="checkTitle">
              <div class="flexBox">
                <h2 class="text-uppercase">carrello</h2>
                <span class="qtaCarrello">2</span>
              </div>
              <div class="zig-zag"></div>

              <h1 style="color: black">446,52 €</h1>
            </div>
            <div id="accordion">
              <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne"
                      aria-expanded="false" aria-controls="collapseOne">
                <span>Alexander Hotto<i class="fas fa-angle-down rotate-icon"></i></span><span
                class="text-right">300€</span>
              </button>
              <div id="collapseOne" class="collapse" aria-labelledby="headingOne"
                   data-parent="#accordion">
                <div class="accordionBody">
                  3 X Derby - 60000 -
                  Sensory Corvino - - <br>
                  <span style="margin-left: 4%;">1X42½, 2X43</span>
                  <div class="editCheck"><i class="fi fi-rr-edit"></i> </div>
                </div>
              </div>
            </div>
            <div id="accordion">
              <button class="btn btn-link" data-toggle="collapse" data-target="#collapse2"
                      aria-expanded="false" aria-controls="collapse2">
                <span>Alexander Hotto<i class="fas fa-angle-down rotate-icon"></i></span><span
                class="text-right">300€</span>
              </button>
              <div id="collapse2" class="collapse" aria-labelledby="heading2"
                   data-parent="#accordion">
                <div class="accordionBody">
                  3 X Derby - 60000 -
                  Sensory Corvino - - <br>
                  <span style="margin-left: 4%;">1X42½, 2X43</span>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table" style="background: #f4f5f8; color: #000; margin-top: 20px">
                <tbody>
                <tr>
                  <td scope="row">IVA</td>
                  <td scope="col" class="text-right">80,52 €
                  </td>
                </tr>
                <tr>
                  <td scope="row">TOTALE</td>
                  <td scope="col" class="text-right">446,52 €
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <button class="btn btn1 mt-3" style="width: 100%;">Paga subito</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

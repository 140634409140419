import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from "environments/environment";
@Injectable({
  providedIn: 'root'
})
export class MercatiService {
  api: string = environment.api;
  constructor(private http: HttpClient) { }

  getList(): Promise<any[]> {
    return this.http.get<any[]>(`${this.api}/mercati`).toPromise();
  }

  getOne(id: number): Promise<any[]> {
    return this.http.get<any[]>(`${this.api}/mercati/${id}`).toPromise();
  }

}

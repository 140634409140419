<div class="main-content" id="panel">

    <app-header></app-header>

    <app-sidebar></app-sidebar>
    <section>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card card-colored card-retailer">
                        <h1 class="text-uppercase text-white border-bottom-ab">Magazzino</h1>
                        <div id="main">
                            <button class="btn agg-mag">
                                Aggiungi
                            </button>
                        </div>
                        <div class="button-mag">
                            <form action="/magazzini/retailer" id="formMagazziniRetailer" method="GET">
                                <input type="hidden" name="export" value="0">


                                <div class="row">
                                    <div class="col-md-2 pr-0">
                                        <select id="brand" multiple="multiple" required
                                                class="form-control select2-brand" name="brand[]"
                                                data-live-search="true">
                                                    <option value="">nome</option>

                                        </select>
                                    </div>
                                    <div class="col-md-2 pr-0">
                                        <input type="text" name="codice_articolo"
                                            value=""
                                            placeholder="CODICE ARTICOLO" class="form-control">
                                    </div>
                                    <div class="col-md-2 pr-0">
                                        <input type="text" class="form-control" name="barcode"
                                            value=""
                                            placeholder="RICERCA BARCODE">
                                    </div>
                                    <div class="col-md-2 pr-0">
                                        <select id="stagioni" multiple="multiple" placeholder="Stagione" required
                                                class="form-control select2-stagioni" name="stagioni[]"
                                                data-live-search="true">
                                                <option value="">nome</option>
                                        </select>
                                    </div>
                                    <div class="col-md-2 pr-0">
                                        <select id="collezioni" multiple="multiple" placeholder="Collezione"
                                                required class="form-control select2-collezioni"
                                                name="collezioni[]" data-live-search="true">
                                                <option value="">nome</option>
                                        </select>
                                    </div>
                                    <div class="col-md-2 pr-0">
                                        <select id="taaglie" multiple="multiple" placeholder="Taglia" required
                                                class="form-control select2-taglie" name="taglie[]"
                                                data-live-search="true">
                                                <option value="">nome</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-2 pr-0">
                                        <select id="tessuti" multiple="multiple" placeholder="Tessuto" required
                                                class="form-control select2-tessuti" name="tessuti[]"
                                                data-live-search="true">
                                                <option value="">nome</option>
                                        </select>
                                    </div>
                                    <div class="col-md-2 pr-0">
                                        <select id="colori" multiple="multiple" placeholder="Colore" required
                                                class="form-control select2-colori" name="colori[]"
                                                data-live-search="true">
                                                <option value="">nome</option>
                                        </select>
                                    </div>
                                    <div class="col-md-2 pr-0">
                                        <select id="settori" multiple onchange="changeSettore(this)"
                                                placeholder="Settore" required
                                                class="form-control select2-settori" name="settore[]"
                                                data-live-search="true">
                                                <option value="">nome</option>
                                        </select>
                                    </div>
                                    <div class="col-md-2 pr-0">
                                        <select id="categorie" multiple placeholder="Categorie" required
                                                class="form-control select2-categorie" name="categorie[]"
                                                data-live-search="true">

                                        </select>
                                    </div>
                                    <div class="col-md-2 pr-0">
                                        <div class="form-flex">
                                            <input type="number" class="form-control" placeholder="PREZZO DAL"
                                                name="prezzo_dal">
                                            <input type="number" class="form-control" placeholder="PREZZO AL"
                                                name="prezzo_al">
                                        </div>

                                    </div>
                                    <div class="col-md-2">
                                        <div class="giac-input">
                                            <label class="giac-label">GIACENZE</label>
                                            <input id="s1" type="checkbox" class="switch" name="giacenza">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="button-flex">
                                            <button title="" type="button"
                                                    class="btn btn1">
                                                <i class="fas fa-file-excel"></i> Export
                                            </button>
                                            <button type="button" onclick="resetForm()" title="" class="btn btn2">
                                                <i class="fas fa-times"></i> resetta
                                            </button>
                                            <button type="button" title=""
                                                    class="btn btn3"
                                                    style="width: 100%">
                                                <i class="fas fa-search"></i> Cerca----
                                            </button>
                                        </div>
                                    </div>

                                </div>


                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <!-- LISTA ARTICOLI -->
    <ng-container *ngIf="listArticles.length > 0">

        <ng-container  *ngFor="let item of listArticles; let indexOfelement=index;">
            <app-detail-articolo-retailer [item] = item></app-detail-articolo-retailer>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="listArticles.length == 0">
        <div class="alert alert-warning col-md-12" role="alert">
            <strong>Info!</strong> Nessun articolo censito
        </div>
    </ng-container>


<app-footer></app-footer>

</div>

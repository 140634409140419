import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  api: string = environment.api;
  constructor(private http: HttpClient) { }


  createCompany(company: any): Observable<any> {
    return this.http.post<any>(this.api + "/aziende/aggiungi", company);
  }

  updateCompany(company: any): Observable<any> {
    return this.http.post<any>(this.api + "/aziende/modifica", company);
  }

  updateCompanyCondizioni(company: any): Observable<any> {
    return this.http.post<any>(this.api + "/aziende/modifica/condizioni", company);
  }

  deleteCompany(obj: any): Observable<any> {
    return this.http.post<any>(this.api + "/aziende/elimina", obj);
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'app/auth/auth.service';
import { ArticoloService } from 'app/services/articolo.service';

@Component({
  selector: 'app-detail-article',
  templateUrl: './detail-article.component.html',
  styleUrls: ['./detail-article.component.sass']
})
export class DetailArticleComponent implements OnInit {
  constructor(private auth: AuthService, private articoloService: ArticoloService, private route: ActivatedRoute) {  }

  public message : string = "";
  public messageclass : string = "";
  public loading : boolean = true;
  public article : any = { 'prezzo_ingrosso' : '0.00', 'prezzo_dettaglio' : '0.00' };
  public variante : any = { };
  public filters : any = {'stagioni': [], 'collezioni': [], 'settori': [], 'taglieTipologie':[], 'tessuti' : [], 'brand'  : [], 'colori' : []}
  public table: any[] = []


  ngOnInit(): void {
    const user = this.auth.getCurrentUser();
    console.log(this.filters);
    this.articoloService.getCreateData()
    .subscribe( resp => {
      this.filters = resp.data;
      console.log(this.filters);
      this.getArticle();
    });
  }

  getData(): void {
    this.articoloService.getCreateData()
    .subscribe( resp => {
      this.filters = resp.data;
      this.loading = false;
    });
  }

  getArticle(): void {
    this.route.queryParams
      .subscribe(params => {
        let  id_articolo = params.id_articolo;
        let  id_tessuto = params.id_tessuto;
        let  id_colore = params.id_colore;
        this.articoloService.getDetail(id_articolo, id_tessuto, id_colore).then((resp :any)=> {
          console.log(`promise result:`, resp['data']);
          this.article = { ...resp['data']['articolo'], ...resp['data']['articoloVariante'] }
          this.variante = resp['data']['articoloVariante'];
          this.article.id_brand = this.filters.brand[0].id ?? 0;
          this.article.id_articolo_variante = this.variante.id;
          this.article.categoria = this.article.categorie.split(" - ")[0];
          this.article.id_categoria = parseInt(this.filters.categorie.find((element: { nome: string; }) => {return element.nome.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) == this.article.categoria})?.id ?? 0);
          this.article.nome_articolo = this.article.nome;
          this.article.prezzo_dettaglio = this.article.prezzo_vendita_dettaglio
          this.article.prezzo_ingrosso = this.article.prezzo_vendita_ingrosso
          this.loading = false;
          this.table = [];
          resp['data']['tessutoColoreTaglieDescrizioni'].forEach((row: any) => {
            row['id_variante'] = row['id'];
            row['giacenza'] = this.auth.getCurrentUser()?.tipo == "BRAND" ? row['giacenza_brand']['quantita'] : row['giacenza_retailer_magazzino'] ?? 0;
            this.table.push({...row['giacenza_brand'], ...row});
          });
          console.log(this.article);
          console.log(this.table);
        });
      });
  }

  saveDatiGenerali(): void {
    this.article.id_categoria = parseInt(this.article.id_categoria) ?? null;
    console.log(this.article);
    const errors: string[] = [];
    if ((this.article.codice_articolo ?? "") == "") {
      errors.push("Codice articolo mancante");
    }
    if ((this.article.nome_articolo ?? "") == "") {
      errors.push("Nome articolo mancante");
    }
    if ((this.article.settore ?? "") == "") {
      errors.push("Selezionare un settore");
    }
    if ((this.article.stagione ?? "") == "") {
      errors.push("Selezionare una stagione");
    }
    if ((this.article.collezione ?? "") == "") {
      errors.push("Selezionare una collezione");
    }
    if ((this.article.id_categoria ?? "") == "") {
      errors.push("Selezionare una categoria");
    }
    if (errors.length > 0) {
      alert(errors.join("\n"));
    }
    else {
      this.articoloService.updateArticle(this.article)
      .subscribe(resp => {
        console.log('RISULTATO: ', resp.data);
        this.message = "Articolo salvato";
        this.messageclass = "bg-success"
        this.loading = true;
        this.getArticle();
      },
      (error) => {
        this.message = "Errore nel salvataggio: "+error;
        this.messageclass = "bg-danger"
      }
    );
    }
  }

  savePrices() {
    const errors: string[] = [];
    if (!(parseFloat(this.article.prezzo_ingrosso) >= 0 && parseFloat(this.article.prezzo_dettaglio) >= 0)) {
      console.log(parseFloat(this.article.prezzo_dettaglio));
      errors.push("Inserire prezzi corretti");
    }
    if (errors.length > 0) {
      alert(errors.join("\n"));
    }

    else {
      this.article.prezzo_ingrosso = parseFloat(this.article.prezzo_ingrosso)
      this.article.prezzo_dettaglio = parseFloat(this.article.prezzo_dettaglio)
      this.articoloService.updatePrices(this.article)
      .subscribe(resp => {
        console.log('RISULTATO: ', resp);
        this.message = "Prezzi aggiornati con successo";
        this.messageclass = "bg-success"
        this.loading = true;
        this.getArticle();
      },
      (error) => {
        this.message = "Errore nel salvataggio: "+error;
        this.messageclass = "bg-danger"
      }
    );
    }
  }

  saveDescriptions() {
    this.articoloService.updateDescription(this.article)
      .subscribe(resp => {
        console.log('RISULTATO: ', resp);
        this.message = "Descrizioni aggiornate con successo";
        this.messageclass = "bg-success"
        this.loading = true;
        this.getArticle();
      },
      (error) => {
        this.message = "Errore nel salvataggio: "+error;
        this.messageclass = "bg-danger"
      }
    );
  }

  saveTessutoColore() {
    this.articoloService.updateTessutoColore(this.article)
      .subscribe(resp => {
        console.log('RISULTATO: ', resp);
        window.location.href = resp['path'];
        this.message = "Colore/Tessuto aggiornati con successo";
        this.messageclass = "bg-success"
        this.loading = true;
        this.getArticle();
      },
      (error) => {
        this.message = "Errore nel salvataggio: "+error;
        this.messageclass = "bg-danger"
      }
    );
  }

  saveVarianti() {
    this.articoloService.updateVariants({"input": this.table})
    .subscribe(resp => {
      console.log('RISULTATO: ', resp);
      this.getArticle();
    },
    (error) => {
      this.message = "Errore nel salvataggio: "+error;
      this.messageclass = "bg-danger"
    })
  }

  addColore() {
    this.articoloService.addColore({"nome": this.article.descrizione_colore_add, "codice": this.article.codice_colore_add})
    .subscribe(resp => {
      console.log(resp);
      this.loading = true;
      this.getData();
      this.article.id_colore = resp['id_colore']
    },
    (error) => {
      alert(error);
    })
  }

  addTessuto() {
    this.articoloService.addTessuto({"nome": this.article.descrizione_tessuto_add, "codice": this.article.codice_tessuto_add})
    .subscribe(resp => {
      console.log(resp);
      this.loading = true;
      this.getData();
      this.article.id_tessuto = resp['id_tessuto']
    },
    (error) => {
      alert(error)
    })
  }

  save(redirect: boolean) {

  }
  validate(): string[] {
    const errors: string[] = [];
      if ((this.article.codice_articolo ?? "") == "") {
        errors.push("Codice articolo mancante");
      }
      if ((this.article.nome_articolo ?? "") == "") {
        errors.push("Nome articolo mancante");
      }
      if (!(this.article.codice_tessuto && !this.checkTessuto()) && !(this.article.codice_tessuto_add && this.article.descrizione_tessuto_add)) {
        errors.push("Selezionare un tessuto o aggiungerne uno");
      }
      if (!(this.article.codice_colore && !this.checkColore()) && !(this.article.codice_colore_add && this.article.descrizione_colore_add)) {
        errors.push("Selezionare un colore o aggiungerne uno");
      }
      if ((this.article.settore ?? "") == "") {
        errors.push("Selezionare un settore");
      }
      if ((this.article.stagione ?? "") == "") {
        errors.push("Selezionare una stagione");
      }
      if ((this.article.collezione ?? "") == "") {
        errors.push("Selezionare una collezione");
      }
      if ((this.article.categoria ?? "") == "") {
        errors.push("Selezionare una categoria");
      }
      if ((this.article.scala_taglie ?? "") == "") {
        errors.push("Selezionare una scala_taglie");
      }
      if (!(parseFloat(this.article.prezzo_ingrosso) >= 0 && parseFloat(this.article.prezzo_dettaglio) >= 0)) {
        console.log(parseFloat(this.article.prezzo_dettaglio));
        errors.push("Inserire prezzi corretti");
      }

    return errors;
  }

  checkColore() : boolean {
    return ((this.article.codice_colore_add ?? '') != '') || ((this.article.descrizione_colore_add ?? '') != '');
  }
  checkTessuto() : boolean {
    return ((this.article.codice_tessuto_add ?? '') != '') || ((this.article.descrizione_tessuto_add ?? '') != '');
  }
}

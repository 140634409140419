<section>
  <div class="">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-colored card-retailer">
          <h1 class="text-uppercase text-center">Dati profilo utente</h1>

          <div *ngIf="!modalIsVisible" class="container-box row justify-content-center">
            <div class="col-md-12">
              <div class="box utente-img box-utente">
                <div class="imgU">
                  <ng-container *ngIf="infoUser!.media_path != undefined">
                    <img loading="lazy" class="imgProfilo" src="{{infoUser!.media_path!}}">
                  </ng-container>

                  <button type="button" class="btn btn-imgP" data-toggle="modal"
                          data-target="#modale-modifica-immagine-profilo">
                    <i style="cursor:pointer" class="fi fi-rr-magic-wand"></i>
                  </button>
                </div>


                <h4>{{infoUser!.nome + ' ' + infoUser!.cognome }}</h4>
                <p><span>Email:</span> {{infoUser!.email}}</p>
                <p>
                  <span>Password:</span>
                  ********
                  <button type="button" class="btn btn-psw" data-toggle="modal"
                          data-target="#modale-cambio-pwd">
                    <i class="fi fi-rr-pen-field"></i>
                  </button>
                </p>
                <p><span>Ruoli:</span> MANCANO RUOLI</p>

              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</section>

<div *ngIf="modalIsVisible" style="margin-top:-2%">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body" style="text-align: center;">
        <div class="loader" style="margin-left: 30%;"></div>
        <br>
        <h5>Caricamento dati in corso...</h5>
      </div>

    </div>
  </div>
</div>


<div class="modal fade" id="modale-cambio-pwd" tabindex="-1" role="dialog" aria-labelledby="modal-default"
     aria-hidden="true">
  <div class="modal-dialog modal- modal-dialog-centered modal-" role="document">
    <div class="modal-content">

      <div class="modal-header">
        <h6 class="modal-title">Cambio Password</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>

      <div class="modal-body">
        <!-- Inserisci la tua password attuale e la nuova -->
        <input type="password" class="form-control" placeholder="Password Attuale" autofocus=""
               [(ngModel)]="password_attuale" required="" name="password_attuale">
        <input type="password" class="form-control" placeholder="Nuova Password" required=""
               name="nuova_password" [(ngModel)]="nuova_password">
        <input type="password" class="form-control" placeholder="Conferma Nuova Password" required=""
               name="conferma_nuova_password" [(ngModel)]="conferma_nuova_password">
      </div>

      <div class="modal-footer">
        <div class="button-flex" style="width: 100%;">
          <button type="button" class="btn btn1" data-dismiss="modal" data-dismiss="modal">Chiudi</button>
          <button type="submit" class="btn btn2" (click)="changePwd()">Invia</button>
        </div>
      </div>

    </div>
  </div>
</div>

<div class="modal fade" id="modale-modifica-immagine-profilo" tabindex="-1" role="dialog"
     aria-labelledby="modal-default" aria-hidden="true">
  <div class="modal-dialog modal- modal-dialog-centered modal-" role="document">
    <div class="modal-content">

      <div class="modal-header">
        <h6 class="modal-title">Modifica Immagine Profilo</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>

      <input type="hidden" name="id_user" value="93">
      <div class="modal-body">

        <div class="row">

          <!-- NOME -->
          <div class="col-md-12 text-center">
            <div class="form-group">
              <input id="uploadImmagineProfilo" required="" type="file" accept="image/*" name="image">
              <div>
                <img style="width: 58%;margin-top: 2%;" id="previewImmagineProfilo" src="">
              </div>
            </div>
          </div>

        </div>


      </div>

      <div class="modal-footer">
        <div class="button-flex" style="width: 100%">
          <button type="button" class="btn btn1" data-dismiss="modal">Chiudi</button>
          <button type="submit" id="bottoneConfermaModaleModificaImmagineProfilo" (click)="changeImgProfile()"
                  class="btn btn2">Conferma
          </button>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- SEZIONE MAGAZZINO -->
<div class="modal fade" id="modale-aggiungi-magazzino" tabindex="-1" role="dialog"
     aria-labelledby="modal-default" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title">Aggiungi Magazzino</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <!-- NOME -->
          <div class="col-md-4">
            <label for="" class="form-control-label">Nome</label>
            <input [(ngModel)]="storeSelected!.nome" class="form-control" required="" type="text"
                   placeholder="Nome" id="nome_magazzino">
          </div>
          <!-- EMAIL -->
          <div class="col-md-4">
            <label for="" class="form-control-label">Email</label>
            <input [(ngModel)]="storeSelected!.email" class="form-control" required="" type="email"
                   placeholder="Email" id="email_magazzino">
          </div>
          <!-- TELEFONO -->
          <div class="col-md-4">
            <label for="" class="form-control-label">Telefono</label>
            <input [(ngModel)]="storeSelected!.telefono" class="form-control" type="tel"
                   placeholder="Telefono" id="telefono_magazzino">
          </div>

        </div>

        <div class="row">

          <!-- VIA -->
          <div class="col-md-7">
            <label for="" class="form-control-label">Via</label>
            <input class="form-control" type="text" placeholder="Via" id="indirizzo_magazzino">
          </div>

          <!-- NUMERO CIVICO -->
          <div class="col-md-2">
            <label for="" class="form-control-label">Numero</label>
            <input class="form-control" type="text" placeholder="N°" id="numero_civico_magazzino">
          </div>

          <!-- CAP -->
          <div class="col-md-3">
            <label for="" class="form-control-label">CAP</label>
            <input class="form-control" type="number" placeholder="CAP" id="cap_magazzino">
          </div>
        </div>

        <div class="row">

          <!-- CITTÀ -->
          <div class="col-md-6">
            <label for="" class="form-control-label">Città</label>
            <input class="form-control" type="text" placeholder="Città" id="citta_magazzino">
          </div>

          <!-- PROVINCIA -->
          <div class="col-md-3">
            <label for="" class="form-control-label">Provincia</label>
            <input class="form-control" type="text" placeholder="Provincia" id="provincia_magazzino">
          </div>

          <!-- NAZIONE -->
          <div class="col-md-3">
            <label for="" class="form-control-label">Nazione</label>
            <input class="form-control" type="text" placeholder="Nazione" id="nazione_magazzino">
          </div>

        </div>

        <div class="row">

          <!-- SPESA SPEDIZIONE -->
          <div class="col-md-4">
            <label for="" class="form-control-label">Spese Spedizione</label>
            <input class="form-control" type="number" min="0" step="0.01" id="spesa_spedizione">
          </div>

        </div>

        <div class="row">

          <!-- GMAPS -->
          <div class="col-md-12">
            <label for="" class="form-control-label">Indirizzo</label>
            <input id="google_maps_magazzino" [(ngModel)]="storeSelected!.formatted_address" type="text"
                   class="form-control pac-target-input" placeholder="Cerca un indirizzo" autocomplete="off">

          </div>

        </div>


      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-link" data-dismiss="modal">Chiudi</button>
        <button type="submit" id="bottoneConfermaModaleAggiungiMagazzino" (click)="createStore()"
                class="btn btn-primary ml-auto">Conferma
        </button>
      </div>


    </div>
  </div>
</div>

<div class="modal fade show" id="modale-modifica-magazzino" tabindex="-1" role="dialog"
     aria-labelledby="modal-default" aria-hidden="true">
  <div class="modal-dialog modal- modal-dialog-centered modal-" role="document">
    <div class="modal-content">

      <div class="modal-header">
        <h6 class="modal-title">Modifica Magazzino - <b>{{storeSelected.nome}}</b></h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>


      <div class="modal-body">

        <div class="row">
          <input type="hidden" [(ngModel)]="storeSelected!.id" id="id_magazzino">
          <!-- NOME -->
          <div class="col-md-4">
            <label for="" class="form-control-label">Nome</label>
            <input [(ngModel)]="storeSelected!.nome" class="form-control" required="" type="text"
                   placeholder="Nome" id="nome_magazzino">
          </div>

          <!-- EMAIL -->
          <div class="col-md-4">
            <label for="" class="form-control-label">Email</label>
            <input [(ngModel)]="storeSelected!.email" class="form-control" required="" type="email"
                   placeholder="Email" id="email_magazzino">
          </div>

          <!-- TELEFONO -->
          <div class="col-md-4">
            <label for="" class="form-control-label">Telefono</label>
            <input [(ngModel)]="storeSelected!.telefono" class="form-control" type="tel"
                   placeholder="Telefono" id="telefono_magazzino">
          </div>


        </div>

        <div class="row">

          <!-- VIA -->
          <div class="col-md-7">
            <label for="" class="form-control-label">Via</label>
            <input [(ngModel)]="storeSelected!.indirizzo" class="form-control" type="text" placeholder="Via"
                   id="indirizzo_magazzino">
          </div>

          <!-- NUMERO CIVICO -->
          <div class="col-md-2">
            <label for="" class="form-control-label">Numero</label>
            <input [(ngModel)]="storeSelected!.numero_civico" class="form-control" type="text"
                   placeholder="N°" id="numero_civico_magazzino">
          </div>

          <!-- CAP -->
          <div class="col-md-3">
            <label for="" class="form-control-label">CAP</label>
            <input [(ngModel)]="storeSelected!.cap" class="form-control" type="number" placeholder="CAP"
                   id="cap_magazzino">
          </div>

        </div>

        <div class="row">

          <!-- CITTÀ -->
          <div class="col-md-6">
            <label for="" class="form-control-label">Città</label>
            <input [(ngModel)]="storeSelected!.citta" class="form-control" type="text" placeholder="Città"
                   id="citta_magazzino">
          </div>

          <!-- PROVINCIA -->
          <div class="col-md-3">
            <label for="" class="form-control-label">Provincia</label>
            <input [(ngModel)]="storeSelected!.provincia" class="form-control" type="text"
                   placeholder="Provincia" id="provincia_magazzino">
          </div>

          <!-- NAZIONE -->
          <div class="col-md-3">
            <label for="" class="form-control-label">Nazione</label>
            <input [(ngModel)]="storeSelected!.nazione" class="form-control" type="text" placeholder="Nazione"
                   id="nazione_magazzino">
          </div>

        </div>

        <div class="row">

          <!-- SPESA SPEDIZIONE -->
          <div class="col-md-4">
            <label for="" class="form-control-label">Spese Spedizione</label>
            <input [(ngModel)]="storeSelected!.spesa_spedizione" class="form-control" type="number" min="0"
                   step="0.01" id="spesa_spedizione">
          </div>

        </div>

        <div class="row" style="display:none">

          <!-- GMAPS -->
          <div class="col-md-12">
            <label for="" class="form-control-label">Indirizzo</label>
            <input id="google_maps_magazzino" [(ngModel)]="storeSelected!.formatted_address"
                   selector="magazzino" type="text" class="form-control pac-target-input"
                   placeholder="Cerca un indirizzo" autocomplete="off">

          </div>

        </div>


      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-link" data-dismiss="modal">Chiudi</button>
        <button type="submit" id="bottoneConfermaModaleModificaMagazzino" (click)="updateStore()"
                class="btn btn-primary ml-auto">Conferma
        </button>
      </div>


    </div>
  </div>
</div>

<div class="modal fade" id="modale-elimina-magazzino" tabindex="-1" role="dialog"
     aria-labelledby="modal-default" aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title">Sei sicuro di vole eliminare il magazzino?</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>


      <div class="modal-footer">
        <button type="button" class="btn btn-primary ml-auto" (click)="deleteStore()">Elimina</button>
        <button type="button" class="btn btn-danger" data-dismiss="modal">Annulla</button>
      </div>
    </div>
  </div>
</div>

<!-- SEZIONE AZIENDA -->

<div class="modal fade" id="modale-aggiungi-azienda" tabindex="-1" role="dialog"
     aria-labelledby="modal-default" aria-hidden="true">
  <div class="modal-dialog modal- modal-dialog-centered modal-" role="document">
    <div class="modal-content">

      <div class="modal-header">
        <h6 class="modal-title">AGGIUNGI I DATI DI FATTURAZIONE</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>


      <div class="modal-body">

        <div class="row">
          <div class="col-12">
          <app-google-autocomplete (addressEvent)="importAddress('azienda', $event)"></app-google-autocomplete>
        </div>

          <!-- RAGIONE SOCIALE -->
          <div class="col-md-6">
            <label for="" class="form-control-label">Ragione Sociale</label>
            <input class="form-control" [(ngModel)]="companySelected!.ragione_sociale" required="" type="text"
                   placeholder="Ragione Sociale" id="ragione_sociale_azienda">
          </div>
          <!-- PIVA -->
          <div class="col-md-6">
            <label for="" class="form-control-label">P.IVA</label>
            <input class="form-control" [(ngModel)]="companySelected!.partita_iva" required="" type="text"
                   placeholder="P.IVA" id="partita_iva_azienda">
          </div>


        </div>


        <div class="row">
          <!-- PEC -->
          <div class="col-md-6">
            <label for="" class="form-control-label">Indirizzo PEC</label>
            <input [(ngModel)]="companySelected!.pec" class="form-control" type="email" placeholder="PEC"
                   id="pec_azienda">
          </div>


          <!-- CODICE SDI -->
          <div class="col-md-6">
            <label for="" class="form-control-label">Codice SDI</label>
            <input [(ngModel)]="companySelected!.sdi" class="form-control" type="text" placeholder="SDI"
                   maxlength="9" id="codice_sdi_azienda">
          </div>
        </div>

        <div class="row">

          <!-- VIA -->
          <div class="col-md-7">
            <label for="" class="form-control-label">Via</label>
            <input class="form-control" type="text" placeholder="Via" id="indirizzo_azienda">
          </div>

          <!-- NUMERO CIVICO -->
          <div class="col-md-2">
            <label for="" class="form-control-label">Numero</label>
            <input class="form-control" type="text" placeholder="N°" id="numero_civico_azienda">
          </div>

          <!-- CAP -->
          <div class="col-md-3">
            <label for="" class="form-control-label">CAP</label>
            <input class="form-control" type="number" placeholder="CAP" id="cap_azienda">
          </div>
        </div>

        <div class="row">

          <!-- CITTÀ -->
          <div class="col-md-6">
            <label for="" class="form-control-label">Città</label>
            <input class="form-control" type="text" placeholder="Città" id="citta_azienda">
          </div>

          <!-- PROVINCIA -->
          <div class="col-md-3">
            <label for="" class="form-control-label">Provincia</label>
            <input class="form-control" type="text" placeholder="Provincia" id="provincia_azienda">
          </div>

          <!-- NAZIONE -->
          <div class="col-md-3">
            <label for="" class="form-control-label">Nazione</label>
            <input class="form-control" type="text" placeholder="Nazione" id="nazione_azienda">
          </div>

        </div>


        <div class="row d-none">

          <!-- GMAPS -->
          <div class="col-md-12">
            <label for="" class="form-control-label">Indirizzo</label>
            <input [(ngModel)]="companySelected!.formatted_address" id="google_maps_azienda"
                   selector="azienda" type="text" class="form-control pac-target-input"
                   placeholder="Cerca un indirizzo" autocomplete="off">
          </div>

        </div>


        <div class="row">

          <!-- DEFAULT -->
          <div class="col-md-6 form-check">
            <input type="checkbox" name="is_default" value="1" class="form-check-input">
            <label for="" class="form-check-label">Azienda di Default</label>
          </div>

        </div>


        <hr>
        <div class="row">
          <!--    <div class="col-md-12">
              <h5 class="text-uppercase mb-3">account stripe aziendale</h5>
              <div class="fatt">

                  <div class="input-fatt">
                      <input type="checkbox" checked="checked" name="radio" value="NO">
                      <label class="form-control-label">Voglio creare un account </label>
              </div>

                  <div class="input-fatt">
                      <input type="radio" name="radio" value="YES">
                      <label class="form-control-label">Ho già un account, Il mio ID è</label>
                  </div>

                  <div id="stripeexpanded" class="mt-2">
                      <input type="text" class="form-control" id="stripe_id_azienda">
                  </div>
              </div>

          </div>-->
        </div>
        <div class="row" style="">

          <!-- LATITUDINE -->
          <div class="col-md-6">
            <label for="" class="form-control-label">Latitudine</label>
            <input class="form-control" type="text" placeholder="Indirizzo" id="latitude_azienda">
          </div>

          <!-- LONGITUDINE -->
          <div class="col-md-6">
            <label for="" class="form-control-label">Longitudine</label>
            <input class="form-control" type="text" placeholder="Nazione" id="longitude_azienda">
          </div>


        </div>


        <input type="hidden" id="place_id_azienda">
        <input type="hidden" id="formatted_address_azienda">


      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-link">
          Chiudi
        </button>
        <button (click)="createCompany()" type="submit" id="bottoneConfermaModaleAggiungiAzienda"
                class="btn btn-primary ml-auto">Conferma
        </button>
      </div>


    </div>
  </div>
</div>

<div class="modal fade" id="modale-modifica-termini">
  <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title">MODIFICA TERMINI E CONDIZIONI PER L'AZIENDA
          <b>{{companySelected!.ragione_sociale}}</b></h6>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
      </div>
      <div class="modal-body">
        <quill-editor [(ngModel)]="companySelected!.condizioni_vendita"></quill-editor>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-link" data-dismiss="modal" aria-label="Close">Chiudi</button>
        <button (click)="updateTermini()" type="submit" id="bottoneConfermaModaleModificaTermini"
                class="btn btn-primary ml-auto">Conferma
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="modale-modifica-azienda" tabindex="-1" role="dialog"
     aria-labelledby="modal-default" aria-hidden="true">
  <div class="modal-dialog modal- modal-dialog-centered modal-" role="document">
    <div class="modal-content">

      <div class="modal-header">
        <h6 class="modal-title">MODIFICA I DATI DI FATTURAZIONE PER L'AZIENDA
          <b>{{companySelected!.ragione_sociale}}</b></h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>


      <div class="modal-body">

        <div class="row">
          <input type="hidden" [(ngModel)]="companySelected!.id" id="id_azienda">
          <!-- RAGIONE SOCIALE -->
          <div class="col-md-6">
            <label for="" class="form-control-label">Ragione Sociale</label>
            <input [(ngModel)]="companySelected!.ragione_sociale" class="form-control" required="" type="text"
                   placeholder="Nome" id="ragione_sociale_azienda">
          </div>
          <!-- PIVA -->
          <div class="col-md-6">
            <label for="" class="form-control-label">P.IVA</label>
            <input [(ngModel)]="companySelected!.partita_iva" class="form-control" required="" type="text"
                   placeholder="P.IVA" id="partita_iva_azienda">
          </div>


        </div>

        <div class="row">

          <!-- PEC -->
          <div class="col-md-6">
            <label for="" class="form-control-label">PEC per la fatturazione</label>
            <input [(ngModel)]="companySelected!.pec" class="form-control" type="email" placeholder="PEC"
                   id="pec_azienda">
          </div>
          <!-- CODICE SDI -->
          <div class="col-md-6">
            <label for="" class="form-control-label">Codice SDI</label>
            <input [(ngModel)]="companySelected!.sdi" class="form-control" type="text" maxlength="9"
                   placeholder="SDI" id="codice_sdi_azienda">
          </div>
        </div>

        <div class="row">

          <!-- VIA -->
          <div class="col-md-7">
            <label for="" class="form-control-label">Via</label>
            <input [(ngModel)]="companySelected!.indirizzo" class="form-control" type="text" placeholder="Via"
                   id="indirizzo_azienda">
          </div>

          <!-- NUMERO CIVICO -->
          <div class="col-md-2">
            <label for="" class="form-control-label">Numero</label>
            <input [(ngModel)]="companySelected!.numero_civico" class="form-control" type="text"
                   placeholder="N°" id="numero_civico_azienda">
          </div>

          <!-- CAP -->
          <div class="col-md-3">
            <label for="" class="form-control-label">CAP</label>
            <input [(ngModel)]="companySelected!.cap" class="form-control" type="number" placeholder="CAP"
                   id="cap_azienda">
          </div>
        </div>

        <div class="row">

          <!-- CITTÀ -->
          <div class="col-md-6">
            <label for="" class="form-control-label">Città</label>
            <input [(ngModel)]="companySelected!.citta" class="form-control" type="text" placeholder="Città"
                   id="citta_azienda">
          </div>

          <!-- PROVINCIA -->
          <div class="col-md-3">
            <label for="" class="form-control-label">Provincia</label>
            <input [(ngModel)]="companySelected!.provincia" class="form-control" type="text"
                   placeholder="Provincia" id="provincia_azienda">
          </div>

          <!-- NAZIONE -->
          <div class="col-md-3">
            <label for="" class="form-control-label">Nazione</label>
            <input [(ngModel)]="companySelected!.nazione" class="form-control" type="text"
                   placeholder="Nazione" id="nazione_azienda">
          </div>

        </div>

        <div class="row d-none">

          <!-- GMAPS -->
          <div class="col-md-12">
            <label for="" class="form-control-label">Indirizzo</label>
            <input id="google_maps_azienda" selector="azienda"
                   [(ngModel)]="companySelected!.formatted_address" type="text"
                   class="form-control pac-target-input" placeholder="Cerca un indirizzo" autocomplete="off">

          </div>

        </div>


        <div class="row">

          <!-- DEFAULT -->
          <div class="col-md-6">
            <input type="checkbox" name="is_default" checked="" value="1">
            <label for="" class="form-control-label">Azienda di Default</label>
          </div>

        </div>

        <div class="row">

          <!-- STRIPE ACCOUNT NON PRESENTE -->
          <div class="col-md-12" style="display:none">
            L'azienda non possiede un account Stripe.
          </div>

          <!-- STRIPE ONBOARDING -->
          <!-- <div class="col-md-12" <?/*= ($azienda->stripe_id && !$azienda->stripe_onboarding) ? "": "style='display:none'" */?>>
             La procedura di onboarding dell'account Stripe non risulta completata.
             <a href="<?/*= $stripeOnboardingUrl */?>">Clicca qui </a> per completarla
           </div>-->

          <!-- STRIPE ONBOARDING -->
          <div class="col-md-12" style="display:none">
            L'account Stripe di questa azienda risulta configurato correttamente.
          </div>

        </div>


        <input type="hidden" id="place_id_azienda" value="ChIJIcuNTPgGQBMRtgoZTkMVNVU">
        <input type="hidden" id="formatted_address_azienda"
               value="Vico II Duomo, 10, 88100 Catanzaro CZ, Italia">


      </div>

      <div class="modal-footer">
        <button type="button" onclick="chiudiModaleModificaAzienda()" class="btn btn-link">Chiudi</button>
        <button (click)="updateCompany()" type="submit" id="bottoneConfermaModaleModificaAzienda"
                class="btn btn-primary ml-auto">Conferma
        </button>
      </div>


    </div>
  </div>
</div>

<div class="modal fade" id="modale-elimina-azienda" tabindex="-1" role="dialog"
     aria-labelledby="modal-default" aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title">Sei sicuro di vole eliminare l'azienda?</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>


      <div class="modal-footer">
        <button type="button" class="btn btn-primary ml-auto" (click)="deleteCompany()">Elimina</button>
        <button type="button" class="btn btn-danger" data-dismiss="modal">Annulla</button>
      </div>
    </div>
  </div>
</div>

<!-- SEZIONE UTENTI -->
<div class="modal fade" id="modale-aggiungi-utente" tabindex="-1" role="dialog"
     aria-labelledby="modal-default" aria-hidden="true">
  <div class="modal-dialog modal- modal-dialog-centered modal-" role="document">
    <div class="modal-content">

      <div class="modal-header">
        <h6 class="modal-title">Aggiungi Utente</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>


      <div class="modal-body">

        <div class="row">

          <!-- NOME -->
          <div class="col-md-6">
            <label for="" class="form-control-label">Nome</label>
            <input class="form-control" required="" type="text" placeholder="Nome" id="nome_utente"
                   [(ngModel)]="userSelected!.nome">
          </div>

          <!-- COGNOME -->
          <div class="col-md-6">
            <label for="" class="form-control-label">Cognome</label>
            <input class="form-control" required="" type="text" placeholder="Cognome" id="cognome_utente"
                   [(ngModel)]="userSelected!.cognome">
          </div>

        </div>


        <div class="row">

          <!-- EMAIL -->
          <div class="col-md-12">
            <label for="" class="form-control-label">Email</label>
            <input class="form-control" required="" type="email" placeholder="Email" id="email_utente"
                   [(ngModel)]="userSelected!.email">
          </div>

        </div>


        <div class="row">

          <!-- RUOLI -->
          <div class="col-md-12">
            <div class="form-group">
              <label for="" class="form-control-label">Ruoli</label>
              <select id="aggiungi-ruoli" required="" class="form-control" name="ruoli[]" tabindex="-1"
                      aria-hidden="true">
                <option value="2">ADMIN</option>
                <option selected="" value="3">USER</option>
              </select>
            </div>


          </div>
        </div>


      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-link" data-dismiss="modal">Chiudi</button>
        <button (click)="createUser()" type="submit" id="bottoneConfermaModaleAggiungiUtente"
                class="btn btn-primary ml-auto">Conferma
        </button>
      </div>


    </div>
  </div>
</div>


<div class="modal fade" id="modale-modifica-utente" tabindex="-1" role="dialog"
     aria-labelledby="modal-default" aria-hidden="true">
  <div class="modal-dialog modal- modal-dialog-centered modal-" role="document">
    <div class="modal-content">

      <div class="modal-header">
        <h6 class="modal-title">Modifica Utente {{userSelected.nome + ' ' + userSelected.cognome || ''}}</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>


      <div class="modal-body">

        <div class="row">
          <input type="hidden" id="id_utente" [(ngModel)]="userSelected!.id">
          <!-- NOME -->
          <div class="col-md-6">
            <label for="" class="form-control-label">Nome</label>
            <input class="form-control" required="" type="text" [(ngModel)]="userSelected!.nome"
                   placeholder="Nome" id="nome_utente">
          </div>

          <!-- COGNOME -->
          <div class="col-md-6">
            <label for="" class="form-control-label">Cognome</label>
            <input class="form-control" required="" type="text" [(ngModel)]="userSelected!.cognome"
                   placeholder="Cognome" id="cognome_utente">
          </div>

        </div>


        <div class="row">

          <!-- EMAIL -->
          <div class="col-md-12">
            <label for="" class="form-control-label">Email</label>
            <input class="form-control" required="" type="email" [(ngModel)]="userSelected!.email"
                   placeholder="Email" id="email_utente">
          </div>

        </div>

        <div class="row">

          <!-- RUOLI -->
          <div class="col-md-12">
            <div class="form-group" *ngIf="userSelected.id">
              <label for="" class="form-control-label">Ruoli</label>
              <select id="modifica-ruoli" required="" class="form-control" name="ruoli[]" tabindex="-1"
                      aria-hidden="true">
                <option [selected]="rolesList[userSelected!.id] == 2" value="2">ADMIN</option>
                <option [selected]="rolesList[userSelected!.id] == 3 || 1 == 1" value="3">USER</option>
              </select>
            </div>
          </div>
        </div>


      </div>

      <div class="modal-footer">
        <button type="button" data-dismiss="modal" class="btn btn-link">Chiudi</button>
        <button type="submit" id="bottoneConfermaModaleModificaUtente" class="btn btn-primary ml-auto"
                (click)="updateUser()">Conferma
        </button>
      </div>

    </div>
  </div>
</div>


<div class="modal fade" id="modale-elimina-utente" tabindex="-1" role="dialog" aria-labelledby="modal-default"
     aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title">Sei sicuro di vole eliminare l'utente?</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>


      <div class="modal-footer">
        <button type="button" class="btn btn-primary ml-auto" (click)="deleteUser()">Elimina</button>
        <button type="button" class="btn btn-danger" data-dismiss="modal">Annulla</button>
      </div>
    </div>
  </div>
</div>


<!-- SEZIONE NEGOZI -->
<div class="modal fade" id="modale-aggiungi-negozio" tabindex="-1" role="dialog"
     aria-labelledby="modal-default" aria-hidden="true">
  <div class="modal-dialog modal- modal-dialog-centered modal-" role="document">
    <div class="modal-content">

      <div class="modal-header">
        <h6 class="modal-title">Aggiungi Negozio</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>


      <div class="modal-body">

        <div class="row">

          <!-- NOME -->
          <div class="col-md-4">
            <label for="" class="form-control-label">Nome</label>
            <input [(ngModel)]="shopSelected!.nome" class="form-control" required="" type="text"
                   placeholder="Nome" id="nome_negozio">
          </div>

          <!-- EMAIL -->
          <div class="col-md-4">
            <label for="" class="form-control-label">Email</label>
            <input [(ngModel)]="shopSelected!.email" class="form-control" required="" type="email"
                   placeholder="Email" id="email_negozio">
          </div>

          <!-- TELEFONO -->
          <div class="col-md-4">
            <label for="" class="form-control-label">Telefono</label>
            <input [(ngModel)]="shopSelected!.telefono" class="form-control" type="tel" placeholder="Telefono"
                   id="telefono_negozio">
          </div>

        </div>


        <div class="row">

          <!-- WEBSITE -->
          <div class="col-md-12">
            <label for="" class="form-control-label">WebSite</label>
            <input [(ngModel)]="shopSelected!.sito_web" class="form-control" type="url" placeholder="WebSite"
                   id="sito_web_negozio">
          </div>

        </div>


        <div class="row">

          <!-- VIA -->
          <div class="col-md-7">
            <label for="" class="form-control-label">Via</label>
            <input [(ngModel)]="shopSelected!.indirizzo" class="form-control" type="text" placeholder="Via"
                   id="indirizzo_negozio">
          </div>

          <!-- NUMERO CIVICO -->
          <div class="col-md-2">
            <label for="" class="form-control-label">Numero</label>
            <input [(ngModel)]="shopSelected!.numero_civico" class="form-control" type="text" placeholder="N°"
                   id="numero_civico_negozio">
          </div>

          <!-- CAP -->
          <div class="col-md-3">
            <label for="" class="form-control-label">CAP</label>
            <input [(ngModel)]="shopSelected!.cap" class="form-control" type="number" placeholder="CAP"
                   id="cap_negozio">
          </div>

        </div>

        <div class="row">

          <!-- CITTÀ -->
          <div class="col-md-6">
            <label for="" class="form-control-label">Città</label>
            <input class="form-control" type="text" placeholder="Città" id="citta_negozio">
          </div>

          <!-- PROVINCIA -->
          <div class="col-md-3">
            <label for="" class="form-control-label">Provincia</label>
            <input class="form-control" type="text" placeholder="Provincia" id="provincia_negozio">
          </div>

          <!-- NAZIONE -->
          <div class="col-md-3">
            <label for="" class="form-control-label">Nazione</label>
            <input class="form-control" type="text" placeholder="Nazione" id="nazione_negozio">
          </div>
        </div>

        <div class="row">

          <!-- LOGO -->
          <div class="col-md-12">
            <div class="form-group">
              <label for="" class="form-control-label">Logo</label><br>

              <input id="uploadLogoNegozio" type="file" accept="image/*" name="image">
              <div>
                <img style="width: 58%;margin-top: 2%;" id="previewLogoNegozio" src="">
              </div>
            </div>
          </div>

        </div>


      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-link" data-dismiss="modal">Chiudi</button>
        <button (click)="createShop()" type="submit" id="bottoneConfermaModaleAggiungiNegozio"
                class="btn btn-primary ml-auto">Conferma
        </button>
      </div>


    </div>
  </div>
</div>


<div class="modal fade" id="modale-social-negozio" tabindex="-1" role="dialog" aria-labelledby="modal-default"
     aria-hidden="true">
  <div class="modal-dialog modal- modal-dialog-centered modal-" role="document">
    <div class="modal-content">

      <div class="modal-header">
        <h6 class="modal-title">Modifica Social Negozio - <b>{{shopSelected.nome}}</b></h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>


      <input type="hidden" id="id_negozio" [(ngModel)]="shopSelected.id">
      <div class="modal-body">

        <!-- SITO WEB -->
        <div class="row">
          <div class="col-md-1">
            <i class="fas fa-lg fa-globe" style="vertical-align: -webkit-baseline-middle;"></i>
          </div>
          <div class="col-md-11">
            <input [(ngModel)]="shopSelected.sito_web" class="form-control" type="url"
                   placeholder="WebSite URL" id="sito_web_negozio">
          </div>
        </div>


        <!-- FACEBOOK -->
        <div class="row">
          <div class="col-md-1">
            <i class="fab fa-lg fa-facebook-f" style="vertical-align: -webkit-baseline-middle;"></i>
          </div>
          <div class="col-md-11">
            <input [(ngModel)]="shopSelected.facebook" class="form-control" type="url"
                   placeholder="Facebook URL" id="facebook_negozio">
          </div>
        </div>

        <!-- TWITTER -->
        <div class="row">
          <div class="col-md-1">
            <i class="fab fa-lg fa-twitter" style="vertical-align: -webkit-baseline-middle;"></i>
          </div>
          <div class="col-md-11">
            <input [(ngModel)]="shopSelected.twitter" class="form-control" type="url"
                   placeholder="Twitter URL" id="twitter_negozio">
          </div>
        </div>

        <!-- INSTAGRAM -->
        <div class="row">
          <div class="col-md-1">
            <i class="fab fa-lg fa-instagram" style="vertical-align: -webkit-baseline-middle;"></i>
          </div>
          <div class="col-md-11">
            <input [(ngModel)]="shopSelected.instagram" class="form-control" type="url"
                   placeholder="Instagram URL" id="instagram_negozio">
          </div>
        </div>


        <!-- LINKEDIN -->
        <div class="row">
          <div class="col-md-1">
            <i class="fab fa-lg fa-linkedin" style="vertical-align: -webkit-baseline-middle;"></i>
          </div>
          <div class="col-md-11">
            <input [(ngModel)]="shopSelected.linkedin" class="form-control" type="url"
                   placeholder="Linkedin URL" id="linkedin_negozio">
          </div>
        </div>


        <!-- PINTEREST -->
        <div class="row">
          <div class="col-md-1">
            <i class="fab fa-lg fa-pinterest" style="vertical-align: -webkit-baseline-middle;"></i>
          </div>
          <div class="col-md-11">
            <input [(ngModel)]="shopSelected.pinterest" class="form-control" type="url"
                   placeholder="Pinterest URL" id="pinterest_negozio">
          </div>
        </div>


      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-link" data-dismiss="modal">Chiudi</button>
        <button type="submit" (click)="updateShopSocial()" id="bottoneConfermaModaleSocialNegozio"
                class="btn btn-primary ml-auto">Conferma
        </button>
      </div>


    </div>
  </div>
</div>

<div class="modal fade" id="modale-modifica-negozio" tabindex="-1" role="dialog"
     aria-labelledby="modal-default" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">

      <div class="modal-header">
        <h6 class="modal-title">Modifica Negozio - <b>{{shopSelected.nome}}</b></h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>


      <div class="modal-body">

        <div class="row">
          <input type="hidden" [(ngModel)]="shopSelected.id" id="id_negozio">
          <!-- NOME -->
          <div class="col-md-4">
            <label for="" class="form-control-label">Nome</label>
            <input [(ngModel)]="shopSelected.nome" class="form-control" required="" type="text"
                   placeholder="Nome" id="nome_negozio">
          </div>

          <!-- EMAIL -->
          <div class="col-md-4">
            <label for="" class="form-control-label">Email</label>
            <input [(ngModel)]="shopSelected.email" class="form-control" required="" type="email"
                   placeholder="Nome" id="email_negozio">
          </div>

          <!-- TELEFONO -->
          <div class="col-md-4">
            <label for="" class="form-control-label">Telefono</label>
            <input [(ngModel)]="shopSelected.telefono" class="form-control" type="tel" placeholder="Telefono"
                   id="telefono_negozio">
          </div>

        </div>


        <div class="row">

          <!-- WEBSITE -->
          <div class="col-md-12">
            <label for="" class="form-control-label">WebSite</label>
            <input [(ngModel)]="shopSelected.sito_web" class="form-control" type="url" placeholder="WebSite"
                   id="sito_web_negozio">
          </div>

        </div>


        <div class="row d-none">

          <!-- GMAPS -->
          <div class="col-md-12">
            <label for="" class="form-control-label">Indirizzo</label>
            <input id="google_maps_negozio" value="" selector="negozio" type="text"
                   class="form-control pac-target-input" placeholder="Cerca un indirizzo" autocomplete="off">

          </div>

        </div>

        <div class="row">

          <!-- VIA -->
          <div class="col-md-7">
            <label for="" class="form-control-label">Via</label>
            <input [(ngModel)]="shopSelected.indirizzo" class="form-control" type="text" placeholder="Via"
                   id="indirizzo_negozio">
          </div>

          <!-- NUMERO CIVICO -->
          <div class="col-md-2">
            <label for="" class="form-control-label">Numero</label>
            <input [(ngModel)]="shopSelected.numero_civico" class="form-control" type="text" placeholder="N°"
                   id="numero_civico_negozio">
          </div>

          <!-- CAP -->
          <div class="col-md-3">
            <label for="" class="form-control-label">CAP</label>
            <input [(ngModel)]="shopSelected.cap" class="form-control" type="number" placeholder="CAP"
                   id="cap_negozio">
          </div>

        </div>

        <div class="row">

          <!-- CITTÀ -->
          <div class="col-md-6">
            <label for="" class="form-control-label">Città</label>
            <input [(ngModel)]="shopSelected.citta" class="form-control" type="text" placeholder="Città"
                   id="citta_negozio">
          </div>

          <!-- NAZIONE -->
          <div class="col-md-3">
            <label for="" class="form-control-label">Nazione</label>
            <input [(ngModel)]="shopSelected.nazione" class="form-control" type="text" placeholder="Nazione"
                   id="nazione_negozio">
          </div>

          <!-- PROVINCIA -->
          <div class="col-md-3">
            <label for="" class="form-control-label">Provincia</label>
            <input [(ngModel)]="shopSelected.provincia" class="form-control" type="text"
                   placeholder="Provincia" id="provincia_negozio">
          </div>

        </div>

        <div class="row" style="display:none">

          <!-- LATITUDINE -->
          <div class="col-md-6">
            <label for="" class="form-control-label">Latitudine</label>
            <input value="0" class="form-control" type="text" placeholder="Indirizzo" id="latitude_negozio">
          </div>

          <!-- LONGITUDINE -->
          <div class="col-md-6">
            <label for="" class="form-control-label">Longitudine</label>
            <input value="0" class="form-control" type="text" placeholder="Nazione" id="longitude_negozio">
          </div>


        </div>


        <input type="hidden" id="place_id_negozio" value="ChIJIcuNTPgGQBMRtgoZTkMVNVU">
        <input type="hidden" id="formatted_address_negozio"
               value="Vico II Duomo, 10, 88100 Catanzaro CZ, Italia">


        <div class="row">

          <!-- LOGO -->
          <div class="col-md-12 text-center">
            <div class="form-group">
              <label for="" class="form-control-label">Logo</label><br>

              <input id="uploadLogoNegozioModifica" type="file" accept="image/*" name="image_modifica">
              <div style="text-align:center">
                <img style="width: 58%;margin-top: 2%;" id="previewLogoNegozioModifica"
                     src="https://r2w-media-dev.fra1.cdn.digitaloceanspaces.com/organizzazione/57/6325d93b5bfea.png">
              </div>

            </div>

            <i style="color:red;cursor:pointer" title="Elimina Immagine"
               class="fas fa-lg fa-trash iconDeleteImmagineNegozio"></i>

          </div>

        </div>


      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-link">Chiudi</button>
        <button (click)="updateShop()" type="submit" id="bottoneConfermaModaleModificaNegozio"
                class="btn btn-primary ml-auto">Conferma
        </button>
      </div>


    </div>
  </div>
</div>

<div class="modal fade" id="modale-elimina-negozio" tabindex="-1" role="dialog"
     aria-labelledby="modal-default" aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title">Sei sicuro di vole eliminare il negozio?</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>


      <div class="modal-footer">
        <button type="button" class="btn btn-primary ml-auto" (click)="deleteShop()">Elimina</button>
        <button type="button" class="btn btn-danger" data-dismiss="modal">Annulla</button>
      </div>
    </div>
  </div>
</div>


<!-- SEZIONE BRAND -->
<div class="modal fade" id="modale-aggiungi-brand" tabindex="-1" role="dialog" aria-labelledby="modal-default"
     aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">

      <div class="modal-header">
        <h6 class="modal-title">Aggiungi Brand</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>


      <div class="modal-body">

        <div class="row">

          <!-- NOME -->
          <div class="col-md-6">
            <label for="" class="form-control-label">Nome Brand</label>
            <input [(ngModel)]="brandSelected.nome" class="form-control" required="" type="text"
                   placeholder="Nome" id="nome_brand">
          </div>

          <!-- EMAIL -->
          <div class="col-md-6">
            <label for="" class="form-control-label">Email</label>
            <input [(ngModel)]="brandSelected!.email" class="form-control" required="" type="email"
                   placeholder="Email" id="email_brand">
          </div>

        </div>


        <div class="row">

          <!-- TELEFONO -->
          <div class="col-md-6">
            <label for="" class="form-control-label">Telefono</label>
            <input [(ngModel)]="brandSelected!.telefono" class="form-control" type="tel"
                   placeholder="Telefono" id="telefono_brand">
          </div>

          <!-- REFERENTE -->
          <div class="col-md-6">
            <label for="" class="form-control-label">Referente</label>
            <input [(ngModel)]="brandSelected!.referente" class="form-control" type="text"
                   placeholder="Referente" id="referente_brand">
          </div>

        </div>


        <div class="row">

          <!-- INDIRIZZO -->
          <div class="col-md-12">
            <label for="" class="form-control-label">Indirizzo</label>
            <input [(ngModel)]="brandSelected!.indirizzo" class="form-control" type="text"
                   placeholder="Indirizzo" id="indirizzo_brand">
          </div>

          <!-- NAZIONE -->
          <div class="col-md-6" style="display: none">
            <label for="" class="form-control-label">Nazione</label>
            <input [(ngModel)]="brandSelected!.nazione" class="form-control" type="text" placeholder="Nazione"
                   id="nazione_brand">
          </div>


        </div>


        <div class="row">

          <!-- LOGO -->
          <div class="col-md-12">
            <div class="form-group">
              <label for="" class="form-control-label">Logo</label><br>

              <input id="uploadLogoBrand" type="file" accept="image/*" name="image">
              <div>
                <img style="width: 58%;margin-top: 2%;" id="previewLogoBrand" src="">
              </div>
            </div>
          </div>

        </div>

        <div class="button-flex" style="width: 100%;">
          <button type="button" data-dismiss="modal" class="btn btn1">Chiudi</button>
          <button type="submit" (click)="createBrand()" class="btn btn2">Conferma</button>
        </div>
      </div>


    </div>
  </div>
</div>


<div class="modal fade" id="modale-elimina-brand" tabindex="-1" role="dialog" aria-labelledby="modal-default"
     aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title">Sei sicuro di vole eliminare il brand?</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>


      <div class="modal-footer">
        <button type="button" class="btn btn-primary ml-auto" (click)="deleteBrand()">Elimina</button>
        <button type="button" class="btn btn-danger" data-dismiss="modal">Annulla</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="modale-social-brand" tabindex="-1" role="dialog" aria-labelledby="modal-default"
     aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">

      <div class="modal-header">
        <h6 class="modal-title">Modifica Social Brand - <b>{{brandSelected.nome}}</b></h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>

      <div class="modal-body">
        <input type="hidden" id="id_brand" [(ngModel)]="brandSelected!.id">
        <!-- SITO WEB -->
        <div class="row">
          <div class="col-md-1">
            <i class="fas fa-lg fa-globe" style="vertical-align: -webkit-baseline-middle;"></i>
          </div>
          <div class="col-md-11">
            <input [(ngModel)]="brandSelected!.sito_web" class="form-control" type="url"
                   placeholder="WebSite URL" id="sito_web_brand">
          </div>
        </div>


        <!-- FACEBOOK -->
        <div class="row">
          <div class="col-md-1">
            <i class="fab fa-lg fa-facebook-f" style="vertical-align: -webkit-baseline-middle;"></i>
          </div>
          <div class="col-md-11">
            <input [(ngModel)]="brandSelected!.facebook" class="form-control" type="url"
                   placeholder="Facebook URL" id="facebook_brand">
          </div>
        </div>

        <!-- TWITTER -->
        <div class="row">
          <div class="col-md-1">
            <i class="fab fa-lg fa-twitter" style="vertical-align: -webkit-baseline-middle;"></i>
          </div>
          <div class="col-md-11">
            <input [(ngModel)]="brandSelected!.twitter" class="form-control" type="url"
                   placeholder="Twitter URL" id="twitter_brand">
          </div>
        </div>

        <!-- INSTAGRAM -->
        <div class="row">
          <div class="col-md-1">
            <i class="fab fa-lg fa-instagram" style="vertical-align: -webkit-baseline-middle;"></i>
          </div>
          <div class="col-md-11">
            <input [(ngModel)]="brandSelected!.instagram" class="form-control" type="url"
                   placeholder="Instagram URL" id="instagram_brand">
          </div>
        </div>


        <!-- LINKEDIN -->
        <div class="row">
          <div class="col-md-1">
            <i class="fab fa-lg fa-linkedin" style="vertical-align: -webkit-baseline-middle;"></i>
          </div>
          <div class="col-md-11">
            <input [(ngModel)]="brandSelected!.linkedin" class="form-control" type="url"
                   placeholder="Linkedin URL" id="linkedin_brand">
          </div>
        </div>


        <!-- PINTEREST -->
        <div class="row">
          <div class="col-md-1">
            <i class="fab fa-lg fa-pinterest" style="vertical-align: -webkit-baseline-middle;"></i>
          </div>
          <div class="col-md-11">
            <input [(ngModel)]="brandSelected!.pinterest" class="form-control" type="url"
                   placeholder="Pinterest URL" id="pinterest_brand">
          </div>
        </div>

        <div class="button-flex" style="width: 100%;">
          <button type="button" data-dismiss="modal" class="btn btn-link">Chiudi</button>
          <button type="submit" (click)="updateSocialBrand()" class="btn btn-primary ml-auto">Conferma
          </button>
        </div>
      </div>


    </div>
  </div>
</div>


<div class="modal fade" id="modale-modifica-brand" tabindex="-1" role="dialog" aria-labelledby="modal-default"
     aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">

      <div class="modal-header">
        <h6 class="modal-title">Modifica Brand - <b>{{brandSelected.nome}}</b></h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <input type="hidden" id="id_brand" [(ngModel)]="brandSelected!.id">

          <!-- NOME -->
          <div class="col-md-6">
            <label for="" class="form-control-label">Nome Brand</label>
            <input [(ngModel)]="brandSelected!.nome" class="form-control" required="" type="text"
                   placeholder="Nome" id="nome_brand">
          </div>

          <!-- EMAIL -->
          <div class="col-md-6">
            <label for="" class="form-control-label">Email</label>
            <input [(ngModel)]="brandSelected!.email" class="form-control" required="" type="email"
                   placeholder="Nome" id="email_brand">
          </div>

        </div>


        <div class="row">

          <!-- TELEFONO -->
          <div class="col-md-6">
            <label for="" class="form-control-label">Telefono</label>
            <input [(ngModel)]="brandSelected!.telefono" class="form-control" type="tel"
                   placeholder="Telefono" id="telefono_brand">
          </div>

          <!-- REFERENTE -->
          <div class="col-md-6">
            <label for="" class="form-control-label">Referente</label>
            <input [(ngModel)]="brandSelected!.referente" class="form-control" type="text"
                   placeholder="Referente" id="referente_brand">
          </div>

        </div>

        <div class="row">

          <!-- INDIRIZZO -->
          <div class="col-md-12">
            <label for="" class="form-control-label">Indirizzo</label>
            <input [(ngModel)]="brandSelected!.indirizzo" class="form-control" type="text"
                   placeholder="Indirizzo" id="indirizzo_brand">
          </div>

          <!-- NAZIONE -->
          <div class="col-md-6" style="display: none">
            <label for="" class="form-control-label">Nazione</label>
            <input [(ngModel)]="brandSelected!.nazione" class="form-control" type="text" placeholder="Nazione"
                   id="nazione_brand">
          </div>


        </div>


        <div class="row">

          <!-- LOGO -->
          <div class="col-md-12 text-center">
            <div class="form-group">
              <label for="" class="form-control-label">Logo</label><br>

              <input id="uploadLogoBrandModifica" type="file" accept="image/*" name="image_modifica">
              <div style="text-align:center">
                <img style="width: 58%;margin-top: 2%;" id="previewLogoBrandModifica" src="">
              </div>

            </div>


          </div>

        </div>
        <div class="button-flex" style="width: 100%;">
          <button type="button" data-dismiss="modal" class="btn btn1">Chiudi</button>
          <button type="submit" (click)="updateBrand()" class="btn btn2">Conferma</button>
        </div>

      </div>


    </div>
  </div>
</div>


<section>
  <div class="card card-colored card-retailer">
    <div class="card mt-5">
      <div id="body-organizzazione">
        <div class="organizzazione">
          <div class="org-box">
            <img loading="lazy" src="assets/img/team-4.jpg">
            <h5 class="alt-font">
              Yapp <span>(modifica nome)</span>
              <span>(modifica immagine)</span>
            </h5>
          </div>

        </div>
      </div>
      <div class="nav-wrapper">
        <ul class="nav nav-pills nav-fill flex-md-row nav-retailer"
            id="tabs-icons-text" role="tablist">

          <li class="nav-item nav-utente" *ngIf="roleUserLogged == 'RETAILER'">
            <a class="nav-link mb-sm-3 mb-md-0 active" id="tabs-negozi-tab"
               data-toggle="tab" href="#tabs-negozi" role="tab"
               aria-controls="tabs-negozi" aria-selected="false">
              <i class="fi fi-rr-home"></i>NEGOZI</a>
          </li>

          <li class="nav-item nav-utente" *ngIf="roleUserLogged == 'BRAND'">
            <a class="nav-link mb-sm-3 mb-md-0 active" id="tabs-brand-tab"
               data-toggle="tab" href="#tabs-brand" role="tab"
               aria-controls="tabs-brand" aria-selected="false">
              <i class="fi fi-rr-gift"></i>BRAND</a>
          </li>


          <li class="nav-item nav-utente">
            <a class="nav-link mb-sm-3 mb-md-0" id="tabs-magazzini-tab"
               data-toggle="tab" href="#tabs-magazzini" role="tab"
               aria-controls="tabs-magazzini" aria-selected="false"><i class="fi fi-rr-shop"></i>MAGAZZINI</a>
          </li>
          <li class="nav-item nav-utente">
            <a class="nav-link mb-sm-3 mb-md-0" id="tabs-aziende-tab"
               data-toggle="tab" href="#tabs-aziende" role="tab"
               aria-controls="tabs-aziende" aria-selected="false"><i class="fi fi-rr-label"></i>AZIENDE</a>
          </li>
          <li class="nav-item nav-utente">
            <a class="nav-link mb-sm-3 mb-md-0" id="tabs-utenti-tab"
               data-toggle="tab" href="#tabs-utenti" role="tab"
               aria-controls="tabs-utenti" aria-selected="false"><i class="fi fi-rr-users-alt"></i>UTENTI</a>
          </li>
        </ul>
      </div>
      <div class="card-body" style="padding: 20px 0;">
        <div class="tab-content" id="myTabContent">

          <!-- NEGOZI -->
          <div class="tab-pane fade show active" id="tabs-negozi"
               role="tabpanel" aria-labelledby="tabs-negozi" *ngIf="roleUserLogged == 'RETAILER'">
            <div id="body-negozi">
              <div class="row">
                <div class="col-md-12">
                  <button data-toggle="tooltip" title="" class="btn btn-sm btn-primary aggiungi-prof"
                          (click)="loadDataShopSelected(null)" data-toggle="modal"
                          data-target="#modale-aggiungi-negozio">
                    Aggiungi nuovo Negozio <i class="fas fa-plus"></i>
                  </button>
                </div>
              </div>

              <div class="row">
                <ng-container *ngIf="shopsList.length == 0">
                  <div class="col-md-12">
                    <div class="alert alert-warning" role="alert">
                      <strong>Info!</strong> Nessun Negozio censito
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="shopsList.length > 0">
                  <ng-container *ngFor="let item of shopsList;">

                    <div class="col-md-6">
                      <div class="panel-group wrap" id="accordion" role="tablist"
                           aria-multiselectable="true">
                        <div class="panel">
                          <div class="panel-heading" role="tab" id="headingOne">
                            <h4 class="panel-title">
                              <a role="button" data-toggle="collapse" data-parent="#accordion"
                                 href="#collapseNegozio{{item.id}}" aria-expanded="true"
                                 aria-controls="collapseNegozio">
                                <!-- <span class="counter-i">1 </span> -->
                                {{item.nome}}</a>
                            </h4>
                          </div>
                          <div id="collapseNegozio{{item.id}}" class="panel-collapse collapse in"
                               role="tabpanel" aria-labelledby="headingOne">
                            <div class="panel-body">
                              <!-- <p>
                                  ID:42                                </p> -->
                              <a target="_blank"
                                 href="https://r2w-media-dev.fra1.cdn.digitaloceanspaces.com/organizzazione/57/6325d93b5bfea.png">
                                <img loading="lazy" width="70" style="margin-bottom: 10px;"
                                     src="https://r2w-media-dev.fra1.cdn.digitaloceanspaces.com/organizzazione/57/6325d93b5bfec-thumb.png">
                              </a>

                              <p>
                                {{item.citta}}
                                {{'(' + item.provincia + ')'}} ,

                                {{item.cap}}
                                {{item.nazione}}
                                <br> {{item.indirizzo}} ,  {{item.numero_civico}} </p>

                              <p>{{item.email}}</p>
                              <p></p>
                              <p><a target="_blank" href="{{item.sito_web}}">
                                {{item.sito_web}}                                        </a>
                              </p>
                              <div class="social-info socialP">
                                <!-- TWITTER -->
                                <!-- INSTAGRAM -->
                                <a target="_blank" href="{{item.instagram}}">
                                  <i class="fab fa-lg fa-instagram" style="cursor:pointer"></i>
                                </a>
                                <!-- LINKEDIN -->
                                <!-- PINTEREST -->
                              </div>

                              <div style="cursor:pointer">
                                <button (click)="changeShopState(item)" type="button"
                                        :class="btn btn-toggle {{item.stato == 1 ? 'active' : ''  }}"
                                        style="cursor:pointer;background:#031d6d">
                                  <div class="handle"></div>
                                </button>
                                <span> - {{item.stato == 1 ? "Aperto" : "Chiuso"}}</span>
                              </div>

                              <div class="actionI">
                                <i data-toggle="tooltip" title="Social Negozio" data-toggle="modal"
                                   data-target="#modale-social-negozio"
                                   (click)="loadDataShopSelected(item)"
                                   class="fas fa-lg fa-share-alt actions-i"></i>
                                <i data-toggle="tooltip" title="Modifica Negozio" data-toggle="modal"
                                   data-target="#modale-modifica-negozio"
                                   (click)="loadDataShopSelected(item)" class="fas fa-pen actions-i"
                                ></i>
                                <i data-toggle="tooltip" title="Elimina Negozio" data-toggle="modal"
                                   data-target="#modale-elimina-negozio"
                                   (click)="loadDataShopSelected(item)"
                                   class="fas fa-lg fa-trash actions-i"
                                ></i>
                              </div>

                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>


            </div>
          </div>


          <!-- BRAND -->
          <div class="tab-pane fade show active" id="tabs-brand" *ngIf="roleUserLogged == 'BRAND'"
               role="tabpanel" aria-labelledby="tabs-brand">
            <div id="body-brand">
              <div class="row">
                <div class="col-md-12">
                  <button data-toggle="tooltip" (click)="loadDataBrandSelected(null)" title=""
                          class="btn btn-sm btn-primary aggiungi-prof" data-toggle="modal"
                          data-target="#modale-aggiungi-brand">
                    Aggiungi nuovo Brand <i class="fas fa-plus"></i>
                  </button>
                </div>
              </div>
              <div class="row">

                <ng-container *ngIf="brandsList.length == 0">
                  <div class="col-md-12">
                    <div class="alert alert-warning" role="alert">
                      <strong>Info!</strong> Nessun Brand censito
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="brandsList.length > 0">
                  <ng-container *ngFor="let item of brandsList;">

                    <div class="col-md-6">
                      <div class="panel-group wrap" id="accordion" role="tablist"
                           aria-multiselectable="true">
                        <div class="panel">
                          <div class="panel-heading" role="tab" id="headingOne">
                            <h4 class="panel-title">
                              <a role="button" data-toggle="collapse" data-parent="#accordion"
                                 href="#collapseBrand{{item.id}}" aria-expanded="true"
                                 aria-controls="collapseBrand" class="">
                                <!-- <span class="counter-i">1 </span> -->
                                {{item.nome}}                        </a>
                            </h4>
                          </div>
                          <div id="collapseBrand{{item.id}}" class="panel-collapse in collapse"
                               role="tabpanel" aria-labelledby="headingOne">
                            <div class="panel-body">
                              <a target="_blank"
                                 href="https://r2w-media-dev.fra1.cdn.digitaloceanspaces.com/organizzazione/54/6324360d316bc.png">
                                <img loading="lazy" style="width:70px;margin-bottom: 10px;"
                                     src="https://r2w-media-dev.fra1.cdn.digitaloceanspaces.com/organizzazione/54/6324360d316bd-thumb.png">
                              </a>
                              <!-- <p>
                                  ID:23                        </p> -->
                              <p> {{item.email}}</p>

                              <p>

                                <br> -

                              </p>
                              <p>
                              </p>
                              <a target="_blank" href=" {{item.sito_web}}">{{item.sito_web}} </a>

                              <div class="social-info socialP">
                                <a target="_blank" href="{{item.facebook}}">
                                  <i class="fab fa-lg fa-facebook-f" style="cursor:pointer"></i>
                                </a>
                                <!-- TWITTER -->
                                <a target="_blank" href="{{item.twitter}}">
                                  <i class="fab fa-lg fa-twitter" style="cursor:pointer"></i>
                                </a>
                                <!-- INSTAGRAM -->
                                <a target="_blank" href="{{item.instagram}}">
                                  <i class="fab fa-lg fa-instagram" style="cursor:pointer"></i>
                                </a>
                                <!-- LINKEDIN -->
                                <!-- PINTEREST -->
                              </div>
                              <div class="actionI">
                                <i data-toggle="tooltip" title="Social Brand"
                                   (click)="loadDataBrandSelected(item)" data-toggle="modal"
                                   data-target="#modale-social-brand"
                                   class="fas fa-lg fa-share-alt actions-i"
                                   style="cursor:pointer;background:#031d6d"></i>
                                <i data-toggle="tooltip" title="Modifica Brand"
                                   (click)="loadDataBrandSelected(item)" data-toggle="modal"
                                   data-target="#modale-modifica-brand"
                                   class="fas fa-lg fa-pen actions-i"
                                   style="cursor:pointer;background:#031d6d"></i>
                                <i data-toggle="tooltip" title="Elimina Brand"
                                   (click)="loadDataBrandSelected(item)" data-toggle="modal"
                                   data-target="#modale-elimina-brand"
                                   class="fas fa-lg fa-lg fa-trash actions-i"
                                   style="cursor:pointer;background:#031d6d"></i>
                              </div>

                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>


              </div>


            </div>
          </div>


          <!-- MAGAZZINI -->
          <div class="tab-pane fade" id="tabs-magazzini"
               role="tabpanel" aria-labelledby="tabs-magazzini">
            <div id="body-magazzini">
              <div class="row">
                <div class="col-md-12">
                  <button type="button" class="btn btn-sm btn-primary aggiungi-prof"
                          (click)="loadDataStoreSelected(null)" data-toggle="modal"
                          data-target="#modale-aggiungi-magazzino">
                    Aggiungi nuovo Magazzino <i class="fas fa-plus"></i>
                  </button>
                </div>

                <div class="col-md-12">
                  <div class="row">
                    <ng-container *ngIf="companiesList.length > 0">
                      <ng-container *ngFor="let item of storesList;">
                        <div class="col-md-6">
                          <div class="panel-group wrap" id="accordion" role="tablist"
                               aria-multiselectable="true">
                            <div class="panel">
                              <div class="panel-heading" role="tab" id="headingOne">
                                <h4 class="panel-title">
                                  <a role="button" data-toggle="collapse" data-parent="#accordion"
                                     href="#collapseMagazzino{{item.id}}" aria-expanded="true"
                                     aria-controls="collapseMagazzino37">
                                    {{item.nome}}
                                  </a>
                                </h4>
                              </div>
                              <div id="collapseMagazzino{{item.id}}" class="panel-collapse collapse in"
                                   role="tabpanel" aria-labelledby="headingOne">
                                <div class="panel-body">
                                  <!-- <p>
                                      ID:37                                </p> -->

                                  <p>
                                    {{item.citta}},
                                    {{ '(' + item.provincia + ')'}}

                                    {{item.cap}}
                                    {{item.nazione}}
                                    <br> {{item.indirizzo}}, {{item.numero_civico}}
                                  </p>

                                  <p>
                                    <i class="fa fa-phone-square" aria-hidden="true"></i>
                                    {{item.telefono != null ? item.telefono : ""}}
                                  </p>

                                  <p>
                                    <i class="fa fa-envelope" aria-hidden="true"></i>
                                    {{item.email}}
                                  </p>


                                  <div style="cursor:pointer">

                                    <button (click)="changeStoreState(item)" type="button"
                                            :class="btn btn-toggle {{item.stato == 1 ? 'active' : ''  }}"
                                            style="cursor:pointer;background:#031d6d">
                                      <div class="handle"></div>
                                    </button>
                                    <span>{{item.stato == 1 ? "Aperto" : "Chiuso"}}</span>
                                  </div>


                                  <!-- AZIONI -->
                                  <div class="actionI">
                                    <button type="button" class="btn btn-primary btn-modal"
                                            (click)="loadDataStoreSelected(item)" data-toggle="modal"
                                            data-target="#modale-modifica-magazzino">
                                      <i data-toggle="tooltip" title="Modifica Magazzino"
                                         class="fas fa-pen actions-i"></i>
                                    </button>

                                    <button type="button" class="btn btn-primary btn-modal"
                                            (click)="loadDataStoreSelected(item)" data-toggle="modal"
                                            data-target="#modale-elimina-magazzino">
                                      <i data-toggle="tooltip" title="Elimina Magazzino"
                                         class="fas fa-lg fa-trash actions-i"></i>
                                    </button>
                                  </div>


                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="companiesList.length == 0">
                      <div class="col-md-12">
                        <div class="alert alert-warning" role="alert">
                          <strong>Info!</strong> Nessun Magazzino censito
                        </div>
                      </div>
                    </ng-container>
                  </div>


                </div>
              </div>


            </div>
          </div>


          <!-- AZIENDE -->
          <div class="tab-pane fade" id="tabs-aziende"
               role="tabpanel" aria-labelledby="tabs-aziende">
            <div id="body-aziende">
              <div class="row">
                <div class="col-md-12">
                  <button title="" class="btn btn-sm btn-primary aggiungi-prof"
                          (click)="loadDataCompanySelected(null)" data-toggle="modal"
                          data-target="#modale-aggiungi-azienda">
                    Aggiungi nuova azienda <i class="fas fa-plus"></i>
                  </button>
                </div>
              </div>
              <div class="row">
                <ng-container *ngIf="companiesList.length > 0">
                  <ng-container *ngFor="let item of companiesList;">
                    <div class="col-md-6">
                      <div class="panel-group wrap" id="accordion" role="tablist"
                           aria-multiselectable="true">
                        <div class="panel">
                          <div class="panel-heading" role="tab" id="headingOne">
                            <h4 class="panel-title">
                              <a role="button" data-toggle="collapse" data-parent="#accordion"
                                 href="#collapseAzienda{{item.id}}" aria-expanded="true"
                                 aria-controls="collapseAzienda43">
                                <!-- <span class="counter-i">1 </span>  -->
                                {{item.ragione_sociale}}</a>
                            </h4>
                          </div>
                          <div id="collapseAzienda{{item.id}}" class="panel-collapse collapse in"
                               role="tabpanel" aria-labelledby="headingOne">
                            <div class="panel-body">
                              <!-- <p>
                                  ID:43                            </p> -->
                              <p>
                                {{item.citta}}
                                {{'(' + item.provincia + ')'}},

                                {{item.cap}}
                                {{item.nazione}}
                                <br>{{item.indirizzo}}, {{item.numero_civico}}</p>
                              <p>{{item.pec}} </p>
                              <p>{{item.partita_iva}}</p>

                              <div class="actionI">
                                <button type="button" class="btn btn-primary btn-modal"
                                        (click)="loadDataCompanySelected(item)" data-toggle="modal"
                                        data-target="#modale-modifica-azienda">
                                  <i data-toggle="tooltip" title="Modifica Azienda"
                                    class="fas fa-lg fa-edit actions-i"></i>
                                </button>
                                <button type="button" class="btn btn-primary btn-modal"
                                        (click)="loadDataCompanySelected(item)" data-toggle="modal"
                                        data-target="#modale-modifica-termini">
                                  <i data-toggle="tooltip" title="Modifica Azienda"
                                    class="fas fa-lg fa-edit actions-i"></i>
                                </button>
                                <button type="button" class="btn btn-primary btn-modal"
                                        (click)="loadDataCompanySelected(item)" data-toggle="modal"
                                        data-target="#modale-elimina-azienda">
                                  <i data-toggle="tooltip" title="Elimina Azienda"
                                     class="fas fa-lg fa-trash actions-i"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="companiesList.length == 0">
                  <div class="col-md-12">
                    <div class="alert alert-warning" role="alert">
                      <strong>Info!</strong> Nessun Azienda censita

                    </div>
                  </div>
                </ng-container>


              </div>
            </div>
          </div>


          <!-- UTENTI -->
          <div class="tab-pane fade" id="tabs-utenti"
               role="tabpanel" aria-labelledby="tabs-utenti">
            <div id="body-utenti">
              <div class="row">
                <div class="col-md-12">


                  <button title="Aggiungi nuovo Utente" class="btn btn-sm btn-primary aggiungi-prof"
                          (click)="loadDataUserSelected(null)" data-toggle="modal"
                          data-target="#modale-aggiungi-utente">
                    Aggiungi nuovo Utente <i class="fas fa-plus"></i>
                  </button>
                </div>
              </div>

              <div class="row">
                <ng-container *ngIf="usersList.length == 0">
                  <div class="col-md-12">
                    <div class="alert alert-warning" role="alert">
                      <strong>Info!</strong> Nessun utente censito
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngIf="usersList.length > 0">
                  <ng-container *ngFor="let item of usersList;">
                    <div class="col-md-6">
                      <div class="panel-group wrap" id="accordion" role="tablist"
                           aria-multiselectable="true">
                        <div class="panel">
                          <div class="panel-heading" role="tab" id="headingOne">
                            <h4 class="panel-title">
                              <a role="button" data-toggle="collapse" data-parent="#accordion"
                                 href="#collapseUtente{{item.id}}" aria-expanded="false"
                                 aria-controls="collapseUtente" class="collapsed">
                                <!-- <span class="counter-i">1 </span>  -->
                                {{item.nome + ' ' + item.cognome}}                            </a>
                            </h4>
                          </div>
                          <div id="collapseUtente{{item.id}}" class="panel-collapse in collapse"
                               role="tabpanel" aria-labelledby="headingOne">
                            <div class="panel-body">
                              <!-- <p>
                                  ID: 103                            </p> -->
                              <p>{{item.email}}</p>
                              <p>{{item.tipo}}</p>
                              <div>
                                <button type="button" class="btn btn-primary btn-modal"
                                        (click)="loadDataUserSelected(item)" data-toggle="modal"
                                        data-target="#modale-modifica-utente">
                                  <i data-toggle="tooltip" title="Modifica Utente"
                                     class="fas fa-pen actions-i"
                                     style="cursor:pointer;color:#76767c"></i>
                                </button>

                                <button type="button" class="btn btn-primary btn-modal"
                                        (click)="loadDataUserSelected(item)" data-toggle="modal"
                                        data-target="#modale-elimina-utente">
                                  <i data-toggle="tooltip" title="Elimina Utente"
                                     class="fas fa-trash actions-i"
                                     style="background: #031d6d;cursor:pointer;color:red"></i>
                                </button>
                                <button type="button" class="btn btn-primary btn-modal"
                                        (click)="loadDataUserSelected(item)" data-toggle="modal"
                                        data-target="#modale-modifica-magazzino">
                                  <i data-toggle="tooltip" title="Invia email di invito"
                                     class="fas fa-lg fa-paper-plane"
                                     style="cursor:pointer;color:blue"></i>
                                </button>


                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </ng-container>
                </ng-container>


              </div>


            </div>
          </div>


        </div>
      </div>
    </div>

  </div>
</section>


<div mat-dialog-title class="new-format-modal">
    <div class="container-fluid header-modal">
      <div class="row">
        <div style="width:90%">
          <h3>CAMBIO PASSWORD</h3>
        </div>
  
        <div>
          <button class="close-button" [mat-dialog-close]="true">×
          </button>
        </div>
      </div>
  
  
    </div>
  </div>
    <mat-dialog-content>
        <div class="container-fluid">

            <div class="row">
                <div class="col-md-12">
                    <input  type="text" class="form-control" [(ngModel)]="passwordCurrent" placeholder="Password Attuale">
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <input  type="text" class="form-control" [(ngModel)]="password" placeholder="Nuova Password">
                </div>
            </div>


            <div class="row">
                <div class="col-md-12">
                    <input  type="text" class="form-control" [(ngModel)]="confirmPassword" placeholder="Conferma Nuova Password">
                </div>
            </div>


    
        </div>

    </mat-dialog-content>
  
  
  
  <div mat-dialog-actions>
    <div class="bottom-actions">
      <button mat-flat-button color="warn" class="btn-modal-close" [mat-dialog-close]="true">Chiudi</button>
      <button mat-button class="button-save" class="btn-modal-confirm" (click)="confirm()">Conferma</button>
    </div>
  </div>
  
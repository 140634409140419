import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/auth/auth.service';
import { PreordiniService } from 'app/services/preordini.service';

@Component({
  selector: 'app-preordini',
  templateUrl: './preordini.component.html',
  styleUrls: ['./preordini.component.sass']
})
export class PreordiniComponent implements OnInit {

  preordini : any[] = [];
  preordini_all: any[] = [];

  constructor(private preordiniService: PreordiniService) { }

  ngOnInit(): void {
    this.getPreordini();
    setTimeout(this.loadFilters,100);
  }

  getPreordini(): void {
    this.preordiniService.getPreordini()
    .subscribe( resp => {
      console.log(resp.data);
      this.loadPreordini(resp.data);
    })
  }

  loadPreordini(data: any[]): void {
    this.preordini = [];
    for (let preordine of data) {
      preordine.totale_prodotti = preordine.ordini_brand_prodotti.reduce((acc: number, prodotto: { quantita: number; }) => {return acc + prodotto.quantita}, 0);
      preordine.totale_valore = preordine.ordini_brand_prodotti.reduce((acc: number, prodotto: { prezzo_totale: number; }) => {return acc + prodotto.prezzo_totale}, 0);
      preordine.data_ordine = preordine.data_ordine.substring(0, preordine.data_ordine.indexOf('T'));
      this.preordini.push(preordine);
      console.log(preordine);
    }
    this.preordini_all = this.preordini;
  }

  filter(): void {
    this.preordini = this.preordini_all;
    const form: FormData = new FormData(document.querySelector('#filters') as HTMLFormElement);
    let dati: { [key: string]: Array<string> } = {};
    for (let entry of form.keys()) {
      console.log(entry);
      dati[entry] = form.getAll(entry) as Array<string>;
    }
    console.log(dati);
    this.preordini = this.preordini.filter((preordine) =>
    { return Object.entries(dati).every(([key,arrayValue]) =>
    { return arrayValue.some((value) =>
    { return key.split('.').reduce((o,i)=> o[i], preordine).toString().toLowerCase().includes(value.toString().toLowerCase())})})});
  }

  deselectAll(): void {
    // @ts-expect-error
    $('#filters select option').attr("selected",false);
    // @ts-expect-error
    $('#filters select').val('').trigger('change');
    // @ts-expect-error
    $('#filters select').selectpicker('refresh');
    document.querySelectorAll('#filters input').forEach(input => (input as HTMLInputElement).value = '');
    this.preordini = this.preordini_all;
  }

  loadFilters(): void {
    const filtri = document.querySelectorAll('#filters select');
    filtri.forEach(filtro => {
      // @ts-expect-error
      $('#'+filtro.id).selectpicker({
        noneSelectedText: filtro.id
      })
    });
  }

  order(event: Event): void {
    const order = (event.target as HTMLElement).dataset.order!;
    const by = (event.target as HTMLElement).dataset.by!;
    document.querySelectorAll('th').forEach(element => element.classList.remove("order-asc","order-desc"))
    if (by == "" || by == "desc") {
      this.preordini.sort((elem1, elem2) => order.split('.').reduce((o,i)=> o[i], elem1).toString().localeCompare(order.split('.').reduce((o,i)=> o[i], elem2).toString()));
      (event.target as HTMLElement).dataset.by = "asc";
      (event.target as HTMLElement).classList.add("order-asc");
    }
    else {
      this.preordini.sort((elem1, elem2) => order.split('.').reduce((o,i)=> o[i], elem2).toString().localeCompare(order.split('.').reduce((o,i)=> o[i], elem1).toString()));
      (event.target as HTMLElement).dataset.by = "desc";
      (event.target as HTMLElement).classList.add("order-desc");
    }
  }
}

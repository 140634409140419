import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClientiService } from 'app/services/clienti.service';
import { FormBuilder, Validators } from '@angular/forms';
import { CondizioniCommercialiService } from 'app/services/condizioni_commerciali.service';

@Component({
  selector: 'app-clienti-detail',
  templateUrl: './clienti-detail.component.html',
  styleUrls: ['./clienti-detail.component.scss']
})
export class ClientiDetailComponent implements OnInit {

  private id_cliente: number = 0;
  cliente: any | undefined;
  num_ordini: number = this.randomInt(10);
  tot_ordini: number = this.randomInt(10000);
  euro_max: number = this.randomInt(5000);
  pezzi_max: number = this.randomInt(50);
  sconto: number = this.randomInt(20);
  canali = ['wholesale','retail','ecommerce'];
  tipi = ['RETAILER','CONSUMER','DISTRIBUTOR'];
  clienteForm: any | undefined;
  condizioni_commerciali: any[] = [];
  condizione_selected: any | undefined;

  constructor(private route: ActivatedRoute, private clientiService: ClientiService, private fb: FormBuilder, private condizioniCommercialiService : CondizioniCommercialiService) { }

  get fcliente() { return this.clienteForm.controls; }

  ngOnInit(): void {
    this.getCliente();
    this.getCondizioni();
  }

  getCliente(): void {
    this.id_cliente = parseInt(this.route.snapshot.paramMap.get('id')!, 10);
    this.clientiService.getCliente(this.id_cliente)
    .subscribe(resp => {
      this.cliente = resp.data;
      this.clienteForm = this.fb.group({
        nome: [this.cliente.nome, [Validators.required]],
        ragione_sociale: [this.cliente.ragione_sociale, [Validators.required]],
        partita_iva: [this.cliente.partita_iva, [Validators.required]],
        telefono: [this.cliente.telefono, [Validators.required]],
        email: [this.cliente.email, [Validators.required]],
        pec: [this.cliente.pec, [Validators.required]],
        sdi: [this.cliente.sdi, [Validators.required]],
        sede_legale: [this.cliente.sede_legale, [Validators.required]],
        indirizzo: [this.cliente.indirizzo, [Validators.required]],
        referente: [this.cliente.referente, [Validators.required]],
        canale: [this.cliente.canale, [Validators.required]],
        tipo: [this.cliente.tipo, [Validators.required]],
      })
    });
  };

  getCondizioni(): void {
    this.id_cliente = parseInt(this.route.snapshot.paramMap.get('id')!, 10);
    this.condizioniCommercialiService.getCondizioni(this.id_cliente)
    .subscribe(resp => {
      console.log(resp.data);
      this.condizioni_commerciali = resp.data;
    })
  }

  createCondizioni(): void {
    this.id_cliente = parseInt(this.route.snapshot.paramMap.get('id')!, 10);
    this.condizioniCommercialiService.createCondizioni(this.id_cliente)
    .subscribe(resp => {
      console.log(resp.data);
      this.getCondizioni();
    })
  }

  modificaCondizione(condizione: any): void {
    this.condizione_selected = condizione;
  }

  salvaCondizione(): void {
    this.condizioniCommercialiService.updateCondizioni(this.condizione_selected.id, this.condizione_selected)
    .subscribe(resp => {
      console.log(resp.data);
      this.getCondizioni();
    })
  }

  update(): void {
    const cliente = {
      nome: this.fcliente.nome.value,
      ragione_sociale: this.fcliente.ragione_sociale.value,
      partita_iva: this.fcliente.partita_iva.value,
      telefono: this.fcliente.telefono.value,
      email: this.fcliente.email.value,
      pec: this.fcliente.pec.value,
      sdi: this.fcliente.sdi.value,
      sede_legale: this.fcliente.sede_legale.value,
      indirizzo: this.fcliente.indirizzo.value,
      referente: this.fcliente.referente.value,
      canale: this.fcliente.canale.value,
      tipo: this.fcliente.tipo.value,
    }
    console.log(cliente);
    this.clientiService.updateCliente(this.id_cliente.toString(), cliente)
    .subscribe(resp => {
      if (resp.status = 200) {
        this.getCliente();
        // @ts-expect-error
        $('#anagraficaModal').modal('hide');
      }
    },
    (error) => {
      console.log(error);
      alert('Errore nella modifica del cliente: ' + error.toString())
    });
  }

  randomInt(max: number): number {
    return Math.floor(Math.random() * max);
  }

  checkCondizioni(): boolean {
    return (this.cliente.tipo == 'consumatore' && ['retail', 'ecommerce'].includes(this.cliente.canale));
  }
}

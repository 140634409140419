<section>
  <div class="">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-colored">
          <h1 class="text-uppercase text-white border-bottom-ab">Magazzino</h1>
          <div id="main" *ngIf="roleUserLogged == 'RETAILER'">
            <button class="btn agg-mag" (click)="openNav()">
              Aggiungi
            </button>
          </div>
          <div class="button-mag">

            <div class="row">
              <div class="col-md-3 pr-0">
                <select id="brand" multiple="multiple" required class="form-control select2-brand"
                  [(ngModel)]="filter.brand" data-live-search="true">
                  <option value="">BRAND</option>
                  <ng-container *ngFor="let item of brand_for_search">
                    <option value="{{item.id}}">{{item.nome}}</option>
                  </ng-container>

                </select>
              </div>
              <div class="col-md-2 pr-0">
                <input type="text" name="codice_articolo" value="" [(ngModel)]="filter.codice_articolo"
                  placeholder="CODICE ARTICOLO" class="form-control">
              </div>
              <div class="col-md-3 pr-0">
                <input type="text" class="form-control" [(ngModel)]="filter.barcode" value=""
                  placeholder="RICERCA BARCODE">
              </div>
              <div class="col-md-2 pr-0">
                <select id="stagioni" multiple="multiple" placeholder="Stagione" required
                  class="form-control select2-stagioni" [(ngModel)]="filter.stagioni" data-live-search="true">
                  <option value="">STAGIONE</option>
                  <ng-container *ngFor="let item of stagioni_for_search">
                    <option value="{{item.id}}">{{item.nome}}</option>
                  </ng-container>
                </select>
              </div>
              <div class="col-md-2 pr-0">
                <select id="collezioni" multiple="multiple" placeholder="Collezione" required
                  class="form-control select2-collezioni" [(ngModel)]="filter.collezioni" data-live-search="true">
                  <option value="">COLLEZIONE</option>
                  <ng-container *ngFor="let item of collezioni_for_search">
                    <option value="{{item.id}}">{{item.nome}}</option>
                  </ng-container>
                </select>
              </div>

            </div>
            <div class="row">
              <div class="col-md-2 pr-0">
                <select id="tessuti" multiple="multiple" placeholder="Tessuto" required
                  class="form-control select2-tessuti" [(ngModel)]="filter.tessuti" data-live-search="true">
                  <option value="">TESSUTO</option>
                  <ng-container *ngFor="let item of tessuti_for_search">
                    <option value="{{item.id}}">{{item.nome}}</option>
                  </ng-container>
                </select>
              </div>
              <div class="col-md-2 pr-0">
                <select id="colori" multiple="multiple" placeholder="Colore" required
                  class="form-control select2-colori" [(ngModel)]="filter.colori" data-live-search="true">
                  <option value="">COLORE</option>
                  <ng-container *ngFor="let item of colori_for_search">
                    <option value="{{item.id}}">{{item.nome}}</option>
                  </ng-container>
                </select>
              </div>
              <div class="col-md-2 pr-0">
                <select (ngModelChange)="sectorChange($event)" id="settori" multiple [(ngModel)]="filter.settore"
                  placeholder="Settore" required class="form-control select2-settori" data-live-search="true">
                  <option value="">SETTORE</option>
                  <ng-container *ngFor="let item of settori_for_search">
                    <option value="{{item.id}}">{{item.nome}}</option>
                  </ng-container>
                </select>
              </div>

              <div class="col-md-2 pr-0">
                <select id="categorie" (ngModelChange)="macroChange($event)" multiple placeholder="Categorie" required
                  [(ngModel)]="filter.macrocategorie" class="form-control select2-categorie" data-live-search="true">
                  <option value="">MACROCATEGORIA</option>
                  <ng-container *ngFor="let item of macrocategorie_for_search">
                    <option value="{{item.id}}">{{item.nome}}</option>
                  </ng-container>

                </select>
              </div>


              <div class="col-md-2 pr-0">
                <select id="categorie" (ngModelChange)="categoryChange($event)" multiple placeholder="Categorie"
                  required [(ngModel)]="filter.categorie" class="form-control select2-categorie"
                  data-live-search="true">
                  <option value="">CATEGORIA</option>
                  <ng-container *ngFor="let item of categorie_for_search">
                    <option value="{{item.id}}">{{item.nome}}</option>
                  </ng-container>

                </select>
              </div>

              <div class="col-md-2 pr-0">
                <select id="categorie" multiple placeholder="Categorie" required [(ngModel)]="filter.sottocategorie"
                  class="form-control select2-categorie" data-live-search="true">
                  <option value="">SOTTOCATEGORIA</option>
                  <ng-container *ngFor="let item of sottocategorie_for_search">
                    <option value="{{item.id}}">{{item.nome}}</option>
                  </ng-container>

                </select>
              </div>
            </div>

            <div class="row">
              <div class="col-md-3 pr-0">
                <select (ngModelChange)="sizeChange($event)" id="taaglie" multiple="multiple" placeholder="Taglia"
                  required class="form-control select2-taglie" [(ngModel)]="filter.taglie" data-live-search="true">
                  <option value="">TAGLIA</option>
                  <ng-container *ngFor="let item of taglie_for_search">
                    <option value="{{item.id}}">{{item.nome}}</option>
                  </ng-container>
                </select>
              </div>
              <div class="col-md-3 pr-0">
                <select id="taaglie" multiple="multiple" placeholder="Taglia" required
                  class="form-control select2-taglie" [(ngModel)]="filter.taglia_descrizione" data-live-search="true">
                  <option value="">TAGLIA DESCRIZIONE</option>
                  <ng-container *ngFor="let item of taglie_for_search">
                    <option value="{{item.id}}">{{item.nome}}</option>
                  </ng-container>
                </select>
              </div>
              <div class="col-md-2 pr-0">
                <div class="giac-input">
                  <label class="giac-label">GIACENZE</label>
                  <input id="s1" type="checkbox" class="switch" [(ngModel)]="filter.giacenza" name="giacenza">
                </div>
              </div>

              <div class="col-md-4 pr-0">
                <div class="form-flex">
                  <input type="number" class="form-control" [(ngModel)]="filter.prezzo_dal" placeholder="PREZZO DAL"
                    name="prezzo_dal">
                  <input type="number" class="form-control" [(ngModel)]="filter.prezzo_al" placeholder="PREZZO AL"
                    name="prezzo_al">
                </div>
              </div>

              <div class="row">
                <div class="col-md-2 pr-0">
                  <select (ngModelChange)="sizeChange($event)" id="taaglie" multiple="multiple" placeholder="Taglia"
                    required class="form-control select2-taglie" [(ngModel)]="filter.taglie" data-live-search="true">
                    <option value="">TAGLIA</option>
                    <ng-container *ngFor="let item of taglie_for_search">
                      <option value="{{item.id}}">{{item.nome}}</option>
                    </ng-container>
                  </select>
                </div>

                <div class="col-md-2 pr-0">
                  <select id="taaglie" multiple="multiple" placeholder="Taglia" required
                    class="form-control select2-taglie" [(ngModel)]="filter.taglia_descrizione" data-live-search="true">
                    <option value="">TAGLIA DESCRIZIONE</option>
                    <ng-container *ngFor="let item of taglie_for_search">
                      <option value="{{item.id}}">{{item.nome}}</option>
                    </ng-container>
                  </select>
                </div>

                <div class="col-md-2">
                  <div class="giac-input">
                    <label class="giac-label">GIACENZE</label>
                    <input id="s1" type="checkbox" class="switch" [(ngModel)]="filter.giacenza" name="giacenza">
                  </div>
                </div>

                <div class="col-md-2 pr-0">
                  <div class="form-flex">
                    <input type="number" class="form-control" [(ngModel)]="filter.prezzo_dal" placeholder="PREZZO DAL"
                      name="prezzo_dal">
                    <input type="number" class="form-control" [(ngModel)]="filter.prezzo_al" placeholder="PREZZO AL"
                      name="prezzo_al">
                  </div>

                </div>


              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="button-flex">
                    <button title="" type="button" class="btn btn1">
                      <i class="fas fa-file-excel"></i> Export
                    </button>
                    <button type="button" (click)="resetForm()" title="" class="btn btn2">
                      <i class="fas fa-times"></i> resetta
                    </button>
                    <button type="button" title="" (click)="search()" class="btn btn3" style="width: 100%">
                      <i class="fas fa-search"></i> Cerca
                    </button>
                  </div>
                </div>

              </div>


            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="button-flex">
                  <button title="" type="button" class="btn btn1">
                    <i class="fas fa-file-excel"></i> Export
                  </button>
                  <button type="button" (click)="resetForm()" title="" class="btn btn2">
                    <i class="fas fa-times"></i> resetta
                  </button>
                  <button type="button" title="" (click)="search()" class="btn btn3" style="width: 100%">
                    <i class="fas fa-search"></i> Cerca
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div *ngIf="modalIsVisible" style="margin-top:-2%">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-body" style="text-align: center;">
          <div class="loader" style="margin-left: 30%;"></div>
          <br>
          <h5>Caricamento dati in corso...</h5>
        </div>
      </div>
    </div>

  </div>

  <div *ngIf="modalIsVisible" style="margin-top:-2%">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-body" style="text-align: center;">
          <div class="loader" style="margin-left: 30%;"></div>
          <br>
          <h5>Caricamento dati in corso...</h5>
        </div>

        <div id="mySidebar" class="sidebar" style="width: 60%;" *ngIf="isVisibleModalSidebar">
          <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">
            <i class="fas fa-times"></i>
          </a>

          <div class="modal-body aggArtRet">
            <div class="row">
              <!-- BRAND -->
              <div class="col-md-4">

                <select class="form-control" id="brand" [(ngModel)]="add_filters.brand">
                  <option value="" disabled="" selected="">Brand</option>
                  <ng-container *ngFor="let item of listBrands; ">
                    <option value="{{item.id}}">{{item.nome}}</option>
                  </ng-container>
                </select>
              </div>
            </div>
          </div>

          <!-- BARCODE -->
          <div class="col-md-4">

            <input class="form-control" type="text" placeholder="Barcode" id="barcode"
              [(ngModel)]="add_filters.barcode">
          </div>

          <!-- CODICE ARTICOLO -->
          <div class="col-md-4">

            <input class="form-control" type="text" placeholder="Codice Articolo" id="codice_articolo"
              [(ngModel)]="add_filters.codice_articolo">
          </div>
        </div>
        <!-- CONTAINER RISULTATO RICERCA -->
        <div class="risultato_ricerca">
          <ng-container *ngFor="let articolo of add_articoli">
            <div>
              <p>Nome: {{articolo.nome}}</p>
              <p>Codice articolo: {{articolo.codice_articolo}}</p>
              <p>Brand: {{articolo.brand_nome}}</p>
              <p>Colore: {{articolo.colore}}</p>
              <p>Tessuto: {{articolo.tessuto}}</p>
              <p>Prezzo sellin: {{articolo.prezzo_vendita_ingrosso}}</p>
              <p>Prezzo sellout: {{articolo.prezzo_vendita_dettaglio}}</p>
              <ng-container *ngFor="let taglia of articolo.taglie_quantita">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">{{taglia.nome}}</span>
                  </div>
                  <input type="number" class="form-control" [(ngModel)]="taglia.quantita" />
                </div>
              </ng-container>
              <button class="btn btn-primary" (click)="add(articolo)">Aggiungi</button>
            </div>
            <hr>
          </ng-container>
        </div>
      </div>


      <div class="modal-footer">
        <div class="button-flex" style="width: 100%;">
          <button type="button" (click)="closeNav()" class="btn btn1">Annulla
          </button>
          <button type="button" title="" (click)="filter()" class="btn btn2">
            <i class="fas fa-search"></i> Cerca
          </button>
        </div>

        <button type="button" title="" (click)="add_search()" class="btn btn-primary ml-auto">
          <i class="fas fa-search"></i> Cerca
        </button>
      </div>

    </div>
  </div>


</section>


<!-- LISTA ARTICOLI -->
<ng-container *ngIf="listArticles.length > 0">

  <ng-container *ngFor="let item of listArticles; let indexOfelement=index;">
    <app-detail-articolo-retailer [item]=item></app-detail-articolo-retailer>
  </ng-container>
</ng-container>

<ng-container *ngIf="listArticles.length == 0 && !modalIsVisible">
  <div class="alert alert-warning col-md-12" role="alert">
    <strong>Info!</strong> Nessun articolo censito
  </div>
</ng-container>
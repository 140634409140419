import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AuthService } from "../auth.service";

@Injectable({
  providedIn: 'root'
})
export class RedirectGuard implements CanActivate {

  constructor(private authService: AuthService, private router : Router) { }

  async canActivate(): Promise<boolean> {
    const user = (await this.authService.getCurrentUserAsync());
    if (user != null) {
      this.router.navigateByUrl('/dashboard');
    }
    return (user == null);
  }
}

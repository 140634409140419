import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CampionariService } from 'app/services/campionari.service';

@Component({
  selector: 'app-campionari-detail',
  templateUrl: './campionari-detail.component.html',
  styleUrls: [ './campionari-detail.component.sass' ]
})
export class CampionariDetailComponent implements OnInit {
  id_campionario: number = 0;
  campionario: any | undefined;
  articoli: any[] = [];
  brands: any[] = [];
  stagioni: any[] = [];
  collezioni: any[] = [];
  taglie: any[] = [];
  tessuti: any[] = [];
  colori: any[] = [];
  settori: any[] = [];

  constructor(private route: ActivatedRoute, private campionariService: CampionariService) { }

  ngOnInit(): void {
    this.id_campionario = parseInt(this.route.snapshot.paramMap.get('id')!, 10);
    this.getCampionario();
  }

  getCampionario(): void {
    this.campionariService.getCampionario(this.id_campionario).subscribe(campionario => this.campionario = campionario);
    this.campionariService.getFiltri(this.id_campionario).subscribe(resp => {
      this.brands = resp.data.brand_for_search;
      this.stagioni = resp.data.stagioni_for_search;
      this.collezioni = resp.data.collezioni_for_search;
      this.taglie = resp.data.taglie_for_search;
      this.tessuti = resp.data.tessuti_for_search;
      this.colori = resp.data.colori_for_search;
      this.settori = resp.data.settori_for_search;
      setTimeout(this.loadFilters, 100);
      console.log(this.brands)
    });
    this.campionariService.getArticoli(this.id_campionario).subscribe(resp => {
      const articoli = resp.data;
      console.log("articoli", articoli)
      this.loadArticoli(articoli);

    });
  }

  loadFilters(): void {
    const filtri = document.querySelectorAll('#filters select');
    filtri.forEach(filtro => {
      // @ts-expect-error
      $('#' + filtro.id).selectpicker({
        noneSelectedText: filtro.id
      })
    });
  }

  loadArticoli(articoli: Array<any>): void {
    this.articoli = [];
    for (let articolo in articoli) {
      if (articoli[ articolo ].id_campionario == this.id_campionario) {
        const articoloDto = articoli[ articolo ];
        articoloDto.brand = this.brands.find(item => item.id == articoloDto.id_brand)?.nome || "Unknown";
        articoloDto.collezione = this.collezioni.find(item => item.id == articoloDto.id_collezione)?.nome || "Unknown";
        articoloDto.settore = this.settori.find(item => item.id == articoloDto.id_settore)?.nome || "Unknown";
        console.log(articoloDto);
        this.articoli.push(articoloDto);
      }
    }
  }

  search(): void {
    // @ts-expect-error
    const form = $('#filters');
    const querystring = form.serialize();
    console.log(form.serializeArray());
    this.campionariService.getArticoli(this.id_campionario, querystring).subscribe(resp => {
      const articoli = resp.data.articoli.data;
      this.loadArticoli(articoli);
    });
  }

  deselectAll(): void {
    // @ts-expect-error
    $('select option').attr("selected", false);
    // @ts-expect-error
    $('select').val('').trigger('change');
    // @ts-expect-error
    $('select').selectpicker('refresh');
    document.querySelectorAll('form input').forEach(input => (input as HTMLInputElement).value = '');
    this.getCampionario();
  }

  exportAll(): void {
    let dati: { [ key: string ]: any } = {
      id_campionario: Number(this.id_campionario),
    };
    console.log(dati);
    this.campionariService.exportCampionario(dati)
      .subscribe(resp => {
        this.saveFile("Export_Campionario.xlsx", resp.data)
      },
        (error) => {
          console.log(error);
          alert(`Errore nell'esportazione del campionario:` + error.toString())
        });
  }

  importCampionario(): void {
    const formData = new FormData();
    const closeBtn = (<HTMLButtonElement>document.getElementById("closeImportModal"));
    const file = (<HTMLInputElement>document.getElementById("xlsx")).files?.item(0) as Blob;
    const zip = (<HTMLInputElement>document.getElementById("imgZip")).files?.item(0) as Blob;

    if (!file) alert("Selezionare un file Excel")
    if (!zip) alert("Selezionare un file zip")

    formData.append("file", file);
    formData.append("zip", zip);
    formData.append("id_campionario", String(this.id_campionario))
    formData.append("id_brand", String(this.brands[ 0 ].id))
    formData.append("id_organizzazione", String(this.brands[ 0 ].id_organizzazione))

    this.campionariService.importCampionario(formData)
      .subscribe(resp => {
        // Buffer.from(resp.data, 'base64').buffer
        console.log("import campionari resp", resp)
        if (resp.message.toLowerCase().startsWith("partial import")) {
          this.saveFile("Campionario_Import_Errored_Rows.xlsx", resp.data)
          alert(resp.message)
        }

        closeBtn.click()
      },
        (error) => {
          console.log(error);
          alert(`Errore nell'importazione del campionario:` + error.toString())
        });
  }

  downloadImportTemplate(): void {
    this.campionariService.downloadImportTemplateCampionario().subscribe(resp => {
      this.saveFile("Campionario_Import_Template.xlsx", resp.data)
    })
  }

  saveFile(filename: string, base64: string) {
    var link = document.createElement('a');
    link.download = filename;
    link.href = "data:application/x-xls;base64," + base64;
    link.target = "_blank";
    link.click();
  }

  getDetailUrl(row: any) {
    return "/article-detail?id_articolo="+row.id+"&id_tessuto="+row.articoli_varianti[0].id_tessuto+"&id_colore="+row.articoli_varianti[0].id_colore;
  }

}

import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/auth/auth.service';
import { UserSession } from 'app/auth/interfaces/user-session';
import { DashboardService } from 'app/services/dashboard.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {
  roleUserLogged:string |undefined;
  userLogged :string |undefined;
  total_brand :number = 0;
  total_r2w :number = 0;
  user: UserSession | null = null;

  constructor(private auth: AuthService, private dashboardService : DashboardService) { }

  ngOnInit(): void {
    const user = this.auth.getCurrentUser();
    this.userLogged = user?.nome + ' ' + user?.cognome;
    this.roleUserLogged = user?.tipo;
  }

  logout(): void {
    this.auth.logout();
  }

}

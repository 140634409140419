<section>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-colored">
          <h1 class="text-uppercase">Elenco ordini</h1>
          <div class="container-box row justify-content-center">
            <div class="col-md-12">
              <div class="box">
                <div class="tab-column">
                  <div class="nav-wrapper">
                    <mat-tab-group mat-align-tabs="start">
                      <mat-tab label="Ordini">
                        <app-table-orders [type]=0></app-table-orders>
                      </mat-tab>
                      <mat-tab label="Da confermare">
                        <app-table-orders [type]=1></app-table-orders>
                      </mat-tab>
                      <mat-tab label="Acquisti" *ngIf="roleUserLogged == 'RETAILER'">
                        <app-table-orders [type]=2></app-table-orders>
                      </mat-tab>
                    </mat-tab-group>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
</section>

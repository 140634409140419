import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PreordiniService {

  private preordiniUrl = environment.backendPath+'/preordini/';

  constructor(private http : HttpClient, private auth : AuthService) { }

  createPreordine(preordine: any): Observable<any> {
    return this.http.post<any>(this.preordiniUrl, preordine);
  }
  updatePreordine(id_preordine: number, preordine: any): Observable<any> {
    return this.http.put<any>(this.preordiniUrl+id_preordine.toString(), preordine);
  }
  getPreordini(): Observable<any> {
    const id_organizzazione = this.auth.getCurrentUser()!.id_organizzazione;
    return this.http.get<any>(this.preordiniUrl+'brand/'+id_organizzazione.toString());
  }
  getPreordine(id_preordine: number): Observable<any> {
    return this.http.get<any>(this.preordiniUrl+id_preordine.toString());
  }
  getProdotti(id_preordine: number): Observable<any> {
    return this.http.get<any>(this.preordiniUrl+id_preordine.toString()+'/prodotti/')
  }
  addProdotto(id_preordine: number, id_prodotto: number, prodotto: any): Observable<any> {
    let request: Observable<any>;
    if (id_prodotto == 0) {
      request = this.http.post<any>(this.preordiniUrl+id_preordine.toString()+'/prodotti/', prodotto);
    }
    else {
      request = this.http.put<any>(this.preordiniUrl+id_preordine.toString()+'/prodotti/'+id_prodotto.toString(), prodotto);
    }
    return request
  }
  rimuoviProdotto(id_preordine: number, id_prodotto: number): Observable<any> {
    return this.http.delete<any>(this.preordiniUrl+id_preordine.toString()+'/prodotti/'+id_prodotto.toString());
  }
}

<ng-container *ngIf="article.only_show != null">
  
    <div class="table-responsive">
        <table class="table mt-3 articolo-table">
            <thead>
            <tr>
                <th style="width:1%" scope="col">TAGLIA</th>
                <ng-container *ngFor="let variante of article.tessutoColoreTaglieDescrizioni;">
                    <th>{{variante.tagliaTipologiaDescrizione}}</th>
                </ng-container>
                    
            </tr>
            </thead>

            <tbody>
            <tr>
                <th style="width:1%" scope="col">Q.TA Magazzino</th>
                    <ng-container *ngFor="let variante of article.tessutoColoreTaglieDescrizioni;">
                      <td>  {{variante.giacenza_brand.quantita != null ?  variante.giacenza_brand.quantita :  '' }} </td>
                    </ng-container>
            </tr>
            </tbody>

        </table>
    </div>
</ng-container>
<ng-container *ngIf="roleUserLogged == 'RETAILER'">

  <i style="cursor:pointer;float:right;text-shadow:none"
  (click)="abilitaModifica()" class="fa fa-pen"></i>
    <div class="table-responsive">
        <table class="table mt-3 articolo-table">
            <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col">Cod. variante</th>
                <th scope="col">COD. A BARRE</th>
                <ng-container *ngIf="roleUserLogged == 'RETAILER'">
                  <th style="width:25%" scope="col">COD. A BARRE BRAND</th>
                </ng-container>
                <!-- <th scope="col">COD. BARRE NEGOZIANTE</th> -->
                <th scope="col">Taglia</th>
                <th scope="col">Giacenza</th>
    
                <ng-container *ngIf="roleUserLogged != 'RETAILER'">
                  <th class="mod" scope="col">
                    Vendibilità
                    <i class="fa fa-info-circle" data-toggle="tooltip" title="La taglia sarà segnata come fuori produzione se non spuntata la checkbox!"></i>
                  </th>
                </ng-container>
                <!-- <th scope="col">Qta. Condivisa</th> -->
            </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let variante of article.tessutoColoreTaglieDescrizioni;">
                <tr class="tr_variante" >
                    <td>
                        <i class="fa fa-info-circle"  data-toggle="tooltip" title="ID:{{variante.id}}"></i>
                    </td>
    
                    <!-- CODICE -->
                    <td>
                        {{variante.codice}}
                    </td>
    
                    <!-- CODICE A BARRE -->
                    
                        <td>     
                            <ng-container *ngIf="roleUserLogged == 'RETAILER'">  
                              <div class="print">
                                <ng-container *ngIf="!updatingEnable">
                                  <ng-container *ngIf="variante.barcode_negoziante != null">
                                    <img src="data:image/png;base64,{{variante.barcode_negoziante}}" alt="" />
                                  </ng-container>
                                  <ng-container *ngIf="variante.barcode_negoziante == null">
                                    N/D
                                  </ng-container>
                                </ng-container>
                                 

                                </div>
                                <ng-container *ngIf="updatingEnable">
                                  <div class="mod">
                                    <input  type="text" id="barcode_negoziante_{{variante.id}}" class="form-control" style="width:50%" value="{{variante.barcode_negoziante != undefined ? variante.barcode_negoziante : '' }}">
                                  </div>
                                </ng-container>
                                
                            </ng-container>
                          
                            <ng-container *ngIf="roleUserLogged != 'RETAILER'">
                              <div class="print">
                                <ng-container *ngIf="!updatingEnable">
                                  <ng-container *ngIf="variante.barcode_fornitore != null">
                                    <img src="data:image/png;base64,{{variante.barcode_fornitore}}" alt="" />
                                  </ng-container>
                                  <ng-container *ngIf="variante.barcode_fornitore == null">
                                    N/D
                                  </ng-container>
                                </ng-container>
                                

                              </div>
                              <ng-container *ngIf="updatingEnable">
                                <div class="mod">
                                  <input  type="text" id="barcode_fornitore_{{variante.id}}" class="form-control" style="width:50%" value="{{variante.barcode_fornitore != undefined ? variante.barcode_fornitore : '' }}">
                                </div>
                              </ng-container>
                            </ng-container>
                        </td>
                    
                    <!-- CODICE A BARRE BRAND (RETAILER) -->
                    <ng-container *ngIf="roleUserLogged == 'RETAILER'">
                      <td>
                        <div class="print">
                          <ng-container *ngIf="variante.barcode_fornitore != null">
                                  <img src="data:image/png;base64,{{variante.barcode_fornitore}}" alt="" />
                                </ng-container>
                                <ng-container *ngIf="variante.barcode_fornitore == null">
                                  N/D
                                </ng-container>
                        </div>
                      </td>
                    </ng-container>
    
                    <!-- TAGLIA -->
                    <td>
                      {{variante.tagliaTipologiaDescrizione}}
                    </td>
    
                    <!-- GIACENZA -->
                    <td style="text-align:center">
                      <div class="print">
                        <ng-container *ngIf="!updatingEnable">
                          {{variante.giacenza_retailer_magazzino.quantita != null ? variante.giacenza_retailer_magazzino.quantita : ''}}
                        </ng-container>
                       
                      
                      <ng-container *ngIf="updatingEnable">
                        <div class="mod">
                          <input type="hidden" id="giacenza_old_{{variante.id}}" value="">
                          <input type="number" min="0" id="giacenza_{{variante.id}}" class="form-control" step="1" value="">
                        </div>
                      </ng-container>
                    </div>
                    </td>
    
                    <!-- VENDIBILITÀ -->
                    <ng-container *ngIf="roleUserLogged != 'RETAILER'">
                      <td class="mod text-center">
                        <input type="checkbox" class="switch mb-2" id="is_sellable_{{variante.id}}" >
                      </td>
                    </ng-container>
    
                </tr>
              </ng-container>
            </tbody>
        </table>
        <ng-container *ngIf="updatingEnable">
        
          <div class="mod azioni-mod">
              <button class="btn btn-secondary" (click)="disablitaModifica()">ANNULLA</button>
              <button class="btn btn-primary" >SALVA</button>
          </div>
        </ng-container>
    
    </div>
    
</ng-container>

import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'app/auth/auth.service';
import { OrderService } from 'app/services/order.service';
import { UtilitiesService } from 'app/services/utilities.service';

@Component({
  selector: 'app-table-orders',
  templateUrl: './table-orders.component.html',
  styleUrls: ['./table-orders.component.sass']
})
export class TableOrdersComponent implements OnInit {
  @Input() type: number | undefined;
  public list : any[] = [];
  public listRetailer: any[] = [];
  public roleUserLogged :string |undefined;
  public filterObj : any = {};

  constructor(private orderService : OrderService, private service: AuthService, public utilitiesService: UtilitiesService) { }


  loadList(){
    if(this.roleUserLogged == 'RETAILER'){
      this.orderService.getListOrdersRetailer().then((resp: any) => {
        var listGeneric = resp['data'];
        if(this.type == 0)
          this.list = listGeneric.ordini.data;
        if(this.type == 1)
          this.list = listGeneric.ordini_da_confermare.data;
        if(this.type == 2)
          this.list = listGeneric.ordini_acquisti;

        console.log(this.list)
      });
  }
  else{
    this.orderService.getListOrdersBrand().then((resp: any) => {
      var listGeneric = resp['data'];
      this.listRetailer = listGeneric.retailers_autorizzazioni;
      if(this.type == 0)
        this.list = listGeneric.ordini.data;
      if(this.type == 1)
        this.list = listGeneric.ordini_da_confermare.data;
      if(this.type == 2)
        this.list = listGeneric.ordini_acquisti;

      console.log(this.list)
    });
  }
  }

  ngOnInit(): void {

    this.service.getCurrentUserAsync().then(value => {
      console.log(`promise result: ${value}`);
      this.roleUserLogged = value?.tipo;
      this.loadList();
      this.loadFilters();
    });

  }

  filter(){
    console.log('filtri:' , this.filterObj)
    this.filterObj.export = 0
    this.filterObj.is_ajax = true
    if(this.type == 0){
      if(this.roleUserLogged == 'RETAILER'){
        this.orderService.searchConfermatiRetailer(this.filterObj).then((resp: any) => {
          var listGeneric = resp['data'];
          this.list = listGeneric.ordini.data;
        });
      }

      if(this.roleUserLogged == 'BRAND'){
        this.orderService.searchConfermatiBrand(this.filterObj).then((resp: any) => {
          var listGeneric = resp['data'];
          this.list = listGeneric.ordini.data;
        });
      }

    }

    else if(this.type == 1){
      if(this.roleUserLogged == 'RETAILER'){
        this.orderService.searchDaConfermareRetailer(this.filterObj).then((resp: any) => {
          var listGeneric = resp['data'];
          this.list = listGeneric.ordini.data;
        });
      }
      if(this.roleUserLogged == 'BRAND'){
        this.orderService.searchDaConfermareBrand(this.filterObj).then((resp: any) => {
          var listGeneric = resp['data'];
          this.list = listGeneric.ordini.data;
        });
      }
    }
    else{

      this.orderService.searchAcquistiRetailer(this.filterObj).then((resp: any) => {
        var listGeneric = resp['data'];
        this.list = listGeneric.ordini.data;
      });
    }

  }

  reset(){
   this.filterObj = {}
   this.loadList();
  }

  loadFilters(): void {
    const filtri = document.querySelectorAll('#filters select');
    console.log('filtros', filtri);
    filtri.forEach(filtro => {
      // @ts-expect-error
      $('#'+filtro.id).selectpicker({
        noneSelectedText: (filtro as HTMLSelectElement).name
      })
    });
  }

}

import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'app/auth/auth.service';
import { ArticoloService } from 'app/services/articolo.service';
import { BrandService } from 'app/services/brand.service';
import { FileDownloadService } from 'app/services/file-download.service';
import { ListiniService } from 'app/services/listini.service';
import { UtilitiesService } from 'app/services/utilities.service';

@Component({
  selector: 'app-detail-listini',
  templateUrl: './detail-listini.component.html',
  styleUrls: [ './detail-listini.component.sass' ]
})
export class DetailListiniComponent implements OnInit {
  id: number = 0;
  data?: any;
  articoliRawList: any = []

  public addArticleData = {
    codice_articolo: 0,
    codice_tessuto: 0,
    codice_colore: 0,
    prezzo_sellin: 0,
    prezzo_sellout: 0
  }

  public selectOptions: any = {
    articoli: [],
    brands: []
  }

  public downloadTemplateData = {
    brandId: 0,
    stagioneId: 0
  }

  public listDati: any[] = [];

  constructor(private route: ActivatedRoute, private listiniService: ListiniService, private authService: AuthService, public utilitiesService: UtilitiesService, public articoloService: ArticoloService, private http: HttpClient, private fileDownloadService: FileDownloadService, private brandService: BrandService) { }

  async ngOnInit(): Promise<void> {
    this.id = parseInt(this.route.snapshot.paramMap.get('id')!, 10);

    this.data = (<any>(await this.listiniService.getById(this.id)))[ 'data' ];
    console.log("🚀 ~ DetailListiniComponent ~ ngOnInit ~ this.data:", this.data)

    await this.loadArticoli();
  }

  async onUpdateArticoloClick(listinoId: any, listinoArticoloId: any, prezzo_sellin: any, prezzo_sellout: any): Promise<any> {
    await this.listiniService.updateArticolo(+listinoId, +listinoArticoloId, +prezzo_sellin, +prezzo_sellout)
    window.location.reload();
  }

  logChanges() {
    console.log('addArticleData', this.addArticleData)
  }

  async loadArticoli() {
    let data: any;
    try {
      data = await this.articoloService.getListForBrand({});
      data = data.data;
    } catch (error) {
      console.error(error)
      return;
    }
    console.log("🚀 ~ DetailListiniComponent ~ loadArticoli ~ data:", data)

    this.articoliRawList = data;
    console.log("🚀 ~ DetailListiniComponent ~ loadArticoli ~ this.articoliRawList:", this.articoliRawList)

    this.updateSelect();
    this.loadBrands();
  }

  loadBrands() {
    this.selectOptions.brands = this.articoliRawList.brand_for_search
    this.selectOptions.stagioni = this.articoliRawList.stagioni_for_search
  }

  async updateSelect() {

    this.selectOptions.articoli = (<any[]>this.articoliRawList.articoli.data)
      .reduce((acc: any[], cur: any, index: number) => {
        if (!acc.find(e => e.id == cur.id))
          acc.push(cur)
        return acc;
      }, []);

    this.selectOptions.tessuti = (<any[]>this.articoliRawList.articoli.data)
      .reduce((acc: any[], cur: any, index: number) => {
        if (this.addArticleData.codice_articolo != cur.id) return acc;

        if (!acc.find(e => e.id == cur.articolo.id_tessuto))
          acc.push(this.articoliRawList.tessuti_for_search.find((c: any) => c.id == cur.articolo.id_tessuto))
        return acc;
      }, []);

    this.selectOptions.colori = (<any[]>this.articoliRawList.articoli.data)
      .reduce((acc: any[], cur: any, index: number) => {
        if (this.addArticleData.codice_articolo != cur.id) return acc;
        if (this.addArticleData.codice_tessuto != cur.articolo.id_tessuto) return acc;
        if (!acc.find(e => e.id == cur.articolo.id_colore))
          acc.push(this.articoliRawList.colori_for_search.find((c: any) => c.id == cur.articolo.id_colore))
        return acc;
      }, []);

    console.log('selectOptions', this.selectOptions)

  }

  async addArticoloToListino() {
    const data = {
      id_listino: +this.id,
      id_articolo: +this.addArticleData.codice_articolo,
      id_articolo_variante: 0,
      prezzo_sellin: +this.addArticleData.prezzo_sellin,
      prezzo_sellout: +this.addArticleData.prezzo_sellout
    }

    const variante = this.articoliRawList.articoli.data.find((a: any) => a.articolo.id == this.addArticleData.codice_articolo && a.articolo.id_tessuto == this.addArticleData.codice_tessuto && a.articolo.id_colore == this.addArticleData.codice_colore)

    data.id_articolo_variante = +variante.articolo.id_articolo_variante;

    this.listiniService.addArticolo(data.id_listino, data.id_articolo, data.id_articolo_variante, data.prezzo_sellin, data.prezzo_sellout)
      .then(res => window.location.reload())
  }

  downloadTemplate() {
    this.listiniService.downloadTemplate(this.downloadTemplateData.stagioneId, this.downloadTemplateData.brandId)
      .subscribe((response: any) => {
        this.fileDownloadService.downloadFile(response, 'ImportTemplate.xlsx')
        window.location.reload();
      })
  }

  downloadExportListino() {
    this.listiniService.downloadExportListino(this.id)
      .subscribe((response: any) => {
        this.fileDownloadService.downloadFile(response, 'ExportListino.xlsx')
        window.location.reload();
      })
  }

  importListino() {
    const formData = new FormData();
    const closeBtn = (<HTMLButtonElement>document.getElementById("closeImportModal"));
    const file = (<HTMLInputElement>document.getElementById("listinoImportXlsx")).files?.item(0) as Blob;

    if (!file) alert("Selezionare un file Excel")

    formData.append("file", file);
    
    this.listiniService.importListino(this.id, formData)
      .subscribe(resp => {
        closeBtn.click()
      },
        (error) => {
          console.log(error);
          alert(`Errore nell'importazione:` + error.toString())
        }
      );
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { AuthService } from '../auth/auth.service';


@Injectable({
  providedIn: 'root'
})
export class CondizioniCommercialiService {
  private condizioniUrl = environment.backendPath+'/condizioni_commerciali/';

  constructor(private http : HttpClient, private auth : AuthService) { }

  getCondizioni(id_cliente: number): Observable<any> {
    return this.http.get<any>(this.condizioniUrl+'cliente/'+id_cliente.toString());
  }

  createCondizioni(id_cliente: number): Observable<any> {
    return this.http.post<any>(this.condizioniUrl+'cliente/'+id_cliente.toString(), {});
  }

  updateCondizioni(id: number, condizione: any): Observable<any> {
    return this.http.put<any>(this.condizioniUrl+id.toString(), condizione);
  }
}

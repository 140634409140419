import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dettaglio-movimento',
  templateUrl: './dettaglio-movimento.component.html',
  styleUrls: ['./dettaglio-movimento.component.sass']
})
export class DettaglioMovimentoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'app/auth/auth.service';
import { OrderService } from 'app/services/order.service';
import { UtilitiesService } from 'app/services/utilities.service';

@Component({
  selector: 'app-detail-order',
  templateUrl: './detail-order.component.html',
  styleUrls: ['./detail-order.component.sass']
})
export class DetailOrderComponent implements OnInit {
  id_order: number = 0;
  order?:any;
  roleUserLogged :string |undefined = "RETAILER";
  public listDati : any[] = [];
  constructor(private route: ActivatedRoute,private orderService : OrderService, private service: AuthService, public utilitiesService: UtilitiesService) {

   }

  ngOnInit(): void {
    this.id_order = parseInt(this.route.snapshot.paramMap.get('id')!, 10);
    this.roleUserLogged = this.service.getCurrentUser()?.tipo;
    this.orderService.getOrderById(this.id_order, this.roleUserLogged == "BRAND").then((resp: any) => {
      this.order = resp['data'];

      this.order.prodottiGroupedForTessutoColore = Object.values(this.order.prodottiGroupedForTessutoColore);

      for (const element of  this.order.prodottiNotGrouped) {
        for (const row of  element){
          this.listDati.push(row);
        }
      }


      console.log(this.listDati)
      console.log(this.order)
    });
  }

}

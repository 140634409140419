import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { AuthService } from '../auth/auth.service';


@Injectable({
  providedIn: 'root'
})
export class ClientiService {

  private clientiUrl = environment.backendPath+'/clienti/';
  private laravelUrl = environment.backendPath+'/registrazione/cliente';

  constructor(private http : HttpClient, private auth : AuthService) { }

  getClienti(): Observable<any> {
    return this.http.get<any>(this.clientiUrl);
  }

  getClientiOrganizzazione(): Observable<any> {
    const id_organizzazione = this.auth.getCurrentUser()!.id_organizzazione;
    return this.http.get<any>(this.clientiUrl+'/organizzazioni/'+id_organizzazione.toString());
  }

  getCliente(id: number): Observable<any> {
    return this.http.get<any>(this.clientiUrl+id.toString());
  }

  createOrganizzazioneCliente(cliente: any) {
    return this.http.post<any>(this.laravelUrl, cliente);
  }

  createCliente(cliente: any): Observable<any> {
    return this.http.post<any>(this.clientiUrl, cliente);
  }

  updateCliente(id_cliente: string, cliente: any): Observable<any> {
    return this.http.put<any>(this.clientiUrl+id_cliente, cliente);
  }

  deleteCliente(id_cliente: string): Observable<any> {
    return this.http.delete<any>(this.clientiUrl+id_cliente.toString());
  }
}

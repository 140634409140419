<!--<ng-container *ngIf="roleUserLogged == 'RETAILER'">
    <div>
        <mat-spinner></mat-spinner><br>
        <label>Recupero dati in corso...</label>
    </div>

</ng-container>-->
<ng-container *ngIf="roleUserLogged == 'RETAILER'">
  <section>
      <div class="row row-eq-height">
        <div class="col-md-12">
          <div class="card card-colored card-retailer">
            <h1 class="text-uppercase">Dashboard</h1>
            <div class="container-box row">
              <div class="col-md-3">
                <a href="/magazzini/retailer">
                  <div class="box box-dashboard">
                    <div class="hoverD">
                      <i class="fi fi-rr-shop"></i>
                      <h5> Magazzino</h5>
                      <h4 class="titleD"><span>7</span> prodotti in stock</h4>
                      <h4 class="titleD"><span>526,30&nbsp;€</span> valore totale</h4>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-md-3">
                <a href="/marketplace">
                  <div class="box box-dashboard">
                    <div class="hoverD">
                      <i class="fi fi-rr-shopping-bag"></i>
                      <h5> Ordini Ricevuti</h5>
                      <h4 class="titleD"><span>0</span> totali da stock</h4>
                      <h4 class="titleD"><span>0</span> da confermare</h4>
                      <h4 class="titleD"><span>0</span> da spedire</h4>
                      <h4 class="titleD"><span>0</span> spediti</h4>
                      <h4 class="titleD"><span>0</span> consegnati</h4>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-md-3">
                <a href="/brand/elenco">
                  <div class="box box-dashboard">
                    <div class="hoverD">
                      <i class="fi fi-rr-gift-card"></i>
                      <h5> Acquisti da Brand</h5>
                      <h4 class="titleD"><span>0</span> totali da stock
                      </h4>
                      <h4 class="titleD"><span>0</span> da confermare
                      </h4>
                      <h4 class="titleD"><span>0</span> da spedire</h4>
                      <h4 class="titleD"><span>0</span> spediti</h4>
                      <h4 class="titleD"><span>0</span> consegnati</h4>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-md-3">
                <a href="/ordini/r2w/elenco">
                  <div class="box box-dashboard">
                    <div class="hoverD">
                      <i class="fi fi-rr-download"></i>
                      <h5> Acquisti da Retailer</h5>
                      <h4 class="titleD"><span>0</span> totali da
                        stock</h4>
                      <h4 class="titleD"><span>0</span> da confermare
                      </h4>
                      <h4 class="titleD"><span>0</span> da spedire</h4>
                      <h4 class="titleD"><span>0</span> spediti</h4>
                      <h4 class="titleD"><span>0</span> consegnati</h4>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>

        </div>
      </div>
  </section>
  <section>
      <!-- GRAFICI -->
      <div class="row">
        <div class="col-md-12">
          <div class="card">

            <div class="container-box row">
              <div class="col-md-6">
                <div class="box">
                  <h5>Acquisti</h5>
                  <div id="chart-acquisti"></div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="box">
                  <h5>Vendite</h5>
                  <div id="chart-vendite"></div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- END GRAFICI -->
  </section>
  <section>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="row">
              <div class="col-md-6">
                <h4>Best Seller</h4>
              </div>
              <div class="col-md-6 info-tabella">
                <h4>Settimana</h4>
                <h4>Mese</h4>
                <h4 class="active-t">Stagione</h4>
              </div>
            </div>

            <table class="table table-striped">
              <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Articoli</th>
                <th scope="col">Top venduto</th>
                <th scope="col">Venduto ultimi 7 giorni</th>
                <th scope="col">Prezzo di vendita</th>
                <th scope="col">Totale</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <th scope="row">
                  <input type="checkbox">
                </th>
                <td>maglietta topolino</td>
                <td>19</td>

                <td>
                  <div class="grafico-verme"></div>
                </td>
                <td>21 €</td>
                <td>210 €</td>
              </tr>
              <tr>
                <th scope="row">
                  <input type="checkbox">
                </th>
                <td>maglietta topolino</td>
                <td>19</td>
                <td>

                </td>
                <td>21 €</td>
                <td>210 €</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
  </section>
</ng-container>


<ng-container *ngIf="roleUserLogged == 'BRAND'">
    <div class="row">
      <div class="col-md-12">


        <div class="card card-colored">
          <h1 class="text-uppercase">Dashboard</h1>
          <div class="container-box row">
            <div class="col-md-4">
              <a href="/magazzini/brand">
                <div class="box box-dashboard">
                  <div class="hoverD">
                    <i class="fi fi-rr-shop"></i>
                    <h5 class="titleD">Magazzino</h5>
                    <h4 class="titleD"><span id="prodotti_stock">977</span> prodotti in stock</h4>
                    <h4 class="titleD"><span id="valore_totale">98.681,11&nbsp;€</span> valore totale</h4>
                  </div>

                </div>
              </a>
            </div>
            <div class="col-md-4">
              <a href="/retailer/elenco">
                <div class="box box-dashboard">
                  <div class="hoverD">
                    <i class="fi fi-rr-users"></i>
                    <h5 class="titleD">Retailer</h5>
                    <h4 class="titleD"><span id="collegati">8</span> retailer collegati</h4>
                    <h4 class="titleD"><span id="in_sospeso">0</span> richieste di collegamento in sospeso</h4>
                  </div>

                </div>
              </a>
            </div>
            <div class="col-md-4">
              <a href="/ordini/brand/elenco">
                <div class="box box-dashboard">
                  <div class="hoverD">
                    <i class="fi fi-rr-download"></i>
                    <h5 class="titleD">Ordini</h5>
                    <h4 class="titleD"><span id="ordini_totali">0</span> totali da stock</h4>
                    <h4 class="titleD"><span id="da_confermanre">0</span> da confermare</h4>
                    <h4 class="titleD"><span id="da_spedire">0</span> da spedire</h4>
                    <h4 class="titleD"><span id="spedito">0</span> spediti</h4>
                    <h4 class="titleD"><span id="consegnato">0</span> consegnati</h4>
                  </div>

                </div>
              </a>
            </div>
          </div>
        </div>

      </div>

    </div>
    <!-- GRAFICI -->
    <div class="row">
      <div class="col-md-12">
        <div class="card card-colored">

          <div class="container-box row">
            <div class="col-md-6">
              <div class="box">
                <h5>Ultima stagione</h5>
                <br>
                <div id="chart-sellout-ultima-stagione"></div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="box">
                <h5>Distribuzione venduto per categoria</h5>
                <br>
                <div id="chart-sellout-categorie"></div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- END GRAFICI -->

</ng-container>

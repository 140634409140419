import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class FileDownloadService {

    downloadFile(response: any, filename: string) {
        if (response.status === 200 && response.data) {
            const blob = this.base64ToXlsxBlob(response.data);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = filename;
            link.click();
            window.URL.revokeObjectURL(url);
        }
    }

    base64ToXlsxBlob(base64: string) {
        const binaryString = window.atob(base64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[ i ] = binaryString.charCodeAt(i);
        }
        return new Blob([ bytes ], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-marketplace-retailer',
  templateUrl: './marketplace-retailer.component.html',
  styleUrls: ['./marketplace-retailer.component.sass']
})
export class MarketplaceRetailerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

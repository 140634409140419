<div mat-dialog-title class="new-format-modal">
    <div class="container-fluid header-modal">
      <div class="row">
        <div style="width:90%">
          <h3>Richiedi Autorizzazione al Brand {{brand_name}}</h3>
        </div>
  
        <div>
          <button class="close-button" [mat-dialog-close]="true">×
          </button>
        </div>
      </div>
  
  
    </div>
  </div>
    <mat-dialog-content>
        <div class="container-fluid">

            <div class="row">
                <div class="col-md-6">
                    <label>PARTITA IVA</label><br>    
                    <section class="example-section">
                        <mat-slide-toggle
                            class="example-margin"
                            >
                        </mat-slide-toggle>
                    </section>
                    <br>
                    <p></p>      
                </div>
            </div>

            <div class="row">
                <!-- MESSAGGIO -->
                <div class="col-md-12">
                    <p class="text-uppercase alt-font">Messaggio per il Brand</p>
                    <textarea name="messaggio"
                            placeholder="Scrivi qui il tuo messaggio per il Brand..."
                            class="form-control"></textarea>
                </div>


            </div>
    
        </div>

    </mat-dialog-content>
  
  
  
  <div mat-dialog-actions>
    <div class="bottom-actions">
      <button mat-flat-button color="warn" class="btn-modal-close" [mat-dialog-close]="true">Chiudi</button>
      <button mat-button class="button-save" class="btn-modal-confirm" (click)="confirm()">Conferma</button>
    </div>
  </div>
  
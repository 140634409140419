import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-request-authorization',
  templateUrl: './dialog-request-authorization.component.html',
  styleUrls: ['./dialog-request-authorization.component.sass']
})
export class DialogRequestAuthorizationComponent implements OnInit {

  public brand_name : string = "PROVA";
  constructor() { }

  ngOnInit(): void {
  }

  confirm(){}

}

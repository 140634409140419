import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: [ './file-uploader.component.sass' ]
})
export class FileUploaderComponent implements OnInit {
  api: string = environment.api;

  @ViewChild('fileUploader') fileUploaderElm!: ElementRef<HTMLInputElement>;

  files: File[] = [];
  uploadedFiles: number[] = [];

  @Input() getUploadUrlFromUrl!: string;
  @Input() url!: string;
  @Input() method!: string;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
  }

  handleDragOver(event: DragEvent) {
    event.preventDefault();
  }

  handleDrop(event: DragEvent) {
    event.preventDefault();
    const droppedFiles = event.dataTransfer?.files;
    if (droppedFiles) {
      this.files = [ ...this.files, ...Array.from(droppedFiles) ];
    }
  }

  isImage(file: File): boolean {
    return file.type.startsWith('image/');
  }

  getFilePreview(file: File): string {
    return URL.createObjectURL(file);
  }

  openFileSelector() {
    this.fileUploaderElm.nativeElement.click();
  }

  handleFileChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      this.files = [ ...this.files, ...Array.from(input.files) ];
    }
    input.value = ''; // Clear the input
  }

  async uploadAllFiles() {
    await Promise.all(this.files.map(async (file, index) => await this.uploadFile(index)));
    // Emit an event or do something upon completion
  }

  async uploadFile(index: number) {
    const file = this.files[ index ];
    const uploadedFiles = this.uploadedFiles;
    console.log('Uploading File', file);

    let uploadURL = this.url;

    if (!uploadURL) {
      const uploadUrlResponse = await this.http.get<any>(`${this.getUploadUrlFromUrl}`, {
        params: { fileName: file.name },
      }).toPromise();

      uploadURL = uploadUrlResponse.data.url;
    }
    console.log('uploadUrl', uploadURL);

    const formData = new FormData();
    formData.append('file', file);
    const method: string = this.method || 'put';
    const reqOptions: any = {
      reportProgress: true,
      observe: 'response',
    }
    let response: any;
    switch (method) {
      case 'post':
        response = await this.http.post(uploadURL, formData, reqOptions).toPromise();
        break;
      case 'put':
        response = await this.http.put(uploadURL, formData, reqOptions).toPromise();
        break;
      default:
        throw new Error(`Unsupported method: ${method}`);
    }

    if (response?.status >= 200 && response?.status <= 299) {
      console.log('File uploaded successfully!');
      uploadedFiles.push(index);
    } else {
      console.error('File upload failed', response);
    }
  }

  removeFile(index: number, event: MouseEvent) {
    event.stopPropagation();
    this.files = this.files.filter((_, i) => i !== index);
  }
}

<app-sidebar></app-sidebar>
  <section>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div *ngIf="modalIsVisible">
            <div class="modal-dialog modal-sm">
              <div class="modal-content">
                <div class="modal-body" style="text-align: center;">
                  <div class="loader" style="margin-left: 30%;"></div>
                  <br>
                  <h5>Caricamento dati in corso...</h5>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!modalIsVisible" class="card card-colored overflow-hidden classe-retailer">
            <h1 class="text-uppercase text-white border-bottom-ab">{{article!.articolo!.nome}}</h1>
            <h5 class="text-white">COD.: <b>{{article!.articolo!.codice_articolo}}</b></h5>

            <button class="btn btn-mod-art" *ngIf="roleUserLogged != 'RETAILER'"
                    data-toggle="modal" data-target="#modale-modifica-dati-generali"
                    (click)="loadModaleModificaDatiGenerali()">
              Modifica
              <i class="fa fa-pen"></i>
            </button>

            <div class="container-box">
              <div class="box">


                <div class="wrapper row">
                  <div class="preview col-md-5">
                    <div class="main-img">
                      <ng-container *ngIf="article.articolo!.media_path != null">

                        <ng-container *ngIf="article.only_show != null">
                          <i style="cursor:pointer"
                             in-evidenza="1" class="fas fa-pencil-alt"></i>
                        </ng-container>

                        <a href="{{article.articolo!.media_path}}"
                           data-lightbox="image-1" data-title="">
                          <img src="{{article.articolo!.media_path}}">
                        </a>
                      </ng-container>
                      <ng-container *ngIf="article.articolo!.media_path == null">
                        <img src="/img/placeholder-articolo.jpg">
                      </ng-container>


                    </div>
                    <div class="imgs">
                      <ng-container *ngFor="let item of article.articoloVariante!.media_path">
                        <div class="modal-box">
                          <a href="{{item}}"
                             data-lightbox="image-1" data-title="">
                            <img src="{{item}}">
                          </a>
                          <ng-container *ngIf="article.only_show != null">
                            <i style="cursor:pointer" class="fas fa-pencil-alt"></i>
                          </ng-container>
                        </div>
                      </ng-container>
                      <ng-container
                        *ngIf="article.articoloVariante!.media_path.length < 6 && article.only_show != null">
                        <div class="modal-box">
                          <div class="add-img">
                            <i class="fas fa-image"></i>
                          </div>
                        </div>
                      </ng-container>

                    </div>


                  </div>
                  <div class="details col-md-7 classe-retailer">
                    <p><b>Brand:</b> {{article.articolo!.brandNome}}</p>
                    <p><b>Codice Articolo:</b>{{article.articolo!.codice_articolo}}</p>
                    <p><b>Nome:</b> {{article.articolo!.nome}}</p>
                    <p><b>Stagione:</b> {{article.articolo!.stagione}}</p>
                    <p><b>Collezione:</b> {{article.articolo!.collezione}}</p>
                    <p><b>Settore:</b> {{article.articolo!.settore}}</p>
                    <p><b>Categoria:</b> {{article.articolo!.categorie}}</p>
                    <p><b>Scala Taglie:</b> {{article.articolo!.tagliaTipologia}}
                    <hr>

                    <p>
                      <b>Variante Tessuto: </b> {{article.articoloVariante!.tessuto}}

                      <ng-container *ngIf="roleUserLogged == 'BRAND'">
                        <i style="cursor:pointer;float:right;text-shadow:none;" data-toggle="tooltip"
                           title="Modifica o aggiungi Tessuto e/o Colore"
                           class="fa fa-pen"></i>
                      </ng-container>

                    </p>
                    <p>
                      <b>Variante Colore:</b>
                      {{article.articoloVariante!.colore}}
                    </p>

                    <ng-container *ngIf="article.altreVariantiDisponibili.length > 0">
                      <p>
                        <b>Altre Varianti Disponibili:</b><br>
                        <ng-container
                          *ngFor="let item of article.altreVariantiDisponibili; let indexOfelement=index;">

                          <a href="">
                            {{item.tessuto}}
                            -
                            {{item.colore}}
                          </a><br>

                        </ng-container>
                      </p>
                    </ng-container>
                    <ng-container *ngIf="article.altreVariantiDisponibili.length == 0">
                      <p>
                        <b>Nessuna altra variante disponibile</b><br>
                      </p>
                    </ng-container>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 mt-4 dettagli-arti classe-retailer">
                    <hr>

                    <!-- PREZZO -->
                    <p><b>
                      Prezzo
                      Ingrosso:</b> {{article.giacenzaArticoloVariante.prezzo_vendita != '' ? article.giacenzaArticoloVariante.prezzo_vendita.toFixed(2) + "€" : ''}}
                      <!-- <b> Sconto:</b> <?= $giacenzaArticoloVariante ? $giacenzaArticoloVariante->sconto : ''?> -
                      <b>Prezzo Scontato:</b> <?= $giacenzaArticoloVariante ? $giacenzaArticoloVariante->prezzo_scontato : ''?> -->


                      <i style="cursor:pointer;float:right;text-shadow:none" data-toggle="modal"
                         data-target="#modale-modifica-tessuto-colore"
                         class="fa fa-pen"></i>


                    </p>
                    <p>
                      <b>Prezzo Dettaglio
                        Consigliato:</b>{{article.giacenzaArticoloVariante.prezzo_vendita_finale != '' ? article.giacenzaArticoloVariante.prezzo_vendita_finale.toFixed(2) + "€" : ''}}
                    </p>
                    <p>
                      <b>Ricarico
                        Consigliato: </b>{{article.giacenzaArticoloVariante.ricarico != '' ? article.giacenzaArticoloVariante.ricarico.toFixed(2) : 'N/D' }}
                      <br>
                    </p>
                    <!-- END PREZZO -->

                    <hr>
                    <p>
                      <b>Descrizione Breve</b>


                      <i style="cursor:pointer;float:right;text-shadow:none" data-toggle="modal"
                         data-target="#modale-modifica-descrizioni"
                         class="fa fa-pen"></i>

                    </p>
                    <p>
                      {{article.articoloVariante.descrizione_breve}}
                    </p>
                    <p>
                      <b>Descrizione Completa</b>
                    </p>
                    <p>
                      {{article.articoloVariante.descrizione_estesa}}
                    </p>
                    <hr>
                    <!-- <div
                        class="custom-control custom-control-alternative custom-checkbox mt-3">
                        <input class="custom-control-input"
                               type="checkbox" <?= $articoloVariante->condiviso_r2w ? 'checked' : '' ?>>
                        <label class="custom-control-label" for="customCheckLogin">
                            Condiviso R2S
                        </label>
                    </div> -->


                    <!-- VARIANTI TAGLIE -->
                    <div id="container-modifica-tabella-varianti">
                      <app-data-article-table [article]=article></app-data-article-table>
                    </div>
                    <!-- END VARIANTI TAGLIE -->


                  </div>
                </div>
              </div>


              <div class="row">
                <div class="col-md-12" style="text-align:center">
                  <button data-toggle="tooltip" title="Elimina Articolo Variante" class="btn btn-primary"
                          type="button" (click)="eliminaArticolo()">ELIMINA
                  </button>
                </div>
              </div>


            </div>
          </div>


        </div>
      </div>
    </div>


    <!-- MODALI -->
    <div class="modal fade" id="modale-modifica-dati-generali" tabindex="-1" role="dialog"
         aria-labelledby="modal-default" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered modal-" role="document">
        <div class="modal-content classe-brand">

          <div class="modal-header">
            <h6 class="modal-title">Modifica Dati Generali</h6>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <form id="formModificaDatiGenerali" action="/articoli/modifica-dati-generali" method="POST"
                enctype="multipart/form-data">

            <input type="hidden" name="id_articolo" value="488">
            <input type="hidden" name="id_articolo_variante" value="9597">

            <div class="modal-body">
              <div class="row">

                <!-- CODICE ARTICOLO -->
                <div class="col-md-6">
                  <label for="" class="form-control-label">Codice Articolo</label>
                  <input class="form-control" name="codice_articolo" value="59012" required="" type="text"
                         placeholder="Codice Articolo" id="codice_articolo">
                </div>

                <!-- NOME -->
                <div class="col-md-6">
                  <label for="" class="form-control-label">Nome</label>
                  <input name="nome" class="form-control" value="Derby" required="" type="text"
                         placeholder="Nome" id="nome">
                </div>

              </div>
              <div class="row mb-3">

                <!-- STAGIONE -->
                <div class="col-md-4">
                  <label for="" class="form-control-label">Stagione</label>
                  <select name="id_stagione" required="" class="select2-stagione select2-hidden-accessible"
                          data-select2-id="select2-data-1-dcp3" tabindex="-1" aria-hidden="true">
                    <option value="">SELEZIONARE</option>
                    <option selected="" value="18" data-select2-id="select2-data-3-shra">SS21</option>
                    <option value="16">FW21-22</option>
                    <option value="17">SS22</option>
                    <option value="19">FW22-23</option>
                  </select><span class="select2 select2-container select2-container--default" dir="ltr"
                                 data-select2-id="select2-data-2-hm9g" style="width: auto;"><span
                  class="selection"><span class="select2-selection select2-selection--single" role="combobox"
                                          aria-haspopup="true" aria-expanded="false" tabindex="0"
                                          aria-disabled="false"
                                          aria-labelledby="select2-id_stagione-g1-container"
                                          aria-controls="select2-id_stagione-g1-container"><span
                  class="select2-selection__rendered" id="select2-id_stagione-g1-container" role="textbox"
                  aria-readonly="true" title="SS21">SS21</span><span class="select2-selection__arrow"
                                                                     role="presentation"><b
                  role="presentation"></b></span></span></span><span class="dropdown-wrapper"
                                                                     aria-hidden="true"></span></span>
                </div>

                <!-- COLLEZIONE -->
                <div class="col-md-4">
                  <label for="" class="form-control-label">Collezione</label>
                  <select name="id_collezione" required=""
                          class="select2-collezione select2-hidden-accessible"
                          data-select2-id="select2-data-4-cdpo" tabindex="-1" aria-hidden="true">
                    <option value="">SELEZIONARE</option>
                    <option value="16">Logomania</option>
                    <option value="17">CF Racing</option>
                    <option value="18">Eye Star</option>
                    <option value="19">Eyelike</option>
                    <option value="20">Nd</option>
                    <option value="21"></option>
                    <option value="22">ACBC x Missoni
                    </option>
                    <option value="23">Venice dip-dye</option>
                    <option selected="" value="24" data-select2-id="select2-data-6-c6q4">Florence collection
                    </option>
                    <option value="25">ACBC</option>
                  </select><span class="select2 select2-container select2-container--default" dir="ltr"
                                 data-select2-id="select2-data-5-6506" style="width: auto;"><span
                  class="selection"><span class="select2-selection select2-selection--single" role="combobox"
                                          aria-haspopup="true" aria-expanded="false" tabindex="0"
                                          aria-disabled="false"
                                          aria-labelledby="select2-id_collezione-jz-container"
                                          aria-controls="select2-id_collezione-jz-container"><span
                  class="select2-selection__rendered" id="select2-id_collezione-jz-container" role="textbox"
                  aria-readonly="true" title="Florence collection">Florence collection</span><span
                  class="select2-selection__arrow" role="presentation"><b
                  role="presentation"></b></span></span></span><span class="dropdown-wrapper"
                                                                     aria-hidden="true"></span></span>
                </div>

                <!-- SCALA TAGLIE -->
                <div class="col-md-4">
                  <label for="" class="form-control-label">Scala Taglie</label>
                  <select name="id_taglia_tipologia" required=""
                          class="select2-taglie-tipologie select2-hidden-accessible"
                          data-select2-id="select2-data-16-6cqv" tabindex="-1" aria-hidden="true">
                    <option value="">SELEZIONARE</option>
                    <option value="1">INTERNAZIONALE</option>
                    <option value="2">IT DONNA</option>
                    <option value="3">IT UOMO</option>
                    <option value="4">TAGLIA UNICA</option>
                    <option value="5">SCARPE DONNA EU</option>
                    <option value="6">SCARPE UOMO EU</option>
                    <option value="7">SCARPE BAMBINO EU</option>
                    <option value="8">JEANS DONNA</option>
                    <option value="9">JEANS UOMO</option>
                    <option value="10">SCARPE DONNA MEZZE MISURE</option>
                    <option selected="" value="11" data-select2-id="select2-data-18-rpym">SCARPE UOMO MEZZE
                      MISURE
                    </option>
                    <option value="15">SCARPE UOMO</option>
                  </select><span class="select2 select2-container select2-container--default" dir="ltr"
                                 data-select2-id="select2-data-17-rp2f" style="width: auto;"><span
                  class="selection"><span class="select2-selection select2-selection--single" role="combobox"
                                          aria-haspopup="true" aria-expanded="false" tabindex="0"
                                          aria-disabled="false"
                                          aria-labelledby="select2-id_taglia_tipologia-zh-container"
                                          aria-controls="select2-id_taglia_tipologia-zh-container"><span
                  class="select2-selection__rendered" id="select2-id_taglia_tipologia-zh-container"
                  role="textbox" aria-readonly="true" title="SCARPE UOMO MEZZE MISURE">SCARPE UOMO MEZZE MISURE</span><span
                  class="select2-selection__arrow" role="presentation"><b
                  role="presentation"></b></span></span></span><span class="dropdown-wrapper"
                                                                     aria-hidden="true"></span></span>
                </div>

              </div>
              <div class="row">


              </div>

              <div class="row">

                <!-- SETTORE -->
                <div class="col-md-4">
                  <label for="" class="form-control-label">Settore</label>
                  <select name="id_settore" required="" class="select2-settore select2-hidden-accessible"
                          onchange="changeSettore(this)" data-select2-id="select2-data-7-7ns1" tabindex="-1"
                          aria-hidden="true">
                    <option value="">SELEZIONARE</option>
                    <option value="656">DONNA</option>
                    <option selected="" value="657" data-select2-id="select2-data-9-gtv5">UOMO</option>
                    <option value="658">BAMBINA</option>
                    <option value="659">BAMBINO</option>
                  </select><span class="select2 select2-container select2-container--default" dir="ltr"
                                 data-select2-id="select2-data-8-6k3f" style="width: auto;"><span
                  class="selection"><span class="select2-selection select2-selection--single" role="combobox"
                                          aria-haspopup="true" aria-expanded="false" tabindex="0"
                                          aria-disabled="false"
                                          aria-labelledby="select2-id_settore-ml-container"
                                          aria-controls="select2-id_settore-ml-container"><span
                  class="select2-selection__rendered" id="select2-id_settore-ml-container" role="textbox"
                  aria-readonly="true" title="UOMO">UOMO</span><span class="select2-selection__arrow"
                                                                     role="presentation"><b
                  role="presentation"></b></span></span></span><span class="dropdown-wrapper"
                                                                     aria-hidden="true"></span></span>
                </div>

                <!-- MACROCATEGORIA -->
                <div class="col-md-4">
                  <label for="" class="form-control-label">Macrocategoria</label>
                  <select name="id_macrocategoria" required=""
                          class="select2-macrocategoria select2-hidden-accessible"
                          onchange="changeMacrocategoria(this)" data-select2-id="select2-data-10-35xz"
                          tabindex="-1" aria-hidden="true">
                    <option value="">SELEZIONARE</option>
                    <option value="269">ABBIGLIAMENTO</option>
                    <option value="390">ACCESSORI</option>
                    <option value="359">BORSE</option>
                    <option value="442">GIOIELLI E OROLOGI</option>
                    <option value="436">INTIMO</option>
                    <option selected="" value="370" data-select2-id="select2-data-12-wuix">SCARPE</option>
                  </select><span class="select2 select2-container select2-container--default" dir="ltr"
                                 data-select2-id="select2-data-11-gu66" style="width: auto;"><span
                  class="selection"><span class="select2-selection select2-selection--single" role="combobox"
                                          aria-haspopup="true" aria-expanded="false" tabindex="0"
                                          aria-disabled="false"
                                          aria-labelledby="select2-id_macrocategoria-28-container"
                                          aria-controls="select2-id_macrocategoria-28-container"><span
                  class="select2-selection__rendered" id="select2-id_macrocategoria-28-container"
                  role="textbox" aria-readonly="true" title="SCARPE">SCARPE</span><span
                  class="select2-selection__arrow" role="presentation"><b
                  role="presentation"></b></span></span></span><span class="dropdown-wrapper"
                                                                     aria-hidden="true"></span></span>
                </div>


                <!-- CATEGORIA -->
                <div class="col-md-4">
                  <label for="" class="form-control-label">Categoria</label>
                  <select name="id_categoria" onchange="changeCategoria(this)"
                          class="select2-categoria select2-hidden-accessible"
                          data-select2-id="select2-data-13-73y1" tabindex="-1" aria-hidden="true">
                    <option value="">SELEZIONARE</option>
                    <option value="371">ESPADRILLAS</option>
                    <option value="372">MOCASSINI</option>
                    <option value="373">SANDALI</option>
                    <option selected="" value="374" data-select2-id="select2-data-15-20hb">SCARPE STRINGATE
                    </option>
                    <option value="378">SNEAKERS</option>
                    <option value="383">STIVALI</option>
                  </select><span class="select2 select2-container select2-container--default" dir="ltr"
                                 data-select2-id="select2-data-14-h7ta" style="width: auto;"><span
                  class="selection"><span class="select2-selection select2-selection--single" role="combobox"
                                          aria-haspopup="true" aria-expanded="false" tabindex="0"
                                          aria-disabled="false"
                                          aria-labelledby="select2-id_categoria-2t-container"
                                          aria-controls="select2-id_categoria-2t-container"><span
                  class="select2-selection__rendered" id="select2-id_categoria-2t-container" role="textbox"
                  aria-readonly="true" title="SCARPE STRINGATE">SCARPE STRINGATE</span><span
                  class="select2-selection__arrow" role="presentation"><b
                  role="presentation"></b></span></span></span><span class="dropdown-wrapper"
                                                                     aria-hidden="true"></span></span>
                </div>


                <!-- SOTTOCATEGORIE -->
                <div class="col-md-4">
                  <label for="" class="form-control-label">Sottocategoria</label>
                  <select name="id_sottocategoria" class="select2-sottocategoria select2-hidden-accessible"
                          data-select2-id="select2-data-19-rfkq" tabindex="-1" aria-hidden="true">
                    <option value="">SELEZIONARE</option>
                    <option value="375">SCARPE CON FIBBIA</option>
                    <option selected="" value="376" data-select2-id="select2-data-21-h17c">SCARPE DERBY
                    </option>
                    <option value="377">SCARPE OXFORD</option>
                  </select><span class="select2 select2-container select2-container--default" dir="ltr"
                                 data-select2-id="select2-data-20-cexs" style="width: auto;"><span
                  class="selection"><span class="select2-selection select2-selection--single" role="combobox"
                                          aria-haspopup="true" aria-expanded="false" tabindex="0"
                                          aria-disabled="false"
                                          aria-labelledby="select2-id_sottocategoria-js-container"
                                          aria-controls="select2-id_sottocategoria-js-container"><span
                  class="select2-selection__rendered" id="select2-id_sottocategoria-js-container"
                  role="textbox" aria-readonly="true" title="SCARPE DERBY">SCARPE DERBY</span><span
                  class="select2-selection__arrow" role="presentation"><b
                  role="presentation"></b></span></span></span><span class="dropdown-wrapper"
                                                                     aria-hidden="true"></span></span>
                </div>


              </div>


            </div>

            <div class="modal-footer">
              <button type="button" data-dismiss="modal" class="btn btn-link">Chiudi</button>
              <button type="submit" id="bottoneConfermaModaleModificaDatiGenerali"
                      class="btn btn-primary ml-auto">Conferma
              </button>
            </div>

          </form>

        </div>
      </div>
    </div>


    <div class="modal fade" id="modale-modifica-tessuto-colore" tabindex="-1" role="dialog"
         aria-labelledby="modal-default" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered modal-" role="document"
           data-select2-id="select2-data-37-2m48">
        <div class="modal-content classe-brand">

          <div class="modal-header">
            <h6 class="modal-title">Modifica Tessuto e/o Colore</h6>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <form id="formModificaDatiGenerali" action="/articoli/modifica-tessuto-colore" method="POST"
                enctype="multipart/form-data">

            <input type="hidden" name="id_articolo" value="488">
            <input type="hidden" name="id_articolo_variante" value="9597">

            <div class="modal-body">

              <div class="row mb-3">

                <!-- TESSUTO -->
                <div class="col-md-12">
                  <label for="" class="form-control-label">
                    Tessuto
                    <i class="fa fa-lg fa-plus-circle" onclick="modaleAggiungiNuovoTessuto()"
                       style="color:green;cursor:pointer" data-toggle="tooltip"
                       title="Aggiungi nuovo Tessuto"></i>
                  </label>
                  <select name="id_tessuto" required="" class="select2-tessuto select2-hidden-accessible"
                          data-select2-id="select2-data-30-8n0t" tabindex="-1" aria-hidden="true">
                    <!-- <option value="">SELEZIONARE</option> -->
                    <option value="98" data-select2-id="select2-data-38-cqd3">CAMOSCIO</option>
                    <option value="99" data-select2-id="select2-data-39-t20q">CAMOSCIO</option>
                    <option value="100" data-select2-id="select2-data-40-ll9h">CAMOSCIO</option>
                    <option value="101" data-select2-id="select2-data-41-ty3m">BUFALO</option>
                    <option selected="" value="102" data-select2-id="select2-data-32-sdak">BUFALO</option>
                    <option value="103" data-select2-id="select2-data-42-6lpz">BUFALO</option>
                    <option value="104" data-select2-id="select2-data-43-3cii">VITELLO</option>
                    <option value="105" data-select2-id="select2-data-44-9tko">CAMOSCIO</option>
                    <option value="106" data-select2-id="select2-data-45-lctl">CAMOSCIO</option>
                    <option value="107" data-select2-id="select2-data-46-ptuz">VITELLO</option>
                    <option value="108" data-select2-id="select2-data-47-ckh5">CAMOSCIO</option>
                  </select><span
                  class="select2 select2-container select2-container--default select2-container--below"
                  dir="ltr" data-select2-id="select2-data-31-u31j" style="width: auto;"><span
                  class="selection"><span class="select2-selection select2-selection--single" role="combobox"
                                          aria-haspopup="true" aria-expanded="false" tabindex="0"
                                          aria-disabled="false"
                                          aria-labelledby="select2-id_tessuto-jn-container"
                                          aria-controls="select2-id_tessuto-jn-container"><span
                  class="select2-selection__rendered" id="select2-id_tessuto-jn-container" role="textbox"
                  aria-readonly="true" title="BUFALO">BUFALO</span><span class="select2-selection__arrow"
                                                                         role="presentation"><b
                  role="presentation"></b></span></span></span><span class="dropdown-wrapper"
                                                                     aria-hidden="true"></span></span>
                </div>

              </div>
              <div class="row mb-3">

                <!-- COLORE -->
                <div class="col-md-12">
                  <label for="" class="form-control-label">
                    Colore
                    <i class="fa fa-lg fa-plus-circle" onclick="modaleAggiungiNuovoColore()"
                       style="color:green;cursor:pointer" data-toggle="tooltip"
                       title="Aggiungi nuovo Colore"></i>
                  </label>
                  <select name="id_colore" required="" class="select2-colore select2-hidden-accessible"
                          data-select2-id="select2-data-33-ioxc" tabindex="-1" aria-hidden="true">
                    <!-- <option value="">SELEZIONARE</option> -->
                    <option value="965" data-select2-id="select2-data-49-xtnf">VIOLA</option>
                    <option value="966" data-select2-id="select2-data-50-7lbr">MARRONE</option>
                    <option selected="" value="967" data-select2-id="select2-data-35-8pwk">NERO</option>
                    <option value="968" data-select2-id="select2-data-51-hxmi">MARRONE SCURO</option>
                  </select><span
                  class="select2 select2-container select2-container--default select2-container--below"
                  dir="ltr" data-select2-id="select2-data-34-oyve" style="width: auto;"><span
                  class="selection"><span class="select2-selection select2-selection--single" role="combobox"
                                          aria-haspopup="true" aria-expanded="false" tabindex="0"
                                          aria-disabled="false"
                                          aria-labelledby="select2-id_colore-k1-container"
                                          aria-controls="select2-id_colore-k1-container"><span
                  class="select2-selection__rendered" id="select2-id_colore-k1-container" role="textbox"
                  aria-readonly="true" title="NERO">NERO</span><span class="select2-selection__arrow"
                                                                     role="presentation"><b
                  role="presentation"></b></span></span></span><span class="dropdown-wrapper"
                                                                     aria-hidden="true"></span></span>
                </div>

              </div>


            </div>

            <div class="modal-footer">
              <button type="button" data-dismiss="modal" class="btn btn-link">Chiudi</button>
              <button type="submit" id="bottoneConfermaModaleModificaDatiGenerali"
                      class="btn btn-primary ml-auto">Conferma
              </button>
            </div>

          </form>

        </div>
      </div>
    </div>


    <div class="modal fade" id="modale-modifica-descrizioni" tabindex="-1" role="dialog"
         aria-labelledby="modal-default" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered modal-" role="document">
        <div class="modal-content">

          <div class="modal-header">
            <h6 class="modal-title">Modifica Descrizioni</h6>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>


          <input type="hidden" name="id_articolo_variante" value="9597">
          <input type="hidden" name="id_organizzazione" value="56">

          <div class="modal-body">

            <!-- DESCRIZIONE BREVE -->
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label>DESCRIZIONE BREVE</label>
                  <textarea id="descrizione_breve" [(ngModel)]="article!.articoloVariante!.descrizione_breve"
                            class="form-control"></textarea>
                </div>
              </div>
            </div>

            <!-- DESCRIZIONE ESTESA -->
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label>DESCRIZIONE ESTESA</label>
                  <textarea id="descrizione_estesa" rows="5" style="height: auto !important;"
                            [(ngModel)]="article!.articoloVariante!.descrizione_estesa"
                            class="form-control"> </textarea>
                </div>
              </div>
            </div>

          </div>

          <div class="modal-footer">
            <button type="button" data-dismiss="modal" class="btn btn-link">Chiudi</button>
            <!-- <button type="button" onclick="eliminaImmagineArticolo()" class="btn btn-danger">Elimina</button> -->
            <button type="button" onclick="confermaModificaDescrizioni()" class="btn btn-primary">Conferma
            </button>
          </div>


        </div>
      </div>
    </div>


  </section>


  <app-footer></app-footer>


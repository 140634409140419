import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { AuthService } from '../auth/auth.service';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  api: string = environment.api;

  constructor(private http : HttpClient, private auth : AuthService) { }


  createUserBrand(dati: User): Observable<any> {
    return this.http.post<any>(this.api+'/registrazione-user-brand', dati);
  }

  createUserRetailer(dati: User): Observable<any> {
    return this.http.post<any>(this.api+'/registrazione-user-retailer', dati);
  }

  createUser(obj: any): Observable<any> {
    return this.http.post<any>(this.api + "/utenti/aggiungi", obj);
  }

  updateUser(obj: any): Observable<any> {
    return this.http.post<any>(this.api + "/utenti/modifica", obj);
  }

  deleteUser(obj: any): Observable<any> {
    return this.http.post<any>(this.api + "/utenti/elimina", obj);
  }

  getInfoBrand(id: number) {
    return this.http.get<any>(this.api+'/account/brand/' + id).toPromise();
  }

  getInfoRetailer(id: number) {
    return this.http.get<any>(this.api+'/account/retailer/' + id).toPromise();
  }


}

<section *ngIf="campionario">
  <div class="">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-colored card-retailer" style="overflow:visible">
          <h1 class="text-uppercase text-white border-bottom-ab">Campionario -
            Stagione {{campionario.id_stagione}}</h1>
          <button class="btn agg-mag" data-toggle="modal" data-target="#campionariModal">Aggiungi</button>
          <div class="button-mag">
            <form id="filters" action="/" method="get">
              <div class="row">
                <div class="col-md-3 pr-0">
                  <select id="brand" class="form-control select2-brand" multiple="multiple" required
                          name="brand[]" data-live-search="true">
                    <option *ngFor="let brand of brands" value="{{brand.id}}">{{brand.nome}}</option>
                  </select>
                </div>
                <div class="col-md-2 pr-0">
                  <input class="form-control" type="text" name="codice_articolo" value=""
                         placeholder="CODICE ARTICOLO">
                </div>
                <div class="col-md-3 pr-0">
                  <input class="form-control" type="text" name="barcode" value=""
                         placeholder="RICERCA BARCODE">
                </div>
                <div class="col-md-2 pr-0">
                  <select id="stagioni" class="form-control select2-stagioni" multiple="multiple" required
                          name="stagioni[]" data-live-search="true">
                    <option *ngFor="let stagione of stagioni"
                            value="{{stagione.id}}">{{stagione.nome}}</option>
                  </select>
                </div>
                <div class="col-md-2 pr-0">
                  <select id="collezioni" class="form-control select2-collezioni" multiple="multiple"
                          required name="collezioni[]" data-live-search="true">
                    <option *ngFor="let collezione of collezioni"
                            value="{{collezione.id}}">{{collezione.nome}}</option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="col-md-2 pr-0">
                  <select id="tessuti" class="form-control select2-tessuti" multiple="multiple" required
                          name="tessuti[]" placeholder="Tessuto" data-live-search="true">
                    <option *ngFor="let tessuto of tessuti" value="{{tessuto.id}}">{{tessuto.nome}}</option>
                  </select>
                </div>
                <div class="col-md-2 pr-0">
                  <select id="colori" class="form-control select2-colori" multiple="multiple" required
                          name="colori[]" placeholder="Colore" data-live-search="true">
                    <option *ngFor="let colore of colori" value="{{colore.id}}">{{colore.nome}}</option>
                  </select>
                </div>
                <div class="col-md-2 pr-0">
                  <select id="settori" class="form-control select2-settori" multiple="multiple" required
                          name="settori[]" placeholder="Settore" data-live-search="true">
                    <option *ngFor="let settore of settori" value="{{settore.id}}">{{settore.nome}}</option>
                  </select>
                </div>
                <div class="col-md-2 pr-0">
                  <select id="macrocategorie" class="form-control select2-macro" multiple="multiple"
                          required name="macrocategorie[]" placeholder="Macrocategoria"
                          data-live-search="true">
                  </select>
                </div>
                <div class="col-md-2 pr-0">
                  <select id="categorie" class="form-control select2-categorie" multiple="multiple" required
                          name="categorie[]" placeholder="Categoria" data-live-search="true">
                  </select>
                </div>
                <div class="col-md-2 pr-0">
                  <select id="sottocategorie" class="form-control select2-sottocat" multiple="multiple"
                          required name="sottocategorie[]" placeholder="Sottocategoria"
                          data-live-search="true">
                  </select>
                </div>
                <div class="col-md-3 pr-0">
                  <select id="taglie" class="form-control select2-taglie" multiple="multiple" required
                          name="taglie[]" placeholder="Taglia" data-live-search="true">
                    <option *ngFor="let taglia of taglie" value="{{taglia.id}}">{{taglia.nome}}</option>
                  </select>
                </div>
                <div class="col-md-3 pr-0">
                  <select id="taglia_descrizione" class="form-control select2-taglia_descrizione"
                          multiple="multiple" required name="taglia_descrizione[]"
                          placeholder="Taglia descrizione" data-live-search="true">
                  </select>
                </div>
                <div class="col-md-4 pr-0">
                  <div class="form-flex">
                    <input type="number" class="form-control" min="0" step="1" placeholder="PREZZO DA"
                           name="prezzo_dal">
                    <input type="number" class="form-control" min="0" step="1" placeholder="PREZZO A"
                           name="prezzo_al">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="button-flex" style="width: 100%">
                    <button title="" type="button" class="btn btn1" (click)="exportAll()">
                      <i class="fas fa-file-excel"></i> Export
                    </button>
                    <button title="" type="button" class="btn btn2" data-toggle="modal"
                            data-target="#campionariModalImport">
                      <i class="fas fa-upload"></i> Import
                    </button>
                    <button title="" type="button" class="btn btn1" (click)="downloadImportTemplate()">
                      <i class="fas fa-download"></i> Template
                    </button>
                    <button type="button" title="" class="btn btn2" (click)="deselectAll()">
                      <i class="fas fa-times"></i> Resetta
                    </button>
                    <button type="button" title="" class="btn btn1" style="width: 100%" (click)="search()">
                      <i class="fas fa-search"></i> Cerca
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section>
  <div class="card">
    <div class="row">
      <div class="col-md-4" *ngFor="let row of articoli">
        <div class="card-img-top">
          <p class="prezzoCamp">{{row.prezzo_vendita_dettaglio | currency:'EUR':'€'}}</p>
          <img src="https://picsum.photos/300?random={{row.id}}" class="img-fluid"
               alt="{{row.nome}}">
          <div class="card-body-camp">
            <h4 class="card-title">{{row.nome}}</h4>
            <div class="zig-zag"></div>
            <p>Codice: {{row.codice_articolo}}</p>

            <p>Brand: {{row.brand}}</p>
            <p>Collezione: {{row.collezione}}</p>
            <p>Settore: {{row.settore}}</p>
            <a class="btn btn2" [href]="getDetailUrl(row)" target="_blank">Dettagli</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="modal fade" id="campionariModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="min-height:80vh">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Aggiungi articoli</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-4">
            <select class="form-control" id="brand">
              <option value="" disabled="" selected="">Brand</option>
              <option *ngFor="let brand of brands" value="{{brand.id}}">{{brand.nome}}</option>
            </select>
          </div>
          <div class="col-md-4">
            <input class="form-control" type="text" placeholder="Barcode" id="barcode">
          </div>
          <div class="col-md-4">
            <input class="form-control" type="text" placeholder="Codice Articolo" id="codice_articolo">
          </div>
        </div>
      </div>
      <div class="col-md-12 mb-3">
        <div class="button-flex" style="width: 100%">
          <button type="button" class="btn btn1" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn2">Cerca</button>
        </div>
      </div>

    </div>
  </div>
</div>


<div class="modal fade" id="campionariModalImport" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="min-height:80vh">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Importa Excel</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-6">
            <fieldset>
              <legend>Excel</legend>
              <input class="form-control" type="file" placeholder="Excel" id="xlsx" accept=".xlsx">
            </fieldset>
          </div>
          <div class="col-md-6">
            <fieldset>
              <legend>Archivio Immagini</legend>
              <input class="form-control" type="file" placeholder="Archivio Immagini" id="imgZip"
                     accept=".zip">
            </fieldset>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" id="closeImportModal">Close
        </button>
        <button type="button" class="btn btn-primary" (click)="importCampionario()">Confirm</button>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/auth/auth.service';
import { UserSession } from 'app/auth/interfaces/user-session';
import { ClientiService } from 'app/services/clienti.service';
import { MagazziniService } from 'app/services/magazzini.service';
import { UtilitiesService } from 'app/services/utilities.service';

@Component({
  selector: 'app-crea-movimento',
  templateUrl: './crea-movimento.component.html',
  styleUrls: ['./crea-movimento.component.sass']
})
export class CreaMovimentoComponent implements OnInit {

  constructor(private auth: AuthService, private clientiService: ClientiService, private magazziniService: MagazziniService) { }

  private user : UserSession | undefined;
  public brands : any[] = [];
  public magazzini : any[] = [];
  public causali : any[] = [];
  public canali : any[] = [];
  public corrieri : any[] = [];
  public stagioni : any[] = [];
  public clienti : any[] = [];
  public movimento : any = {
    tipo_movimento: "CARICO",
    articoli_varianti: [],
    note: "",
    fornitore: "",
    tracking_url: "",
    tracking_number: "",
  };
  public filters : any = {};


  ngOnInit(): void {
    this.user = this.auth.getCurrentUser();
    this.clientiService.getClientiOrganizzazione()
    .subscribe(resp => {
      this.clienti = resp.data;
    },
    error => {
      console.log(error);
    });
    this.magazziniService.getFilters()
    .subscribe(resp => {
      this.brands = resp['data']['brands'];
      this.magazzini = resp['data']['magazzini'];
      this.causali = resp['data']['causali'];
      this.canali = resp['data']['canali'];
      this.corrieri = resp['data']['corrieri'];
      this.stagioni = resp['data']['stagioni'];
    })
  }

  save(): void {
    this.validate();
    this.magazziniService.save(this.movimento)
    .subscribe(resp => {console.log(resp)}, error => {alert(error)})
  }

  validate(): void {
    this.movimento.magazzino = parseInt(this.movimento.magazzino);
    this.movimento.causale  = parseInt(this.movimento.causale );
    this.movimento.canale  = parseInt(this.movimento.canale );
    this.movimento.corriere  = parseInt(this.movimento.corriere );
    this.movimento.cliente  = parseInt(this.movimento.cliente );
    this.movimento.fornitore = 0;
    this.movimento.numero_ordine  = parseInt(this.movimento.numero_ordine );
    for (let i = 0; i < this.movimento.articoli_varianti.length; i++) {
      this.movimento.articoli_varianti[i].id_articolo_variante = parseInt(this.movimento.articoli_varianti[i].id);
      this.movimento.articoli_varianti[i].qta_variazione  = parseInt(this.movimento.articoli_varianti[i].qta_variazione );
      this.movimento.articoli_varianti[i].prezzo = parseFloat(this.movimento.articoli_varianti[i].prezzo);
      this.movimento.articoli_varianti[i].prezzo_finale  = parseFloat(this.movimento.articoli_varianti[i].prezzo_finale );
    }
  }

  addArticoli(): void {
    const id_articoli = [];
    this.movimento.articoli_varianti.forEach(articolo => {
      id_articoli.push(articolo.id);
    });
    this.filters['articoli_varianti_presenti[]'] = id_articoli;
    this.filters.magazzino = this.movimento.magazzino;
    console.log('filters', this.filters);
    this.magazziniService.addArticoli(this.filters)
    .subscribe(resp => {
      if (resp.data) {
        resp.data.articoliVarianti.forEach(articolo => {
          articolo.qta_variazione = 0;
          articolo.prezzo = parseFloat(this.user.tipo == "BRAND" ? articolo.prezzo_vendita_ingrosso : articolo.prezzo_vendita_dettaglio).toFixed(2);
          this.movimento.articoli_varianti.push(articolo);
        });
      }
      else {
        alert('Nessun articolo trovato');
      }
    }, error => {
      alert(error);
    })
  }

  resetArticoli(): void {
    this.movimento.articoli_varianti = [];
  }

  changeQtaArticolo(event, articolo) {
    const index = this.movimento.articoli_varianti.indexOf(articolo);
    if (index > -1) {
      this.movimento.articoli_varianti[index].qta_variazione = event.target.value;
      this.prezzoFinale(articolo);
    }
  }

  changePrezzoArticolo(event, articolo) {
    const index = this.movimento.articoli_varianti.indexOf(articolo);
    if (index > -1) {
      this.movimento.articoli_varianti[index].prezzo = event.target.value;
      this.prezzoFinale(articolo);
    }
  }

  deleteArticoloMovimento(articolo): void {
    const index = this.movimento.articoli_varianti.indexOf(articolo);
    if (index > -1) {
      this.movimento.articoli_varianti.splice(index, 1);
    }
  }

  getCausaliFiltered(): any[] {
    return this.causali.filter(causale => {return causale.tipo == this.movimento.tipo_movimento})
  }

  giacenzaFinale(articolo) {
    const segno = this.movimento.tipo_movimento == "SCARICO" ? -1 : 1;
    return parseInt(articolo.quantita) + (parseInt(articolo.qta_variazione) * segno);
  }

  prezzoFinale(articolo) {
    const index = this.movimento.articoli_varianti.indexOf(articolo);
    if (index > -1) {
      this.movimento.articoli_varianti[index].prezzo_finale = (parseInt(articolo.qta_variazione) * parseFloat(articolo.prezzo)).toFixed(2);
    }
  }

  dettagliMagazzino() {
    const magazzino = this.magazzini.find(magazzino => magazzino.id == this.movimento.magazzino);
    console.log(magazzino);
    return [magazzino.nome, magazzino.formatted_address, magazzino.email];
  }

  dettagliCliente() {
    const cliente = this.clienti.find(cliente => cliente.id == this.movimento.cliente);
    console.log(cliente);
    return cliente ? cliente.nome : "";
  }

  causaleMovimento() {
    const causale = this.causali.find(causale => causale.id == this.movimento.causale);
    return causale ? causale.nome : "";
  }



}

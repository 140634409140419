import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'app/auth/auth.service';
import { UserSession } from 'app/auth/interfaces/user-session';
import { DialogRequestAuthorizationComponent } from 'app/dialogs/dialog-request-authorization/dialog-request-authorization.component';
import { Brand } from 'app/models/brand';
import { UtilitiesService } from 'app/services/utilities.service';

@Component({
  selector: 'app-item-list-brand',
  templateUrl: './item-list-brand.component.html',
  styleUrls: ['./item-list-brand.component.sass']
})
export class ItemListBrandComponent implements OnInit {

  @Input() item: any = [];
  @Output() brandSelected: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {

   }

  selectBrand(item:any) {
    this.brandSelected.emit(item);
  }


  /*GESTIONE NUOVA MODALE
  openDialog() {
    console.log('openDialog');
    const dialogRef = this.dialog.open(DialogRequestAuthorizationComponent,{
      data:{
      }, width: "500px",
    });
  } */
}

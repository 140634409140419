import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-detail-articolo-retailer',
  templateUrl: './detail-articolo-retailer.component.html',
  styleUrls: ['./detail-articolo-retailer.component.sass']
})
export class DetailArticoloRetailerComponent implements OnInit {
  @Input() item: any = [];
  constructor() { }

  ngOnInit(): void {
    console.log(this.item)
    this.item.tessuti = this.item.tessuti
    console.log(this.item.tessuti)
  }

}

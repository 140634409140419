import { Component, OnInit } from '@angular/core';
import { ArticoloService } from 'app/services/articolo.service';

@Component({
  selector: 'app-list-articles',
  templateUrl: './list-articles.component.html',
  styleUrls: ['./list-articles.component.sass']
})
export class ListArticlesComponent implements OnInit {

  public listArticles : any[] = [];
  constructor(private articoloService : ArticoloService) { }

  ngOnInit(): void {
    this.articoloService.getList({}).then((resp: any) => {
      var list = resp['data'].articoli.data
      this.listArticles = Object.values(list);

      console.log(list)
    });
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AgentiService {
  private agentiUrl = environment.backendPath+'/agenti/';

  constructor(private http : HttpClient, private auth : AuthService) { }

  getAgenti(): Observable<any> {
    return this.http.get<any>(this.agentiUrl);
  }

  getAgente(id: number): Observable<any> {
    return this.http.get<any>(this.agentiUrl+id.toString());
  }

  deleteAgente(id_agente: string) {
    return this.http.delete<any>(this.agentiUrl+id_agente.toString());
  }

  getClientiBrandAgente(id: number): Observable<any> {
    return this.http.get<any>(this.agentiUrl+id.toString()+'/clienti_brand/');
  }

  createAgente(agente: any): Observable<any> {
    return this.http.post<any>(this.agentiUrl, agente);
  }

  updateAgente(id_agente: number, agente: any): Observable<any> {
    return this.http.put<any>(this.agentiUrl+id_agente.toString(), agente);
  }

  createAgenteBrand(id_brand: number, dati: any): Observable<any> {
    return this.http.post<any>(this.agentiUrl+'brand/'+id_brand.toString(), dati);
  }

  updateAgenteBrand(id_agente: number, id_brand: number, dati: any): Observable<any> {
    return this.http.put<any>(this.agentiUrl+id_agente.toString()+'/brand/'+id_brand.toString(), dati);
  }

  deleteAgenteBrand(id_agente: number, id_brand: number): Observable<any> {
    return this.http.delete<any>(this.agentiUrl+id_agente.toString()+'/brand/'+id_brand.toString());
  }

  createAgenteCliente(id_agente: number, dati: any): Observable<any> {
    return this.http.post<any>(this.agentiUrl+id_agente.toString()+'/clienti_brand/', dati);
  }

  updateAgenteCliente(id_agente_cliente: number, dati: any): Observable<any> {
    return this.http.put<any>(this.agentiUrl+'clienti_brand/'+id_agente_cliente.toString(), dati);
  }

  deleteAgenteCliente(id_agente_cliente: string): Observable<any> {
    return this.http.delete<any>(this.agentiUrl+'clienti_brand/'+id_agente_cliente.toString());
  }
}

<section>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-colored classe-brand">
        <h1 class="text-uppercase text-white border-bottom-ab">Dettaglio Movimento</h1>
        <div class="dettaglioMov">
          <!-- <img src="/img/team-4.jpg" width="20"> -->
          <p>Magazzino<br> <span>Magazzino Milano<br>ferragnicollection@r2w.it<br>Corso Lodi,  Milano (20139)</span>
          </p>
        </div>
        <div class="cont-dett">
          <div class="dettaglioMov movSingolo">
            <p>Tipo movimento: <span>CARICO</span></p>
          </div>
          <div class="dettaglioMov movSingolo">
            <p>Causale: <span>Acquisto</span></p>
          </div>
        </div>
        <div class="cont-dett">
          <div class="dettaglioMov movSingolo">
            <p>Cliente: <span>Lorem</span></p>
          </div>
          <div class="dettaglioMov movSingolo">
            <p>Fornitore: <span>Lorem</span></p>
          </div>
        </div>
        <div class="cont-dett">
          <div class="dettaglioMov movSingolo">
            <p>Ordine di riferimento: <span>21</span></p>
          </div>
          <div class="dettaglioMov movSingolo">
            <p>Canale: <span>POS</span></p>
          </div>
        </div>
        <div class="cont-dett">
          <div class="dettaglioMov movSingolo">
            <p>Data: <span>17/11/2023</span></p>
          </div>
          <div class="dettaglioMov movSingolo">
            <p>Note: <span>lorem</span></p>
          </div>
        </div>
        <div class="cont-dett">
          <div class="dettaglioMov movSingolo">
            <p>Tracking number: <span>1234567890</span></p>
          </div>
          <div class="dettaglioMov movSingolo">
            <p>Tracking url: <span>www.sellup.it</span></p>
          </div>
        </div>
        <div class="cont-dett">
          <div class="dettaglioMov movSingolo">
            <p>Operatore: <span>Chiara Ferragni</span></p>
          </div>
          <div class="dettaglioMov movSingolo">
            <p>Valore Totale: <span>4,00€</span></p>
          </div>
        </div>


        <div class="table-responsive mt-3">
          <table class="table table-hover table-mc-light-blue">
            <thead class="thead-light">
            <tr>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col">Codice variante</th>
              <th scope="col" class="text-center">Taglia</th>
              <th scope="col" class="text-center">Giacenza iniziale</th>
              <th scope="col" class="text-center">Q.ta variazione</th>
              <th scope="col" class="text-center">Giacenza finale</th>
              <th scope="col" class="text-center">VAlore unitario</th>
              <th scope="col" class="text-center">VAlore Totale</th>
            </tr>
            </thead>
            <tbody class="">
            <tr class="">
              <td style="width:1%">1</td>
              <td scope="row" style="width:5%">
                <a
                  href="https://r2w-media-dev.fra1.cdn.digitaloceanspaces.com/articoli/54/632971b2d6ed1.jpg"
                  data-lightbox="image-1" data-title="">
                  <img style="width: 100%"
                       src="https://r2w-media-dev.fra1.cdn.digitaloceanspaces.com/articoli/54/632971b2d6ed1.jpg">
                </a>
              </td>
              <td>71CBA121-----36</td>
              <td class="text-center">36</td>
              <td class="text-center">8</td>
              <td class="text-center">2</td>
              <td class="text-center">10</td>
              <td class="text-center">2</td>
              <td class="text-center">4</td>
            </tr>
            </tbody>
          </table>
        </div>


        <div class="row mt-3">
          <div class="col-md-12" style="text-align:center">
            <div class="button-flex">
              <button data-toggle="tooltip" title="" class="btn btn1" type="button"
                      data-original-title="Modifica Movimento">MODIFICA
              </button>
              <button data-toggle="tooltip" title="" class="btn btn2" type="button"
                      data-original-title="Elimina Movimento">ELIMINA
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>

<footer class="footer classe-retailer" *ngIf="roleUserLogged == 'RETAILER'">
    <div class="row align-items-center justify-content-lg-between">
      <div class="col-lg-6">
        <div class="copyright text-center  text-lg-left">
          &copy; 2023 <a href="#" class="font-weight-bold ml-1" target="_blank">R2S SRL</a>
        </div>
      </div>
    </div>
  </footer>


  <!-- BRAND -->

  <footer class="footer classe-brand" *ngIf="roleUserLogged == 'BRAND'">
    <div class="row align-items-center justify-content-lg-between">
      <div class="col-lg-6">
        <div class="copyright text-center  text-lg-left">
          &copy; 2023 <a href="#" class="font-weight-bold ml-1" target="_blank">R2S SRL</a>
        </div>
      </div>
    </div>
  </footer>



  <!-- SCRIPTS
  <script src="/vendor/jquery/dist/jquery.min.js"></script>
  <script src="/vendor/bootstrap/dist/js/bootstrap.bundle.min.js"></script>
  <script src="/vendor/js-cookie/js.cookie.js"></script>
  <script src="/vendor/jquery.scrollbar/jquery.scrollbar.min.js"></script>
  <script src="/vendor/jquery-scroll-lock/dist/jquery-scrollLock.min.js"></script>
  <script src="/js/argon.js?v=1.2.0"></script>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/jqueryui/1.12.1/jquery-ui.min.js" integrity="sha512-uto9mlQzrs59VwILcLiRYeLKPPbS/bT71da/OEBYEwcdNUk8jYIy+D176RYoop1Da+f9mvkYrmj5MCLZWEtQuA==" crossorigin="anonymous"></script>
  <script src="/vendor/apexchart/apexcharts.min.js"></script>
  <script src="/js/dashboard.js"></script>
  <script src="/js/magazzino.js"></script>
  <script src="/vendor/sweetalert2/dist/sweetalert2.all.min.js"></script>
  <script src="https://cdn.jsdelivr.net/npm/select2@4.1.0-rc.0/dist/js/select2.min.js"></script>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/lightbox2/2.11.3/js/lightbox.min.js" integrity="sha512-k2GFCTbp9rQU412BStrcD/rlwv1PYec9SNrkbQlo6RZCf75l6KcC3UwDY8H5n5hl4v77IDtIPwOk9Dqjs/mMBQ==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
  <script src="/js/bootstrap-select.min.js"></script>
  <script src="/js/global.js"></script>

  CUSTOM SCRIPTS -->

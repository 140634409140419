import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/auth/auth.service';

@Component({
  selector: 'app-list-orders',
  templateUrl: './list-orders.component.html',
  styleUrls: ['./list-orders.component.sass']
})
export class ListOrdersComponent implements OnInit {
  roleUserLogged :string |undefined;
  constructor(private auth: AuthService) {  }

  ngOnInit(): void {

    const user = this.auth.getCurrentUser();
    this.roleUserLogged = user?.tipo;
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from "environments/environment";
@Injectable({
  providedIn: 'root'
})
export class StoreService {

  api: string = environment.api;
  constructor(private http: HttpClient) { }

  getListByorganizzazione(id_organizzazione: number) : Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/magazzini/show/${id_organizzazione}`);
  }

  createStore(store: any): Observable<any> {
    return this.http.post<any>(this.api + "/magazzini/aggiungi", store);
  }

  updateStore(store: any): Observable<any> {
    return this.http.post<any>(this.api + "/magazzini/modifica", store);
  }

  updateStoreState(store: any): Observable<any> {
    return this.http.post<any>(this.api + "/magazzini/modifica/stato", store);
  }

  deleteStore(obj: any): Observable<any> {
    return this.http.post<any>(this.api + "/magazzini/elimina", obj);
  }

}

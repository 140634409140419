<section>
    <div class="">
        <div class="row">
            <div class="col-md-12">

                <h1 class="text-uppercase">Elenco Listini</h1>

                <div class="row">
                    <div class="col-md-6">
                        <a style="padding: 5px 20px" class="btn btn1" data-toggle="modal" data-target="#addListinoModal">
                            Aggiungi Listino
                        </a>
                    </div>
                </div>

                <div class="box">
                    <div class="tab-column">

                        <div class="nav-wrapper">
                            <app-table-listini></app-table-listini>
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="addListinoModal" tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Crea nuovo Listino</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="form-group">
                                    <label for="inputField1">Nome Listino</label>
                                    <input type="text" class="form-control" placeholder="Nome Listino" [(ngModel)]="addData.nome" />
                                </div>
                                <div class="form-group">
                                    <label for="inputField1">Attivo</label>
                                    <input type="checkbox" class="form-control" placeholder="Attivo" [(ngModel)]="addData.attivo" (change)="logChanges()" />
                                </div>
                                <div class="form-group">
                                    <label for="inputField1">Mercato</label>
                                    <select class="form-control" placeholder="Codice Articolo" [(ngModel)]="addData.id_mercato">
                                        <option default selected hidden></option>
                                        <option *ngFor="let elm of selectOptions.mercati" [value]="elm.id">{{elm.nome}}</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label for="inputField1">Clienti</label>
                                    <select multiple class="form-control" placeholder="Organizzazione" [(ngModel)]="addData.id_clienti">
                                        <option default selected hidden></option>
                                        <option *ngFor="let elm of selectOptions.clienti" [value]="elm.id">{{elm.nome}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="button" class="btn btn-primary" (click)="confirmModal()">Save changes</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>



</section>

import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/auth/auth.service';
import { ArticoloService } from 'app/services/articolo.service';

@Component({
  selector: 'app-create-article',
  templateUrl: './create-article.component.html',
  styleUrls: ['./create-article.component.sass']
})
export class CreateArticleComponent implements OnInit {
  constructor(private auth: AuthService, private articoloService: ArticoloService) {  }

  public loading : boolean = true;
  public article : any = { 'prezzo_ingrosso' : '0.00', 'prezzo_dettaglio' : '0.00' };
  public filters : any = {'stagioni': [], 'collezioni': [], 'settori': [], 'taglieTipologie':[], 'tessuti' : [], 'brand'  : [], 'colori' : []}

  ngOnInit(): void {
    const user = this.auth.getCurrentUser();
    console.log(this.filters);
    this.articoloService.getCreateData()
    .subscribe( resp => {
      this.filters = resp.data;
      this.article.id_brand = this.filters.brand[0].id ?? 0;
      this.loading = false;
    });
  }

  save(redirect: boolean): void {
    console.log(this.article);
    const errors = this.validate();
    if (errors.length > 0) {
      alert(errors.join("\n"));
    }
    else {
      this.articoloService.createArticle(this.article)
      .subscribe(resp => {
        console.log('RISULTATO: ', resp.data);
        if (redirect) {
          window.location.href = `/article-detail?id_articolo=${resp.data.id_articolo}&id_tessuto=${resp.data.id_tessuto}&id_colore=${resp.data.id_colore}`
        }
        else {
          window.location.reload();
        }

      })
    }
  }

  validate(): string[] {
    const errors: string[] = [];
      if ((this.article.codice_articolo ?? "") == "") {
        errors.push("Codice articolo mancante");
      }
      if ((this.article.nome_articolo ?? "") == "") {
        errors.push("Nome articolo mancante");
      }
      if (!(this.article.codice_tessuto && !this.checkTessuto()) && !(this.article.codice_tessuto_add && this.article.descrizione_tessuto_add)) {
        errors.push("Selezionare un tessuto o aggiungerne uno");
      }
      if (!(this.article.codice_colore && !this.checkColore()) && !(this.article.codice_colore_add && this.article.descrizione_colore_add)) {
        errors.push("Selezionare un colore o aggiungerne uno");
      }
      if ((this.article.settore_add ?? this.article.settore ?? "") == "") {
        errors.push("Selezionare un settore");
      }
      if ((this.article.stagione_add ?? this.article.stagione ?? "") == "") {
        errors.push("Selezionare una stagione");
      }
      if ((this.article.collezione_add ?? this.article.collezione ?? "") == "") {
        errors.push("Selezionare una collezione");
      }
      if ((this.article.categoria_add ?? this.article.categoria ?? "") == "") {
        errors.push("Selezionare una categoria");
      }
      if ((this.article.scala_taglie ?? "") == "") {
        errors.push("Selezionare una scala_taglie");
      }
      if (!(parseFloat(this.article.prezzo_ingrosso) >= 0 && parseFloat(this.article.prezzo_dettaglio) >= 0)) {
        console.log(parseFloat(this.article.prezzo_dettaglio));
        errors.push("Inserire prezzi corretti");
      }

    return errors;
  }

  checkColore() : boolean {
    return ((this.article.codice_colore_add ?? '') != '') || ((this.article.descrizione_colore_add ?? '') != '');
  }
  checkTessuto() : boolean {
    return ((this.article.codice_tessuto_add ?? '') != '') || ((this.article.descrizione_tessuto_add ?? '') != '');
  }

  loadDescrizioneTessuto(): void {
    this.article.descrizione_tessuto = this.filters.tessuti.find((element: { codice: string; }) => {return element.codice == this.article.codice_tessuto}).nome;
  }
  loadDescrizioneColore(): void {
    this.article.descrizione_colore = this.filters.colori.find((element: { codice: string; }) => {return element.codice == this.article.codice_colore}).nome;
  }
}

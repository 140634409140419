<div class="row">
  <div class="col-md-12">
    <div class="card card-colored">
      <h1 class="text-uppercase text-white border-bottom-ab">Movimenti</h1>
      <input type="hidden" name="export" value="0">

      <a routerLink="/crea-movimento" class="btn agg-mag">
        CREA MOVIMENTO</a>

      <div class="button-mag">
        <div class="row">
          <div class="col-md-4 pr-0">
            <select class="form-control">
              <option>Magazzini</option>
            </select>

          </div>
          <div class="col-md-4 pr-0">
            <select class="form-control">
              <option>Tipo di Movimento</option>
            </select>

          </div>
          <div class="col-md-4 pr-0">
            <select class="form-control">
              <option>Casuale</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 pr-0">
            <input type="text" placeholder="CLIENTE" name="cliente" class="form-control">
          </div>
          <div class="col-md-4 pr-0">
            <input type="text" placeholder="FORNITORE" name="fornitore" class="form-control">
          </div>
          <div class="col-md-4 pr-0">
            <input type="text" placeholder="OPERATORE" name="id_user" class="form-control">
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 pr-0">
            <input type="date" class="form-control">
          </div>
          <div class="col-md-4 pr-0">
            <input type="text" placeholder="RICERCA BARCODE" class="form-control">
          </div>
          <div class="col-md-2 pr-0">
            <input type="text" placeholder="CODICE ARTICOLO" class="form-control">
          </div>
          <div class="col-md-2 pr-0">
            <input type="text" placeholder="N. ORDINE" class="form-control">
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="button-flex flex-100">
              <button type="button" title="" class="btn btn2">
                <i class="fas fa-search"></i> Cerca
              </button>
              <button type="button" title="" class="btn btn3">
                <i class="fas fa-times"></i> Resetta
              </button>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</div>


<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="row">
        <div class="table-responsive">
          <table class="table table-hover table-mc-light-blue">
            <thead>
            <tr>
              <th scope="col">Data</th>
              <th scope="col">MAGAZZINO</th>
              <th scope="col">TIPO MOVIMENTO</th>
              <th scope="col">Causale</th>
              <th scope="col">N.Ordine</th>
              <th scope="col">Cliente</th>
              <th scope="col">Fornitore</th>
              <th scope="col">Tot.</th>
              <th class="text-center" scope="col">Dettaglio</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let movimento of movimenti">
              <td>{{movimento.data}}</td>
              <td>{{movimento.nome}}<br>{{movimento.email}}<br>{{movimento.formatted_address}}</td>
              <td>
                <b>{{movimento.tipo_movimento}}</b></td>
              <td>{{movimento.causale_movimento}}</td>
              <td>21</td>
              <td>{{movimento.cliente}}</td>
              <td>{{movimento.fornitore}}</td>
              <td>0</td>
              <td class="text-center iconMov">
                <i class="fi fi-rr-circle-user"></i>
                <i class="fi fi-rr-comment-info"></i>
                <i class="fi fi-rr-angle-circle-right" href="/dettaglio-movimento/"></i>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>



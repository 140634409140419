import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/auth/auth.service';
import { Brand } from 'app/models/brand';
import { BrandService } from 'app/services/brand.service';
import { UtilitiesService } from 'app/services/utilities.service';

@Component({
  selector: 'app-list-brand-retailer',
  templateUrl: './list-brand-retailer.component.html',
  styleUrls: ['./list-brand-retailer.component.sass']
})
export class ListBrandRetailerComponent implements OnInit {

  public negozi: any[] = [];
  public aziende: any[] = [];
  public user: any;
  public listBrand : Brand[] = [];
  public modalIsVisible :boolean = false;
  public brandSelected : Brand | null = null;
  public searchText = "";

  constructor(private brandService : BrandService,private auth: AuthService,private utilitiesService: UtilitiesService,) {  this.modalIsVisible = true;}


  loadBrands(){
    this.brandService.getListByNegozio(null).then((resp: any) => {
      var list = resp['data'].brand.data
      this.listBrand = list;
      this.modalIsVisible = false;
      console.log(list)
    });
  }

  ngOnInit(): void {
    this.loadBrands()
    this.user = this.auth.getCurrentUser();
    this.loadAziende();
    this.loadShops();
  }

  reset(){
    this.searchText = "";
    this.loadBrands()
  }

  search(){
    console.log('filtro: ',this.searchText)
    this.listBrand = [];
    this.modalIsVisible = true;
    this.brandService.getListByNegozio(this.searchText).then((resp: any) => {
      var list = resp['data'].brand.data
      this.listBrand = list;
      this.modalIsVisible = false;
      console.log(list)
    });
  }

  selectBrand(item : Brand) {
    this.brandSelected = item;
  }

  loadAziende() {
    this.utilitiesService.getListCompaniesByOrganizzazione(this.user.id_organizzazione).then((res:any) => {
      console.log('Lista AZIENDE: ', res['data'].aziende);
      this.aziende = !res ? [] : res['data'].aziende;
      console.log('Aziende', this.aziende)
    })
  }

  loadShops() {
    this.utilitiesService.getListShopsByOrganizzazione(this.user.id_organizzazione).then((res:any) => {
      console.log('Lista NEGOZI: ', res['data'].negozi);
      this.negozi = !res ? [] : res['data'].negozi;
    })
  }

  requestAuthorization() {
    const form: FormData = new FormData(document.querySelector('#richiestaAutorizzazione') as HTMLFormElement);
    let dati: { [key: string]: any } = {
      id_brand: this.brandSelected?.id,
      id_negozi: form.getAll('negozi[]'),
      id_aziende: form.getAll('aziende[]'),
      messaggio: form.get('messaggio'),
    };
    console.log(dati);
    this.brandService.requestReqBrand(dati).subscribe(resp => {
      console.log(resp);
    }, (error) => {
      console.log(error);
      // @ts-expect-error
      $('#modale-richiedi-autorizzazione').modal('hide')
      this.loadBrands();
    }, () => {
      // @ts-expect-error
      $('#modale-richiedi-autorizzazione').modal('hide')
      this.loadBrands();
    })


  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ArticoloService {
  api: string = environment.api;
  constructor(private http: HttpClient) { }

  getList(obj: any) : Promise<any[]>{
    return this.http.post<any[]>(`${this.api}/magazzini/retailer`, obj).toPromise();
  }

  getListForBrand(obj: any) : Promise<any[]>{
    return this.http.post<any[]>(`${this.api}/magazzini/brand`, obj).toPromise();
  }

  getDetail(id_articolo :number , id_tessuto :number, id_colore :number) : Promise<any[]>{
    return this.http.get<any[]>(`${this.api}/articolo?id_articolo=${id_articolo}&id_tessuto=${id_tessuto}&id_colore=${id_colore}`).toPromise();
  }

  getListMarketPlace(id_organizzazione: number, obj: any) : Promise<any[]>{
    return this.http.post<any[]>(`${this.api}/marketplace?id_organizzazione=${id_organizzazione}`,obj).toPromise();
  }

  updateVariants(obj: any): Observable<any> {
    return this.http.post<any>(this.api + "/articolo/varianti/modifica", obj);
  }

  updateArticle(obj: any): Observable<any> {
    return this.http.post<any>(this.api + "/articoli/modifica-dati-generali", obj);
  }

  updatePrices(obj: any): Observable<any> {
    return this.http.post<any>(this.api + '/articoli/modifica/prezzi', obj);
  }

  updateDescription(obj: any): Observable<any> {
    return this.http.post<any>(this.api + '/articoli/modifica/descrizioni', obj);
  }

  updateTessutoColore(obj: any): Observable<any> {
    return this.http.post<any>(this.api + '/articoli/modifica-tessuto-colore', obj);
  }

  addTessuto(obj: any): Observable<any> {
    return this.http.post<any>(this.api + '/articoli/aggiungi/tessuto', obj);
  }

  addColore(obj: any): Observable<any> {
    return this.http.post<any>(this.api + '/articoli/aggiungi/colore', obj);
  }

  getCreateData(): Observable<any> {
    return this.http.get<any[]>(this.api + "/articolo/create/data");
  }

  createArticle(obj: any): Observable<any> {
    return this.http.post<any[]>(this.api + "/articolo/create", obj);
  }

  searchArticolo(obj: any): Observable<any> {
    return this.http.get<any[]>(this.api + '/magazzini/ricerca/aggiungi/articolo/retailer', { params: obj});
  }

  addArticoloRetailer(obj: any): Observable<any> {
    return this.http.post<any[]>(this.api + "/magazzini/aggiungi/articolo/retailer", obj);
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {
  api: string = environment.api;
  constructor(private http: HttpClient) { }

  getFormatDateTime(dateStr : string){
    let d = dateStr.split("T");
    let data = d[0].split("-");
    let time = d[1].substring(0, 5)
    return data[2] + "/" + data[1] + "/" + data[0] +" " + time;
  }

  getListStoresByOrganizzazione(id_organizzazione: number){
    return this.http.get<any[]>(`${this.api}/magazzini/show?id_organizzazione=${id_organizzazione}`).toPromise();
  }

  getListUsersByOrganizzazione(id_organizzazione: number){
    return this.http.get<any[]>(`${this.api}/utenti/show?id_organizzazione=${id_organizzazione}`).toPromise();
  }

  getListCompaniesByOrganizzazione(id_organizzazione: number) {
    return this.http.get<any[]>(`${this.api}/aziende/show?id_organizzazione=${id_organizzazione}`).toPromise();
  }

  getListShopsByOrganizzazione(id_organizzazione: number) {
    return this.http.get<any[]>(`${this.api}/negozi/show?id_organizzazione=${id_organizzazione}`).toPromise();
  }

  getListBrandsByOrganizzazione(id_organizzazione: number) {
    return this.http.get<any[]>(`${this.api}/brand/show?id_organizzazione=${id_organizzazione}`).toPromise();
  }


  getListSizesDescBySize(sizes: string) {
    return this.http.get<any>(`${this.api}/get-taglie-descrizioni-from-taglia-multiple?${sizes}`).toPromise();
  }


  getListMacroCategoriesBySector(sectors: string) {
    return this.http.get<any[]>(`${this.api}/get-macrocategorie-multiple-from-settore?${sectors}`).toPromise();
  }

  getListCategoriesByMacro(macrocategories: string) {
    return this.http.get<any[]>(`${this.api}/get-categorie-multiple-from-macrocategoria?${macrocategories}`).toPromise();
  }


  getListSottoCategoriesByCategory(categories: string) {
    return this.http.get<any[]>(`${this.api}/get-sottocategorie-multiple-from-categoria?${categories}`).toPromise();
  }



}

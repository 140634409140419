<section>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-colored classe-brand">
        <h1 class="text-uppercase text-white border-bottom-ab">Crea Movimento</h1>
        <div class="button-mag">
          <div class="card-inner inactive-1"></div>
          <div class="card-inner inactive-2"></div>
          <div class="card">
            <div class="tab-content" id="pills-tabContent">
              <div class="tab-pane fade show active pill1" id="pills1" role="tabpanel"
                   aria-labelledby="pills1-tab">
                <div class="row">
                  <div class="col-md-4 pr-0">
                    <select class="form-control" [(ngModel)]="movimento.magazzino">
                      <option *ngFor="let magazzino of magazzini" [value]="magazzino.id">{{magazzino.nome}}</option>
                    </select>
                  </div>
                  <div class="col-md-4 pr-0">
                    <select required="" class="form-control" [(ngModel)]="movimento.tipo_movimento">
                      <option value="CARICO">CARICO</option>
                      <option value="SCARICO">SCARICO</option>
                    </select>
                  </div>
                  <div class="col-md-4 pr-0">
                    <select
                      placeholder="Causale" required="" class="form-control" [(ngModel)]="movimento.causale">
                      <option *ngFor="let causale of getCausaliFiltered()" [value]="causale.id">{{causale.nome}}</option>
                    </select>
                  </div>
                  <div class="col-md-2 pr-0">
                    <input type="date" class="form-control" [(ngModel)]="movimento.data">
                  </div>
                  <div class="col-md-2 pr-0">
                    <input type="text" class="form-control" placeholder="NUMERO ORDINE" [(ngModel)]="movimento.numero_ordine">
                  </div>
                  <div class="col-md-2 pr-0">
                    <select class="form-control" name="" [(ngModel)]="movimento.canale">
                      <option *ngFor="let canale of canali" [value]="canale.id">{{canale.nome}}</option>
                    </select>
                  </div>
                  <div class="col-md-2 pr-0">
                    <select
                      class="form-control select2-corrieri" name="" [(ngModel)]="movimento.corriere">
                      <option *ngFor="let corriere of corrieri" [value]="corriere.id">{{corriere.nome}}</option>
                    </select>
                  </div>
                  <div class="col-md-4 pr-0">
                    <select class="form-control" [(ngModel)]="movimento.cliente">
                      <option *ngFor="let cliente of clienti" [value]="cliente.id">{{cliente.nome}}</option>
                    </select>
                  </div>
                  <div class="col-md-4 pr-0">
                    <input type="text" class="form-control" placeholder="FORNITORE" [(ngModel)]="movimento.fornitore">
                  </div>
                  <div class="col-md-4 pr-0">
                    <input type="text" class="form-control" placeholder="TRACKING NUMBER" [(ngModel)]="movimento.tracking_number">
                  </div>
                  <div class="col-md-4 pr-0">
                    <input type="text" class="form-control" placeholder="TRACKING URL" [(ngModel)]="movimento.tracking_url">
                  </div>


                  <div class="col-md-12 pr-0">
                    <input type="text" class="form-control" placeholder="NOTE" [(ngModel)]="movimento.note">
                  </div>
                </div>
              </div>
              <div class="tab-pane fade pill2" id="pills2" role="tabpanel"
                   aria-labelledby="pills2-tab">
                <div class="row">
                  <div class="col-md-3 pr-0">
                    <select class="form-control" [(ngModel)]="filters.brand">
                      <option *ngFor="let brand of brands" [value]="brand.id">{{brand.nome}}</option>
                    </select>
                  </div>
                  <div class="col-md-3 pr-0">
                    <select class="form-control" [(ngModel)]="filters.stagione">
                      <option *ngFor="let stagione of stagioni" [value]="stagione.id">{{stagione.nome}}</option>
                    </select>
                  </div>
                  <div class="col-md-3 pr-0">
                    <input type="text" class="form-control" placeholder="CODICE ARTICOLO" [(ngModel)]="filters.codice_articolo">
                  </div>
                  <div class="col-md-3 pr-0">
                    <input type="text" class="form-control" placeholder="BARCODE" [(ngModel)]="filters.barcode">
                  </div>
                  <div class="col-md-12 pr-0">
                    <div class="button-flex">
                      <button title="" class="btn btn1" (click)="addArticoli()">Aggiungi
                      </button>
                      <button title="" class="btn btn2" (click)="resetArticoli()">Reset
                      </button>
                    </div>

                  </div>
                </div>


                <div class="table-responsive mt-3">
                  <table class="table table-mc-light-blue">
                    <thead class="thead-light">
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">Codice variante</th>
                      <th scope="col" class="text-center">Taglia</th>
                      <th scope="col" class="text-center">Giacenza iniziale</th>
                      <th scope="col" class="text-center">Q.ta variazione</th>
                      <th scope="col" class="text-center">Giacenza finale</th>
                      <th scope="col" class="text-center">Valore unitario <span class="span_tipo_prezzo">acquisto</span></th>
                      <th scope="col" class="text-center">Valore Totale</th>
                      <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody class="risultati-body">
                    <tr *ngFor="let articolo of movimento.articoli_varianti">
                      <td scope="row" style="width:5%">
                        <a href="https://r2w-media-dev.fra1.cdn.digitaloceanspaces.com/articoli/54/632971b2d6ed1.jpg" data-lightbox="image-1" data-title="">
                          <img style="width: 100%" id="img-1455" src="https://r2w-media-dev.fra1.cdn.digitaloceanspaces.com/articoli/54/632971b2d6ed1.jpg">
                        </a>
                      </td>
                      <td>{{articolo.codice}}</td>
                      <td class="text-center">{{articolo.codice.substring(articolo.codice.lastIndexOf('-')+1)}}</td>

                      <!-- giacenza attuale -->
                      <td class="text-center">
                        {{articolo.quantita}}</td>

                      <!-- qta variazione -->
                      <td class="text-center">
                        <input class="form-control" type="number" min="0" [value]="articolo.qta_variazione" (change)="changeQtaArticolo($event, articolo)" style="width:50%;margin: 0 auto;">
                      </td>

                      <!-- giacenza finale -->
                      <td class="text-center">
                        <span>{{giacenzaFinale(articolo)}}</span>
                      </td>

                      <!-- prezzo -->
                      <td class="text-center">
                        <input class="form-control" type="number" min="0" step="0.01" [value]="articolo.prezzo" (change)="changePrezzoArticolo($event, articolo)" id="" style="width:50%;margin: 0 auto;">
                      </td>

                      <!-- prezzo finale -->
                      <td class="text-center">
                        <span id="prezzo_finale">{{articolo.prezzo_finale}}</span>
                      </td>

                      <td class="text-center">
                        <i class="fas fa-trash" style="color:red;cursor:pointer" (click)="deleteArticoloMovimento(articolo)"></i>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="tab-pane fade" id="pills3" role="tabpanel"
                   aria-labelledby="pills3-tab">
                <div class="container-step-3">
                  <div class="dettaglioMov" *ngIf="movimento.magazzino">
                    <!-- <img src="/img/team-4.jpg" width="20"> -->
                    <p>Magazzino<br>
                      <span *ngFor="let dettaglio of dettagliMagazzino()">{{dettaglio}}<br/></span>
                    </p>
                  </div>
                  <div class="cont-dett">
                    <div class="dettaglioMov movSingolo">
                      <p>Tipo movimento: <span>{{movimento.tipo_movimento}}</span></p>
                    </div>
                    <div class="dettaglioMov movSingolo">
                      <p>Causale: <span>{{causaleMovimento()}}</span></p>
                    </div>
                  </div>
                  <div class="cont-dett">
                    <div class="dettaglioMov movSingolo">
                      <p>Cliente: <span>{{dettagliCliente()}}</span></p>
                    </div>
                    <div class="dettaglioMov movSingolo">
                      <p>Fornitore: <span>{{movimento.fornitore}}</span></p>
                    </div>
                  </div>
                  <div class="cont-dett">
                    <div class="dettaglioMov movSingolo">
                      <p>Ordine di riferimento: <span>{{movimento.numero_ordine}}</span></p>
                    </div>
                    <div class="dettaglioMov movSingolo">
                      <p>Canale: <span>{{movimento.canale}}</span></p>
                    </div>
                  </div>
                  <div class="cont-dett">
                    <div class="dettaglioMov movSingolo">
                      <p>Data: <span>{{movimento.data}}</span></p>
                    </div>
                    <div class="dettaglioMov movSingolo">
                      <p>Corriere: <span>{{movimento.corriere}}</span></p>
                    </div>
                  </div>
                  <div class="cont-dett">
                    <div class="dettaglioMov movSingolo">
                      <p>Tracking number: <span>{{movimento.tracking_number}}</span></p>
                    </div>
                    <div class="dettaglioMov movSingolo">
                      <p>Tracking url: <span>{{movimento.tracking_url}}</span></p>
                    </div>
                  </div>
                  <div class="dettaglioMov">
                    <p>Note: <span>{{movimento.note}}</span></p>
                  </div>


                  <div class="table-responsive mt-3">
                    <table class="table align-items-center table-flush">
                      <thead class="thead-light">
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">Codice variante</th>
                        <th scope="col" class="text-center">Taglia</th>
                        <th scope="col" class="text-center">Giacenza iniziale</th>
                        <th scope="col" class="text-center">Q.ta variazione</th>
                        <th scope="col" class="text-center">Giacenza finale</th>
                        <th scope="col" class="text-center">Valore unitario <span
                          class="span_tipo_prezzo"></span></th>
                        <th scope="col" class="text-center">Valore Totale</th>
                      </tr>
                      </thead>
                      <tbody class="">
                      <tr class="" *ngFor="let articolo of movimento.articoli_varianti">
                        <td scope="row" style="width:5%">


                          <a
                            href="https://r2w-media-dev.fra1.cdn.digitaloceanspaces.com/articoli/54/632971b2d6ed1.jpg"
                            data-lightbox="image-1" data-title="">
                            <img style="width: 100%" id="img-1455"
                                 src="https://r2w-media-dev.fra1.cdn.digitaloceanspaces.com/articoli/54/632971b2d6ed1.jpg">
                          </a>


                        </td>
                        <td>{{articolo.codice}}</td>
                        <td class="text-center">{{articolo.codice.substring(articolo.codice.lastIndexOf('-')+1)}}</td>

                        <!-- giacenza attuale -->
                        <td class="text-center" id="qta_attuale_9433">
                          {{articolo.quantita}}
                        </td>

                        <!-- qta variazione -->
                        <td class="text-center">
                          {{articolo.qta_variazione}}
                        </td>

                        <!-- giacenza finale -->
                        <td class="text-center">
                          {{giacenzaFinale(articolo)}}
                        </td>

                        <!-- prezzo -->
                        <td class="text-center">
                          {{articolo.prezzo}}
                        </td>

                        <!-- prezzo finale -->
                        <td class="text-center">
                          {{articolo.prezzo_finale}}
                        </td>

                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active" id="pills1-tab" data-toggle="pill"
                        data-target="#pills1" type="button" role="tab" aria-controls="pills1"
                        aria-selected="true">Step 1
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="pills2-tab" data-toggle="pill"
                        data-target="#pills2" type="button" role="tab" aria-controls="pills2"
                        aria-selected="false">Step 2
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="pills3-tab" data-toggle="pill"
                        data-target="#pills3" type="button" role="tab" aria-controls="pills3"
                        aria-selected="false">Step 3
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="pills3-tab" (click)="save()">Conferma</button>
              </li>
            </ul>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>

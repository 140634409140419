<section>
  <div class="container-fluid">
    <div class="row row-eq-height height-100vh">
      <div class="col-md-8 p-0">
          <div class="login-form login-ab">
            <form [formGroup]="loginForm" (submit)="login()">
                  <img src="/assets/img/SellUp-Logo-Black.png" alt="" width="150" />
                  <div class="form-group">
                      <i class="fas fa-envelope-open-text"></i>
                    <input  id="email" type="email" class="form-control" formControlName="email" Placeholder="Email" required autofocus>
                  </div>
                  <div class="form-group">
                      <i class="fas fa-unlock-alt"></i>
                      <input id="password" type="password" class="form-control " formControlName="password" placeholder="Password" required>
                  </div>
                  <div class="custom-control custom-control-alternative custom-checkbox">
                      <input class="custom-control-input" type="checkbox" name="remember" id="customCheckLogin">
                      <label class="custom-control-label" for="customCheckLogin">
                          <span class="text-muted">Ricordami</span>
                      </label>
                  </div>
                  <div class="text-center">
                      <button type="submit" [disabled]="!loginForm.valid" class="btn accedi">Accedi</button>
                  </div>
                  <div class="text-center">
                    <button (click)="resetPwd()" style="cursor: pointer;border: none; text-align: center; margin-bottom: 20px; padding: 10px 40px; border-radius: 5px;">Password dimenticata?</button>
                  </div>
            </form>
          </div>
                   <a (click)="goToRegistrazione()" style="cursor:pointer"  class="crea-account">Crea account</a>


      </div>
        <div class="col-md-4 p-0 xs-display-none">
            <div class="img-intro" style="background: url('../../../assets/img/login-img.jpg');">
            </div>
        </div>
    </div>
  </div>
</section>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-base-layout',
  template: `
  <app-sidebar></app-sidebar>
  <div class="main-content" id="panel">
    <app-header></app-header>
    <section>
      <div class="container-fluid">
        <router-outlet></router-outlet>
      </div>
    </section>
    <app-footer></app-footer>
  </div>
  `,
  styles: [
  ]
})
export class BaseLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<div id="filters" class="button-mag" style="margin-top: 20px !important;">
  <form action="" id="formOrdiniConfermati">
    <input type="hidden" name="export" value="0">
    <div class="row">
      <div class="col-md-3 pr-0">
        <div class="custom-checkbox">
          <input type="text" class="form-control" name="numero"
                 [(ngModel)]="filterObj.numero" placeholder="N. ORDINE">
        </div>
      </div>
      <div class="col-md-3 pr-0">
        <input type="date" class="form-control" name="data"
               [(ngModel)]="filterObj.data"
               placeholder="Data">
      </div>
      <ng-container *ngIf="type == 0">
        <div class="col-md-3 pr-0">
          <select id="stati" multiple="multiple" placeholder="Stato" required [(ngModel)]="filterObj.stati"
                  class="form-control select2-stati" name="stati[]"
                  data-live-search="true">
            <option value="">STATO</option>
            <option value="CONFERMATO">CONFERMATO</option>
            <option value="RIFIUTATO">RIFIUTATO</option>
            <option value="SPEDITO">SPEDITO</option>
          </select>
        </div>
      </ng-container>
      <ng-container *ngIf="roleUserLogged == 'BRAND'">
        <div class="col-md-3">
          <select id="retailer" multiple="multiple" placeholder="Stato" required
                  [(ngModel)]="filterObj.retailer"
                  class="form-control select2-retailer" name="retailer[]"
                  data-live-search="true">
            <ng-container *ngFor="let item of listRetailer">
              <option value="">CLIENTE</option>
              <option value="{{item.id}}">{{item.ragione_sociale}}</option>
            </ng-container>
          </select>
        </div>
      </ng-container>
    </div>
    <div class="row mb-3">
      <div class="col-md-12">
        <div class="button-flex classe-brand odini-flex">
          <button type="button" title="Cerca" (click)="filter()"
                  class="btn btn1">
            <i class="fas fa-search"></i> Cerca
          </button>
          <button type="button" title="Resetta" (click)="reset()"
                  class="btn btn2">
            <i class="fas fa-times"></i> Resetta
          </button>
        </div>
      </div>

    </div>


  </form>
</div>


<div class="box boxBorder" id="containerListaOrdiniConfermati">
  <div class="table-responsive">
    <table id="table" class="table table-striped bg-white">
      <thead>
      <tr>
        <th><b>N. Ordine</b></th>
        <th><b>Data</b></th>
        <ng-container *ngIf="type != 2">
          <th><b>Cliente</b></th>
        </ng-container>
        <ng-container *ngIf="type == 2">
          <th><b>venditore</b></th>
        </ng-container>
        <th><b>Tot.Articoli</b></th>
        <th><b>Importo</b></th>
        <th><b>Stato</b></th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngIf="list.length > 0">
        <tr *ngFor="let order of list; let indexOfelement=index;">
          <td><p>{{order.numero}}</p></td>
          <td><p>{{utilitiesService.getFormatDateTime(order.created_at)}}</td>
          <ng-container *ngIf="type != 2">
            <td><p>{{order.cliente}}</p></td>
          </ng-container>
          <ng-container *ngIf="type == 2">
            <td><p>{{order.fornitore}}</p></td>
          </ng-container>

          <td><p>{{order.quantita}}</p></td>
          <td>
            <p> {{order.prezzo.toFixed(2)}} €</p>
          </td>
          <td><p [class]="order.status">{{order.status}}</p></td>

          <td>

            <i style="cursor:pointer;color:green" class="fi fi-rr-file-excel"></i>
            <i style="cursor:pointer;color:red" class="fi fi-rr-file-pdf"></i>
            <a routerLink="/order-detail/{{order.id}}">
              <i style="cursor:pointer" class="fi fi-rr-angle-circle-right"></i></a>


          </td>
        </tr>
      </ng-container>
      <ng-container *ngIf="list.length == 0">
        <tr>
          <td colspan="7" style="text-align:center">NESSUN
            ORDINE
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </div>
</div>



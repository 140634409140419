<div class="classe-retailer">
  <div class="prod-mag">
    <!-- DATI ARTICOLO PARENT -->
    <div class="first-level">
      <p>
        <span class="giac classe-retailer">TOT: {{item.quantita}} - {{item.articolo.nome}}</span>
      </p>
      <div class="info-prod classe-retailer">
        <span class="type"><i class="fas fa-heart"></i> {{item.brand_nome}}</span>
        <span class="type"><i class="fas fa-barcode"></i> {{item.codice_articolo}}</span>
        <span class="type"><i class="fas fa-cloud-sun"></i> {{item.stagione}} </span>
        <span class="type"><i class="fas fa-bookmark"></i> {{item.collezione}}</span>
        <span class="type"><img src="/img/settore.png" width="15" alt=""/> {{item.settore}} </span>
        <span class="type"><img src="/img/categoria.png" width="15" alt=""/>{{item.categorie}} </span>
      </div>
    </div>
    <!-- END DATI ARTICOLO PARENT -->

    <!-- DATI TESSUTI -->

    <ng-container *ngFor="let tess of item.tessuti; let indexOfelement=index;">
      <div class="second-level">

        <div class="col-md-2 pl-0">
          <div class="row">
            <div class="col-md-12">
              <div class="box-level">
                <h2 class="giac classe-retailer">
                  TOT: {{ tess['quantita-tessuto']}} </h2>
                <p class="mt-3"><i class="fas fa-tshirt classe-retailer"></i>
                  {{ tess['tessuto-codice']}}
                  <br>
                  - {{ tess['tessuto-descrizione'] }}
                </p>
                <p>

                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- COLORE -->
        <div class="col-md-10">
          <ng-container *ngFor="let color of tess.colori;">
            <div class="row contenitore-var">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6 xs-p-0">
                    <div class="box-level">

                      <ng-container *ngIf="item.media_path != ''">
                        <a href="{{item.media_path}}" data-title="">
                          <img id="img-{{item.id}}"
                               src="{{item.media_path}}">
                        </a>
                      </ng-container>
                      <ng-container *ngIf="item.media_path == ''">
                        <img src="/img/team-4.jpg" width="100%"/>
                      </ng-container>


                      <div class="info-col">
                        <h2 class="giac float-left classe-retailer">
                          TOT: {{ color['quantita-tessuto-colore']}}</h2>

                        <p class="magg-dett text-white classe-retailer">
                          <a data-toggle="collapse"
                             href="{{'#collapseExample-' + item.articolo.id}}" role="button"
                             aria-expanded="false" aria-controls="collapseExample">
                            Espandi <i class="fi fi-rr-angle-small-down"></i>
                          </a>
                        </p>

                        <!-- CODICE COLORE -->
                        <p class="colore"><i
                          class="fi fi-rr-palette"></i>{{ color['colore-codice'] != "-" ? color['colore-codice'] : "N/D" }}
                          {{ color['colore-descrizione'] != "-" ? color['colore-descrizione'] : "" }}
                          - {{ color['colore-descrizione'] != "-" ? color['colore-descrizione'] : "N/D" }}
                        </p>


                        <!-- PREZZO -->
                        <p class="tooltipp colore">

                          <!-- brand -->
                          <i class="fi fi-rr-wallet"></i>
                          <span class="">
                            {{ item['prezzo_vendita_dettaglio'] != "" && item['prezzo_vendita_dettaglio'] != undefined ? +item['prezzo_vendita_dettaglio'].toFixed(2) : 0 }}
                            €</span>
                          {{ item['prezzo_scontato'] != "" && item['prezzo_scontato'] != undefined ? +item['prezzo_scontato'].toFixed(2) : 0 }}
                          €

                          <span class="tooltiptext tooltip-top">
                                                                    <em><b>Prezzo all'ingrosso: </b></em>
                            {{ item['prezzo_vendita_ingrosso'] != "" && item['prezzo_vendita_ingrosso'] != undefined ? +item['prezzo_vendita_ingrosso'].toFixed(2) : 0 }}
                            €
                                                                    <br>
                                                                    <em><b>Ricarico:</b>
                                                                      {{ item['ricarico'] != "" ? item['ricarico'] : ' - ' }}
                                                                    </em>
                                                                </span>

                        </p>


                      </div>

                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="bottoni-dettagli">
                      <a class="btn btn1"
                         href="/article-detail?id_articolo={{item.articolo.id}}&id_tessuto={{tess['id_tessuto']}}&id_colore={{color['id_colore']}}">
                        Dettagli
                      </a>
                      <a class="btn btn2" routerLink="/store">
                        Acquista
                      </a>
                    </div>
                  </div>
                  <div class="condivisoR2W" id="condiviso-" style="display:none">
                    <span><i class="far fa-smile-wink"></i>Condiviso</span>
                  </div>


                  <div class="collapse col-md-12" id="collapseExample-{{item.articolo.id}}">
                    <div class="table-responsive">
                      <table
                        class="table table-striped bg-white table-elenco-r">
                        <thead>
                        <!-- TAGLIE -->
                        <tr>
                          <th scope="row" style="width: 20%;">Taglia</th>
                          <th scope="col">{{item.taglia}}</th>
                          <th style="width: 1%;">
                            <i style="cursor:pointer" title="Modifica Giacenze" class="fa fa-lg fa-edit"></i>
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td scope="row">Q.TA Magazzino</td>
                          <td scope="col">
                            <div class="print-{{ item['id'] + ' ' +  color['id_colore'] }}">
                              {{item['quantita']}}
                            </div>
                            <div style="display:none"
                                 class="mod-{{ item['id'] + ' ' +  color['id_colore'] }}">
                              <input type="number" step="1" min="0" old-value="" value="" style="width:80px"
                                     class="form-control">
                            </div>

                          </td>
                          <td rowspan="2">
                            <div style="display:none" class="conferma-mond">
                              <i class="fa fa-lg fa-times-circle" data-toggle="tooltip" title="Annulla"
                                 style="cursor:pointer;color:var(--4)"></i>
                              <i class="fa fa-lg fa-check-circle" data-toggle="tooltip" title="Conferma"
                                 style="cursor:pointer;color:var(--2)"></i>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td scope="row">Q.TA Condivisa</td>

                          <td scope="col">
                            <div>
                              {{item['quantita_condivisa']}}
                            </div>
                            <div style="display:none">
                              <input type="number" step="1" min="0" old-value="">
                            </div>
                          </td>

                        </tr>

                        </tbody>
                      </table>
                    </div>
                  </div>


                </div>

              </div>
            </div>
          </ng-container>
        </div>
        <!-- END COLORE -->


      </div>
    </ng-container>
    <!-- END DATI TESSUTI -->

  </div>
</div>










  <section>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div *ngIf="loading">
            <div class="modal-dialog modal-sm">
              <div class="modal-content">
                <div class="modal-body" style="text-align: center;">
                  <div class="loader" style="margin-left: 30%;"></div>
                  <br>
                  <h5>Caricamento dati in corso...</h5>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!loading" class="card card-colored overflow-hidden classe-retailer">
            <h1 class="text-uppercase text-white border-bottom-ab">Creazione articolo</h1>
            <div class="container-box">
              <div class="box">


                <div class="wrapper row">
                  <div class="preview col-md-5">
                    <div class="main-img">
                        <a href="#"
                          data-lightbox="image-1" data-title="">
                          <img src="/img/placeholder-articolo.jpg">
                      </a>
                    </div>
                  </div>
                  <div class="details col-md-7 classe-retailer">
                    <div class="form-group row">
                      <label for="brand" class="col-sm-2 col-form-label"><b>Brand:</b></label>
                      <div class="col-sm-10">
                        <select class="form-control form-control-sm" id="brand" [(ngModel)]="article.id_brand">
                          <option *ngFor="let brand of filters.brand" [value]="brand.id">{{brand.nome}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="codice_articolo" class="col-sm-2 col-form-label"><b>Codice Articolo:</b></label>
                      <div class="col-sm-10">
                        <input type="text" class="form-control form-control-sm" id="codice_articolo" [(ngModel)]="article.codice_articolo" />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="nome_articolo" class="col-sm-2 col-form-label"><b>Nome:</b></label>
                      <div class="col-sm-10">
                        <input type="text" class="form-control form-control-sm" id="nome_articolo" [(ngModel)]="article.nome_articolo" />
                      </div>
                    </div>


                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 mt-4 dettagli-arti classe-retailer">
                    <div class="form-group row">
                      <label for="stagione" class="col-sm-2 col-form-label"><b>Stagione:</b></label>
                      <div class="col-sm-5">
                        <select class="form-control form-control-sm" id="stagione" [(ngModel)]="article.stagione" [disabled]="article.stagione_add || '' != ''">
                          <option *ngFor="let stagione of filters.stagioni" [value]="stagione.nome">{{stagione.nome}}</option>
                        </select>
                      </div>
                      <div class="col-sm-5">
                        <input type="text" class="form-control form-control-sm" id="stagione_add" [(ngModel)]="article.stagione_add" placeholder="Aggiungi una nuova stagione"/>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="collezione" class="col-sm-2 col-form-label"><b>Collezione:</b></label>
                      <div class="col-sm-5">
                        <select class="form-control form-control-sm" id="collezione" [(ngModel)]="article.collezione" [disabled]="article.collezione_add || '' != ''">
                          <option *ngFor="let collezione of filters.collezioni" [value]="collezione.nome">{{collezione.nome}}</option>
                        </select>
                      </div>
                      <div class="col-sm-5">
                        <input type="text" class="form-control form-control-sm" id="collezione_add" [(ngModel)]="article.collezione_add" placeholder="Aggiungi una nuova collezione"/>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="settore" class="col-sm-2 col-form-label"><b>Settore:</b></label>
                      <div class="col-sm-5">
                        <select class="form-control form-control-sm" id="settore" [(ngModel)]="article.settore" [disabled]="article.settore_add || '' != ''">
                          <option *ngFor="let settore of filters.settori" [value]="settore.nome">{{settore.nome}}</option>
                        </select>
                      </div>
                      <div class="col-sm-5">
                        <input type="text" class="form-control form-control-sm" id="settore_add" [(ngModel)]="article.settore_add" placeholder="Aggiungi un nuovo settore"/>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="categoria" class="col-sm-2 col-form-label"><b>Categoria:</b></label>
                      <div class="col-sm-5">
                        <select class="form-control form-control-sm" id="categoria" [(ngModel)]="article.categoria" [disabled]="article.categoria_add || '' != ''">
                          <option *ngFor="let categoria of filters.categorie" [value]="categoria.nome">{{categoria.nome}}</option>
                        </select>
                      </div>
                      <div class="col-sm-5">
                        <input type="text" class="form-control form-control-sm" id="categoria_add" [(ngModel)]="article.categoria_add" placeholder="Aggiungi una nuova categoria"/>
                      </div>
                    </div>
                    <hr>
                    <div class="form-group row">
                      <label for="taglieTipologie" class="col-sm-2 col-form-label"><b>Scala taglie:</b></label>
                      <div class="col-sm-10">
                        <select class="form-control form-control-sm" id="taglieTipologie" [(ngModel)]="article.scala_taglie">
                          <option *ngFor="let scala_taglie of filters.taglieTipologie" [value]="scala_taglie.nome">{{scala_taglie.nome}}</option>
                        </select>
                      </div>
                    </div>
                    <hr>
                    <p>
                      <b>Variante Tessuto: </b>
                    </p>
                    <div class="form-group row">
                      <div class="col-sm-4">
                        <select class="form-control form-control-sm" id="tessuto" [(ngModel)]="article.codice_tessuto" [disabled]="checkTessuto()" (change)="loadDescrizioneTessuto()">
                          <option *ngFor="let tessuto of filters.tessuti" [value]="tessuto.codice">{{tessuto.nome}}</option>
                        </select>
                      </div>
                      <div class="col-sm-4">
                        <input type="text" class="form-control form-control-sm" id="codice_tessuto_add" [(ngModel)]="article.codice_tessuto_add" placeholder="Aggiungi un nuovo codice tessuto"/>
                      </div>
                      <div class="col-sm-4">
                        <input type="text" class="form-control form-control-sm" id="descrizione_tessuto_add" [(ngModel)]="article.descrizione_tessuto_add" placeholder="Aggiungi un nuovo nome tessuto"/>
                      </div>
                    </div>
                    <hr>
                    <p>
                      <b>Variante Colore:</b>
                    </p>
                    <div class="form-group row">
                      <div class="col-sm-4">
                        <select class="form-control form-control-sm" id="colore" [(ngModel)]="article.codice_colore" [disabled]="checkColore()" (change)="loadDescrizioneColore()">
                          <option *ngFor="let colore of filters.colori" [value]="colore.codice">{{colore.nome}}</option>
                        </select>
                      </div>
                      <div class="col-sm-4">
                        <input type="text" class="form-control form-control-sm" id="codice_colore_add" [(ngModel)]="article.codice_colore_add" placeholder="Aggiungi un nuovo codice colore"/>
                      </div>
                      <div class="col-sm-4">
                        <input type="text" class="form-control form-control-sm" id="descrizione_colore_add" [(ngModel)]="article.descrizione_colore_add" placeholder="Aggiungi un nuovo nome colore"/>
                      </div>
                    </div>
                    <hr>

                    <!-- PREZZO -->
                    <div class="form-group row">
                      <label for="prezzo_ingrosso" class="col-sm-2 col-form-label"><b>Prezzo ingrosso:</b></label>
                      <div class="col-sm-4">
                        <input type="text" class="form-control form-control-sm" id="prezzo_ingrosso" [(ngModel)]="article.prezzo_ingrosso"/>
                      </div>
                      <label for="prezzo_dettaglio" class="col-sm-2 col-form-label"><b>Prezzo dettaglio consigliato:</b></label>
                      <div class="col-sm-4">
                        <input type="text" class="form-control form-control-sm" id="prezzo_dettaglio" [(ngModel)]="article.prezzo_dettaglio"/>
                      </div>
                    </div>
                    <!-- END PREZZO -->

                    <hr>
                    <p>
                      <b>Descrizione Breve</b>
                      <textarea class="form-control" rows="2" [(ngModel)]="article.descrizione_breve"></textarea>
                    </p>
                    <p>
                      <b>Descrizione Completa</b>
                      <textarea class="form-control" rows="5" [(ngModel)]="article.descrizione_estesa"></textarea>
                    </p>
                    <hr>
                  </div>
                </div>
              </div>


              <div class="row">
                <div class="col-md-12" style="text-align:center">
                  <button data-toggle="tooltip" title="Salva Articolo Variante" class="btn btn-primary"
                          type="button" (click)="save(true)">SALVA E VAI ALL'ARTICOLO
                  </button>
                  <button data-toggle="tooltip" title="Salva Articolo Variante" class="btn btn-primary ml-2"
                  type="button" (click)="save(false)">SALVA E AGGIUNGI NUOVO
                  </button>
                </div>
              </div>


            </div>
          </div>


        </div>
      </div>
    </div>
  </section>


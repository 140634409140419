import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'app/models/user';
import { UserService } from 'app/services/user.service';

@Component({
  selector: 'app-sign-up-retailer',
  templateUrl: './sign-up-retailer.component.html',
  styleUrls: ['./sign-up-retailer.component.sass']
})
export class SignUpRetailerComponent implements OnInit {

  public user : User = new User();
  public accept:boolean;

  constructor(private route: Router, private userService: UserService) { 
    this.accept = false;
  }

  ngOnInit(): void {
  }

  register(){
    if(this.user.nome_organizzazione != "" && this.user.nome != "" && this.user.cognome != "" &&  this.user.email != "" && this.user.password != "")
    {
        this.userService.createUserRetailer(this.user).subscribe(resp => {
          this.route.navigate(["/"]);
        },
        (error) => {
          console.log(error);
          alert('Errore: ' + error.toString())
        });
    }
    else
    alert('Campi obbligatori mancanti!');
  }

}

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'app/auth/auth.service';
import { User } from 'app/models/user';
import { BrandService } from 'app/services/brand.service';
import { CompanyService } from 'app/services/company.service';
import { ShopService } from 'app/services/shop.service';
import { StoreService } from 'app/services/store.service';
import { UserService } from 'app/services/user.service';
import { UtilitiesService } from 'app/services/utilities.service';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.sass']
})
export class ProfileComponent implements OnInit {

  public user : any;
  public infoUser : any = {};
  public usersList: any[] = [];
  public rolesList: any[] = [];
  public companiesList: any[] = [];
  public storesList: any[] = [];
  public shopsList: any[] = [];
  public brandsList: any[] = [];
  roleUserLogged :string |undefined;
  public shopSelected : any = {};
  public userSelected : User = new User();
  public companySelected : any = {};
  public storeSelected : any = {};
  public brandSelected : any = {};
  public password_attuale :string |undefined;
  public nuova_password :string |undefined;
  public conferma_nuova_password :string |undefined;
  public modalIsVisible :boolean = false;

  constructor(private auth: AuthService, private dialog: MatDialog,private utilitiesService: UtilitiesService,
    private userService: UserService, private companyService: CompanyService, private storeService: StoreService,
    private shopService: ShopService,  private brandService: BrandService) { this.modalIsVisible = true;}

  ngOnInit(): void {
    const user = this.auth.getCurrentUser();
    this.roleUserLogged = user?.tipo;
    this.user = user;
    this.loaInfoUser();
    this.loadUsers();
    this.loadAziende();
    this.loadStores();
    if(this.roleUserLogged == 'RETAILER')
      this.loadShops();
    if(this.roleUserLogged == 'BRAND')
      this.loadBrands();
  }

  changeImgProfile(){}

  changePwd(){}

  loaInfoUser() {
    if(this.roleUserLogged == 'RETAILER'){
      this.userService.getInfoRetailer(this.user.id).then((res:any)  => {
        console.log('INFO RETAILER: ', res['data'].user);
        this.infoUser = !res ? [] : res['data'].user
      })
    }
    else{
      this.userService.getInfoBrand(this.user.id).then((res:any)  => {
        console.log('INFO BRAND: ', res['data'].user);
        this.infoUser = !res ? [] : res['data'].user
      })
    }
  }


  loadUsers() {
    this.utilitiesService.getListUsersByOrganizzazione(this.user.id_organizzazione).then((res:any)  => {
      console.log('Lista UTENTE: ', res['data'].utenti);
      console.log('Lista RUOLI: ', res['data'].ruoli);
      this.usersList = !res ? [] : res['data'].utenti;
      this.rolesList = !res ? [] : res['data'].ruoli;
    })
  }

  async loadUsersAsync() {
    const res:any = await this.utilitiesService.getListUsersByOrganizzazione(this.user.id_organizzazione);
    console.log('Lista UTENTE: ', res['data'].utenti);
    console.log('Lista RUOLI: ', res['data'].ruoli);
    this.usersList = !res ? [] : res['data'].utenti;
    this.rolesList = !res ? [] : res['data'].ruoli;
  }

  loadAziende() {
    this.utilitiesService.getListCompaniesByOrganizzazione(this.user.id_organizzazione).then((res:any) => {
      console.log('Lista AZIENDE: ', res['data'].aziende);
      this.companiesList = !res ? [] : res['data'].aziende
    })
  }

  async loadAziendeAsync() {
    const res:any = await this.utilitiesService.getListCompaniesByOrganizzazione(this.user.id_organizzazione);
    console.log('Lista AZIENDE: ', res['data'].aziende);
    this.companiesList = !res ? [] : res['data'].aziende
  }

  loadStores() {
    this.utilitiesService.getListStoresByOrganizzazione(this.user.id_organizzazione).then((res:any) => {
      console.log('Lista MAGAZZINI: ', res['data'].magazzini);
      this.storesList = !res ? [] : res['data'].magazzini
    })
  }

  async loadStoresAsync () {
    const res: any = await this.utilitiesService.getListStoresByOrganizzazione(this.user.id_organizzazione);
    console.log('Lista MAGAZZINI: ', res['data'].magazzini);
    this.storesList = !res ? [] : res['data'].magazzini
  }


  loadShops() {
    this.utilitiesService.getListShopsByOrganizzazione(this.user.id_organizzazione).then((res:any) => {
      console.log('Lista NEGOZI: ', res['data'].negozi);
      this.shopsList = !res ? [] : res['data'].negozi;
      this.modalIsVisible = false;
    })
  }

  async loadShopsAsync() {
    const res: any = await this.utilitiesService.getListShopsByOrganizzazione(this.user.id_organizzazione)
    console.log('Lista NEGOZI: ', res['data'].negozi);
    this.shopsList = !res ? [] : res['data'].negozi;
    this.modalIsVisible = false;
  }

  loadBrands() {
    this.utilitiesService.getListBrandsByOrganizzazione(this.user.id_organizzazione).then((res:any) => {
      console.log('Lista BRAND: ', res['data'].brands);
      this.brandsList = !res ? [] : res['data'].brands;
      this.modalIsVisible = false;
    })
  }

  async loadBrandsAsync() {
    const res: any = await this.utilitiesService.getListBrandsByOrganizzazione(this.user.id_organizzazione);
    console.log('Lista BRAND: ', res['data'].brands);
    this.brandsList = !res ? [] : res['data'].brands;
    this.modalIsVisible = false;
  }

  loadDataBrandSelected(row:any){
    this.brandSelected = row != null ? row : {};
  }

  loadDataShopSelected(row:any){
    this.shopSelected = row != null ? row : {};
  }

  loadDataUserSelected(row:any){
    this.userSelected = row != null ? row : {};
  }


  loadDataCompanySelected(row:any){
    this.companySelected =  row != null ? row : {};
  }

  loadDataStoreSelected(row:any){
    this.storeSelected =  row != null ? row : {};
  }


  //AREA UTENTI

  createUser() {
    const inputs = document.querySelectorAll('#modale-aggiungi-utente input');
    const utente: any = {}
    console.log(inputs)
    for (const input of inputs) {
      const _input = (input as HTMLInputElement);
      //brand['id_brand'] = this.shopSelected.id
      utente[input.id] = _input.value
    }
    utente.ruoli = [(document.querySelector('#aggiungi-ruoli') as HTMLSelectElement).value]
    console.log(utente);
    this.userService.createUser(utente).subscribe(async resp => {
      console.log(resp);
      await this.loadUsersAsync();
      // @ts-expect-error
      $('#modale-aggiungi-utente').modal('hide');
    },
    (error) => {
      console.log(error);
      alert(`Errore nella creazione dell'utente: ${error.toString()}`)
      this.loadUsers();
      // @ts-expect-error
      $('#modale-aggiungi-utente').modal('hide');
    });
  }
  updateUser() {
    const utente = {
      id_user: this.userSelected.id,
      nome_utente: this.userSelected.nome,
      cognome_utente: this.userSelected.cognome,
      email_utente: this.userSelected.email,
      ruoli: [(document.querySelector('#modifica-ruoli') as HTMLSelectElement).value],
    }
    this.userService.updateUser(utente)
    .subscribe(async resp => {
      if (resp.status = 200) {
        await this.loadUsersAsync();
        // @ts-expect-error
        $('#modale-modifica-utente').modal('hide');
      }
    },
    (error) => {
      console.log(error);
      alert(`Errore nella modifica dell'utente: ${error.toString()}`)
      this.loadUsers();
      // @ts-expect-error
      $('#modale-modifica-utente').modal('hide');
    });
  }
  deleteUser(){
    const user = {
      id_utente: this.userSelected.id
    }

    this.userService.deleteUser(user)
      .subscribe(resp => {
        if (resp.status = 200) {
          location.reload();
        }
      },
      (error) => {
        console.log(error);
        alert(`Errore nella rimozione dell'utente: ${error.toString()}`)
      });
  }


  //AREA AZIENDE

  createCompany() {
    const inputs = document.querySelectorAll('#modale-aggiungi-azienda input');
    const azienda: any = {}
    console.log(inputs)
    for (const input of inputs) {
      const _input = (input as HTMLInputElement);
      //brand['id_brand'] = this.shopSelected.id
      azienda[input.id] = _input.value
    }
    console.log(azienda);
    this.companyService.createCompany(azienda).subscribe(async resp => {
      console.log(resp);
      await this.loadAziendeAsync();
      // @ts-expect-error
      $('#modale-aggiungi-azienda').modal('hide');
    },
    (error) => {
      console.log(error);
      alert(`Errore nella creazione dell'azienda: ${error.toString()}`)
    });
  }

  updateCompany() {
    const inputs = document.querySelectorAll('#modale-modifica-azienda input');
    const azienda: any = {}
    console.log(inputs)
    for (const input of inputs) {
      const _input = (input as HTMLInputElement);
      //brand['id_brand'] = this.shopSelected.id
      azienda[input.id] = _input.value
    }
    console.log(azienda);
    this.companyService.updateCompany(azienda).subscribe(async resp => {
      console.log(resp);
      //await this.loadAziendeAsync();
      // @ts-expect-error
      $('#modale-modifica-azienda').modal('hide');
    },
    (error) => {
      console.log(error);
      alert(`Errore nella creazione dell'azienda: ${error.toString()}`)
    });
  }

  updateTermini() {
    console.log(this.companySelected);
    this.companyService.updateCompanyCondizioni(this.companySelected).subscribe(async resp => {
      console.log(resp);
      // @ts-expect-error
      $('#modale-modifica-termini').modal('hide');
    },
    (error) => {
      console.log(error);
      alert(`Errore nella modifica dei termini: ${error.toString()}`)
    });
  }

  deleteCompany(){
    const company = {
      id_azienda: this.companySelected.id
    }

    this.companyService.deleteCompany(company)
      .subscribe(resp => {
        if (resp.status = 200) {
          location.reload();
        }
      },
      (error) => {
        console.log(error);
        alert(`Errore nella rimozione del brand dall'agente: ${error.toString()}`)
      });
  }


  //AREA MAGAZZINI
  createStore() {
    const inputs = document.querySelectorAll('#modale-aggiungi-magazzino input');
    const magazzino: any = {}
    console.log(inputs)
    for (const input of inputs) {
      const _input = (input as HTMLInputElement);
      //brand['id_brand'] = this.shopSelected.id
      magazzino[input.id] = _input.value
    }
    console.log(magazzino);
    this.storeService.createStore(magazzino).subscribe(async resp => {
      console.log(resp);
      await this.loadStoresAsync();
      // @ts-expect-error
      $('#modale-aggiungi-magazzino').modal('hide');
    },
    (error) => {
      console.log(error);
      alert(`Errore nella creazione del magazzino: ${error.toString()}`)
      this.loadStores();
      // @ts-expect-error
      $('#modale-aggiungi-magazzino').modal('hide');
    });
  }

  updateStore() {
    const inputs = document.querySelectorAll('#modale-modifica-magazzino input');
    const magazzino: any = {}
    console.log(inputs)
    for (const input of inputs) {
      const _input = (input as HTMLInputElement);
      //brand['id_brand'] = this.shopSelected.id
      magazzino[input.id] = _input.value
    }
    console.log(magazzino);
    this.storeService.updateStore(magazzino).subscribe(async resp => {
      console.log(resp);
      //await this.loadStoresAsync();
      // @ts-expect-error
      $('#modale-modifica-magazzino').modal('hide');
    },
    (error) => {
      console.log(error);
      alert(`Errore nella modifica del magazzino: ${error.toString()}`)
      this.loadStores();
      // @ts-expect-error
      $('#modale-aggiungi-magazzino').modal('hide');
    });
  }

  changeStoreState(store: any) {
    console.log(store.id, store.stato)
    const request = {"id_magazzino" : store.id, "stato_magazzino" : Math.abs(parseInt(store.stato) - 1)}
    this.storeService.updateStoreState(request).subscribe(resp => {
      console.log(resp);
      this.loadStores();
    })
  }

  deleteStore() {
    const store = {
      id_magazzino: this.storeSelected.id
    }

    this.storeService.deleteStore(store)
      .subscribe(resp => {
        if (resp.status = 200) {
          location.reload();
        }
      },
      (error) => {
        console.log(error);
        alert(`Errore nella rimozione del magazzino: ${error.toString()}`)
      });
  }


  //AREA NEGOZI

  deleteShop(){
    const store = {
      id_negozio: this.shopSelected.id
    }

    this.shopService.deleteShop(store)
      .subscribe(resp => {
        if (resp.status = 200) {
          location.reload();
        }
      },
      (error) => {
        console.log(error);
        alert(`Errore nella rimozione del brand dall'agente: ${error.toString()}`)
      });
  }


  createShop() {
    const inputs = document.querySelectorAll('#modale-aggiungi-negozio input');
    const negozio: any = {}
    console.log(inputs)
    for (const input of inputs) {
      const _input = (input as HTMLInputElement);
      //brand['id_brand'] = this.shopSelected.id
      negozio[input.id] = _input.value
    }
    console.log(negozio);
    this.shopService.createShop(negozio).subscribe(async resp => {
      console.log(resp);
      await this.loadShopsAsync();
      // @ts-expect-error
      $('#modale-aggiungi-negozio').modal('hide');
    },
    (error) => {
      console.log(error);
      alert(`Errore nella creazione del negozio: ${error.toString()}`)
    });
  }

  updateShop() {
    const inputs = document.querySelectorAll('#modale-modifica-negozio input');
    const negozio: any = {}
    console.log(inputs)
    for (const input of inputs) {
      const _input = (input as HTMLInputElement);
      //brand['id_brand'] = this.shopSelected.id
      negozio[input.id] = _input.value
    }
    console.log(negozio);
    this.shopService.updateShop(negozio).subscribe(async resp => {
      console.log(resp);
      //await this.loadShopsAsync();
      // @ts-expect-error
      $('#modale-modifica-negozio').modal('hide');
    },
    (error) => {
      console.log(error);
      alert(`Errore nella modifica del negozio: ${error.toString()}`)
    });
  }

  updateShopSocial() {
    const inputs = document.querySelectorAll('#modale-social-negozio input');
    const negozio: any = {}
    console.log(inputs)
    for (const input of inputs) {
      const _input = (input as HTMLInputElement);
      //brand['id_brand'] = this.shopSelected.id
      negozio[input.id] = _input.value
    }
    console.log(negozio);
    this.shopService.updateShopSocial(negozio).subscribe(resp => {
      console.log(resp);
      this.loadShops();
      // @ts-expect-error
      $('#modale-social-negozio').modal('hide');
    })
  }

  changeShopState(shop: any) {
    console.log(shop.id, shop.stato)
    const request = {"id_negozio" : shop.id, "stato_negozio" : Math.abs(parseInt(shop.stato) - 1)}
    this.shopService.updateStatusShop(request).subscribe(async resp => {
      console.log(resp);
      await this.loadShopsAsync();
    })
  }

  //AREA BRAND

  deleteBrand(){
    const brand = {
      id_brand: this.shopSelected.id
    }

    this.brandService.deleteBrand(brand)
      .subscribe(resp => {
        if (resp.status = 200) {
          location.reload();
        }
      },
      (error) => {
        console.log(error);
        alert(`Errore nella rimozione del brand: ${error.toString()}`)
      });
  }


  createBrand() {
    const inputs = document.querySelectorAll('#modale-aggiungi-brand input');
    const brand: any = {}
    console.log(inputs)
    for (const input of inputs) {
      const _input = (input as HTMLInputElement);
      //brand['id_brand'] = this.shopSelected.id
      brand[input.id] = _input.value
    }
    console.log(brand);
    this.brandService.createBrand(brand).subscribe(async resp => {
      console.log(resp);
      await this.loadBrandsAsync();
      // @ts-expect-error
      $('#modale-aggiungi-brand').modal('hide');
    },
    (error) => {
      console.log(error);
      alert(`Errore nella creazione del brand: ${error.toString()}`)
    });
  }

  updateBrand() {
    const inputs = document.querySelectorAll('#modale-modifica-brand input');
    const brand: any = {}
    console.log(inputs)
    for (const input of inputs) {
      const _input = (input as HTMLInputElement);
      //brand['id_brand'] = this.shopSelected.id
      brand[input.id] = _input.value
    }
    console.log(brand);
    this.brandService.updateBrand(brand).subscribe(async resp => {
      console.log(resp);
      //await this.loadBrandsAsync();
      // @ts-expect-error
      $('#modale-modifica-brand').modal('hide');
    },
    (error) => {
      console.log(error);
      alert(`Errore nella modifica del brand: ${error.toString()}`)
    });
  }

  updateSocialBrand() {
    const inputs = document.querySelectorAll('#modale-social-brand input');
    const brand: any = {}
    console.log(inputs)
    for (const input of inputs) {
      const _input = (input as HTMLInputElement);
      //brand['id_brand'] = this.shopSelected.id
      brand[input.id] = _input.value
    }
    console.log(brand);
    this.brandService.updateSocialBrand(brand).subscribe(resp => {
      console.log(resp);
      this.loadBrands();
      // @ts-expect-error
      $('#modale-social-brand').modal('hide');
    })
  }

  importAddress(entity: string, address: any) {
    console.log(address, entity);
    (document.querySelector('#place_id_'+entity) as HTMLInputElement).value = address.place_id;
    (document.querySelector('#formatted_address_'+entity) as HTMLInputElement).value = address.formatted_address;
    (document.querySelector('#latitude_'+entity) as HTMLInputElement).value = address.latitudine;
    (document.querySelector('#longitude_'+entity) as HTMLInputElement).value = address.longitudine;
    (document.querySelector('#cap_'+entity) as HTMLInputElement).value = address.cap;
    (document.querySelector('#indirizzo_'+entity) as HTMLInputElement).value = address.terms[0].value ?? "";
    (document.querySelector('#citta_'+entity) as HTMLInputElement).value = address.terms[1].value ?? "";
    (document.querySelector('#provincia_'+entity) as HTMLInputElement).value = address.terms[2].value ?? "";
    (document.querySelector('#nazione_'+entity) as HTMLInputElement).value = address.terms[3].value ?? "";
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  api: string = environment.api;
  constructor(private http: HttpClient) { }

  getListOrdersRetailer() : Promise<any[]>{
    return this.http.get<any[]>(`${this.api}/ordini/r2w/elenco`).toPromise();
  }

  getListOrdersBrand() : Promise<any[]>{
    return this.http.get<any[]>(`${this.api}/ordini/brand/elenco`).toPromise();
  }

  getOrderById(id:number, brand:boolean) : Promise<any[]>{
    const type = brand ? "&type=brand" : ""
    return this.http.get<any[]>(`${this.api}/ordine/r2w/riepilogo?id_ordine=${id}${type}`).toPromise();
  }

  //SEZIONE FILTRI
  searchConfermatiRetailer(obj: any) : Promise<any[]>{
    return this.http.get<any[]>(`${this.api}/ordini/r2w/elenco/paginate/confermati`).toPromise();
  }

  searchConfermatiBrand(obj: any) : Promise<any[]>{
    return this.http.get<any[]>(`${this.api}/ordini/brand/elenco/paginate/confermati`).toPromise();
  }


  searchDaConfermareRetailer(obj: any) : Promise<any[]>{
    return this.http.get<any[]>(`${this.api}/ordini/r2w/elenco/paginate/da-confermare`).toPromise();
  }

  searchDaConfermareBrand(obj: any) : Promise<any[]>{
    return this.http.get<any[]>(`${this.api}/ordini/brand/elenco/paginate/da-confermare`).toPromise();
  }


  searchAcquistiRetailer(obj: any) : Promise<any[]>{
    return this.http.get<any[]>(`${this.api}/ordini/r2w/elenco/paginate/acquisti`).toPromise();
  }

}

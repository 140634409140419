import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ShopService {

  api: string = environment.api;
  constructor(private http: HttpClient) { }


  createShop(shop: any): Observable<any> {
    return this.http.post<any>(this.api + "/negozi/aggiungi", shop);
  }

  updateShop(shop: any): Observable<any> {
    return this.http.post<any>(this.api + "/negozi/modifica", shop);
  }

  updateShopSocial(shop: any): Observable<any> {
    return this.http.post<any>(this.api + "/negozi/modifica/social", shop);
  }

  deleteShop(obj: any): Observable<any> {
    return this.http.post<any>(this.api + "/negozi/elimina", obj);
  }

  deleteImgShop(obj: any): Observable<any> {
    return this.http.post<any>(this.api + "/negozi/elimina/immagine", obj);
  }

  updateStatusShop(obj: any): Observable<any> {
    return this.http.post<any>(this.api + "/negozi/modifica/stato", obj);
  }
}

import { Injectable } from '@angular/core';
import { HttpInterceptor,  HttpRequest,  HttpHandler,  HttpEvent,  HttpErrorResponse} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';

@Injectable()

export class ErrorInterceptor implements HttpInterceptor {
  constructor( private authService: AuthService, private router: Router ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError((err: HttpErrorResponse) => {
      if (err.status === 401 && !(window.location.pathname == '/') && !window.location.href.includes('/registrazione')) {
          this.authService.logout();
          window.location.href = "/";
      }
      if (err.status === 403) {
        window.location.href = "/";
      }
      const error = err.error.error || err.error.message || err.statusText;
      //alert(error);
      return throwError(error);
    }));
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { AuthService } from '../auth/auth.service';


@Injectable({
  providedIn: 'root'
})
export class MagazziniService {
  private api = environment.backendPath;

  constructor(private http : HttpClient, private auth : AuthService) { }

  getMovimenti(): Observable<any> {
    let url = "";
    if (this.auth.getCurrentUser().tipo == "BRAND")
      url = "brand/";
    return this.http.get<any>(this.api+'/magazzini-movimenti/'+url);
  }

  getFilters(): Observable<any> {
    return this.http.get<any>(this.api+'/magazzini-movimenti/aggiungi');
  }

  addArticoli(filters): Observable<any> {
    return this.http.get<any>(this.api+'/magazzini-movimenti/cerca-articoli',{ params: filters})
  }

  save(movimento): Observable<any> {
    return this.http.post<any>(this.api+'/magazzini-movimenti/salva', movimento);
  }
}

<div class="row">
  <div class="col-md-12">
    <div class="card card-colored card-brand" style="overflow:visible">
      <h1 class="text-uppercase text-white border-bottom-ab">Lista agenti</h1>
      <button class="btn agg-mag" data-toggle="modal" data-target="#creazioneModal">Aggiungi</button>
      <div class="button-mag">
        <form id="filters" action="#" method="get" (submit)="filter()">
          <div class="row">
            <div class="col-md-4 pr-0">
              <input class="form-control" type="text" name="nome" value="" placeholder="Nome e cognome">
            </div>
            <div class="col-md-4 pr-0">
              <input class="form-control" type="text" name="ragione_sociale" value=""
                     placeholder="Ragione sociale">
            </div>
            <div class="col-md-4 pr-0">
              <input class="form-control" type="text" name="partita_iva" value="" placeholder="Partita iva">
            </div>
            <div class="col-md-4 pr-0">
              <input class="form-control" type="text" name="aree" value="" placeholder="Area di competenza">
            </div>
            <div class="col-md-4 pr-0">
              <input class="form-control" type="text" name="nome_showroom" value="" placeholder="Showroom">
            </div>
            <div class="col-md-4 pr-0">
              <select id="brand" multiple="multiple" required="" name="brands" data-live-search="true"
                      class="form-control select2-brand" tabindex="null">
                <option value="23">Chiara Ferragni Collection</option>
                <option value="24">ACBC</option>
                <option value="25">Alexander Hotto</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="button-flex">
                <button title="" type="button" class="btn btn1">
                  <i class="fas fa-file-excel"></i> Export
                </button>
                <button type="button" title="" class="btn btn2" (click)="deselectAll()">
                  <i class="fas fa-times"></i> Resetta
                </button>
                <button type="submit" title="" class="btn btn3" (click)="filter()">
                  <i class="fas fa-search"></i> Cerca
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div class="card">
  <div class="table-responsive">
    <table class="table table-hover table-mc-light-blue">
      <thead>
      <tr>
        <th scope="col" (click)="order($event)" data-order="nome" data-by="">Nome</th>
        <th scope="col" (click)="order($event)" data-order="ragione_sociale" data-by="">Ragione sociale</th>
        <th scope="col" (click)="order($event)" data-order="partita_iva" data-by="">P.Iva</th>
        <th scope="col" (click)="order($event)" data-order="nome_showroom" data-by="">Showroom</th>
        <th scope="col">Brand</th>
        <th scope="col">Aree di Competenza</th>
        <th scope="col">Dettagli</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let agente of agenti">
        <td scope="col" (click)="getAgente(agente.id)">{{agente.nome}} {{agente.cognome}}</td>
        <td scope="col">{{agente.ragione_sociale}}</td>
        <td scope="col">{{agente.partita_iva}}</td>
        <td scope="col">{{agente.nome_showroom}}</td>
        <td scope="col" style="overflow: hidden;text-overflow: ellipsis;max-width: 150px;"
            title="{{agente.brands}}">{{agente.brands}}</td>
        <td scope="col">{{agente.aree}}</td>
        <td scope="col">
          <div class="btn-group" role="group">
            <a routerLink="/agenti/{{agente.id}}" class="btn btn1">Dettaglio</a>
            <a class="btn btn2" (click)="delete(agente.id)">Rimuovi</a>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="modal fade" id="creazioneModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <form [formGroup]="agenteForm" (submit)="create()">
          <div class="modal-header">
            <h5 class="modal-title">Dati agente</h5>
          </div>
          <div class="modal-body">
            <h4 class="modal4">Anagrafica</h4>
            <div class="form-group">
              <label for="nome" class="form-label">Nome e cognome</label>
              <div class="d-flex" style="gap:10px">
                <input type="text" class="form-control" id="nome" formControlName="nome" placeholder="Nome">
                <input type="text" class="form-control" id="cognome" formControlName="cognome"
                       placeholder="Cognome">
              </div>
            </div>
            <div class="form-group">
              <label for="nome_showroom" class="form-label">Nome showroom</label>
              <input type="text" class="form-control" id="nome_showroom" formControlName="nome_showroom">
            </div>
            <div class="form-group">
              <label for="indirizzo_showroom" class="form-label">Indirizzo showroom</label>
              <input type="text" class="form-control" id="indirizzo_showroom"
                     formControlName="indirizzo_showroom">
            </div>
            <div class="form-group">
              <label for="email" class="form-label">Email</label>
              <input type="email" class="form-control" id="email" formControlName="email">
            </div>
            <div class="form-group">
              <label for="telefono" class="form-label">Telefono</label>
              <input type="text" class="form-control" id="telefono" formControlName="telefono">
            </div>
            <h4 class="modal4">Fatturazione</h4>
            <div class="form-group">
              <label for="ragione_sociale" class="form-label">Ragione sociale</label>
              <input type="text" class="form-control" id="ragione_sociale"
                     formControlName="ragione_sociale">
            </div>
            <div class="form-group">
              <label for="partita_iva" class="form-label">Partita iva</label>
              <input type="text" class="form-control" id="partita_iva" formControlName="partita_iva">
            </div>
            <div class="form-group">
              <label for="codice_fiscale" class="form-label">Codice Fiscale</label>
              <input type="text" class="form-control" id="codice_fiscale" formControlName="codice_fiscale">
            </div>
            <div class="form-group">
              <label for="sede_legale" class="form-label">Sede legale</label>
              <input type="text" class="form-control" id="sede_legale_indirizzo"
                     formControlName="sede_legale_indirizzo">
            </div>
            <div class="form-group">
              <label for="pec" class="form-label">Pec</label>
              <input type="email" class="form-control" id="pec" formControlName="pec">
            </div>
            <div class="form-group">
              <label for="id_brand" class="form-label">Brand</label>
              <select class="form-control" id="id_brand" formControlName="id_brand">
                <option value="23">Chiara Ferragni Collection</option>
                <option value="24">ACBC</option>
                <option value="25">Alexander Hotto</option>
              </select>
            </div>
            <div class="form-group">
              <label for="provvigione" class="form-label">Provvigione</label>
              <input type="number" min="0" max="100" class="form-control" id="provvigione"
                     formControlName="provvigione">
              <div class="col-sm-10">

              </div>
            </div>
            <div class="form-group">
              <label for="area_competenza" class="form-label">Competenza</label>
              <input type="text" class="form-control" id="area_competenza"
                     formControlName="area_competenza">
            </div>
            <div class="button-flex" style="width: 100%">
              <button type="button" class="btn btn1" data-dismiss="modal">Chiudi</button>
              <button type="button" class="btn btn2" type="submit">Salva</button>
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>

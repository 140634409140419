import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'app/auth/auth.service';
import { ArticoloService } from 'app/services/articolo.service';

@Component({
  selector: 'app-market-place-articles',
  templateUrl: './market-place-articles.component.html',
  styleUrls: ['./market-place-articles.component.sass']
})
export class MarketPlaceArticlesComponent implements OnInit {

  public listArticles : any[] = [];
  @Input() item: any = [];
  public itemSelected : any = {};
  public roleUserLogged :string |undefined;
  public sidebarVisible :boolean = false;

  constructor(private auth: AuthService) { }

  ngOnInit(): void {
    this.sidebarVisible = false;
    console.log(`item articolo: ${this.item['articolo'].quantita}`);
    const user = this.auth.getCurrentUser();
    this.roleUserLogged = user?.tipo;
  }

  loadDataItemSelected(row:any){
    this.itemSelected = row != null ? row : {};
    this.sidebarVisible = true;
  }

 closeModalSidebar(){
    this.itemSelected = {};
    this.sidebarVisible = false;
  }


}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ListiniService {
  api: string = environment.api;
  constructor(private http: HttpClient) { }

  getAll(): Promise<any[]> {
    return this.http.get<any[]>(`${this.api}/listini`).toPromise();
  }

  getById(id: number): Promise<any[]> {
    return this.http.get<any[]>(`${this.api}/listini/${id}`).toPromise();
  }

  updateArticolo(listinoId: number, listinoArticoloId: number, prezzo_sellin: number, prezzo_sellout: number): Promise<any> {
    return this.http.put<any[]>(`${this.api}/listini/${listinoId}/articoli/${listinoArticoloId}`, { prezzo_sellin: prezzo_sellin, prezzo_sellout: prezzo_sellout }).toPromise();
  }

  addArticolo(listinoId: number, articoloId: number, varianteId: number, prezzo_sellin: number, prezzo_sellout: number) {
    console.log('creating articolo with arguments', arguments)
    return this.http.post<any>(`${this.api}/listini/${listinoId}/articoli`, {
      id_listino: listinoId,
      id_articolo: articoloId,
      id_articolo_variante: varianteId,
      prezzo_sellin: prezzo_sellin,
      prezzo_sellout: prezzo_sellout
    }).toPromise()
  }

  createListino(nome: string, attivo: boolean, id_mercato: number, id_organizzazione: number, id_clienti: number) {
    return this.http.post<any>(`${this.api}/listini`, { nome, attivo, id_mercato, id_organizzazione, id_clienti }).toPromise()
  }
  downloadTemplate(stagioneId: number, brandId: number): Observable<any> {
    return this.http.get(`${this.api}/listini/download_import_template?id_stagione=${stagioneId}&id_brand=${brandId}`, { responseType: 'json' })
  }
  importListino(id: number, formData: any): Observable<any> {
    return this.http.post<any>(`${this.api}/listini/${id}/articoli/import`, formData);
  }
  downloadExportListino(id: number): Observable<any> {
    return this.http.get(`${this.api}/listini/${id}/export`, { responseType: 'json' })
  }
}

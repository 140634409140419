import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from "environments/environment";
@Injectable({
  providedIn: 'root'
})
export class RetailerService {
  api: string = environment.api;
  constructor(private http: HttpClient) { }

  getList() : Promise<any[]>{
    return this.http.get<any[]>(`${this.api}/retailer/elenco`).toPromise();
  }

  getListByNegozio(negozio: string, page: number) : Promise<any[]>{
    return this.http.get<any[]>(`${this.api}/retailer/elenco-retailer-pagination-ajax/${negozio}/${page}`).toPromise();
  }

  getListNegozi(negozio: string) : Promise<any[]>{
    return this.http.get<any[]>(`${this.api}/retailer/elenco-negozi-pagination-ajax?negozio=${negozio}`).toPromise();
  }



}

import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'app/auth/auth.service';
import { ListiniService } from 'app/services/listini.service';
import { UtilitiesService } from 'app/services/utilities.service';

@Component({
  selector: 'app-table-listini',
  templateUrl: './table-listini.component.html',
  styleUrls: [ './table-listini.component.sass' ]
})
export class TableListiniComponent implements OnInit {
  public list: any[] = [];
  public listRetailer: any[] = [];
  public roleUserLogged: string | undefined;
  public filterObj: any = {};

  constructor(private listiniService: ListiniService, private service: AuthService, public utilitiesService: UtilitiesService) { }

  loadList() {
    this.listiniService.getAll().then((resp: any) => {
      const data = resp[ 'data' ];
      this.list = data;

      console.log(data)
    });
  }

  ngOnInit(): void {
    this.service.getCurrentUserAsync().then(value => {
      console.log(`promise result: ${value}`);
      this.roleUserLogged = value?.tipo;
      this.loadList();
    });
  }

}

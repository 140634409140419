import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/auth/auth.service';
import { ClientiService } from 'app/services/clienti.service';
import { ListiniService } from 'app/services/listini.service';
import { MercatiService } from 'app/services/mercati.service';

@Component({
  selector: 'app-list-listini',
  templateUrl: './list-listini.component.html',
  styleUrls: [ './list-listini.component.sass' ]
})
export class ListListiniComponent implements OnInit {
  roleUserLogged: string | undefined;
  public addData: any = {}

  public selectOptions = {
    mercati: [] as any[],
    clienti: [] as any[]
  }

  constructor(private auth: AuthService, private listiniService: ListiniService, private clientiService: ClientiService, private mercatiService: MercatiService) { }

  async ngOnInit(): Promise<void> {
    const user = this.auth.getCurrentUser();
    this.roleUserLogged = user?.tipo;

    await this.loadData();
  }

  async confirmModal() {
    this.listiniService.createListino(this.addData.nome, this.addData.attivo, +this.addData.id_mercato, +this.addData.id_organizzazione, this.addData.id_clienti)
      .then(res => window.location.reload())
  }

  async loadData() {
    this.selectOptions.mercati = (<any>(await this.mercatiService.getList())).data;
    this.selectOptions.clienti = (<any>(await this.clientiService.getClienti().toPromise())).data;

    const user = this.auth.getCurrentUser()
    this.addData.id_organizzazione = user?.id_organizzazione;
  }
  logChanges() {
    console.log('addData', this.addData)
  }
}

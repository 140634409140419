import { Component, OnInit } from '@angular/core';
import { MagazziniService } from 'app/services/magazzini.service';

@Component({
  selector: 'app-movimenti-magazzino',
  templateUrl: './movimenti-magazzino.component.html',
  styleUrls: ['./movimenti-magazzino.component.sass']
})
export class MovimentiMagazzinoComponent implements OnInit {

  constructor(private magazziniService : MagazziniService) { }

  public movimenti : any[] = []

  ngOnInit(): void {
    this.magazziniService.getMovimenti()
    .subscribe(resp => {this.movimenti = resp.data});
  }

}

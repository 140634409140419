<section>
  <div class="">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-colored card-retailer">
          <h1 class="text-uppercase text-white border-bottom-ab">Lista campionari</h1>
          <button class="btn agg-mag" data-toggle="modal" data-target="#addCampionarioModal">Aggiungi</button>
          <div class="button-mag">
            <div class="row">
              <div class="col-md-3 pr-0">
                <select class="form-control">
                  <option value="opzione 1">Brand</option>
                </select>
              </div>
              <div class="col-md-3 pr-0">
                <input type="text"
                       value=""
                       placeholder="CODICE ARTICOLO" class="form-control">
              </div>
              <div class="col-md-3 pr-0">
                <input type="text"
                       value=""
                       placeholder="Ricerca Barcode" class="form-control">
              </div>
              <div class="col-md-3 pr-0">
                <select class="form-control">
                  <option value="opzione 1">Stagione</option>
                </select>
              </div>
              <div class="col-md-3 pr-0">
                <select class="form-control">
                  <option value="opzione 1">Collezione</option>
                </select>
              </div>
              <div class="col-md-3 pr-0">
                <select class="form-control">
                  <option value="opzione 1">Tessuto</option>
                </select>
              </div>
              <div class="col-md-3 pr-0">
                <select class="form-control">
                  <option value="opzione 1">Colore</option>
                </select>
              </div>
              <div class="col-md-3 pr-0">
                <select class="form-control">
                  <option value="opzione 1">Settore</option>
                </select>
              </div>
              <div class="col-md-3 pr-0">
                <select class="form-control">
                  <option value="opzione 1">Macrocategoria</option>
                </select>
              </div>
              <div class="col-md-3 pr-0">
                <select class="form-control">
                  <option value="opzione 1">Categoria</option>
                </select>
              </div>
              <div class="col-md-3 pr-0">
                <select class="form-control">
                  <option value="opzione 1">Sottocategoria</option>
                </select>
              </div>
              <div class="col-md-3 pr-0">
                <select class="form-control">
                  <option value="opzione 1">Taglia</option>
                </select>
              </div>
              <div class="col-md-3 pr-0">
                <select class="form-control">
                  <option value="opzione 1">Taglia descrizione</option>
                </select>
              </div>
              <div class="col-md-4 pr-0">
                <div class="form-flex">
                  <input type="number" class="form-control" placeholder="PREZZO DA" name="prezzo_dal">
                  <input type="number" class="form-control" placeholder="PREZZO A" name="prezzo_al">
                </div>

              </div>
              <div class="col-md-4 pr-0">
                <div class="form-flex">
                  <a class="btn btn1">Export file PDF</a>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="" *ngFor="let campionario of campionari">
        <div class="row">
          <div class="col-9">
            <h3 class="card-title mb-0 text-uppercase font-weight-700">Campionario {{campionario.id}}</h3>
            <p class="card-text">Stagione
              <b>{{campionario.stagione ? campionario.stagione.nome : ''}}</b><br>
              Brand <b>{{campionario.brand ? campionario.brand.nome : ''}}</b></p>
          </div>

          <div class="col-3 d-flex align-items-center">
            <div class="button-flex">
              <a [routerLink]="['/campionari/', campionario.id]" class="btn btn1">Dettagli</a>
              <a class="btn btn2">Rimuovi</a>
            </div>
          </div>
        </div>
        <hr>
      </div>
    </div>
    <div *ngIf="nocampionari" class="card bg-warning">
      Nessun campionario presente.
    </div>

    <div class="modal fade" id="addCampionarioModal" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Crea nuovo campionario</h5>
          </div>
          <div class="modal-body">
            <form id="campionario">
              <div class="">
                <label for="id_stagione" class="col-sm-2 col-form-label">Stagione</label>
                <select class="form-control" id="id_stagione" name="id_stagione">
                  <option value="16">FW21-22</option>
                  <option value="17">PE22</option>
                  <option value="18">SS21</option>
                </select>
              </div>
              <div class="">
                <label for="id_brand" class="col-sm-2 col-form-label">Brand</label>
                <select class="form-control" id="id_brand" name="id_brand">
                  <option value="23">Chiara Ferragni Collection</option>
                  <option value="24">ACBC</option>
                  <option value="25">Alexander Hotto</option>
                </select>
              </div>

              <div style="margin-top: 30px">
                <label class="switch" for="attivo"><p>Attivo</p>
                  <input type="checkbox" checked="" class="form-check-input" id="attivo" name="attivo" value="">
                  <span class="slider round"></span>
                </label>
              </div>
              <div class="">
                <label for="data_inizio" class="col-form-label">Data apertura</label>
                <input type="date" class="form-control" id="data_inizio" name="data_apertura" value="">
              </div>
              <div class="">
                <label for="data_chiusura" class="col-form-label">Data chiusura</label>
                <input type="date" class="form-control" id="data_chiusura" name="data_chiusura" value="">
              </div>
              <div class="">
                <label for="data_pubblicazione">Data pubblicazione</label>
                <input type="date" class="form-control" id="data_pubblicazione" name="data_pubblicazione"
                       value="">
              </div>
            </form>
            <div class="button-flex">
              <button type="button" class="btn btn1" data-dismiss="modal">Chiudi</button>
              <button type="button" class="btn btn2" (click)="addCampionario()">Salva</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

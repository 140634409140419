import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AgentiService } from 'app/services/agenti.service';

@Component({
  selector: 'app-agenti',
  templateUrl: './agenti.component.html',
  styleUrls: ['./agenti.component.sass']
})
export class AgentiComponent implements OnInit {

  agenti : any[] = [];
  agenti_all: any[] = [];
  brands: any[] = [];
  agenteForm = this.fb.group({
    nome: ['', [Validators.required]],
    cognome: ['', [Validators.required]],
    nome_showroom: ['', [Validators.required]],
    indirizzo_showroom: ['', [Validators.required]],
    ragione_sociale: ['', [Validators.required]],
    partita_iva: ['', [Validators.required]],
    codice_fiscale: ['', [Validators.required]],
    telefono: ['', [Validators.required]],
    email: ['', [Validators.required]],
    pec: ['', [Validators.required]],
    sede_legale_indirizzo: ['', [Validators.required]],
    id_brand: ['', [Validators.required]],
    provvigione: ['', [Validators.required]],
    area_competenza: ['', [Validators.required]],
  })

  constructor(private agentiService : AgentiService, private fb: FormBuilder) { }

  get fagente() { return this.agenteForm.controls; }

  ngOnInit(): void {
    this.getAgenti();
    setTimeout(this.loadFilters,100);
  }

  getAgenti(): void {
    this.agentiService.getAgenti()
    .subscribe(resp => {
      this.loadAgenti(resp.data);
    });
  }

  getAgente(id: number): void {
    this.agentiService.getAgente(id)
    .subscribe(resp => {
      console.log(resp.data);
    })
  }

  loadAgenti(agenti: Array<any>): void {
    this.agenti = [];
    for (let agente of agenti) {
      const brands = agente.brand_agente.map(function(item: any) {return item['brand']['nome']});
      agente.brands = [...new Set(brands)].toString();
      const aree = agente.brand_agente.map(function(item: any) {return item['area_competenza']});
      agente.aree = [...new Set(aree)].toString();
      this.agenti.push(agente);
    }
    this.agenti_all = this.agenti;
  }

  loadFilters(): void {
    const filtri = document.querySelectorAll('#filters select');
    filtri.forEach(filtro => {
      // @ts-expect-error
      $('#'+filtro.id).selectpicker({
        noneSelectedText: filtro.id
      })
    });
  }

  deselectAll(): void {
    // @ts-expect-error
    $('#filters select option').attr("selected",false);
    // @ts-expect-error
    $('#filters select').val('').trigger('change');
    // @ts-expect-error
    $('#filters select').selectpicker('refresh');
    document.querySelectorAll('#filters input').forEach(input => (input as HTMLInputElement).value = '');
    this.agenti = this.agenti_all;
  }

  order(event: Event): void {
    const order = (event.target as HTMLElement).dataset.order!;
    const by = (event.target as HTMLElement).dataset.by!;
    document.querySelectorAll('th').forEach(element => element.classList.remove("order-asc","order-desc"))
    if (by == "" || by == "desc") {
      this.agenti.sort((elem1, elem2) => order.split('.').reduce((o,i)=> o[i], elem1).localeCompare(order.split('.').reduce((o,i)=> o[i], elem2)));
      (event.target as HTMLElement).dataset.by = "asc";
      (event.target as HTMLElement).classList.add("order-asc");
    }
    else {
      this.agenti.sort((elem1, elem2) => order.split('.').reduce((o,i)=> o[i], elem2).localeCompare(order.split('.').reduce((o,i)=> o[i], elem1)));
      (event.target as HTMLElement).dataset.by = "desc";
      (event.target as HTMLElement).classList.add("order-desc");
    }
  }

  filter(): void {
    this.agenti = this.agenti_all;
    const form: FormData = new FormData(document.querySelector('#filters') as HTMLFormElement);
    let dati: { [key: string]: Array<string> } = {};
    for (let entry of form.keys()) {
      console.log(entry);
      dati[entry] = form.getAll(entry) as Array<string>;
    }
    console.log(dati);
    this.agenti = this.agenti.filter((agente) =>
    { return Object.entries(dati).every(([key,arrayValue]) =>
    { return arrayValue.some((value) =>
    { return key.split('.').reduce((o,i)=> o[i], agente).toString().toLowerCase().includes(value.toString().toLowerCase())})})});
  }

  create(): void {
    const agente = {
      nome: this.fagente.nome.value,
      cognome: this.fagente.cognome.value,
      nome_showroom: this.fagente.nome_showroom.value,
      indirizzo_showroom: this.fagente.indirizzo_showroom.value,
      ragione_sociale: this.fagente.ragione_sociale.value,
      partita_iva: this.fagente.partita_iva.value,
      codice_fiscale: this.fagente.codice_fiscale.value,
      telefono: this.fagente.telefono.value,
      email: this.fagente.email.value,
      pec: this.fagente.pec.value,
      sede_legale_indirizzo: this.fagente.sede_legale_indirizzo.value,
      id_brand: Number(this.fagente.id_brand.value),
      provvigione: this.fagente.provvigione.value,
      area_competenza: this.fagente.area_competenza.value,
    }
    this.agentiService.createAgente(agente)
    .subscribe(resp => {
      if (resp.status = 200) {
        this.getAgenti();
        // @ts-expect-error
        $('#creazioneModal').modal('hide');
      }
    },
    (error) => {
      console.log(error);
      alert('Errore nella creazione dell\'agente: ' + error.toString())
    });
  }

  delete(id_agente: string): void {
    if (confirm('Vuoi cancellare l\'agente?')) {
      this.agentiService.deleteAgente(id_agente)
      .subscribe(resp => {
        if (resp.status = 200) {
          this.getAgenti();
        }
      },
      (error) => {
        console.log(error);
        alert(`Errore nella rimozione del brand dall'agente: ${error.toString()}`)
      });
    }
  }
}

        <section>
            <div class="">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card card-colored overflow-hidden card-retailer">
                            <h1 class="text-uppercase text-white border-bottom-ab">Brand</h1>
                            <h5 class="text-white">Elenco Brand</h5>
                            <div class="container-box contB">
                                    <div class="temp-emailbox classe-retailer">
                                        <div class="temp-emailbox-form">
                                            <div class="input-box-col">
                                                <div class="input-warp">
                                                    <input placeholder="Cerca" value="" name="nome" [(ngModel)]="searchText"
                                                        type="text">
                                                    <button type="submit" (click)="search()"
                                                            class="btn-rds icon-btn btn-l-gary popover-qr">
                                                        <i class="fas fa-search"></i>
                                                        <span>Cerca</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="input-box-col hidden-xs-sm">
                                                <button type="reset" (click)="reset()"
                                                        class="btn-rds icon-btn bg-theme click-to-copy copyIconGreenBtn">
                                                    <i class="fas fa-times"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <ng-container  *ngFor="let item of listBrand; let indexOfelement=index;">
                                        <app-item-list-brand [item] = item (brandSelected)="selectBrand(item)"></app-item-list-brand>
                                    </ng-container>



                            </div>


                        </div>
                    </div>
                </div>
            </div>


            <div *ngIf="modalIsVisible" style="/*! margin-top: -2%; */position: fixed;top: 0;width: 100%;z-index: 10;height: 100%;right: 0;">
                <div class="modal-dialog modal-dialog-centered modal-sm">
                  <div class="modal-content">
                  <div class="modal-body" style="text-align: center;">
                    <div class="loader" style="margin-left: 30%;"></div><br>
                    <h5>Caricamento dati in corso...</h5>
                  </div>

                  </div>
                </div>
            </div>

        </section>

        <div class="modal fade" [id]="'modale-richiedi-autorizzazione'" tabindex="-1" role="dialog" data-backdrop="false" aria-labelledby="modal-default" aria-hidden="true">
          <div class="modal-dialog" role="document">
          <div *ngIf="brandSelected" class="modal-content">

              <div class="modal-header">
                  <h6 class="modal-title">Richiedi Autorizzazione al Brand <br><b>{{brandSelected.nome}}</b></h6>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">×</span>
                  </button>
              </div>


                  <div class="modal-body">

                    <form  id="richiestaAutorizzazione">
                      <div class="row">

                          <input type="hidden" name="id" [value]="brandSelected.id">
                          <!-- NEGOZI -->
                          <div class="col-md-12" id="checkboxNegozi">
                              <p class="text-uppercase alt-font mb-2">Negozi da autorizzare</p>
                              <div *ngFor="let negozio of negozi" class="mb-2">
                                <input class="checkbox_negozi switch mb-2" type="checkbox" [value]="negozio.id" name="negozi[]" checked>
                                <label>{{negozio.nome}}</label>
                              </div>
                          </div>

                      </div>


                      <div class="row">
                          <!-- AZIENDE -->
                          <div class="col-md-12" id="checkBoxAziende">
                              <p class="text-uppercase alt-font mb-2">Partita Iva </p>
                              <div *ngFor="let azienda of aziende" class="mb-2">
                                <input class="checkbox_negozi switch mb-2" type="checkbox" [value]="azienda.id" name="aziende[]" checked>
                                <label>{{azienda.ragione_sociale}}</label>
                              </div>
                          </div>
                      </div>
                      <div class="row">
                          <!-- MESSAGGIO -->
                          <div class="col-md-12">
                              <p class="text-uppercase alt-font">Messaggio per il Brand</p>
                              <textarea name="messaggio" placeholder="Scrivi qui il tuo messaggio per il Brand..." class="form-control"></textarea>
                          </div>
                      </div>
                    </form>
                  </div>
                  <div class="modal-footer">
                    <div class="button-flex" style="width: 100%;">
                      <button type="button" data-dismiss="modal" class="btn btn1">
                        Chiudi
                      </button>
                      <button (click)="requestAuthorization()"
                              id="bottoneConfermaModaleRichiediAutorizzazione"
                              class="btn btn2">Conferma
                      </button>
                    </div>

                  </div>



          </div>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-change-password',
  templateUrl: './dialog-change-password.component.html',
  styleUrls: ['./dialog-change-password.component.sass']
})
export class DialogChangePasswordComponent implements OnInit {

  public passwordCurrent : string = "";
  public password : string = "";
  public confirmPassword : string = "";

  constructor() { }

  ngOnInit(): void {
  }

  confirm(){}

}

import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'app/auth/auth.service';

@Component({
  selector: 'app-data-article-table',
  templateUrl: './data-article-table.component.html',
  styleUrls: ['./data-article-table.component.sass']
})
export class DataArticleTableComponent implements OnInit {
  @Input() article: any = [];
  roleUserLogged :string |undefined;
  public updatingEnable :boolean = false;
  constructor(private service: AuthService) { this.updatingEnable = false; }

  ngOnInit(): void {
    this.service.getCurrentUserAsync().then(value => {
      console.log(`promise result: ${value}`);
      this.roleUserLogged = value?.tipo;
    });
  }


  abilitaModifica(){
    this.updatingEnable = true;
  }

  disablitaModifica(){
    this.updatingEnable = false;
  }
}

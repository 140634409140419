<nav class="sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light" id="sidenav-main">
  <div class="scrollbar-inner">


    <!-- LOGO RETAILER -->

    <div class="logo-cont">
      <a routerLink="/">
        <img src="/assets/img/SellUp-Logo-Black.png" class="navbar-brand-img" alt="R2Wear" width="120"/>
      </a>
    </div>

    <div class="navbar-inner">


      <div class="collapse navbar-collapse" id="sidenav-collapse-main">
        <!-- Nav items -->


        <!-- NAVBAR RETAILER -->

        <ul class="navbar-nav classe-retailer" *ngIf="roleUserLogged == 'RETAILER'">
          <li id="sidebar-dashboard" class="nav-item li-active">
            <a class="nav-link active" routerLink="">
              <img src="assets/img/home.svg">
              <span class="nav-link-text">Dashboard</span>
            </a>
          </li>


          <li id="sidebar-magazzino" class="nav-item">
            <a class="nav-link active" routerLink="/store">
              <img src="assets/img/shop.svg">
              <span class="nav-link-text">Magazzino</span>
            </a>
          </li>

          <li id="sidebar-brand" class="nav-item">
            <a class="nav-link active" routerLink="/brands-list">
              <img src="assets/img/gift-card.svg">
              <span class="nav-link-text">Brand</span>
            </a>
          </li>


          <li id="sidebar-brand-market" class="nav-item">
            <a class="nav-link active" routerLink="/market-place">
              <img src="assets/img/shopping-cart.svg">
              <span class="nav-link-text">Marketplace</span>
            </a>
          </li>


          <li id="sidebar-ordini" class="nav-item">
            <a class="nav-link active" routerLink="/orders-list">
              <img src="assets/img/download.svg">
              <span class="nav-link-text">Ordini</span>
            </a>
          </li>


          <li id="sidebar-impostazioni" class="nav-item">
            <a class="nav-link active" routerLink="/profile">
              <img src="assets/img/settings.svg">
              <span class="nav-link-text">Impostazioni</span>
            </a>
          </li>
        </ul>
        <!-- NAVBAR BRAND  -->
        <ul class="navbar-nav 'classe-brand" *ngIf="roleUserLogged == 'BRAND'">
          <li id="sidebar-dashboard" class="nav-item li-active">
            <a class="nav-link active" routerLink="/">
              <img src="/assets/img/home.svg">
              <span class="nav-link-text">Dashboard</span>
            </a>
          </li>


          <li class="nav-item dropdown" id="sidebar-magazzino">
            <a class="nav-link active dropdown-toggle" data-toggle="dropdown" href="#" aria-expanded="false">
              <img src="assets/img/tshirt.png">
              <span class="nav-link-text">Prodotti</span>
            </a>
            <ul class="dropdown-menu drop-menu">
              <li><a id="sub-magazzino-articoli" href="/store">Magazzino</a></li>
              <li><a id="sub-magazzino-movimenti" href="/movimenti-magazzino">Movimenti magazzino</a></li>
            </ul>
          </li>

          <li id="sidebar-retailer" class="nav-item">
            <a class="nav-link active" routerLink="/retailer-list">
              <img src="/assets/img/following.svg">
              <span class="nav-link-text">Retailer</span>
            </a>
          </li>

          <li id="sidebar-ordini" class="nav-item">
            <a class="nav-link active" routerLink="/orders-list">
              <img src="/assets/img/download.svg">
              <span class="nav-link-text">Ordini</span>
            </a>
          </li>

          <li id="sidebar-ordini" class="nav-item">
            <a class="nav-link active" routerLink="/preordini">
              <img src="/assets/img/apps-sort.png">
              <span class="nav-link-text">Preordini</span>
            </a>
          </li>

          <li id="sidebar-ordini" class="nav-item">
            <a class="nav-link active" routerLink="/campionari">
              <img src="/assets/img/clothes-hanger.png">
              <span class="nav-link-text">Campionari</span>
            </a>
          </li>

          <li id="sidebar-ordini" class="nav-item">
            <a class="nav-link active" routerLink="/clienti">
              <img src="/assets/img/users.svg">
              <span class="nav-link-text">Clienti</span>
            </a>
          </li>

          <li id="sidebar-listini" class="nav-item">
            <a class="nav-link active" routerLink="/listini-list">
              <img src="/assets/img/note.png">
              <span class="nav-link-text">Listini</span>
            </a>
          </li>

          <li id="sidebar-ordini" class="nav-item">
            <a class="nav-link active" routerLink="/agenti">
              <img src="/assets/img/user-headset.png">
              <span class="nav-link-text">Agenti</span>
            </a>
          </li>

          <li class="nav-item" style="display:none">
            <a class="nav-link active" routerLink="/">
              <img src="/assets/img/shop.svg">
              <span class="nav-link-text">Sellout</span>
            </a>
          </li>


          <li id="sidebar-impostazioni" class="nav-item">
            <a class="nav-link active" routerLink="/profile">
              <img src="/assets/img/settings.svg">
              <span class="nav-link-text">Impostazioni</span>
            </a>
          </li>
        </ul>

        <!-- SUPER ADMIN -->
        <ul class="navbar-nav" *ngIf="roleUserLogged == 'admin'">
          <li id="sidebar-dashboard" class="nav-item">
            <a class="nav-link active" routerLink="/">
              <i class="fa-regular fa-house"></i>
              <span class="nav-link-text">Dashboard</span>
            </a>
          </li>
          <li id="sidebar-importer" class="nav-item">
            <a class="nav-link active" routerLink="/import-superadmin">
              <i class="fas fa-upload"></i>
              <span class="nav-link-text">Importer</span>
            </a>
          </li>
        </ul>

        <!-- UTENTE NON LOGGATO
        <ul class="navbar-nav">
          <li id="sidebar-impostazioni" class="nav-item">
            <a class="nav-link active" target="_blank" routerLink="https://r2w.it">
              <img src="/assets/img/comment-heart.svg">
              <span class="nav-link-text">SCOPRI Sellup</span>
            </a>
          </li>
        </ul>

        -->
        <!-- Divider -->

        <!-- Heading -->
        <!-- <h6 class="navbar-heading p-0 text-muted">
          <span class="docs-normal">Documentation</span>
        </h6> -->
        <!-- Navigation -->
        <!-- <ul class="navbar-nav mb-md-3">
          <li class="nav-item">
            <a class="nav-link" routerLink="https://demos.creative-tim.com/argon-dashboard/docs/getting-started/overview.html" target="_blank">
              <i class="ni ni-spaceship"></i>
              <span class="nav-link-text">Getting started</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="https://demos.creative-tim.com/argon-dashboard/docs/foundation/colors.html" target="_blank">
              <i class="ni ni-palette"></i>
              <span class="nav-link-text">Foundation</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="https://demos.creative-tim.com/argon-dashboard/docs/components/alerts.html" target="_blank">
              <i class="ni ni-ui-04"></i>
              <span class="nav-link-text">Components</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="https://demos.creative-tim.com/argon-dashboard/docs/plugins/charts.html" target="_blank">
              <i class="ni ni-chart-pie-35"></i>
              <span class="nav-link-text">Plugins</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link active active-pro" routerLink="upgrade.html">
              <i class="ni ni-send text-dark"></i>
              <span class="nav-link-text">Upgrade to PRO</span>
            </a>
          </li>
        </ul> -->
      </div>
    </div>
  </div>
</nav>

import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { UserLogin } from '../../auth/interfaces/user-login';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})

export class LoginComponent implements OnInit {
  loginForm = this.fb.group({
    email: ['', [Validators.email, Validators.required]],
    password: ['', Validators.required]
  })
  constructor( private fb: FormBuilder, private authService : AuthService, private route: Router, ) { }

  ngOnInit(): void {
    this.authService.getCurrentUserAsync();
  }

  get f() { return this.loginForm.controls; }

  login(): void {
    const loginRequest : UserLogin = {
      email: this.f.email.value,
      password: this.f.password.value,
    }
    this.authService.login(loginRequest);
  }

  loginLaravel() : void {
    const loginRequest : UserLogin = {
      email: this.f.email.value,
      password: this.f.password.value,
    }
    this.authService.loginLaravel(loginRequest);
  }


  goToRegistrazione(){
    this.route.navigate(["sign-up"]);
  }


  resetPwd(){}
}

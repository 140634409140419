<!-- Topnav -->
<div class="logo-cont logo-header">
  <a href="/">
    <img src="/assets/img/SellUp-Logo-Black.png" class="navbar-brand-img" alt="R2Wear" width="100"/>
  </a>
</div>
<nav class="navbar navbar-top navbar-expand navbar-dark">
  <div class="container-fluid">
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <div class="">

      </div>

      <ul class="navbar-nav align-items-center  ml-md-auto ">
        <li class="nav-item dropdown carrello-doppio">
          <!-- icona carrello -->
          <ng-container *ngIf="roleUserLogged == 'RETAILER'">
            <a class="nav-link nav-cart c-d" href="#" role="button" data-toggle="dropdown"
               aria-haspopup="true" aria-expanded="false" id="dropdownMenuBrand">
              <img src="assets/img/gift-card.svg" style="width: 19px; vertical-align: text-bottom;margin: 0 5px;">
              <b>Marketplace Brand</b>
              <span>{{total_brand}}</span>
            </a>
            <div class="container_dettaglio_minicart">
              <div class="dropdown-menu dropdown-menu-cart dropdown-menu-xl dropdown-menu-right py-0 overflow-hidden" id="dropdownMenuBrand">
                <!-- recap -->
                <div class="px-3 py-3">
                  <h6 class="text-sm text-muted m-0">Hai <strong class="text-primary">1</strong> articoli nel carrello.</h6>
                </div>

                <!-- lista articoli carrello -->
                <div class="list-group list-group-flush">

                  <a href="#" class="list-group-item list-group-item-action">
                    <div class="row align-items-center">
                      <div class="col-auto">

                        <!-- IMMAGINI -->
                        <img alt="Image placeholder" src="https://r2w-media-dev.fra1.cdn.digitaloceanspaces.com/articoli/56/6329721f6a0c7.JPG" class="avatar rounded-circle">

                      </div>
                      <div class="col ml--2">
                        <div class="d-flex justify-content-between align-items-center">
                          <div>
                            <h4 class="mb-0 text-sm">RETAILER</h4>
                          </div>
                          <div class="text-right text-muted">
                            <small>
                              3                      X
                              122,00€
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>



                </div>
                <!-- end lista articoli carrello -->
                <button onclick="location.href='/carrello/brand/riepilogo'" class="btn btn-cart">Carrello</button>
              </div>
            </div>
          </ng-container>
        </li>
        <li class="nav-item dropdown carrello-doppio">
          <!-- icona carrello -->
          <ng-container *ngIf="roleUserLogged == 'RETAILER'">
            <a class="nav-link nav-cart c-d" href="#" role="button" data-toggle="dropdown"
               aria-haspopup="true" aria-expanded="false">
              <img src="assets/img/shopping-cart-nav.svg" style="width: 19px; vertical-align: text-bottom;margin: 0 5px;"> <b>Marketplace R2W</b>
              <span>{{total_r2w}}</span>
            </a>
            <div class="container_dettaglio_minicart">
              <div class="dropdown-menu dropdown-menu-cart dropdown-menu-xl dropdown-menu-right py-0 overflow-hidden" id="dropdownMenuBrand">
                <!-- recap -->
                <div class="px-3 py-3">
                  <h6 class="text-sm text-muted m-0">Hai <strong class="text-primary">1</strong> articoli nel carrello.</h6>
                </div>

                <!-- lista articoli carrello -->
                <div class="list-group list-group-flush">

                  <a href="#" class="list-group-item list-group-item-action">
                    <div class="row align-items-center">
                      <div class="col-auto">

                        <!-- IMMAGINI -->
                        <img alt="Image placeholder" src="https://r2w-media-dev.fra1.cdn.digitaloceanspaces.com/articoli/56/6329721f6a0c7.JPG" class="avatar rounded-circle">

                      </div>
                      <div class="col ml--2">
                        <div class="d-flex justify-content-between align-items-center">
                          <div>
                            <h4 class="mb-0 text-sm">CIAOOOO</h4>
                          </div>
                          <div class="text-right text-muted">
                            <small>
                              3                      X
                              122,00€
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>



                </div>
                <!-- end lista articoli carrello -->
                <button routerLink="/marketplace-brand" class="btn btn-cart">Carrello</button>
              </div>
            </div>
          </ng-container>

        </li>
      </ul>

      <ul class="navbar-nav align-items-center  ml-auto ml-md-0 ">
        <li class="nav-item dropdown">
          <a class="nav-link pr-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true"
             aria-expanded="false">
            <div class="media align-items-center">
                            <span class="avatar avatar-sm rounded-circle">
                              <img class="imgProfilo" alt="Image placeholder"
                                   src="/utente/22/609e81e1a7dbc.png">
                            </span>
              <div class="media-body ml-2 d-none d-lg-block">
                              <span class="mb-0 text-sm">
                                {{userLogged}}
                              </span>
              </div>
            </div>
          </a>
          <div class="dropdown-menu  dropdown-menu-right ">
            <!-- <a href="/account/<?= strtolower(\Auth::user()->tipo) ?>/<?= \Auth::user()->id ?>" class="dropdown-item">
                <i class="ni ni-single-02"></i>
                <span>Profilo</span>
            </a> -->
            <a href="/profile" class="dropdown-item">
              <i class="fi fi-rr-settings"></i>
              <span>Impostazioni</span>
            </a>
            <a onclick="modaleCambioPassword()" href="#" class="dropdown-item">
              <i class="fi fi-rr-lock"></i>
              <span>Cambio Password</span>
            </a>
            <!-- <a href="#!" class="dropdown-item">
              <i class="ni ni-support-16"></i>
              <span>Support</span>
            </a> -->

            <button (click)="logout()" class="dropdown-item">
              <i class="fi fi-rr-angle-circle-right"></i>
              <span>Logout</span>
            </button>
          </div>
        </li>
        <li class="nav-item d-xl-none">
          <!-- Sidenav toggler -->
          <div class="pl-3 sidenav-toggler sidenav-toggler-dark" data-action="sidenav-pin"
               data-target="#sidenav-main">
            <div class="sidenav-toggler-inner">
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
            </div>
          </div>
        </li>
      </ul>

    </div>
  </div>
</nav>
<!-- Header -->

<div class="row">
  <div class="col-md-12">
    <div
      class="card card-colored">
      <h1 class="text-uppercase">Riepilogo ordine</h1>
      <div class="container-box" *ngIf="order">
        <div class="box dettaglio-ordine-box ">
          <div class="row">
            <div class="col-md-6">
              <h3>Numero Ordine: {{order!.ordine!.numero}}</h3>
              <p>Effettuato in data <b>{{utilitiesService.getFormatDateTime(order!.ordine!.created_at)}}</b>
              </p>
              <p>Stato ordine
                <span [class]="order!.ordine!.status">{{order!.ordine!.status}}</span>
                <i style="cursor:pointer;color:green" class="fi fi-rr-file-excel"></i>
                <i style="cursor:pointer;color:red" class="fi fi-rr-file-pdf"></i>
              </p>


              <!-- DATI SPEDIZIONE -->
              <ng-container *ngIf="roleUserLogged != 'RETAILER'">
                <p>
                  Corriere: se sono brand
                </p>
              </ng-container>
              <p>
                Tracking
                number: {{order!.ordine.shipment_tracking_number != "" ? order!.ordine.shipment_tracking_number : "N/D"}}
              </p>
            </div>
            <div class="col-md-6 text-right">
              <p class="dett-prezzo">
                Numero articoli
                <span>{{order!.ordine.quantita}}</span>
              </p>
              <p class="dett-prezzo">Totale
                <span>{{order!.ordine.prezzo}} €</span>
              </p>
            </div>
            <div class="col-md-12">
              <div class="box-bottoni-dettaglio">
                <div class="button-flex classe-brand bottoni-dett-ordine">
                  <ng-container *ngIf="order!.ordine.status != 'SPEDITO'">
                    <ng-container
                      *ngIf="order!.ordine.status != 'CONFERMATO' && order!.ordine.status != 'RIFIUTATO'">
                      <button class="btn btn1" type="button">CONFERMA</button>
                      <button class="btn btn2" type="button">ANNULLA ORDINE</button>
                    </ng-container>

                    <ng-container *ngIf="order!.ordine.status == 'RIFIUTATO'">
                      <button class="btn btn1" type="button">RIPRISTINA ORDINE</button>
                    </ng-container>

                    <ng-container *ngIf="order!.ordine.status == 'CONFERMATO'">
                      <button type="button" class="btn btn3" data-toggle="modal" data-target="#shipmentModal">
                        Spedisci
                      </button>
                    </ng-container>
                  </ng-container>


                </div>
              </div>

            </div>
          </div>

          <hr>
          <div class="">
            <div class="table-responsive">
              <h4>Venditore: {{order!.organizzazione.nome}}</h4>
              <table class="table table-striped bg-white">
                <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Codice Articolo</th>
                  <th scope="col">Descr. breve</th>
                  <th scope="col">Colore</th>
                  <th scope="col">Stagione</th>
                  <th scope="col">Collezione</th>
                  <th scope="col">Settore</th>
                  <th scope="col">Categoria</th>
                  <th scope="col">Dettaglio</th>
                  <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let row of order.prodottiGroupedForTessutoColore">
                  <tr>
                    <th scope="row">
                      <img width="30"
                           src="{{row[0].media_url != '' ? row[0].media_url : '/img/placeholder-articolo.jpg'}}">
                    </th>
                    <td>{{row[0]!.articolo!.codice_articolo}} </td>
                    <td>{{row[0]!.articolo!.nome}} </td>
                    <td>{{row[0]!.colore}}</td>
                    <td>{{row[0]!.stagione}}</td>
                    <td>{{row[0]!.collezione}}</td>
                    <td>{{row[0]!.settore}}</td>
                    <td>{{row[0]!.categorie}}</td>
                   <!-- <td>
                      <a style="padding: 5px 20px" class="btn btn-secondary" data-toggle="collapse"
                         href="#articolo{{row[0]!.articolo!.codice_articolo.trim()}}">REVISIONA</a>
                    </td>-->
                   <td>
                     <button type="button" style="padding: 5px 20px" class="btn btn1" data-toggle="modal" data-target="#exampleModal">REVISIONA</button>
                   </td>
                  </tr>
                  <tr>
                    <td>Taglia N/D x 5</td>
                    <td>Ordinato<br><b>TOT 5</b></td>
                  </tr>
                  <tr>
                    <td style="background:#ffebeb">3</td>
                    <td>Confermato<br><b>TOT 3</b></td>
                    <td>Prezzo<br><b>556,04€</b></td>
                  </tr>
                  <!--<tr>
                    <td colspan="9">
                      <div class="collapse" id="articolo{{row[0]!.articolo!.codice_articolo.trim()}}">
                        <table class="table">
                          <thead class="thead-dark">
                          <tr>
                            <th scope="col">Taglia</th>
                            <th scope="col">Quantità</th>
                            <th scope="col">Ordinato</th>
                            <th scope="col">Accettato</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr *ngFor="let variante of row">
                            <td>{{variante.taglia}}</td>
                            <td>{{variante.quantita}}</td>
                            <td>{{variante.ordinata}}</td>
                            <td>{{variante.quantita_accettata}}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>-->

                </ng-container>
                </tbody>

              </table>

            </div>
          </div>

          <!-- FATTURAZIONE E SPEDIZIONE -->
          <div class="row mt-5">
            <div class="col-md-6">
              <div class="box-dati-dett">
                <h4>SPEDIZIONE</h4>
                <div class="zig-zag"></div>
                {{order!.ordine!.spedizione.nome}}
                <br>
                {{order!.ordine!.spedizione.email}}
                <br>
                {{order!.ordine!.spedizione.formatted_address}}
              </div>

            </div>
            <div class="col-md-6">
              <div class="box-dati-dett">
                <h4>FATTURAZIONE</h4>
                <div class="zig-zag"></div>
                {{order!.ordine!.aziendaFatturazione.ragione_sociale}}
                <br>
                {{order!.ordine!.aziendaFatturazione.pec}}
                <br>
                {{order!.ordine!.aziendaFatturazione.formatted_address}}
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Revisiona</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body modal-qta">
        <div class="row">
          <div class="col-md-12">
              <img id="img-1397" src="https://r2w-media-dev.fra1.cdn.digitaloceanspaces.com/articoli/55/632970dcc9ee3.jpg">
            <h3>ACBC x Missoni BasketÂ&nbsp;</h3>
            <p>
              Cod.Articolo: SHMISBAM
              <br>
              <i class="fi fi-rr-palette classe-retailer"></i><span>Bianco e Blu</span>
             <br> <i class="fi fi-rr-wallet"></i>
              <span class="">Brand: 151,92€</span>
              <span class=""> - Retailer: 316,00€</span></p>
            <div class="table-responsive">
              <table class="table table-hover table-mc-light-blue">
                <thead>
                <tr>
                  <th scope="row">Taglia</th>
                  <th>N/D </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>Q.TA Ordinata</td>
                  <td>5</td>
                </tr>

                <tr>
                  <td>Q.TA Confermata</td>
                  <td>
                    <select>
                      <option value=""></option>
                      <option>1</option>
                      <option>2</option>
                      <option selected="">3</option>
                      <option>4</option>
                      <option>5</option>
                    </select>
                  </td>

                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="button-flex" style="width: 100%">
          <button type="button" class="btn btn2" data-dismiss="modal">Chiudi</button>
          <button type="button" class="btn btn1">Accetta</button>
          <button type="button" class="btn btn2" data-dismiss="modal">Rifiuta</button>
        </div>

      </div>
    </div>
  </div>
</div>

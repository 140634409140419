import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AgentiService } from 'app/services/agenti.service';
import { ClientiService } from 'app/services/clienti.service';

@Component({
  selector: 'app-agenti-detail',
  templateUrl: './agenti-detail.component.html',
  styleUrls: ['./agenti-detail.component.sass']
})

export class AgentiDetailComponent implements OnInit {

  private id_agente: number = 0;
  agente: any | undefined;
  brand_selezionato: any | undefined;
  brands_filter: Array<any> = [];
  clienti_brand: Array<any> = [];
  cliente_selezionato: any | undefined;
  clienti: Array<any> = [];
  agenteForm: any | undefined;

  constructor(private route: ActivatedRoute, private agentiService : AgentiService, private clientiService: ClientiService, private fb: FormBuilder) { }

  get fagente() { return this.agenteForm.controls; }

  ngOnInit(): void {
    this.id_agente = parseInt(this.route.snapshot.paramMap.get('id')!, 10);
    this.getAgente();
    this.getClienti();
  }

  getAgente(): void {
    this.agentiService.getAgente(this.id_agente)
    .subscribe(resp => {
      this.agente = resp.data;
      console.log(resp.data);
      this.brand_selezionato = this.agente.brand_agente[0];
      this.getClientiAgente();
      this.agenteForm = this.fb.group({
        nome: [this.agente.nome, [Validators.required]],
        cognome: [this.agente.cognome, [Validators.required]],
        nome_showroom: [this.agente.nome_showroom, [Validators.required]],
        indirizzo_showroom: [this.agente.indirizzo_showroom, [Validators.required]],
        ragione_sociale: [this.agente.ragione_sociale, [Validators.required]],
        partita_iva: [this.agente.partita_iva, [Validators.required]],
        codice_fiscale: [this.agente.codice_fiscale, [Validators.required]],
        telefono: [this.agente.telefono, [Validators.required]],
        email: [this.agente.email, [Validators.required]],
        pec: [this.agente.pec, [Validators.required]],
        sede_legale_indirizzo: [this.agente.sede_legale_indirizzo, [Validators.required]],
      })
    });
  }

  getClienti(): void {
    this.clientiService.getClienti()
    .subscribe(resp => {
      this.clienti = resp.data;
    })
  }

  getClientiAgente(): void {
    this.agentiService.getClientiBrandAgente(this.id_agente)
    .subscribe(resp => {
      this.agente.clienti_brand = resp.data;
      this.clienti_brand = resp.data;
      for (let cliente of this.clienti_brand) {
        cliente.data_inizio = cliente.data_inizio.substring(0, cliente.data_inizio.indexOf('T'))
        cliente.data_fine = cliente.data_fine.substring(0, cliente.data_fine.indexOf('T'))
      }
      this.loadBrands();
    })
  }

  selectBrand(event: Event): void {
    this.brand_selezionato = this.agente.brand_agente.find((item: { id_brand: string; }) => item.id_brand == (event.target! as HTMLSelectElement).value);
  }

  selectCliente(id_cliente: string): void {
    this.cliente_selezionato = this.agente.clienti_brand.find((item: { id: string; }) => item.id == id_cliente);
    // @ts-expect-error
    setTimeout(() => {$('#clienteModal').modal('show'),1000});
  }

  loadBrands(): void {
    for (let cliente of this.agente.clienti_brand) {
      this.brands_filter.push(cliente.brand);
    }
    this.brands_filter = [...new Set(this.brands_filter)];
    setTimeout(this.loadFilters, 1000);
  }

  loadFilters(): void {
    const filtri = document.querySelectorAll('#filters select');
    filtri.forEach(filtro => {
      // @ts-expect-error
      $('#'+filtro.id).selectpicker({
        noneSelectedText: filtro.id
      })
    });
  }

  filter(): void {
    this.clienti_brand = this.agente.clienti_brand;
    console.log(this.clienti_brand)
    const form: FormData = new FormData(document.querySelector('#filters') as HTMLFormElement);
    let dati: { [key: string]: Array<string> } = {};
    for (let entry of form.keys()) {
      console.log(entry);
      dati[entry] = form.getAll(entry) as Array<string>;
    }
    console.log(dati);
    this.clienti_brand = this.clienti_brand.filter((cliente) =>
    { return Object.entries(dati).every(([key,arrayValue]) =>
    { return arrayValue.some((value) =>
    { return key.split('.').reduce((o,i)=> o[i], cliente).toString().toLowerCase().includes(value.toString().toLowerCase())})})});
  }

  deselectAll(): void {
    // @ts-expect-error
    $('#filters select option').attr("selected",false);
    // @ts-expect-error
    $('#filters select').val('').trigger('change');
    // @ts-expect-error
    $('#filters select').selectpicker('refresh');
    document.querySelectorAll('#filters input').forEach(input => (input as HTMLInputElement).value = '');
    this.clienti_brand = this.agente.clienti_brand;
  }

  update(): void {
    const agente = {
      nome: this.fagente.nome.value,
      cognome: this.fagente.cognome.value,
      nome_showroom: this.fagente.nome_showroom.value,
      indirizzo_showroom: this.fagente.indirizzo_showroom.value,
      ragione_sociale: this.fagente.ragione_sociale.value,
      partita_iva: this.fagente.partita_iva.value,
      codice_fiscale: this.fagente.codice_fiscale.value,
      telefono: this.fagente.telefono.value,
      email: this.fagente.email.value,
      pec: this.fagente.pec.value,
      sede_legale_indirizzo: this.fagente.sede_legale_indirizzo.value,
    }
    this.agentiService.updateAgente(this.id_agente, agente)
    .subscribe(resp => {
      if (resp.status = 200) {
        this.getAgente();
        // @ts-expect-error
        $('#anagraficaModal').modal('hide');
      }
    },
    (error) => {
      console.log(error);
      alert('Errore nella modifica dell\'agente: ' + error.toString())
    });
  }

  updateBrand(): void {
    const form: FormData = new FormData(document.querySelector('#brand_agente') as HTMLFormElement);
    let dati: { [key: string]: any } = {
      provvigione: Number(form.get('provvigione')),
      area_competenza: form.get('area_competenza'),
    };
    const tmp_brand = this.brand_selezionato;
    this.agentiService.updateAgenteBrand(this.id_agente, this.brand_selezionato.id_brand, dati)
    .subscribe(resp => {
      this.getAgente();
      // @ts-expect-error
      $('#provvigioneModal').modal('hide');
    },
    (error) => {
      console.log(error);
      alert('Errore nella modifica della provvigione: ' + error.toString())
    });
  }

  addBrand(): void {
    const form: FormData = new FormData(document.querySelector('#brand_agente_add') as HTMLFormElement);
    let dati: { [key: string]: any } = {
      id_agente: this.id_agente,
      provvigione: Number(form.get('provvigione')),
      area_competenza: form.get('area_competenza'),
    };
    this.agentiService.createAgenteBrand(Number(form.get('id_brand')), dati)
    .subscribe(resp => {
      this.getAgente();
      // @ts-expect-error
      $('#addBrandModal').modal('hide');
    },
    (error) => {
      console.log(error);
      alert('Errore: ' + error.toString())
    });
  }

  deleteBrand(): void {
    if (confirm('Vuoi rimuovere il brand dall\'agente?')) {
      this.agentiService.deleteAgenteBrand(this.id_agente, this.brand_selezionato.id_brand)
      .subscribe(resp => {
        if (resp.status = 200) {
          this.getAgente();
        }
      },
      (error) => {
        console.log(error);
        alert(`Errore nella cancellazione dell'agente: ${error.toString()}`)
      });
    }
  }

  updateCliente(): void {
    const form: FormData = new FormData(document.querySelector('#cliente_agente') as HTMLFormElement);
    let dati: { [key: string]: any } = {
      provvigione: Number(form.get('provvigione')),
      data_inizio: form.get('data_inizio')+'T00:00:00.000Z',
      data_fine: form.get('data_fine')+'T23:59:59.000Z',
    };
    this.agentiService.updateAgenteCliente(this.cliente_selezionato.id, dati)
    .subscribe(resp => {
      this.getClientiAgente();
      // @ts-expect-error
      $('#clienteModal').modal('hide');
    },
    (error) => {
      console.log(error);
      alert(`Errore nella modifica del cliente: ${error.toString()}`)
    });
  }

  addCliente(): void {
    const form: FormData = new FormData(document.querySelector('#cliente_agente_add') as HTMLFormElement);
    let dati: { [key: string]: any } = {
      id_cliente: Number(form.get('id_cliente')),
      id_brand: Number(form.get('id_brand')),
      provvigione: Number(form.get('provvigione')),
      data_inizio: form.get('data_inizio')+'T00:00:00.000Z',
      data_fine: form.get('data_fine')+'T23:59:59.000Z',
    };
    console.log(dati);
    this.agentiService.createAgenteCliente(this.id_agente, dati)
    .subscribe(resp => {
      this.getClientiAgente();
      // @ts-expect-error
      $('#addClienteModal').modal('hide');
    },
    (error) => {
      console.log(error);
      alert(`Errore nell'associazione del cliente:` + error.toString())
    });
  }

  deleteCliente(id_cliente: string): void {
    if (confirm('Vuoi rimuovere il cliente dall\'agente?')) {
      this.agentiService.deleteAgenteCliente(id_cliente)
      .subscribe(resp => {
        if (resp.status = 200) {
          this.getClientiAgente();
        }
      },
      (error) => {
        console.log(error);
        alert(`Errore nella rimozione del cliente: ${error.toString()}`)
      });
    }
  }
}

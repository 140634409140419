import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from "environments/environment";
@Injectable({
  providedIn: 'root'
})
export class BrandService {
  api: string = environment.api;
  constructor(private http: HttpClient) { }

  getListByNegozio(searchText : any) : Promise<any[]>{
    if(searchText != null)
      return this.http.get<any[]>(`${this.api}/brand/elenco?nome=${searchText}`).toPromise();
    else
      return this.http.get<any[]>(`${this.api}/brand/elenco`).toPromise();
  }

  getByIdOrganizzazione(id_organizzazione: number) : Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/brand/elenco/${id_organizzazione}`);
  }

  createBrand(brand: any): Observable<any> {
    return this.http.post<any>(this.api + "/brand/aggiungi", brand);
  }

  updateBrand(brand: any): Observable<any> {
    return this.http.post<any>(this.api + "/brand/modifica", brand);
  }

  updateSocialBrand(brand: any): Observable<any> {
    return this.http.post<any>(this.api + "/brand/modifica/social", brand);
  }

  deleteBrand(obj: any): Observable<any> {
    return this.http.post<any>(this.api + "/brand/rimuovi-autorizzazione", obj);
  }

  requestReqBrand(obj: any): Observable<any> {
    return this.http.post<any>(this.api + "/brand/richiedi-autorizzazione", obj);
  }

  acceptReqBrand(obj: any): Observable<any> {
    return this.http.post<any>(this.api + "/brand/accetta-richiesta", obj);
  }

  refuseReqBrand(obj: any): Observable<any> {
    return this.http.post<any>(this.api + "/brand/rifiuta-richiesta", obj);
  }

}

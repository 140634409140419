<div class="{{roleUserLogged == 'RETAILER' ? 'classe-retailer' : 'classe-brand' }}">
  <div class="prod-mag">
    <!-- DATI ARTICOLO PARENT -->
    <div class="first-level">
      <p>
        <span
          class="giac {{roleUserLogged == 'RETAILER' ? 'classe-retailer' : 'classe-brand' }}">TOT: {{item['articolo'].quantita}}
          - {{item['articolo'].nome}} </span>
      </p>
      <div class="info-prod {{roleUserLogged == 'RETAILER' ? 'classe-retailer' : 'classe-brand' }}">
        <span class="type"><i class="fas fa-heart"></i> {{item['brand_nome']}}</span>
        <span class="type"><i class="fas fa-barcode"></i>  {{item['articolo'].codice_articolo}}</span>
        <span class="type"><i class="fas fa-cloud-sun"></i>  {{item['stagione']}}</span>
        <span class="type"><i class="fas fa-bookmark"></i> {{item['collezione']}}</span>
        <span class="type"><img src="/img/settore.png" width="15" alt=""/> {{item['settore']}} </span>
        <span class="type"><img src="/img/categoria.png" width="15" alt=""/>{{item['categorie']}}</span>
      </div>
    </div>

    <!-- DATI TESSUTI -->
    <ng-container *ngFor="let tess of item['tessuti']; ">
      <div class="second-level">
        <div class="col-md-2 pl-0">
          <div class="row">
            <div class="col-md-12">
              <div class="box-level">
                <h2 class="giac {{roleUserLogged == 'RETAILER' ? 'classe-retailer' : 'classe-brand' }}">
                  TOT: {{ tess['quantita-tessuto'] != null ? tess['quantita-tessuto'] : '0'}}
                </h2>
                <p class="mt-3"><i
                  class="fas fa-tshirt {{roleUserLogged == 'RETAILER' ? 'classe-retailer' : 'classe-brand' }}"></i>
                  {{ tess['tessuto-codice'] }}
                  <br>
                  - {{ tess['tessuto-descrizione'] != null ? tess['tessuto-descrizione'] : 'N/D'}}
                </p>
                <p>

                </p>
              </div>
            </div>
          </div>
        </div>


        <div class="col-md-10">
          <ng-container *ngFor="let color of tess['colori']; ">
            <div class="row contenitore-var">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-7">
                    <div class="box-level">
                      <ng-container *ngIf="item['media_path'] != null && item['media_path'] != ''">
                        <a href="{{item['media_path']}}"
                           data-title="">
                          <img src="{{item['media_path']}}">
                        </a>
                      </ng-container>
                      <ng-container *ngIf="item['media_path'] == null || item['media_path'] == ''">
                        <img src="/img/team-4.jpg" width="100%"/>
                      </ng-container>
                      <div class="info-col">
                        <h2
                          class="giac float-left {{roleUserLogged == 'RETAILER' ? 'classe-retailer' : 'classe-brand' }}">
                          TOT: {{ color['quantita-tessuto-colore'] != null ? color['quantita-tessuto-colore'] : '0'}}</h2>

                        <a class="magg-dett text-white classe-retailer"
                           href="{{'#collapsable' + item['articolo'].id + '-' + color['id_colore']}}"
                           data-toggle="collapse"
                           aria-expanded="false"
                           aria-controls="collapsable"
                        >
                          Espandi <i class="fi fi-rr-angle-small-down"></i></a>

                        <!-- COLORE -->
                        <p class="colore">
                          <i
                            class="fas fa-palette {{roleUserLogged == 'RETAILER' ? 'classe-retailer' : 'classe-brand' }}"></i>
                          {{ color['colore-codice'] != null ? color['colore-codice'] : 'N/D'}}
                          {{ color['colore-descrizione'] != null ? color['colore-descrizione'] : 'N/D'}}
                        </p>

                        <!-- PREZZO -->
                        <p class="tooltipp colore">
                          <i class="fas fa-wallet classe-retailer"></i>
                          <span>
                                                              Brand:
                            {{ item['articolo'].prezzo_vendita_ingrosso != null && item['articolo'].prezzo_vendita_ingrosso != undefined ? item['articolo'].prezzo_vendita_ingrosso.toFixed(2) : '0'}}
                            €
                                                            </span>
                          <span>
                                                              - Retailer:
                                                              MANCA PREZZO RETAILER
                                                            </span>
                        </p>

                      </div>
                    </div>
                  </div>
                  <div class="col-md-5">
                    <div class="bottoni-dettagli">
                      <button class="btn btn-left" data-toggle="modal"
                              data-target="#modale-social-negozio" (click)="loadDataItemSelected(item)">
                        Dettagli
                      </button>
                      <button class="btn btn-primary" type="button">
                        Acquista
                      </button>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="collapse"
                         id="{{'collapsable' + item['articolo'].id + '-' + color['id_colore']}}">
                      <div class="table-responsive">
                        <table
                          class="table table-hover table-mc-light-blue"
                          style="background-color: rgb(0 0 0 / 9%);">
                          <thead>
                          <tr>
                            <th scope="row"
                                style="width: 20%;">Taglia
                            </th>
                            <th scope="col">
                              {{ item['articolo'].taglia}}
                            </th>
                          </tr>
                          </thead>
                          <tbody>

                          <!-- QUANTITÀ BRAND -->
                          <tr>
                            <td scope="row">Q.TA Brand</td>
                            <td scope="col">
                              <div>
                                {{ item['articolo'].quantita != null ? item['articolo'].quantita : '0'}}
                              </div>

                            </td>
                          </tr>

                          <!-- QUANTITÀ R2W -->
                          <tr>
                            <td scope="row">Q.TA R2W</td>
                            <td scope="col">
                              <div>
                                {{ item['articolo'].quantita_condivisa != null ? item['articolo'].quantita_condivisa : '0'}}
                              </div>

                            </td>
                          </tr>


                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                </div>
              </div>


            </div>

          </ng-container>
        </div>

      </div>

    </ng-container>


    <!-- MODAL SIDEBAR DETT ARTICOLO -->
    <div id="dett-articolo" class="sidebar" style="width: 60%;" *ngIf="sidebarVisible == true">
      <a href="javascript:void(0)" class="closebtn" (click)="closeModalSidebar()">×</a>
      <div class="wrapper row dett-art-mod">
        <div class="col-md-12">
          <h2>{{itemSelected['articolo'].nome}} - COD.: <b>{{itemSelected['articolo'].codice_articolo}}</b>
          </h2>
        </div>
        <div class="preview col-md-5">

          <!-- IMMAGINI -->

          <div class="main-img">
            <a href="{{itemSelected['media_path']}}" data-lightbox="image-1" data-title="">
              <img id="img-1469" src="{{itemSelected['media_path']}}">
            </a>
          </div>
          <div class="imgs" style="grid-template-columns:unset">
          </div>
          <!-- END IMMAGINI -->

        </div>


        <div class="details col-md-7 classe-retailer">
          <p><b>Brand:</b> {{itemSelected['brand_nome']}}</p>
          <p><b>Codice Articolo:</b> {{itemSelected['articolo'].codice_articolo}}</p>
          <p><b>Nome:</b> {{itemSelected['articolo'].nome}}</p>
          <p><b>Stagione:</b> {{itemSelected['stagione']}}</p>
          <p><b>Collezione:</b> {{itemSelected['collezione']}}</p>
          <p><b>Settore:</b> {{itemSelected['settore']}}</p>
          <p><b>Categoria:</b> {{itemSelected['categorie']}}</p>
          <p><b>Scala Taglie:</b> MANCA SCALA </p>
          <hr>
          <p><b>Variante Tessuto: </b> MANCA VARIANTE TESSUTO</p>
          <p><b>Variante Colore:</b>MANCA VARIANTE COLORE</p>
        </div>
        <div class="table-responsive">
          <table class="table table-striped tabella-brand table-elenco-r qta-t classe-retailer"
                 style="background-color: rgb(0 0 0 / 9%);">
            <!-- TAGLIE -->
            <thead>
            <tr>
              <th scope="row" style="width: 20%;">Taglia</th>
              <th>{{itemSelected['articolo'].taglia}}</th>

            </tr>
            </thead>
            <tbody>

            <!-- QUANTITÀ BRAND -->
            <tr>
              <td scope="row">Q.TA Brand</td>
              <td>
                {{itemSelected['articolo'].quantita}}
              </td>

            </tr>

            <!-- QUANTITÀ R2W -->
            <tr>
              <td scope="row">Q.TA R2W</td>
              <td>
                {{itemSelected['articolo'].quantita_condivisa}}
              </td>

            </tr>

            <!-- QTA MAGAZZINO RETAILER -->
            <tr class="ret-magazzino">
              <td scope="row">Q.TA Magazzino</td>
              <td>
                MANCA
              </td>

            </tr>

            </tbody>
          </table>
        </div>
        <button class="btn btn-primary" style="margin:0 auto; width: max-content" type="button">Acquista</button>
      </div>
    </div>

  </div>
</div>

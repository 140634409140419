import { Component, OnInit } from '@angular/core';
import { Retailer } from 'app/models/retailer';
import { BrandService } from 'app/services/brand.service';
import { RetailerService } from 'app/services/retailer.service';

@Component({
  selector: 'app-list-retailer-brand',
  templateUrl: './list-retailer-brand.component.html',
  styleUrls: ['./list-retailer-brand.component.sass']
})
export class ListRetailerBrandComponent implements OnInit {

  public listShopWaiting? : any[] = [];
  public listShopAuthorized? : any[] = [];
  public listShop? : any[] = [];
  public objModal : any = {};
  public textAreaRifiuto:string = "";
  public modalIsVisible :boolean = false;
  public searchText :string = "";

  constructor(private retailerService : RetailerService, private brandService : BrandService) { this.modalIsVisible = true;}

  loadList(){
    this.retailerService.getList().then((resp: any) => {
      console.log(resp);
      this.listShopAuthorized = resp['data'].retailer_autorizzati != undefined ? resp['data'].retailer_autorizzati : [];
      this.listShopWaiting = resp['data'].retailer_in_attesa != undefined ? resp['data'].retailer_in_attesa : [];
      this.listShop = resp['data'].negozi_paginate.data != undefined ? resp['data'].negozi_paginate.data : [];
      this.modalIsVisible = false;
      console.log('In attesa', this.listShopWaiting)
      console.log('In autorizzati', this.listShopAuthorized)
      console.log('Lista negozi', this.listShop)
    });
  }
  ngOnInit(): void {
    this.loadList()
  }


  removeAuth(){
    const dati = {
      id_richiesta: this.objModal.id,
      motivo_rifiuto: this.textAreaRifiuto,
    }
    this.brandService.refuseReqBrand(dati).subscribe(resp => {
      console.log(resp);
    }, (error) => {
      console.log(error);
      this.loadList();
      // @ts-expect-error
      $('#modale-rimuovi-autorizzazione').modal('hide');
    }, () => {
      this.loadList();
      // @ts-expect-error
      $('#modale-rimuovi-autorizzazione').modal('hide');
    });
  }


  setItem(elem:any){
    this.objModal.id = elem.id;
    this.objModal.negozio = elem.negozio!.nome;
    this.objModal.brand = elem.brand!.nome;
    // @ts-expect-error
    $('#modale-rimuovi-autorizzazione').modal('show');
  }

  search(){
    this.modalIsVisible = true;
    this.listShop = []
    this.retailerService.getListNegozi(this.searchText).then((resp: any) => {
      this.listShop = resp['data'].negozi_paginate.data != undefined ? resp['data'].negozi_paginate.data : [];
      this.modalIsVisible = false;
    });
  }

  reset(){
    this.searchText = ""
    this.listShop = []
    this.loadList()
  }

  getShopWaiting(shops: any[]) {
    for (let shop of shops) {
      const autorizzazione = shop.autorizzazioni.find((item: { id: any; }) => item.id == shop.id)
      shop.negozio = autorizzazione.negozio;
      shop.brand = autorizzazione.brand;
      this.listShopWaiting?.push(shop);
    }
  }

  getShopAuthorized(shops: any[]) {
    for (let shop of shops) {
      const autorizzazione = shop.autorizzazioni.find((item: { id: any; }) => item.id == shop.id)
      shop.negozio = autorizzazione.negozio;
      shop.brand = autorizzazione.brand;
      this.listShopAuthorized?.push(shop);
    }
  }

  acceptRequest(item:any) {
    const dati = {
      id_richiesta: item.id,
    }
    this.brandService.acceptReqBrand(dati).subscribe(resp => {
      console.log(resp);
    }, (error) => {
      console.log(error);
      this.loadList();
      // @ts-expect-error
      $('#modale-rimuovi-autorizzazione').modal('hide');
    }, () => {
      this.loadList();
      // @ts-expect-error
      $('#modale-rimuovi-autorizzazione').modal('hide');
    });
  }
}

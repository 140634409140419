import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/auth/auth.service';
import { Article } from 'app/models/article';
import { ArticoloService } from 'app/services/articolo.service';
import { BrandService } from 'app/services/brand.service';
import { UtilitiesService } from 'app/services/utilities.service';

@Component({
  selector: 'app-store-general',
  templateUrl: './store-general.component.html',
  styleUrls: ['./store-general.component.sass']
})
export class StoreGeneralComponent implements OnInit {

  public listArticles : any[] = [];
  public listBrands : any[] = [];

  public roleUserLogged :string |undefined;
  public modalIsVisible :boolean = false;
  public isVisibleModalSidebar :boolean = false;

  public brand_for_search : any[] = [];
  public stagioni_for_search : any[] = [];
  public collezioni_for_search : any[] = [];
  public taglie_for_search : any[] = [];
  public taglie_descrizioni_for_search : any[] = [];
  public tessuti_for_search : any[] = [];
  public colori_for_search : any[] = [];
  public settori_for_search : any[] = [];


  //categorie
  public macrocategorie_for_search : any[] = [];
  public categorie_for_search : any[] = [];
  public sottocategorie_for_search : any[] = [];

  public filter: any = {}

  // aggiungi articolo retailer
  public add_filters: any = {};
  public taglie: any[] = [];
  public add_articoli: any[] = [];


  //campi per filtri
  public sizeStr: string = ""
  public sectorStr: string = ""
  public macroStr: string = ""
  public categoriaStr: string = ""

  constructor(private articoloService : ArticoloService, private auth: AuthService, private brandService : BrandService, private utilitiesService : UtilitiesService) {
    this.modalIsVisible = true;
    this.isVisibleModalSidebar = false;
   }


   loadData(objFilter : any){

    this.utilitiesService.getListMacroCategoriesBySector("settori=").then((resp: any) => {
      this.macrocategorie_for_search = resp['data'].macrocategorie
    });

    this.utilitiesService.getListCategoriesByMacro("id_macrocategoria=").then((resp: any) => {
      this.categorie_for_search = resp['data'].macrocategorie
    });


    this.utilitiesService.getListSottoCategoriesByCategory("id_categoria=").then((resp: any) => {
      this.sottocategorie_for_search = resp['data'].macrocategorie
    });


    if(this.roleUserLogged == 'RETAILER'){
      this.articoloService.getList(objFilter).then((resp: any) => {
        var list = resp['data'].articoli.data
        this.listArticles = Object.values(list);
        this.modalIsVisible = false;

        this.brand_for_search = resp['data'].brand_for_search
        this.stagioni_for_search = resp['data'].stagioni_for_search
        this.collezioni_for_search = resp['data'].collezioni_for_search
        this.taglie_for_search = resp['data'].taglie_for_search
        this.tessuti_for_search = resp['data'].tessuti_for_search
        this.colori_for_search = resp['data'].brand_for_search
        this.brand_for_search = resp['data'].settori_for_search

        console.log(list)
      });
    }
    else{
        this.articoloService.getListForBrand(objFilter).then((resp: any) => {
          var list = resp['data'].articoli.data
          this.listArticles = Object.values(list);
          this.modalIsVisible = false;
          this.brand_for_search = resp['data'].brand_for_search
          this.stagioni_for_search = resp['data'].stagioni_for_search
          this.collezioni_for_search = resp['data'].collezioni_for_search
          this.taglie_for_search = resp['data'].taglie_for_search
          this.tessuti_for_search = resp['data'].tessuti_for_search
          this.colori_for_search = resp['data'].brand_for_search
          this.brand_for_search = resp['data'].settori_for_search

          console.log(list)
        });
    }
   }

  ngOnInit(): void {
    this.loadBrands();
    const user = this.auth.getCurrentUser();
    this.roleUserLogged = user?.tipo;
    this.loadData({});
  }

  loadBrands(){
    this.brandService.getListByNegozio(null).then((resp: any) => {
      var list = resp['data'].brand.data
      this.listBrands = list;
      this.modalIsVisible = false;
      console.log(list)
    });
  }

  closeNav(){
    this.isVisibleModalSidebar = false;
  }

  openNav(){
    this.isVisibleModalSidebar = true;
  }

  search(){
    this.filter.is_ajax = true
    this.filter.export = 0
    this.listArticles = [];
    this.loadData(this.filter);
  }

  resetForm(){
    this.filter = {}
    this.loadData({});
  }

  sizeChange(size:any){
    this.sizeStr = this.sizeStr == "" ? "id_taglia[]=" + size : this.sizeStr + "&id_taglia[]=" + size;
    this.utilitiesService.getListSizesDescBySize(this.sizeStr).then((resp: any) => {
      this.taglie_descrizioni_for_search = resp['data'].taglieDescrizioni
    });
  }

  sectorChange(sector:any){
    this.sectorStr = this.sectorStr == "" ? "settori[]=" + sector : this.sectorStr + "&settori[]=" + sector;
    this.utilitiesService.getListMacroCategoriesBySector(this.sectorStr).then((resp: any) => {
      this.macrocategorie_for_search = resp['data'].macrocategorie
    });
  }

  macroChange(macrocategoria:any){
    this.macroStr = this.macroStr == "" ? "id_macrocategoria=[]=" + macrocategoria : this.macroStr + "&id_macrocategoria=[]=" + macrocategoria;
    this.utilitiesService.getListCategoriesByMacro(this.macroStr).then((resp: any) => {
      this.categorie_for_search = resp['data'].macrocategorie
    });
  }


  categoryChange(categoria:any){
    this.categoriaStr = this.categoriaStr == "" ? "id_categoria==[]=" + categoria : this.categoriaStr + "&id_categoria==[]=" + categoria;
    this.utilitiesService.getListSottoCategoriesByCategory(this.categoriaStr).then((resp: any) => {
      this.sottocategorie_for_search = resp['data'].macrocategorie
    });
  }

  add_search() {
    this.add_articoli = [];
    this.articoloService.searchArticolo(this.add_filters)
    .subscribe(async resp => {
      const articoli = resp.data.articoli;
      const id_taglie = [...new Set(articoli.map((articolo: { id_taglia_tipologia: any; }) => articolo.id_taglia_tipologia))]
      const query_taglie = 'id_taglia[]='+id_taglie.join('&id_taglia[]=');
      const taglie_resp = await this.utilitiesService.getListSizesDescBySize(query_taglie);
      this.taglie = taglie_resp.data.taglieDescrizioni;
      console.log(this.taglie);
      articoli.forEach((articolo: any) => {
        articolo.taglie_quantita = this.filterTaglie(articolo);
        articolo.prezzo = articolo.prezzo_vendita_ingrosso;
        this.add_articoli.push(articolo);
      });
      console.log(this.add_articoli)
    })
  }

  filterTaglie(articolo: any) {
    const taglie = this.taglie.filter(taglia => taglia.id_taglia_tipologia == articolo.id_taglia_tipologia && taglia.nome != "");
    const taglie_r: any[] = [];
    taglie.forEach(taglia => {
      let tagliatmp = taglia;
      taglia.id_articolo_variante = articolo.id_articolo_variante;
      taglia.quantita = 0;
      taglie_r.push(tagliatmp);
    });
    return taglie_r;
  }

  add(articolo: any) {
    console.log(articolo);
    this.articoloService.addArticoloRetailer(articolo)
    .subscribe(resp => {
      console.log(resp);
      this.closeNav();
    },
    (error) => {
      alert(error);
    })
  }

}

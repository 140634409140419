<section>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-colored">
        <h1 class="text-uppercase text-white border-bottom-ab">Lista preordini</h1>
        <a class="btn agg-mag" routerLink="/preordini/nuovo">Aggiungi</a>
        <div class="button-mag">
          <form id="filters" method="get" (submit)="filter()">
            <div class="row">
              <div class="col-md-4 pr-0">
                <select id="brand" multiple="multiple" name="brand.id" data-live-search="true" class="form-control select2-brand" tabindex="null">
                  <option value="23">Chiara Ferragni Collection</option>
                  <option value="24">ACBC</option>
                  <option value="25">Alexander Hotto</option>
                  </select>
                </div>
                <div class="col-md-4 pr-0">
                  <select id="stagioni" class="form-control select2-stagioni" multiple="multiple" name="stagione.id" data-live-search="true">
                    <option value="16">FW21-22</option>
                    <option value="17">PE22</option>
                    <option value="18">SS21</option>
                  </select>
                </div>
                <div class="col-md-4 pr-0">
                  <select id="stato" class="form-control select2-stagioni" multiple="multiple" name="status" data-live-search="true">
                    <option value="NUOVO">NUOVO</option>
                    <option value="CONFERMATO">CONFERMATO</option>
                    <option value="RIFIUTATO">RIFIUTATO</option>
                    <option value="SPEDITO">SPEDITO</option>
                  </select>
                </div>
                <div class="col-md-4 pr-0">
                  <input class="form-control" type="text" name="agente.ragione_sociale" value="" placeholder="Agente">
                </div>
                <div class="col-md-4 pr-0">
                  <input class="form-control" type="text" name="retailer.nome" value="" placeholder="Cliente (rag. sociale)">
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="button-flex">
                    <button title="" type="button" class="btn btn1">
                      <i class="fas fa-file-excel"></i> Export
                    </button>
                    <button type="button" title="" class="btn btn2" (click)="deselectAll()">
                      <i class="fas fa-times"></i> Resetta
                    </button>
                    <button type="submit" title="" class="btn btn3" (click)="filter()">
                      <i class="fas fa-search"></i> Cerca
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="card">
        <table class="table table-hover table-mc-light-blue">
          <thead>
          <tr>
            <th scope="col" (click)="order($event)" data-order="numero" data-by="">Numero</th>
            <th scope="col" (click)="order($event)" data-order="data_ordine" data-by="">Data</th>
            <th scope="col" (click)="order($event)" data-order="brand.nome" data-by="">Brand</th>
            <th scope="col" (click)="order($event)" data-order="stagione.nome" data-by="">Stagione</th>
            <th scope="col" (click)="order($event)" data-order="retailer.nome" data-by="">Cliente</th>
            <th scope="col" (click)="order($event)" data-order="agente.ragione_sociale" data-by="">Agente</th>
            <th scope="col" (click)="order($event)" data-order="totale_prodotti" data-by="">Tot. articoli</th>
            <th scope="col" (click)="order($event)" data-order="totale_valore" data-by="">Valore totale</th>
            <th scope="col" (click)="order($event)" data-order="status" data-by="">Stato</th>
            <th scope="col">Dettaglio</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let preordine of preordini">
            <td>{{preordine.numero}}</td>
            <td>{{preordine.data_ordine}}</td>
            <td>{{preordine.brand.nome}}</td>
            <td>{{preordine.stagione.nome}}</td>
            <td>{{preordine.retailer.nome}}</td>
            <td>{{preordine.agente.ragione_sociale}}</td>
            <td>{{preordine.totale_prodotti}}</td>
            <td>{{preordine.totale_valore.toFixed(2)}} €</td>
            <td>{{preordine.status}}</td>
            <td><a routerLink="/preordini/{{preordine.id}}" class="btn btn1">Dettaglio</a></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>


</section>
